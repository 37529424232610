import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    fetchApplicantAnalytics,
    applicantAnalytics,
} from "../features/metabaseSlice";
import {
    getApplicantAnalyticLocalCache,
} from "../cache/localStorage";

const MetabaseAnalyticsApplicant = () => {
    const [url, setUrl] = useState("");
    const dispatch = useDispatch();
    const applicantAnalytic = useSelector(applicantAnalytics);
    useEffect(() => {
        const getData = async () => {
            const appUrl = getApplicantAnalyticLocalCache();
            if (!appUrl) {
                dispatch(fetchApplicantAnalytics());
                setUrl(applicantAnalytic);
            } else {
                setUrl(appUrl);
            }
        }
        getData()
    }, [
        dispatch,
        applicantAnalytic,
        url, setUrl
    ]);

    return (
        <div style={{ width: "98%", maxWidth: "80vw" }} >
            <iframe
                loading="eager"
                src={url}
                style={{
                    width: "100%",
                    height: "100%",
                    minHeight: "80vh",
                    border: "2px solid #4C577370"
                }}
                title="Applicants Analytics"
            />
        </div>
    );
};

export default MetabaseAnalyticsApplicant;
