import React, { useEffect, useState } from "react";
import { message, Switch, Table, Input } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";

const GetAlluserChats = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoader] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [id, setId] = useState(null);

    const handleChange = async (checked, userData) => {
        setisLoading(true);
        setId(userData.userID);
        try {
            const payload = {
                username: userData?.name,
                email: userData?.email,
                id: userData?.userID,
                Firstname: userData?.name?.split(" ")[0],
                Lastname: userData?.name?.split(" ")[1],
                type: userData?.type,
                role: (userData?.type === "partner" || userData?.type === "partnerAdmin") ? "guest"
                    :
                    (userData?.type === "superAdmin") ? "admin" : "user"
            };
            const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/chat/activateOrDeactivateUser`, {
                data: payload,
                status: checked
            }, { withCredentials: true });
            
            if (res.status === 200) {
                if(checked){
                    message.success(`${userData?.name} is added to Chat`);
                }else{
                    message.info(`${userData?.name} is now removed from Chat`);
                }
                setData(prevData => 
                    prevData.map(user => 
                        user.userID === userData.userID 
                            ? {...user, isAddedToChat: checked} 
                            : user
                    )
                );
                setFilteredData(prevData => 
                    prevData.map(user => 
                        user.userID === userData.userID 
                            ? {...user, isAddedToChat: checked} 
                            : user
                    )
                );
            } else {
                throw new Error("Response status is not 200");
            }
        } catch (error) {
            message.error("Some error occurred");
            console.log(error);
            setData(prevData => 
                prevData.map(user => 
                    user.userID === userData.userID 
                        ? {...user, isAddedToChat: !checked} 
                        : user
                )
            );
            setFilteredData(prevData => 
                prevData.map(user => 
                    user.userID === userData.userID 
                        ? {...user, isAddedToChat: !checked} 
                        : user
                )
            );
        }
        setisLoading(false);
    };

    const columns = [
        {
            title: 'User Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Role',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'companyName',
            render: (_, record) => (
                <p>{record?.companyName || "N/A"}</p>
            ),
        },
        {
            title: 'EnableChat',
            dataIndex: 'EnableChat',
            render: (_, record) => (
                <Switch 
                    checked={record.isAddedToChat} 
                    loading={id === record?.userID && isLoading} 
                    onChange={(checked) => handleChange(checked, record)} 
                />
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Link to={`/seeChat/${record?.name?.split(" ").join("_")}`}
                    state={{ useer: record }}
                    style={{
                        padding: "10px 10px",
                        backgroundColor: "#6855f6",
                        color: "white",
                        borderRadius: "10px"
                    }}
                >
                    View Chat
                </Link>
            ),
        },
    ];

    const fetchUsers = async () => {
        try {
            setLoader(true);
            const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/chat/getAllusers`, { withCredentials: true });
            const sortedData = res.data.sort((a, b) => {
                const order = ["superAdmin", "zyvkaAdmin", "zyvkaUser", "partnerAdmin","partner"];
                return order.indexOf(a.type) - order.indexOf(b.type);
            });
            setData(sortedData);
            setFilteredData(sortedData);
            setLoader(false);
        } catch (error) {
            console.log(error);
            setLoader(false);
        }
    };

    const onSearch = (event) => {
        const value = event.target.value.toLowerCase();
        const filtered = data.filter(user => 
            (user.name ? user.name.toLowerCase().includes(value) : user.email.toLowerCase().includes(value))
        );
        setFilteredData(filtered);
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    if (loading) {
        return (
            <div
                style={{
                    display: "flex",
                    height: "80vh",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <ClipLoader color="#6855f6" />
            </div>
        );
    }

    return (
        <div style={{ marginTop: "40px" }}>
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "20px" }}>
                <Input
                    placeholder="Search User"
                    onChange={onSearch}
                    style={{ width: 300 }}
                />
            </div>
            <Table 
                scroll={{ x: true, y: true }} 
                columns={columns} 
                dataSource={filteredData} 
                bordered 
            />
        </div>
    );
};

export default React.memo(GetAlluserChats);


// import React, { useEffect, useState } from "react";
// import { message, Switch, Table, Button, Checkbox } from "antd";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import { ClipLoader } from "react-spinners";

// const GetAlluserChats = () => {
//     const [data, setData] = useState([]);
//     const [loading, setLoader] = useState(false);
//     const [isLoading, setIsLoading] = useState(false);
//     const [id, setId] = useState(null);
//     const [multiSelectMode, setMultiSelectMode] = useState(false);
//     const [selectedUsers, setSelectedUsers] = useState([]);
//     const [addLoading, setAddLoading] = useState(false);
//     const [removeLoading, setRemoveLoading] = useState(false);

//     const handleSingleChange = async (checked, userData) => {
//         setIsLoading(true);
//         setId(userData.userID);
//         try {
//             const payload = {
//                 username: userData?.username,
//                 email: userData?.email,
//                 id: userData?.userID,
//                 Firstname: userData?.name?.split(" ")[0],
//                 Lastname: userData?.name?.split(" ")[1],
//                 type: userData?.type,
//                 role: (userData?.type == "partner" || userData?.type == "partnerAdmin") ? "guest"
//                     :
//                     (userData?.type == "superAdmin") ? "admin" : "user"
//             }
//             const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/chat/activateOrDeactivateUser`, {
//                 data: payload,
//                 status: checked
//             }, { withCredentials: true });
            
//             if (res.status === 200) {
//                 if(checked){
//                     message.success(`${userData?.name} is added to Chat`);
//                 } else {
//                     message.info(`${userData?.name} is now removed from Chat`);
//                 }
//                 setData(prevData => 
//                     prevData.map(user => 
//                         user.userID === userData.userID 
//                             ? {...user, isAddedToChat: checked} 
//                             : user
//                     )
//                 );
//             } else {
//                 throw new Error("Response status is not 200");
//             }
//         } catch (error) {
//             message.error("Some error Occurred");
//             console.log(error);
//             setData(prevData => 
//                 prevData.map(user => 
//                     user.userID === userData.userID 
//                         ? {...user, isAddedToChat: !checked} 
//                         : user
//                 )
//             );
//         }
//         setIsLoading(false);
//     };

//     const handleMultiChange = async (status) => {
//         if (status) {
//             setAddLoading(true);
//         } else {
//             setRemoveLoading(true);
//         }

//         try {
//             const usersToUpdate = selectedUsers.filter(userId => {
//                 const user = data.find(u => u.userID === userId);
//                 return user.isAddedToChat !== status;
//             });

//             if (usersToUpdate.length === 0) {
//                 message.info(`All selected users are already ${status ? 'added to' : 'removed from'} Chat`);
//                 setAddLoading(false);
//                 setRemoveLoading(false);
//                 return;
//             }

//             const promises = usersToUpdate.map(userId => {
//                 const userData = data.find(user => user.userID === userId);
//                 const payload = {
//                     username: userData?.username,
//                     email: userData?.email,
//                     id: userData?.userID,
//                     Firstname: userData?.name?.split(" ")[0],
//                     Lastname: userData?.name?.split(" ")[1],
//                     type: userData?.type,
//                     role: (userData?.type == "partner" || userData?.type == "partnerAdmin") ? "guest"
//                         :
//                         (userData?.type == "superAdmin") ? "admin" : "user"
//                 };
//                 return axios.post(`${process.env.REACT_APP_SERVER_URL}/api/chat/activateOrDeactivateUser`, {
//                     data: payload,
//                     status: status
//                 }, { withCredentials: true });
//             });

//             await Promise.all(promises);

//             setData(prevData => 
//                 prevData.map(user => 
//                     usersToUpdate.includes(user.userID)
//                         ? {...user, isAddedToChat: status} 
//                         : user
//                 )
//             );

//             message.success(`${usersToUpdate.length} user(s) have been ${status ? 'added to' : 'removed from'} Chat`);
            
//             if (usersToUpdate.length !== selectedUsers.length) {
//                 message.info(`${selectedUsers.length - usersToUpdate.length} user(s) were already ${status ? 'added to' : 'removed from'} Chat`);
//             }
//         } catch (error) {
//             message.error("Some error Occurred");
//             console.log(error);
//         }
//         setAddLoading(false);
//         setRemoveLoading(false);
//         setSelectedUsers([]);
//         setMultiSelectMode(false);
//     };

//     const toggleMultiSelectMode = () => {
//         setMultiSelectMode(!multiSelectMode);
//         setSelectedUsers([]);
//     };

//     const onSelectChange = (selectedRowKeys) => {
//         setSelectedUsers(selectedRowKeys);
//     };

//     const columns = [
//         {
//             title: 'User Name',
//             dataIndex: 'name',
//             key: 'name',
//             render: (text) => <a>{text}</a>,
//         },
//         {
//             title: 'Email',
//             dataIndex: 'email',
//             key: 'email',
//         },
//         {
//             title: 'Role',
//             dataIndex: 'type',
//             key: 'type',
//         },
//         {
//             title: 'EnableChat',
//             dataIndex: 'EnableChat',
//             render: (_, record) => (
//                 <Switch 
//                     checked={record.isAddedToChat} 
//                     loading={id == record?.userID && isLoading} 
//                     onChange={(checked) => handleSingleChange(checked, record)} 
//                     disabled={multiSelectMode}
//                 />
//             ),
//         },
//         {
//             title: 'Action',
//             key: 'action',
//             render: (_, record) => (
//                 <Link to={`/seeChat/${record?.username?.split(" ").join("_")}`}
//                     state={{ useer: record }}
//                     style={{
//                         padding: "10px 10px",
//                         backgroundColor: "#6855f6",
//                         color: "white",
//                         borderRadius: "10px"
//                     }}
//                 >
//                     View Chat
//                 </Link>
//             ),
//         },
//     ];

//     const fetchUsers = async () => {
//         try {
//             setLoader(true)
//             const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/chat/getAllusers`, { withCredentials: true })
//             setData(res?.data)
//             setLoader(false)
//         } catch (error) {
//             console.log(error);
//             setLoader(false)
//         }
//     }

//     useEffect(() => {
//         fetchUsers()
//     }, [])

//     if (loading) {
//         return (
//             <div
//                 style={{
//                     display: "flex",
//                     height: "80vh",
//                     justifyContent: "center",
//                     alignItems: "center"
//                 }}
//             >
//                 <ClipLoader color="#6855f6" />
//             </div>
//         );
//     }

//     const rowSelection = {
//         selectedRowKeys: selectedUsers,
//         onChange: onSelectChange,
//     };

//     return (
//         <div style={{ marginTop: "40px" }}>
//             <div style={{ marginBottom: "20px" }}>
//                 <Button onClick={toggleMultiSelectMode}>
//                     {multiSelectMode ? "Exit Multi-Select Mode" : "Enter Multi-Select Mode"}
//                 </Button>
//                 {multiSelectMode && (
//                     <>
//                         <Button 
//                             onClick={() => handleMultiChange(true)} 
//                             disabled={selectedUsers.length === 0 || addLoading}
//                             loading={addLoading}
//                             style={{ marginLeft: "10px" }}
//                         >
//                             Add Selected to Chat
//                         </Button>
//                         <Button 
//                             onClick={() => handleMultiChange(false)} 
//                             disabled={selectedUsers.length === 0 || removeLoading}
//                             loading={removeLoading}
//                             style={{ marginLeft: "10px" }}
//                         >
//                             Remove Selected from Chat
//                         </Button>
//                     </>
//                 )}
//             </div>
//             <Table 
//                 scroll={{ x: true, y: true }} 
//                 columns={columns} 
//                 dataSource={data} 
//                 bordered 
//                 rowSelection={multiSelectMode ? rowSelection : undefined}
//                 rowKey="userID"
//             />
//         </div>
//     );
// }

// export default React.memo(GetAlluserChats);