import React, { useState, useEffect } from "react";
import { Form, Card, Grid, Segment } from "semantic-ui-react";
import axios from "axios";
import TextArea from "antd/lib/input/TextArea";
import { UserState } from "../features/userSlice";
import { useSelector } from "react-redux";
const API = axios.create({ baseURL: process.env.REACT_APP_SERVER_URL });

const AddNewNotifications = () => {
    const mainOptions = [
        { key: 1, text: "All", value: "All" },
        { key: 2, text: "Zyvka Users", value: "zyvkaUsers" },
        { key: 3, text: "Partners", value: "partners" },
        { key: 4, text: "Select Ids from below options", value: "Others" }
    ];
    const userState = useSelector(UserState);
    //const allowedIds = ["clbdtpd3s0000oi4bfa591bvw", "clbdtpd3s0000oi4bfa591bvw", "clbkfjn6n000koiwy1n5xba0s", "cllggewh300pnoihhg91fjhqr", "clo5ip6gt000aoivua3s1etel", "clbdg75rq0000uof8uxff88sw", "cli2y8nke00z4oimr2u4rngu6"];
    const [loading, setLoading] = useState(false);
    const [idOptions, setIdOptions] = useState([]);

    const [formValues, setFormValues] = useState({
        notificationTitle: "",
        notificationMessage: "",
        recipientType: "",
        recipientId: [],

    });
    const sendNotification = async (user, data) => {
        await API.post(`api/notification/sendGroupNotification/${user}`, data, { withCredentials: true });
    }
    const sendNotificationtoAll = async (data) => {
        await API.post(`api/notification/sendGroupNotificationtoAll`, data, { withCredentials: true });
    }
    const sendNotificationToIds = async (data) => {
        await API.post(`api/notification/sendIndividualNotification`, data, { withCredentials: true });
    }
    const getIds = async () => {
        const res = await API.get('/api/zyvka/getAllUsers', { withCredentials: true });
        const rep = res?.data?.map((el, idx) => {
            return {
                type: el?.type,
                value: el?.userID,
                key: el?.id,
                email: el?.email,
                text: `${el?.userDetails?.firstName} ${el?.userDetails?.lastName} (${el?.userDetails?.email})`,
            }
        })
        setIdOptions(rep);
    }
    useEffect(() => {
        getIds();
    }, []);
    const onFinish = async (e) => {
        e.preventDefault();
        console.log("loading true");
        setLoading(true);
        try {
            if (userState?.role === 'zyvkaAdmin' || userState?.role === 'superAdmin') {
                if (formValues?.recipientType !== "Others") {
                    if (formValues?.recipientType === "All") {
                        await sendNotificationtoAll({ title: formValues?.notificationTitle, body: formValues?.notificationMessage })
                    } else {
                        await sendNotification(formValues?.recipientType, { title: formValues?.notificationTitle, body: formValues?.notificationMessage });
                    }
                } else {
                    await sendNotificationToIds({ title: formValues?.notificationTitle, ids: formValues?.recipientId, body: formValues?.notificationMessage })
                }
                alert("Notification Sent Successfully 📨 ");
            } else {
                alert("This Action Requires Superlative Privileges");
            }
        } catch (e) {
            alert("Some error Occurred while sending the Notifications ⛔ ");
            console.log(e);
        }
        setLoading(false);
        console.log("loading false");
    };
    return (
        <Segment>
            <br></br>
            <Grid stackable padded>
                <Grid.Column  >
                    <Card
                        headStyle={{ fontSize: "25px", fontWeight: "bold", }}
                        bordered={false}
                        style={{
                            width: "auto",
                            borderRadius: "10px",
                            Opacity: `${loading === true} ? " 0.6  " : " 1 " `,
                        }}
                    >
                        <Card.Content header="Publish New Notifications"></Card.Content>
                        <Card.Content>
                            <Form onSubmit={onFinish}>
                                <Form.Input
                                    label="Notification Title"
                                    name="notificationTitle"
                                    type="text"
                                    showCount
                                    value={formValues.notificationTitle}
                                    required={true}
                                    disabled={loading}
                                    maxLength={100}
                                    onChange={(e) => {
                                        setFormValues({
                                            ...formValues,
                                            [e.target.name]: e.target.value,
                                        });
                                    }}

                                    placeholder="This is the notification message, 250 character limit"
                                ></Form.Input>
                                <Form.Field style={{ fontWeight: 750 }} >Notification Message <span style={{ color: "red" }} >*</span> </Form.Field>
                                <TextArea rows={4} label="Notification Message"
                                    name="notificationMessage"
                                    showCount
                                    type="text"
                                    value={formValues.notificationMessage}
                                    required={true}
                                    disabled={loading}
                                    autoSize={{
                                        minRows: 3,
                                        maxRows: 5,
                                    }}
                                    onChange={(e) => {
                                        setFormValues({
                                            ...formValues,
                                            [e.target.name]: e.target.value,
                                        });
                                    }}
                                    placeholder="This is the notification message" maxLength={250} />
                                <Form.Select
                                    label="recipient"
                                    name="recipientType"
                                    value={formValues?.recipientType}
                                    required={true}
                                    disabled={loading}
                                    onChange={(e, data1) => {
                                        setFormValues({
                                            ...formValues,
                                            [data1.name]: data1.value,
                                        });
                                    }}
                                    options={mainOptions}
                                    placeholder="Send Notifications to ..."
                                ></Form.Select>
                                <Form.Select
                                    multiple
                                    search
                                    disabled={formValues?.recipientType !== "Others" || loading}
                                    label="recipient IDs"
                                    name="recipientId"
                                    fluid
                                    value={formValues?.recipientId}
                                    onChange={(e, data1) => {
                                        setFormValues({
                                            ...formValues,
                                            [data1.name]: data1.value,
                                        });
                                    }}
                                    options={idOptions ? idOptions : null}
                                    placeholder="Select recipient IDs"
                                ></Form.Select>
                                <Form.Button
                                    type="submit"
                                    style={{
                                        backgroundColor: "#6855f6",
                                        color: "white",
                                    }}
                                >
                                    {" "}
                                    Add Notification{" "}
                                </Form.Button>
                            </Form>
                        </Card.Content>
                    </Card>
                </Grid.Column>
            </Grid>
        </Segment>
    );
};

export default AddNewNotifications;
