import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    zyvkaInternal,
    fetchZyvkaInternal,
} from "../features/metabaseSlice";
import {
    getZyvkaInternalLocalCache,
} from "../cache/localStorage";

const MetabaseAnalyticsInternal = () => {
    const [url, setUrl] = useState("");
    const dispatch = useDispatch();
    const zyvkaInternals = useSelector(zyvkaInternal);

    useEffect(() => {
        const getData = async () => {
            const posUrl = getZyvkaInternalLocalCache();
            if (!posUrl) {
                dispatch(fetchZyvkaInternal());
                setUrl(zyvkaInternals);
            } else {
                setUrl(posUrl);
            }
        }
        getData()
    }, [
        dispatch,
        zyvkaInternals,
        url, setUrl
    ]);

    return <div style={{ width: "98%", maxWidth: "80vw" }} >
        <iframe
            loading="eager"
            src={url}
            style={{
                width: "100%",
                height: "100%",
                minHeight: "80vh",
                border: "2px solid #4C577370"
            }}
            title="Internal Analytics"
        />
    </div>;
};

export default MetabaseAnalyticsInternal;
