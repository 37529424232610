import React, { useState } from "react";
import {
    Button,
    Card,
    Divider,
    Descriptions,
    Col,
    Row,
    Drawer,
    Tag,
    Typography,
    notification,
    message,
    Popconfirm
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { UserState, selectUser } from "../features/userSlice";
import ReactTimeAgo from "react-time-ago";
import "../styles/customStyle.css";
import parse from "html-react-parser";
import Currency, { ctcMap, CurrencySymbols } from "../Data/Currency";
import { applyPartnerForJob } from "../api/partner";
import axios from "axios";
const { Title } = Typography;

const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper">
        <p className="site-description-item-profile-p-label">{title}:</p>
        {content}
    </div>
);

const JobAssignDetails = ({
    data,
    width,
    ChangeFlag,
    skills,
    archived = false,
    closed = false,
    AllPartnerAdmins = [],
    AllZyvkaUsers = [],
}) => {
    const userState = useSelector(UserState);
    const dispatch = useDispatch();


    const showDrawer = () => {
        setOpenDrawer(true);
    };
    const onClose = () => {
        setOpenDrawer(false);
    };
    const user = useSelector(selectUser);
    const [opendrawer, setOpenDrawer] = useState(false);
    const applyForJob = async (e) => {
        try {
            const result = await applyPartnerForJob({ partnerAdminId: userState?.userInfo?.id, jobID: data?.id }, dispatch);
            if (result?.response?.status !== 500) {
                console.log(result?.data?.count)
                notification.open({
                    message: <span style={{ fontWeight: 'bold', fontSize: '17px' }}>Job Allotment Request Sent✅</span>,
                    description:
                        result?.data?.count >= 5 ?
                            "It seems that you currently have five active job allocations. Please wait for your job allotment request to be approved by Zyvka." : 'Position Assigned Successfully'
                });
                ChangeFlag()
            } else {
                ChangeFlag()
                throw new Error("Some error occurred, please try after some time")
            }
        } catch (e) {
            notification.open({
                message: <span style={{ fontWeight: 'bold', fontSize: '17px' }}>Error Occurred</span>,
                description:
                    'Due to some Error We could not assign you the job, Please try after some time',
            });
        }
    }

    const confirm = (e) => {
        console.log(e);
        applyForJob();
    };
    const cancel = (e) => {
        console.log(e);
        message.error('Cancelled');
    };
    //For dynamic pdf generation
    const handleshowPdf = async () => {
        try {
            if (data?.isZyvkaJdAiEnable) {
                const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/files/jd/generate/${data.job_title.split(" ").join("-")}`, {

                    jobId: data?.id,
                    name: user?.firstName + " " + user?.lastName,
                    mobile: user?.mobileNum,
                    email: user?.email
                }, {
                    responseType: 'blob',
                    withCredentials: true
                });
                const url = URL.createObjectURL(response.data);
                window.open(url, '_blank');
            } else {
                const url = `${process.env.REACT_APP_SERVER_URL}/api/files/jd/${data.jd_urn}`
                window.open(url, '_blank')
            }

        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div>
            <br></br>
            <Card
                className="job-cards"
                hoverable
                bordered={false}
                title={data.job_title}
                size="small"
                headStyle={{ fontSize: "18px", fontWeight: "bold" }}
                style={{ borderRadius: "10px", width: ` ${width}px` }}
                extra={
                    <>
                        <Button
                            type="ghost"
                            primary
                            onClick={showDrawer}
                            style={{ border: "solid 1px #6855F6", color: "#6855F6", marginRight: "8px" }}
                        >
                            Detail
                        </Button>
                        <Popconfirm
                            title="Are you sure to get this job?"
                            onConfirm={confirm}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        ><Button type="primary" > Claim </Button></Popconfirm>
                    </>
                }
            >
                <Descriptions title={data.company_name}>
                    <Descriptions.Item
                        label="Location"
                        contentStyle={{ display: "flex", justifyContent: "flex-end" }}
                        span={3}
                    >
                        {data?.isRemote ? "Remote" : data.city + ", " + data.country}
                    </Descriptions.Item>

                    <Descriptions.Item
                        label="Expected CTC"
                        contentStyle={{ display: "flex", justifyContent: "flex-end" }}
                        span={3}
                    >
                        {CurrencySymbols[data.currencyType]}{" "}
                        {data.min_CTC + ctcMap[data.currencyType] + " - " + data.max_CTC}
                        {`${ctcMap[data.currencyType]} P. A.`}
                    </Descriptions.Item>

                    <Descriptions.Item
                        label="Positions Filled"
                        contentStyle={{ display: "flex", justifyContent: "flex-end" }}
                        span={3}
                    >
                        {data.current_filled_positions} / {data.number_of_positions}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="Total Applications"
                        contentStyle={{ display: "flex", justifyContent: "flex-end" }}
                        span={3}
                    >
                        {data?.applications?.length}
                    </Descriptions.Item>

                    <Descriptions.Item
                        label="Opened"
                        contentStyle={{ display: "flex", justifyContent: "flex-end" }}
                        span={3}
                    >
                        <ReactTimeAgo
                            date={data.date}
                            locale="en-US"
                            timeStyle="facebook"
                        />
                    </Descriptions.Item>
                </Descriptions>
                <br></br>
            </Card>

            <Drawer
                width={window.innerWidth > 740 ? 740 : window.innerWidth - 0}
                placement="right"
                onClose={onClose}
                visible={opendrawer}
                title="Job Details"
            >
                {/* hello there */}
                <Card
                    title={data.job_title}
                    headStyle={{ fontSize: "18px", fontWeight: "bold" }}
                    extra={
                        <Popconfirm
                            title="Are you sure to get this job?"
                            onConfirm={confirm}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        ><Button type="primary" style={{ width: "200px" }}> Claim </Button></Popconfirm>
                    }
                >
                    <Row>
                        <Col span={12}>
                            <DescriptionItem
                                title="Company Name"
                                Link={"https://www.google.com"}
                                content={
                                    <a href={data?.client?.url} style={{ color: "inherit" }}>
                                        {data?.company_name}
                                    </a>
                                }
                            />
                        </Col>
                        <Col span={12}>
                            <DescriptionItem
                                title="Total Rounds"
                                content={data.number_of_rounds}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <DescriptionItem
                                title="Experience"
                                content={
                                    data.min_experience_req +
                                    " - " +
                                    data.max_experience_req +
                                    " Years"
                                }
                            />
                        </Col>
                        <Col span={12}>
                            <DescriptionItem
                                title="CTC"
                                content={`${CurrencySymbols[data.currencyType]}
            ${data.min_CTC}${ctcMap[data.currencyType ? data.currencyType : "INR"]
                                    } - ${data.max_CTC}${ctcMap[data.currencyType ? data.currencyType : "INR"]
                                    } P.A.`}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ paddingTop: "13px" }}>
                            <DescriptionItem
                                title="Skills Required"
                                labelStyle
                                content={
                                    <div
                                        style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
                                    >
                                        {data.skills_req.map((data1) => {
                                            return (
                                                <>
                                                    <Tag color="blue">{data1.toUpperCase() + "  "}</Tag>
                                                </>
                                            );
                                        })}
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Card>
                <Card style={{ marginTop: "30px" }}>
                    <Row>
                        <Col span={24}>
                            <DescriptionItem
                                title={<b> Job Description</b>}
                                content={
                                    <>
                                        {data?.job_description[0] === "<" ? (
                                            <div>{parse(data?.job_description)}</div>
                                        ) : (
                                            <pre
                                                style={{
                                                    color: "black",
                                                    whiteSpace: "pre-wrap",
                                                    fontFamily: "monospace",
                                                }}
                                            >
                                                {data?.job_description}
                                            </pre>
                                        )}
                                    </>
                                }
                            />
                        </Col>
                        <Divider />
                        <Col span={24}>
                            <a
                                target="_blank"
                                rel="noreferrer"
                            // href={`${process.env.REACT_APP_SERVER_URL}/api/files/jd/${data.jd_urn}`}
                            >
                                <Button type="primary" icon={<DownloadOutlined />} onClick={handleshowPdf}>
                                    Download JD
                                </Button>
                            </a>
                        </Col>
                    </Row>
                </Card>

                <Card style={{ marginTop: "30px" }}>
                    <Row>
                        <Col span={24}>
                            <DescriptionItem
                                title={<b>Hiring Process / Round Details</b>}
                                content={data.hiring_process.map((val, _idx) => {
                                    //console.log(val);
                                    return (
                                        <div>
                                            <Title level={5}>{JSON.parse(val).name} :</Title>
                                            <pre
                                                style={{
                                                    whiteSpace: "pre-wrap",
                                                    fontFamily: "inherit",
                                                }}
                                            >
                                                {JSON.parse(val).description}
                                            </pre>
                                        </div>
                                    );
                                })}
                            />
                        </Col>
                    </Row>
                </Card>
            </Drawer>
        </div>
    );
};

export default JobAssignDetails;
