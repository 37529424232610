import React, { useEffect, useState } from "react";
import { Layout, Typography } from "antd";

import HeaderComponent from "../components/HeaderComponent";
import ApplicantRemarks from "../components/ApplicantRemarks";
import { useSelector } from "react-redux";
import { selectUser } from "../features/userSlice";
import { useNavigate, useParams } from "react-router-dom";

const { Content } = Layout;

export const CandSheet = () => {
  const { id } = useParams();
  return (
    <>
      <Layout
        className="layout-default layout-signin"
        // style={{ background: "white" }}
      >
        <HeaderComponent />
        {/* Header for the sign in page */}
        <Content className="candidate sheet">
          <ApplicantRemarks id={id} />
        </Content>
      </Layout>
    </>
  );
};
//This comment is for testing