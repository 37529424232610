import axios from "axios";
import {
  start,
  successFetch,
  errors,
  deleteSuccess,
  successFetchMyApplicant,
  successFetchJob,
  success,
  hotJobsFetchSuccess,
  TechJobsFetchSuccess,
  NonTechJobsFetchSuccess,
  ReferApplicantError,
  ReferApplicantSuccess,
  startUpdatePartner,
  errorsUpdatePartner,
  succesUpdatePartner,
  pendingJobListFetchSuccess,
} from "../features/partnersSlice";
import PartnerPendingJobs from "../components/PartnerPendingJobs";
const API = axios.create({ baseURL: process.env.REACT_APP_SERVER_URL });

export const getPartners = async (dispatch) => {
  dispatch(start());
  try {
    const partners = await API.get("/api/partner/getPartners", {
      withCredentials: true,
    });
    dispatch(successFetch(partners.data));
  } catch (error) {
    console.log(error);
    dispatch(errors());
  }
};
export const deletePartner = async (partnerId, dispatch) => {
  dispatch(start());
  try {
    const response = await API.post(
      "/api/partner/deletePartner",
      {
        partnerID: partnerId,
      },
      {
        withCredentials: true,
      }
    );
    dispatch(deleteSuccess(response));
  } catch (error) {
    console.log(error);
    dispatch(errors());
  }
};

export const getMyApplicant = async (jobID, dispatch) => {
  dispatch(start());
  try {
    // console.log(jobID);
    const result = await API.get(
      `/api/partner/getPApplicantsForPAdmins/${jobID}`,
      {
        withCredentials: true,
      }
    );
    // console.log(result.data);
    dispatch(successFetchMyApplicant(result.data));
  } catch (error) {
    console.log(error);
    dispatch(error);
  }
};
export const getMyApplicantForNonPartners = async (jobID, dispatch) => {
  dispatch(start());
  try {
    // console.log(jobID);
    const result = await API.get(`/api/partner/getApplicants/${jobID}`, {
      withCredentials: true,
    });
    // console.log(result.data);
    dispatch(successFetchMyApplicant(result.data));
  } catch (error) {
    console.log(error);
    dispatch(error);
  }
};

export const getPApplicantsForPAdminsAndP = async () => {
  try {
    const result = await API.get(
      "/api/partner/getApplicantsForPartnersAdminORPartners",
      {
        withCredentials: true,
      }
    );
    console.log(result);
  } catch (error) {
    console.log(error);
  }
};

export const getAllApplicantsForPartners = async (dispatch, page, filter) => {
  // dispatch(start());
  try {
    const result = await API.post(
      `/api/partner/getApplicantsForPartnersAdminORPartners/${page}`,
      filter,
      {
        withCredentials: true,
      }
    );
    let val = [];
    result?.data?.data?.map((data, idx) => {
      val.push({
        ...data,
        key: idx + 1,
      });
    });
    console.log(val, "its here");
    return { data: val, count: result?.data?.count };
    // dispatch(successFetchMyApplicant(val));
    //console.log(result)
    // console.log(val)
    //dispatch(successFetchMyApplicant(result.data));
  } catch (error) {
    console.log(error);
  }
};

export const referApplicant = async (data, dispatch) => {
  dispatch(start());
  try {
    const result = await API.post("/api/jobs/referApplicant", data, {
      withCredentials: true,
    });
    dispatch(ReferApplicantSuccess(result.data));
  } catch (error) {
    console.log(error);
    dispatch(ReferApplicantError());
  }
};

export const getAllJobs = async (dispatch) => {
  dispatch(start());
  try {
    const Jobs = await API.get("/api/jobs/assignedJobs", {
      withCredentials: true,
    });
    dispatch(successFetchJob(Jobs.data));
  } catch (error) {
    console.log(error);
    dispatch(errors());
  }
};

export const filterJobsPartner = async (data, dispatch) => {
  dispatch(start());
  try {
    const result = await API.post("/api/partner/filterJobs", data, {
      withCredentials: true,
    });
    dispatch(successFetchJob(result.data));
  } catch (error) {
    console.log(error);
    dispatch(errors());
  }
};
export const getSearchedJob = async (data, dispatch) => {
  dispatch(start());
  try {
    const result = await API.post("/api/jobs/searchJobsForPartner", data, {
      withCredentials: true,
    });
    dispatch(successFetchJob(result.data));
  } catch (error) {
    dispatch(errors());
    console.log(error);
  }
};
export const GetHotJobs = async (data, dispatch) => {
  dispatch(start());
  try {
    const result = await API.get(
      `/api/jobs/getHotJobsForPartner/${data.active_status}`,
      {
        withCredentials: true,
      }
    );

    // console.log(result.data);
    dispatch(hotJobsFetchSuccess(result.data));
  } catch (error) {
    console.log(error);
    dispatch(errors());
  }
};
export const GetTechJobs = async (data, dispatch) => {
  dispatch(start());
  try {
    const result = await API.get(
      `/api/jobs/getTechJobsForPartner/${data.active_status}`,
      {
        withCredentials: true,
      }
    );
    dispatch(TechJobsFetchSuccess(result.data));
  } catch (error) {
    console.log(error);
    dispatch(errors());
  }
};
export const GetNonTechJobs = async (data, dispatch) => {
  dispatch(start());
  try {
    const result = await API.get(
      `/api/jobs/getNonTechJobsForPartner/${data.active_status}`,
      {
        withCredentials: true,
      }
    );
    dispatch(NonTechJobsFetchSuccess(result.data));
  } catch (error) {
    console.log(error);
    dispatch(errors());
  }
};

export const getPartnerPendingJobList = async (data, dispatch) => {
  dispatch(start());
  try {
    const result = await API.post(`/api/partner/partnerPendingJobClaim`, data, {
      withCredentials: true,
    });
    console.log(result);
    dispatch(pendingJobListFetchSuccess(result.data));
    return result?.data;
  } catch (error) {
    console.log(error);
    dispatch(errors());
  }
};

export const getPartnerAssignedPendingJobList = async (data, dispatch) => {
  dispatch(start());
  try {
    const result = await API.post(
      `/api/partner/allAssignedJobsPartnerSide`,
      data,
      {
        withCredentials: true,
      }
    );
    console.log(result);
    dispatch(pendingJobListFetchSuccess(result.data));
    return result?.data;
  } catch (error) {
    console.log(error);
    dispatch(errors());
  }
};

export const applyPartnerForJob = async (data, dispatch) => {
  dispatch(start());
  try {
    const result = await API.post(`/api/partner/jobClaimByPartner`, data, {
      withCredentials: true,
    });
    dispatch(success());
    return result;
  } catch (error) {
    console.log(error);
    dispatch(errors());
    return error;
  }
};



export const fetchPartnerList = async (data) => {
  try {
    const result = await API.post(`/api/partner/getAllPartnersOfCompany`, data, {
      withCredentials: true,
    });
    return result?.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};



export const AcceptPartnerJobProposal = async (data, dispatch) => {
  dispatch(start());
  try {
    const result = await API.post(
      `/api/partner/assignedJobAcceptedByPartner`,
      data,
      {
        withCredentials: true,
      }
    );
    dispatch(success());
  } catch (error) {
    console.log(error);
    dispatch(errors());
  }
};

// export const applyPartnerForJob = async (data, dispatch) => {
//   dispatch(start());
//   try {
//     const result = await API.post(
//       `/api/partner/partnerPendingJobClaim`,
//       data,
//       {
//         withCredentials: true,
//       }
//     );
//     dispatch(success());
//   } catch (error) {
//     console.log(error);
//     dispatch(errors());
//   }
// };

export const UpdatePartnerProfile = async (data, dispatch) => {
  dispatch(startUpdatePartner());
  try {
    const result = await API.post(`/api/partner/updateProfilePartner/`, data, {
      withCredentials: true,
    });
    dispatch(succesUpdatePartner(result.data));
  } catch (error) {
    console.log(error);
    dispatch(errorsUpdatePartner());
  }
};

export const filterCandidateForNonPartner = async (
  jobID,
  data,
  page,
  dispatch
) => {
  dispatch(start());
  try {
    const result = await API.post(
      `/api/applicant/filteredAppforNonPartners/${jobID}/${page}`,
      data,
      {
        withCredentials: true,
      }
    );
    dispatch(successFetchMyApplicant(result.data.data));
    return result.data;
  } catch (error) {
    console.log(error);
    dispatch(error());
  }
};

export const filterCandidateForPartner = async (
  jobID,
  data,
  page,
  dispatch
) => {
  // dispatch(start());
  try {
    const result = await API.post(
      `/api/applicant/filteredAppforPartners/${jobID}/${page}`,
      data,
      {
        withCredentials: true,
      }
    );
    dispatch(successFetchMyApplicant(result.data));
    return result.data;
  } catch (error) {
    console.log(error);
    // dispatch(error);
  }
};

export const deallocateJob = async (data) => {
  // dispatch(start());
  try {
    const result = await API.post(
      `/api/partner/deallocationJobsToPartner`,
      data,
      {
        withCredentials: true,
      }
    );
  } catch (error) {
    console.log(error);
    // dispatch(error);
  }
};


export const getAllJobsForCompany = async (data) => {
  try {
    const result = await API.post(
      `/api/partner/partnersJobsAndItsCand`,
      data,
      {
        withCredentials: true,
      }
    );
    const val = result?.data?.jobs?.map((data, idx) => ({
      ...data,
      key: idx + 1,
    }));
    return { data: val, count: val?.length };
  } catch (error) {
    console.log(error);
  }
};