import React, { useState, useEffect } from "react";
import {
  createJob,
  getAllClients,
  fetchAllPartnerAdmins,
  getAllZyvkaUsers,
  getPrimarySkillsData,
  fetchPartnerRecomm,
} from "../api/ZyvkaMember";
import { UserState, selectUser } from "../features/userSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  selectAllPartnerAdminsDetails,
  ZyvkaMemberState,
} from "../features/ZyvkaMemberSlice";
import { getAllJobProfiles, fetchAllSkills } from "../api/auth";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import RichTextEditor from "../Small-Components/RichTextEditor";
import { Card, Form, Item, Icon, Button, Divider, DropDown } from "semantic-ui-react";
import { profilePicURL } from "../utils/functions";
import Currency, { defaultCurrency } from "../Data/Currency";
import ErrorMessage from "../Error/ErrorMessage";
import SuccessMessage from "../Small-Components/SuccessMessage";
import FormItem from "antd/es/form/FormItem";
import QuestionsForJob from "./QuestionsForJob";
import data from "../Data/Data";
import { ClipLoader } from "react-spinners"
import { message } from "antd";
const CreateJob = () => {
  const [countryOption, setCountryOption] = useState([]);
  const [stateOption, setStateOption] = useState([]);
  const [cityOption, setCityOption] = useState([]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState(null);
  const [revenueVisible, setRevenueVisible] = useState(true)
  const [allPartnerAdmins, setAllPartnerAdmins] = useState();

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userState = useSelector(UserState);
  const [skillOption, setSkillOptions] = useState([]);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const zyvkaState = useSelector(ZyvkaMemberState);
  const [currComp, setCurrComp] = useState()
  const clients = zyvkaState.AllClients;
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedClientID, setSelectedCLientID] = useState("");
  const [clientContactOptions, setClientContactOptions] = useState([]);
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [primarySkillsOptions, setPrimarySkillsOptions] = useState([]);
  const [partnerRecommendations, setPartnerRecommendations] = useState([]);
  const [getJd, setJd] = useState("")

  const navigate = useNavigate();

  const addQuestionClick = () => {
    setShowQuestionModal(e => !e)
    console.log(showQuestionModal)
  }

  const getValue = (value) => {
    setFormData({ ...formData, job_description: value });
    ;
  };

  const onFinish = async (e) => {
    e.preventDefault();
    console.log(formData);
    const formdata = new FormData();
    formdata.append("zyvkaUserID", user.id);
    formdata.append("job_title", formData?.job_title);
    formdata.append("job_description", formData?.job_description);
    formdata.append("gpt_output", formData?.gpt_output);
    formdata.append("skills_req", formData.skills_req);
    formdata.append("file", formData.file);
    formdata.append("min_experience_req", formData?.min_experience_req);
    formdata.append("max_experience_req", formData?.max_experience_req);
    formdata.append(
      "country",
      countryOption?.find((e) => e.iso2 === formData?.country)?.name
    );
    formdata.append("number_of_positions", formData?.number_of_positions);
    formdata.append(
      "state",
      stateOption?.find((e) => e.iso2 === formData?.state).name || ""
    );
    formdata.append("city", formData?.city || "");
    formdata.append("prefered_industry", formData?.prefered_industry);
    formdata.append("certification", formData?.certification);
    formdata.append("min_CTC", formData?.mnCtc);
    formdata.append("max_CTC", formData?.mxCtc);
    formdata.append("primary_roles", formData?.primary_roles);
    formdata.append("notice_period", formData?.notice_period);
    formdata.append("hiring_process", JSON.stringify(formData?.hiring_process));
    formdata.append("company_name", formData?.company_name);
    formdata.append("number_of_rounds", formData?.hiring_process.length);
    formdata.append("openToInternalTeamOnly", formData?.openToInternalTeamOnly);
    formdata.append(
      "assigned_partners",
      (formData?.openToAll === false && formData?.openToInternalTeamOnly === false && formData?.assigned_partners) || []
    );
    formdata.append("questions", JSON.stringify(formData?.questions));
    formdata.append("questionType", JSON.stringify(formData?.questionType));
    formdata.append("zyvka_team_members", formData?.zyvka_team_members);
    formdata.append("openToAll", formData?.openToAll);
    formdata.append("jobType", formData?.jobType);
    formdata.append("ESOP", formData?.esop);
    formdata.append("Drive", formData?.drive);
    formdata.append("internalTeamWorking", formData?.IsInternalTeamWorking);
    formdata.append("isVideoResumeRequired", formData?.isVideoResumeRequired);
    formdata.append("isZyvkaAiEnable", formData?.isZyvkaAiEnable);
    formdata.append("isZyvkaJdAiEnable", formData?.isZyvkaJdAiEnable);
    formdata.append("hotJob", formData?.hotJob);
    formdata.append("prevJD", false);
    formdata.append("clientUserId", formData?.clientUserId);
    formdata.append("currencyType", formData?.currencyType);
    formdata.append("isCommit", formData?.isCommit);
    formdata.append("commitDate", formData?.commitDate);
    //formdata.append("typeOfHiring", formData?.typeOfHiring);
    formdata.append("clientId", selectedClientID);
    formdata.append("isRemote", formData?.remoteJob);
    /* revenue model  */
    formdata.append("RevenueMode", formData?.revenue_model);
    formdata.append("Value", formData?.charge_value);
    formdata.append("PaymentTerms", formData?.payment_terms);
    formdata.append("InvoicingTerms", formData?.invoicing_terms);
    formdata.append("ReplacementTimeline", formData?.replacement_timeline);
    formdata.append("ReplacementModel", formData?.replacement_model);
    formdata.append("Currency", formData?.currency);
    if (!formData?.jdVideo_urn || /^(ftp|http|https):\/\/[^ "]+$/.test(formData.jdVideo_urn)) {
      formdata.append("jdVideo_urn", formData?.jdVideo_urn);
    } else {
      message.error("Enter Valid Video Url");
      return;
    }
    console.log(formdata, "formdata here")
    await createJob(formdata, dispatch, navigate, user?.id);
  };

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const result = await axios.get(
          "https://api.countrystatecity.in/v1/countries",
          {
            headers: {
              "X-CSCAPI-KEY":
                "eVNMbzF6NEVPNFFld1IyMXlvVlN5OVpMaExVWFN1RG82elc2SFBscg==",
            },
          }
        );
        setCountryOption(result.data);
      } catch (error) {
        console.log(error);
      }
    };
    const fetchJobProfiles = async () => {
      try {
        await getAllJobProfiles(dispatch);
      } catch (error) {
        console.log(error);
      }
    };
    const fetchClients = async () => {
      try {
        const res = await getAllClients(dispatch);
        console.log(res, "client here");
      } catch (error) {
        console.log(error);
      }
    };
    const getAllPartnerAdmins = async () => {
      try {
        await fetchAllPartnerAdmins(dispatch);
      } catch (error) {
        console.log(error);
      }
    };
    const getZyvkaUsers = async () => {
      try {
        await getAllZyvkaUsers(dispatch);
      } catch (error) {
        console.log(error);
      }
    };
    const fetchPrimarySkills = async () => {
      try {
        const res = await getPrimarySkillsData();
        setPrimarySkillsOptions(res?.primarySkills);
      } catch (e) {
        console.error(e)
      }
    }
    const fetchGlobalSkills = async () => {
      try {
        await fetchAllSkills(dispatch);
      } catch (error) {
        console.log(error);
      }
    };
    const fetchSkills = async () => {
      try {
        if (profile) {
          const result = await axios.get(
            `${process.env.REACT_APP_SERVER_URL}/api/zyvka/getSkillForJobProfile/${profile}`,
            { withCredentials: true }
          );
          setSkillOptions(result.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCountry();
    fetchJobProfiles();
    fetchSkills();
    fetchClients();
    getAllPartnerAdmins();
    getZyvkaUsers();
    fetchGlobalSkills();
    fetchPrimarySkills()
  }, [dispatch]);

  const getRecomm = async () => {
    const cur = clients?.find(el => el.name === formData?.company_name);
    console.log(cur, "current company is here")
    const rekt = await fetchAllPartnerAdmins(dispatch, setAllPartnerAdmins);
    const resp = await fetchPartnerRecomm({ clientIndustry: cur?.industry || "Software & Technology", jobsPrimaryRoles: formData?.primary_roles, jobType: formData?.jobType });
    const rep = combineRecommendedAndNormalLists(resp, rekt, formData?.pending_assignment);
    console.log(rekt, "all admin", rep, "filtered admin", resp, "partner recommen");
    setPartnerRecommendations(rep);
  }

  const renderLabel = (item) => ({
    content: (
      <span>
        {item.text.split('-')[0]}
        {item.text.split('-')[1] ?
          <span style={{ color: 'white', background: '#6855F6', fontWeight: "bolder", borderRadius: '4px', padding: '0px 4px', marginLeft: "4px" }}> Recommended✨ </span>
          : null}
      </span>
    ),
  });
  const getClientId = async () => {
    const ids = clients?.map((data) => {
      if (data?.name === formData?.company_name) {
        return data?.id;
      }
      return null;
    }).filter((id) => id !== null);
    console.log(ids)
    return ids;
  };


  const fetchSkillsOnChange = async () => {
    try {
      if (profile) {
        const result = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/zyvka/getSkillForJobProfile/${profile}`,
          { withCredentials: true }
        );

        setFormData({ ...formData, skills_req: result.data });
        ;
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log(clients, "asdas")
  useEffect(() => {
    fetchSkillsOnChange();
  }, [profile]);

  useEffect(() => {
    const fetchState = async () => {
      try {
        if (country) {
          const result = await axios.get(
            `https://api.countrystatecity.in/v1/countries/${country}/states`,
            {
              headers: {
                "X-CSCAPI-KEY":
                  "eVNMbzF6NEVPNFFld1IyMXlvVlN5OVpMaExVWFN1RG82elc2SFBscg==",
              },
            }
          );
          setStateOption(result.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchState();
  }, [country]);
  function combineRecommendedAndNormalLists(recommendedList, rekt, pending_assignment) {
    if (recommendedList?.length === 0) {
      return rekt?.filter(el => !pending_assignment?.includes(el?.key));
    }

    const recommendedKeys = new Set(recommendedList?.map(item => item.key));

    const uniqueNormalList = rekt?.filter(el =>
      !recommendedKeys?.has(el.key) && !pending_assignment?.includes(el?.key)
    );

    const combinedList = [...recommendedList, ...uniqueNormalList];
    return combinedList;
  }

  useEffect(() => {
    const fetchCity = async () => {
      try {
        if (state) {
          const result = await axios.get(
            `https://api.countrystatecity.in/v1/countries/${country}/states/${state}/cities`,
            {
              headers: {
                "X-CSCAPI-KEY":
                  "eVNMbzF6NEVPNFFld1IyMXlvVlN5OVpMaExVWFN1RG82elc2SFBscg==",
              },
            }
          );
          setCityOption(result.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCity();
  }, [state]);

  const changeHandlerCountry = (e) => {
    setCountry(e);
    console.log(e);
  };

  const changeHandlerState = (e) => {
    // console.log(e);
    setState(e);
  };

  const AllProfiles = userState.gProfile;
  const AllSkills = userState.gSkills;

  const changeHandlerProfile = (e) => {
    setProfile(e);
  };

  const partnerAdmins = useSelector(selectAllPartnerAdminsDetails);

  const clientSelectedHandler = (e) => {
    console.log(e);
    setSelectedClient(e);
    const val = clients.find((item) => item.name === e);

    setSelectedCLientID(val?.id);
    // setFormData({ ...formData, clientUserId: "" });

    setClientContactOptions(val?.contact_person);
  };

  const [formData, setFormData] = useState({
    job_title: null,
    zyvka_team_members: null,
    jobType: "Non-Tech",
    hotJob: false,
    company_name: null,
    clientUserId: "",
    min_experience_req: 0,
    max_experience_req: 0,
    skills_req: [],
    file: null,
    number_of_rounds: 0,
    number_of_positions: 1,
    openToAll: false,
    openToInternalTeamOnly: false,
    // hasQuestions: false,
    questions: [],
    questionType: [],
    assigned_partners: [],
    certification: "",
    prefered_industry: "",
    remoteJob: false,
    esop: false,
    drive: false,
    IsInternalTeamWorking: false,
    isVideoResumeRequired: false,
    isZyvkaAiEnable: false,
    isZyvkaJdAiEnable: true,
    currencyType: defaultCurrency,
    //typeOfHiring: "Fresher",
    round_name: "",
    round_desc: "",
    hiring_process: [],
    job_description: "",
    jdVideo_urn: "",
    gpt_output: "",
    mnCtc: 0,
    mxCtc: 0,
    notice_period: 0,
    country: "",
    state: "",
    city: "",
    isCommit: false,
    commitDate: "",
    revenue_model: null,
    charge_value: null,
    payment_terms: null,
    invoicing_terms: null,
    replacement_model: null,
    replacement_timeline: null,
    currency: null,
    primary_roles: null
  });

  const changeHandler = (e) => {
    console.log(e.target.name, e.target.value)
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const changeName = (e) => {
    setFormData({ ...formData, "round_name": e.target.value });
  }

  const handleUploadJD = async (event) => {
    try {
      if (!formData.isZyvkaJdAiEnable) {
        setFormData({ ...formData, [event.target.name]: event.target.files[0] })
      } else {

        setLoading(true)
        const datatosend = new FormData();
        datatosend.append("file", event.target.files[0])
        // datatosend.append("id",)
        const res = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/jobs/uploadJD`,
          datatosend
        );
        const pdfData = res.data.data;
        setFormData({ ...formData, "gpt_output": pdfData })
        const jobdata = pdfData.split('JobDescriptionHTML": "')[1];
        const jobDescriptionHTML = jobdata.split('IdealCandidateResumeHTML')[0].split('>"')[0] + ">";
        setJd(jobDescriptionHTML);
        setLoading(false)
      }
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  }
  const addRoundHandler = () => {
    //todo add R1 - {name}
    // let res = R-${}
    // R{idx} - aptitude round
    let idx = formData?.hiring_process.length;
    let res = `Round ${idx + 1}`;

    let tmp = {
      name: res,
      description: formData?.round_desc,
    };

    tmp = JSON.stringify(tmp);
    let tmp1 = formData?.hiring_process;
    tmp1.push(tmp);

    setFormData({
      ...formData,
      hiring_process: tmp1,
    });

  };
  const deleteRoundHandler = (idx) => {
    let prev_value = formData?.hiring_process;
    const new_value = prev_value.splice(idx, formData?.hiring_process.length - idx + 1);
    setFormData({
      ...formData,
      [formData.hiring_process]: new_value,
    });
  };
  const deleteQuestionHandler = (idx) => {
    let prev_ques = formData?.questions;
    let prev_ques_type = formData?.questionType;
    const new_ques = prev_ques.splice(idx, 1);
    const new_ques_type = prev_ques_type.splice(idx, 1);
    setFormData({
      ...formData,
      [formData.questions]: new_ques,
      [formData.questionType]: new_ques_type
    });
  }
  const zyvkaMembers = zyvkaState?.zyvkaMembers.filter(function (elem) {
    if (elem.id !== user?.id) {
      return elem;
    }
  });
  const currencyOptions = [
    { id: 1, text: "INR", value: "INR" },
    { id: 2, text: "USD", value: "USD" },
  ];
  const modelOptions = [
    { id: 1, text: "Percentage", value: "Percentage" },
    { id: 2, text: "Fixed Price", value: "Fixed Price" },
  ];
  const repModel = [
    { id: 1, text: "Replacement", value: "Replacement" },
    { id: 2, text: "Refund", value: "Refund" },
  ];
  const clientType = [
    { id: 1, text: "Hot", value: "Hot" },
    { id: 2, text: "Warm", value: "Warm" },
    { id: 3, text: "Cold", value: "Cold" },
  ];
  const positions_available = [
    { id: 1, text: "Yes", value: true },
    { id: 2, text: "No", value: false }
  ];
  // const typeOfHiringOption = [
  //   { id: 1, text: "Fresher", value: "Fresher" },
  //   { id: 2, text: "Lateral", value: "Lateral" },
  // ];
  useEffect(() => {
    const getRevenueValues = async () => {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/client/getRevenue/${selectedClientID}`,
          { withCredentials: true }
        );
        if (result?.data?.length !== 0) {
          setRevenueVisible(true)
          setFormData({ ...formData, revenue_model: result?.data?.RevenueMode, charge_value: result?.data?.Value, payment_terms: result?.data?.PaymentTerms, invoicing_terms: result?.data?.InvoicingTerms, replacement_timeline: result?.data?.ReplacementTimeline, replacement_model: result?.data?.ReplacementModel, currency: result?.data?.Currency });
        } else {
          setRevenueVisible(false)
        }
      } catch (error) {
        console.log(error);
      }
    }
    getRevenueValues()
  }, [selectedClientID])
  useEffect(() => {
    getRecomm();
  }, [dispatch, formData?.primary_roles, formData?.job_type, formData?.company_name])
  // console.log(typeof formData.questions);
  return (
    <div>
      {loading && <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: '9999',
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: '100%',
          height: '100%',
        }}
      >
        <ClipLoader loading={loading} size={50} color="#5d62b3" />
      </div>}
      <br></br>
      <Card
        headStyle={{ fontSize: "25px", fontWeight: "bold" }}
        bordered={false}
        style={{
          width: "auto",
          padding: "15px",
          maxWidth: "900px",
        }}
      >
        <Card.Content header="Open new position"></Card.Content>
        <Card.Content>
          <Form onSubmit={onFinish}>
            <Form.Select
              search
              label="Job Title"
              name="job_title"
              value={formData?.job_title}
              required={true}
              onChange={(e, data) => {
                setFormData({ ...formData, [data.name]: data.value });
                const val = AllProfiles.find(
                  (elem) => elem.profile === data.value
                );
                changeHandlerProfile(val?.id);
              }}
              options={AllProfiles.map((data, idx) => {
                return { key: idx, text: data.profile, value: data.profile };
              })}
              placeholder="Select Job Profile"
            ></Form.Select>
            <Form.Select
              search
              label="Zyvka Team"
              name="zyvka_team_members"
              multiple
              placeholder="Select people with access to updating this job"
              options={zyvkaMembers.map((data, idx) => {
                return {
                  key: idx,
                  text: `${data.firstName} ${data.lastName} (${data.email})`,
                  value: data.id,
                  image: { avatar: true, src: profilePicURL(data.pfp_urn) },
                };
              })}
              value={formData?.zyvka_team_members}
              onChange={(e, data) => {
                setFormData({ ...formData, [data.name]: data.value });
              }}
            ></Form.Select>
            <Form.Group widths={"equal"}>
              <Form.Select
                label="Job Type"
                name="jobType"
                placeholder="Select Job Type"
                value={formData?.jobType}
                onChange={(e, { name, value }) => {
                  setFormData({ ...formData, [name]: value });
                }}
                required={true}
                options={[
                  { key: 0, text: "Tech", value: "Tech" },
                  { key: 1, text: "Non-Tech", value: "Non-Tech" },
                ]}
              ></Form.Select>
              <Form.Checkbox
                toggle
                label="Hot Job"
                style={{ marginTop: "30px" }}
                checked={formData?.hotJob}
                onChange={(e, data) => {
                  setFormData({ ...formData, [data.name]: data.checked });
                }}
                name="hotJob"
              ></Form.Checkbox>
              <Form.Checkbox
                toggle
                label="Zyvka JdAi"
                style={{ marginTop: "30px" }}
                checked={formData?.isZyvkaJdAiEnable}
                onChange={(e, data) => {
                  setFormData({ ...formData, [data.name]: data.checked });
                }}
                name="isZyvkaJdAiEnable"
              ></Form.Checkbox>
            </Form.Group>
            <Form.Group widths={"equal"}>
              <Form.Select
                search
                name="company_name"
                label="Company"
                value={formData?.company_name}
                required={true}
                onChange={(e, data) => {
                  setFormData({ ...formData, [data.name]: data.value });
                  clientSelectedHandler(data.value);
                  getClientId()
                  const gtt = clients?.find(el => el.name === data.value);
                  console.log("match value", gtt);
                  setCurrComp(gtt);
                }}
                options={clients.map((data, idx) => {
                  return { key: idx, value: data.name, text: data.name };
                })}
                placeholder="Select Company/Client"
              ></Form.Select>
              <Form.Select
                search
                label="Client Contact"
                required={true}
                name="clientUserId"
                value={formData?.clientUserId}
                onChange={(e, data) => {
                  setFormData({ ...formData, [data.name]: data.value });
                }}
                options={clientContactOptions.map((data, idx) => {
                  return {
                    key: idx,
                    value: data.id,
                    text: `${data?.name} (${data?.email})`,
                  };
                })}
                disabled={clientContactOptions.length === 0}
                placeholder="Select Contact Person"
              ></Form.Select>
            </Form.Group>
            <Form.Group widths={"equal"}>
              <Form.Input
                type="number"
                name="min_experience_req"
                label="Minimum experience"
                value={formData?.min_experience_req}
                onChange={changeHandler}
                placeholder="Min exp. required (in years)"
              ></Form.Input>
              <Form.Input
                type="number"
                name="max_experience_req"
                label="Maximum experience"
                value={formData?.max_experience_req}
                onChange={changeHandler}
                placeholder="Max. exp. required (in years)"
              ></Form.Input>
              {/* <Form.Select
                options={typeOfHiringOption}
                label="Type of Hiring"
                required={true}
                name="typeOfHiring"
                value={formData?.typeOfHiring}
                onChange={(e, data) => {
                  setFormData({ ...formData, [data.name]: data.value });
                }}
              ></Form.Select> */}
              <Form.Select
                search

                options={primarySkillsOptions?.map((data, idx) => {
                  return { key: idx, value: data.name, text: data.name };
                })}
                placeholder="Select Primary Roles"
                name="primary_roles"
                value={formData?.primary_roles}
                onChange={(e, data) => {
                  setFormData({ ...formData, [data.name]: data.value });
                }}
                label="Primary Skills"
              ></Form.Select>
            </Form.Group>
            <Form.Input
              type="text"
              name="prefered_industry"
              label="Preferred Industry"
              value={formData?.prefered_industry}
              onChange={changeHandler}
              placeholder="Preferred Industry"
            ></Form.Input>
            <Form.Input
              type="file"
              name="file"
              label="Upload JD"
              // onChange={(e) => {
              //   setFormData({
              //     ...formData,
              //     [e.target.name]: e.target.files[0],
              //   });
              // }}
              onChange={handleUploadJD}
              required={true}
              accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ></Form.Input>
            <label style={{ fontWeight: "bolder" }}>Job Description</label>
            <RichTextEditor initialValue={getJd || ""} getValue={getValue} />
            <br />

            <Form.Select
              search
              multiple
              options={AllSkills.map((data, idx) => {
                return { key: idx, value: data.skill, text: data.skill };
              })}
              placeholder="Select required skills"
              name="skills_req"
              value={formData?.skills_req}
              onChange={(e, data) => {
                setFormData({ ...formData, [data.name]: data.value });
              }}
              label="Skills"
            ></Form.Select>
            <Form.Input
              text
              placeholder="Enter Video Link of Jd"
              name="jdVideo_urn"
              value={formData?.jdVideo_urn}
              onChange={(e, data) => {
                setFormData({ ...formData, [data.name]: data.value });
              }}
              label="Jd Video Url"
            ></Form.Input>
            <Form.Input
              type="number"
              label="Number of rounds"
              name="number_of_rounds"
              value={formData?.number_of_rounds}
              onChange={changeHandler}
              placeholder="Enter Total Number of Rounds"
            ></Form.Input>
            <Card style={{ width: "auto", padding: "20px" }}>
              {" "}
              {formData?.hiring_process.length > 0 && (
                <Item.Group divided>
                  {formData?.hiring_process.map((data, idx) => {
                    return (
                      <Item key={idx}>
                        {/* <Item.Image src="https://react.semantic-ui.com/images/wireframe/image.png" /> */}

                        <Item.Content>
                          <Item.Header
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>Round {idx + 1}</span>
                            <Button
                              floated="right"
                              style={{
                                backgroundColor: "red",
                                color: "white",
                                padding: "5px",
                              }}
                              onClick={() => {
                                deleteRoundHandler(idx);
                              }}
                            >
                              <Icon name="close" style={{ margin: "0px" }} />
                            </Button>
                          </Item.Header>

                          <Item.Description>
                            <Item.Meta>
                              <span className="cinema">
                                {JSON.parse(data).description}
                              </span>
                            </Item.Meta>
                          </Item.Description>
                          <Item.Extra></Item.Extra>
                        </Item.Content>
                      </Item>
                    );
                  })}
                </Item.Group>
              )}
              <Divider />
              <p
                name="round_name"
                onChange={changeHandler}
              >
                Round {formData?.hiring_process.length + 1}
              </p>
              <Form.TextArea
                placeholder="Coding / HR "
                name="round_desc"
                value={formData?.round_desc}
                onChange={changeHandler}
              ></Form.TextArea>
              <Form.Group>
                <Form.Button
                  type="button"
                  onClick={addRoundHandler}
                  style={{ backgroundColor: "#6855f6", color: "#fff" }}
                >
                  Add
                </Form.Button>
                <Form.Button type="button">Cancel</Form.Button>
              </Form.Group>
            </Card>

            <Form.Group >
              <Form.Input
                name="number_of_positions"
                label="Open Positions"
                placeholder="Enter Number of Open Positions"
                value={formData?.number_of_positions}
                type="number"
                onChange={changeHandler}
              ></Form.Input>
              <Form.Checkbox
                toggle
                style={{ marginTop: "30px" }}
                name="openToAll"
                label="Open to all ?"
                checked={formData?.openToAll}
                onChange={(e, data) => {
                  setFormData({ ...formData, [data.name]: data.checked });
                }}
              ></Form.Checkbox>
              <Form.Checkbox
                toggle
                style={{ marginTop: "30px" }}
                name="openToInternalTeamOnly"
                label="Open to internalTeam only ?"
                checked={formData?.openToInternalTeamOnly}
                onChange={(e, data) => {
                  data?.checked ? setFormData({ ...formData, [data.name]: data.checked, IsInternalTeamWorking: true }) :
                    setFormData({ ...formData, [data.name]: data.checked });
                }}
              ></Form.Checkbox>
              <Form.Checkbox
                toggle
                style={{ marginTop: "30px" }}
                name="IsInternalTeamWorking"
                label="Internal Team Working ?"
                checked={formData?.IsInternalTeamWorking}
                onChange={(e, data) => {
                  setFormData({ ...formData, [data.name]: data.checked });
                }}
              ></Form.Checkbox>
            </Form.Group>

            {!(formData?.openToAll || formData?.openToInternalTeamOnly) && (
              <Form.Select
                multiple
                search
                name="assigned_partners"
                label="Assign Job"
                value={formData?.assigned_partners}
                onChange={(e, data) => {
                  getRecomm();
                  setFormData({ ...formData, [data.name]: data.value });
                }}
                renderLabel={renderLabel}
                // options={partnerAdmins.map((data, idx) => {
                //   return {
                //     key: idx,
                //     text: `${data.firstName} ${data.lastName} ( ${data.email} )`,
                //     value: data.id,
                //     image: { avatar: true, src: profilePicURL(data.pfp_urn) },
                //   };
                // })}
                options={partnerRecommendations}
                placeholder="Select partners for this job"
              ></Form.Select>
            )}

            <Form.Group widths={"equal"}>
              <Form.Input
                name="certification"
                label="Certification"
                placeholder="Cetrification (If any)"
                value={formData?.certification}
                onChange={changeHandler}
              ></Form.Input>
            </Form.Group>
            <Item.Group>
              {
                formData?.questions.map((el, idx) => {
                  return (
                    <Item key={idx}>
                      <Item.Content>
                        <Item.Header
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>
                            {el}
                          </span>
                          <Button
                            floated="right"
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              padding: "5px",
                            }}
                            onClick={() => {
                              deleteQuestionHandler(idx);
                            }}
                          >
                            <Icon name="close" style={{ margin: "0px" }} />
                          </Button>
                        </Item.Header>
                        <Item.Extra></Item.Extra>
                      </Item.Content>
                    </Item>
                  )
                })
              }
              {
                formData?.questions.length === 0 ? (
                  <p>Please provide the questions you would like the job candidate to answer</p>
                ) : null
              }
              <Form.Button type="button" onClick={addQuestionClick}>Add Questions</Form.Button>
              {showQuestionModal ? <QuestionsForJob setFormData={setFormData} formData={formData} Open={true} /> : null}
            </Item.Group>
            <Form.Group widths={"equal"}>
              {/* U+200E is a blank character inserted for proper spacing, nothing else */}
              <Form.Select
                search
                fluid
                name="country"
                label="Location"
                required={true}
                placeholder="Select country"
                value={formData?.country}
                onChange={(e, data) => {
                  changeHandlerCountry(data.value);
                  setFormData({
                    ...formData,
                    [data.name]: data.value,
                  });
                }}
                options={countryOption.map((data, idx) => {
                  return {
                    text: `${data.name + "  (" + data.iso2 + ")"}`,
                    value: `${data.iso2}`,
                    key: `${idx}`,
                  };
                })}
              />
              <Form.Select
                search
                fluid
                label="‎ "
                placeholder="Select State"
                name="state"
                value={formData?.state}
                onChange={(e, data) => {
                  changeHandlerState(data.value);
                  setFormData({
                    ...formData,
                    [data.name]: data.value,
                  });
                }}
                options={stateOption.map((data, idx) => {
                  return {
                    text: `${data.name + "  (" + data.iso2 + ")"}`,
                    value: `${data.iso2}`,
                    key: `${idx}`,
                  };
                })}
              />
              <Form.Select
                fluid
                search
                label="‎ "
                placeholder="Select City"
                name="city"
                value={formData?.city}
                onChange={(e, data) => {
                  setFormData({ ...formData, [data.name]: data.value });
                }}
                options={cityOption.map((data, idx) => {
                  return {
                    text: `${data.name}`,
                    value: `${data.name}`,
                    key: `${idx}`,
                  };
                })}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Checkbox
                toggle
                name="remoteJob"
                label="Remote Job ?"
                checked={formData?.remoteJob}
                onChange={(e, data) => {
                  setFormData({ ...formData, [data.name]: data.checked });
                }}
              ></Form.Checkbox>
              <Form.Checkbox
                toggle
                name="esop"
                label="ESOP ?"
                checked={formData?.esop}
                onChange={(e, data) => {
                  setFormData({ ...formData, [data.name]: data.checked });
                }}
              ></Form.Checkbox>
              <Form.Checkbox
                toggle
                name="drive"
                label="Bulk ?"
                checked={formData?.drive}
                onChange={(e, data) => {
                  setFormData({ ...formData, [data.name]: data.checked });
                }}
              ></Form.Checkbox>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Select
                options={Currency}
                label="Currency Type"
                required={true}
                name="currencyType"
                value={formData?.currencyType}
                onChange={(e, data) => {
                  setFormData({ ...formData, [data.name]: data.value });
                }}
              ></Form.Select>
              <Form.Input
                type="number"
                label={`Minimum CTC ( In ${formData?.currencyType === "INR" ? "LPA" : "KPA"
                  })`}
                required={true}
                name="mnCtc"
                placeholder={`Enter Minimum ctc (in ${formData?.currencyType === "INR" ? "LPA" : "KPA"
                  })`}
                value={formData?.mnCtc}
                onChange={(e, data) => {
                  setFormData({ ...formData, [data.name]: data.value });
                }}
              ></Form.Input>
              <Form.Input
                type="number"
                label={`Maximum CTC ( In ${formData?.currencyType === "INR" ? "LPA" : "KPA"
                  })`}
                required={true}
                placeholder={`Enter maximum ctc (in ${formData?.currencyType === "INR" ? "LPA" : "KPA"
                  })`}
                name="mxCtc"
                value={formData?.mxCtc}
                onChange={(e, data) => {
                  setFormData({ ...formData, [data.name]: data.value });
                }}
              ></Form.Input>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                type="number"
                name="notice_period"
                label="Notice Period"
                placeholder="Enter notice period (in days)"
                value={formData?.notice_period}
                onChange={changeHandler}
              ></Form.Input>
              <Form.Checkbox
                toggle
                style={{ marginTop: "30px" }}
                name="isVideoResumeRequired"
                label="Video Resume Required ?"
                checked={formData?.isVideoResumeRequired}
                onChange={(e, data) => {
                  setFormData({ ...formData, [data.name]: data.checked });
                }}
              ></Form.Checkbox>
              <Form.Checkbox
                toggle
                style={{ marginTop: "30px" }}
                name="isZyvkaAiEnable"
                label="Enable ZyvkaAI ?"
                checked={formData?.isZyvkaAiEnable}
                onChange={(e, data) => {
                  setFormData({ ...formData, [data.name]: data.checked });
                }}
              ></Form.Checkbox>
            </Form.Group>
            <Form.Group widths={"equal"}>
  <Form.Checkbox
    toggle
    style={{ marginTop: "30px" }}
    name="isCommit"
    label="Commit?"
    checked={formData?.isCommit}
    onChange={(e, data) => {
      setFormData({ ...formData, [data.name]: data.checked });
    }}
  ></Form.Checkbox>
  
  <Form.Input
    type="date"
    name="commitDate"
    label="Commit Date"
    onChange={(e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }}
    min={new Date().toISOString().split('T')[0]}
    disabled={!formData?.isCommit}
  ></Form.Input>
</Form.Group>

            {
              revenueVisible &&
              <>
                <Divider horizontal>Revenue Parameters</Divider>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "20px",
                    backgroundColor: "#f0f0f0",
                    border: "2px solid #007bff50",
                    borderRadius: "10px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      padding: "20px",
                      maxWidth: "600px"

                    }}
                  >
                    These parameters will be filled with selected client company's revenue model. You can change the parameters for a specific job too
                  </p>
                </div>
                <Form.Group widths={"equal"}>
                  <Form.Select
                    label="Revenue Model "
                    name="revenue_model"
                    placeholder="Percentage"
                    value={formData?.revenue_model}
                    onChange={(e, data) => {
                      console.log(data.name, data.value)
                      setFormData({ ...formData, [data.name]: data.value });

                    }}
                    options={modelOptions}
                  ></Form.Select>

                  <Form.Input
                    fluid
                    name="charge_value"
                    type="number"
                    placeholder="10%"
                    value={formData?.charge_value}
                    onChange={(e, data) => {
                      console.log(data.name, data.value)
                      setFormData({ ...formData, [data.name]: data.value });
                    }}
                    label="Charge Value"
                  />
                </Form.Group>
                <Form.Group widths={"equal"}>
                  <Form.Input
                    label="Payment Terms (in days)"
                    name="payment_terms"
                    placeholder="30 days"
                    value={formData?.payment_terms}
                    onChange={(e, data) => {
                      console.log(data.name, data.value)
                      setFormData({ ...formData, [data.name]: data.value });
                    }}
                    type="number"
                  ></Form.Input>
                  <Form.Input
                    label="Invoicing Terms (in days)"
                    name="invoicing_terms"
                    placeholder="10 days"
                    value={formData?.invoicing_terms}
                    onChange={(e, data) => {
                      console.log(data.name, data.value)
                      setFormData({ ...formData, [data.name]: data.value });
                    }}
                    type="number"
                  ></Form.Input>
                  <Form.Input
                    label="Replacement Timeline (in days)"
                    name="replacement_timeline"
                    placeholder="60 days"
                    value={formData?.replacement_timeline}
                    onChange={(e, data) => {
                      console.log(data.name, data.value)
                      setFormData({ ...formData, [data.name]: data.value });
                    }}
                    type="number"
                  ></Form.Input>
                </Form.Group>
                <Form.Group widths={"equal"}>
                  <Form.Select
                    label="Replacement Model "
                    name="replacement_model"
                    placeholder="Replacement"
                    value={formData?.replacement_model}
                    onChange={(e, data) => {
                      console.log(data?.value, data?.name);
                      setFormData({ ...formData, [data.name]: data.value });
                    }}
                    options={repModel}
                  ></Form.Select>
                  <Form.Select
                    label="Currency"
                    name="currency"
                    placeholder="INR"
                    value={formData?.currency}
                    onChange={(e, data) => {
                      console.log(data?.value, data?.name);
                      setFormData({ ...formData, [data.name]: data.value });
                    }}
                    options={currencyOptions}
                  ></Form.Select>
                </Form.Group>
              </>
            }
            {/* <ErrorMessage header="Error" content={"Some Error Occured"} /> */}
            <Form.Button
              type="submit"
              style={{ backgroundColor: "#6855f6", color: "#fff" }}
            >
              Submit
            </Form.Button>
          </Form>
        </Card.Content>
      </Card>
    </div>
  );
};

export default CreateJob;