import React, { useState, useEffect, useCallback } from 'react';
import { useChatContext } from 'stream-chat-react';
import { SearchIcon } from '../assets';
import ResultsDropdown from './ResultsDropdown';

const ChannelSearch = ({ setToggleContainer }) => {
    const { client, setActiveChannel } = useChatContext();
    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [teamChannels, setTeamChannels] = useState([]);
    const [directChannels, setDirectChannels] = useState([]);
    const [allChannels, setAllChannels] = useState([]);

    const fetchChannels = useCallback(async () => {
        try {
            const filter = { members: { $in: [client.userID] } };
            const sort = { last_message_at: -1 };
            const limit = 30; 
            let offset = 0;
            let allFetchedChannels = [];

            while (true) {
                const channels = await client.queryChannels(filter, sort, {
                    watch: true,
                    state: true,
                    limit: limit,
                    offset: offset,
                });

                if (channels.length === 0) break;

                allFetchedChannels = [...allFetchedChannels, ...channels];
                offset += limit;

                if (channels.length < limit) break;
            }

            setAllChannels(allFetchedChannels);
        } catch (error) {
            console.error("Error fetching channels:", error);
        }
    }, [client]);

    useEffect(() => {
        fetchChannels();
    }, [fetchChannels]);

    const filterChannels = useCallback(() => {
        if (!query) {
            setTeamChannels([]);
            setDirectChannels([]);
            return;
        }

        const teamResults = allChannels.filter(channel => 
            channel.type === 'team' && 
            (channel.state._channel.data.name?.toLowerCase().includes(query.toLowerCase()))
        );

        const directResults = allChannels.filter(channel => {
            if (channel.type !== 'messaging') return false;

            // Find the other user in the direct message channel
            const otherMember = Object.values(channel.state.members).find(
                member => member.user.id !== client.userID
            );

            if (!otherMember) return false;

            const { Firstname, Lastname, id } = otherMember.user;
            const fullName = `${Firstname} ${Lastname}`.toLowerCase();

            return (
                fullName.includes(query.toLowerCase()) ||
                id.toLowerCase().includes(query.toLowerCase())
            );
        });

        setTeamChannels(teamResults);
        setDirectChannels(directResults);
        setLoading(false);
    }, [query, allChannels, client.userID]);

    useEffect(() => {
        setLoading(true);
        filterChannels();
    }, [query, filterChannels]);

    const onSearch = (event) => {
        event.preventDefault();
        setQuery(event.target.value);
        setLoading(true);
    }

    const setChannel = (channel) => {
        setQuery('');
        setActiveChannel(channel);
    }

    return (
        <div className="channel-search__container">
            <div className="channel-search__input__wrapper">
                <div className="channel-search__input__icon">
                    <SearchIcon />
                </div>
                <input 
                    className="channel-search__input__text" 
                    placeholder="Search" 
                    type="text" 
                    value={query}  
                    onChange={onSearch}
                />
            </div>
            { query && (
                <ResultsDropdown 
                    teamChannels={teamChannels}
                    directChannels={directChannels}
                    loading={loading}
                    setChannel={setChannel}
                    setQuery={setQuery}
                    setToggleContainer={setToggleContainer}
                />
            )}
        </div>
    )
}

export default ChannelSearch;