import { createSlice } from "@reduxjs/toolkit";
// import {
//   getDashboardApplicant,
//   getDashboardJobs,
//   getPartnerData,
//   getUserMonitorData,
//   getZyvkaInternalData,
// } from "../api/MetabaseFetch";

const initialState = {
  isLoading: false,
  error: null,
  tableData: null,
  email: localStorage.getItem("ClientEmail") || null,
};

const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.isLoading = true;
    },
    setError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setTableData: (state, action) => {
      state.isLoading = false;
      state.tableData = action.payload;
    },
    setEmail: (state, action) => {
      state.isLoading = false;
      state.email = action.payload;
      localStorage.setItem("ClientEmail", action.payload);
    },
  },
});

export const { setLoading, setError, setTableData, setEmail } =
  clientSlice.actions;

export const clientData = (state) => state.client.tableData;
export const email = (state) => state.client.email;

export default clientSlice.reducer;
