import axios from "axios";
import {
  start,
  errors,
  createApplicantSuccess,
  createPartnerAdminSuccess,
  fetchErrors,
  setSourcingVerticalData,
} from "../features/userSlice";
import {
  startfetch,
  fetcherrors,
  successfetch,
  successfetchMyJobs,
  successfetchAllApplicants,
  successfetchClients,
  queryOpenStart,
  queryOpenError,
  qeuryOpenSuccess,
  hotJobsFetchSuccess,
  TechJobsFetchSuccess,
  NonTechJobsFetchSuccess,
  successfetchAllPartnerAdmins,
  editStatusErrors,
  editStatusSuccess,
  editStatusStart,
  startUpdatePartnerAdmin,
  ErrorsUpdateParnterAdmin,
  succesUpdatePartnerAdmin,
  createJobProfileErrors,
  createJobProfileStart,
  createJobProfileSuccess,
  addGlobalSkillsErrors,
  addGlobalSkillsSuccess,
  addGlobalSkillsStart,
  updateProfileStart,
  updateProfileErrors,
  updateProfileSuccess,
  createZyvkaUserStart,
  createZyvkaUserSuccess,
  createZyvkaUserError,
  fetchAllZyvkaUsersSuccess,
  StartUpdateZyvka,
  ErrorsUpdateZyvka,
  SuccessUpdateZyvka,
  updateJobStart,
  updateJobErrors,
  updateJobSuccess,
  jobStatusUpdateStart,
  jobStatusUpdateErrors,
  jobStatusUpdateSuccess,
  QueryAskedInJobsStart,
  QueryAskedInJobsSuccess,
  QueryAskedInJobsErrors,
  DisablePartnerStart,
  DisablePartnerError,
  EnablePartnerStart,
  EnablePartnerSuccess,
  DisablePartnerSuccess,
  getDisabledPartnerAdminSuccess,
  createPartnerAdminStart,
  createPartnerAdminError,
  createClientStart,
  createClientSuccess,
  createClientError,
  createJobError,
  createJobSuccess,
  createJobStart,
  makeAdminStart,
  makeAdminError,
  makeAdminSuccess,
  archiveJobStart,
  archiveJobSuccess,
  archiveJobError,
  unarchiveJobStart,
  unarchiveJobSuccess,
  unarchiveJobError,
  AddClientContactPersonStart,
  AddClientContactPersonError,
  AddClientContactPersonSuccess,
  makePSMStart,
  makePSMSuccess,
} from "../features/ZyvkaMemberSlice";
import { defaultcCode } from "../Data/CountryCode";
import { profilePicURL } from "../utils/functions";
import {
  partnerHotJobsFetchSuccess,
  partnerNonTechJobsFetchSuccess,
  partnerTechJobsFetchSuccess,
} from "../features/partnersSlice";
const API = axios.create({ baseURL: process.env.REACT_APP_SERVER_URL });

export const createPartnerAdmin = async (
  partnerAdmin,
  dispatch,
  setFormValues = (e) => { }
) => {
  dispatch(createPartnerAdminStart());
  try {
    const result = await API.post(
      "api/zyvka/createPartnerAdmin",
      partnerAdmin,
      {
        withCredentials: true,
      },
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    await dispatch(createPartnerAdminSuccess(result.data));
    setFormValues({
      fname: "",
      lname: "",
      cCode: defaultcCode,
      company_name: "",
      mobileNum: "",
      email: "",
      confirmEmail: "",
    });
  } catch (error) {
    console.log(error);
    dispatch(createPartnerAdminError(error.response));
  }
};
export const fetchMyPartnerAdmins = async (data, dispatch) => {
  dispatch(startfetch());
  try {
    const result = await API.get(`api/zyvka/getMyPartnerAdmins/${data.id}`, {
      withCredentials: true,
    });
    dispatch(successfetch(result.data));
  } catch (error) {
    dispatch(fetcherrors(error.response));
    console.log(error);
  }
};
export const fetchAllPartnerAdmins = async (
  dispatch,
  setAllPartnerAdmins = (e) => { }
) => {
  dispatch(startfetch());
  try {
    const result = await API.get("api/zyvka/getPartnerAdmins", {
      withCredentials: true,
    });
    dispatch(successfetchAllPartnerAdmins(result.data));
    console.log(result?.data);

    let tmp = [];
    result.data.forEach((element) => {
      tmp.push({
        key: element.id,
        text: `${element.firstName} ${element.lastName} (${element.email})`,
        value: element.id,
        image: {
          avatar: true,
          src: profilePicURL(element.pfp_urn),
        },
      });
    });
    setAllPartnerAdmins(tmp);
    return tmp;
  } catch (error) {
    dispatch(fetcherrors(error.response));
    console.log(error);
  }
};
export const createApplicant = async (formData, dispatch) => {
  dispatch(start());
  try {
    const result = await API.post(
      "/api/applicant/create",
      formData,
      { withCredentials: true },
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    dispatch(createApplicantSuccess(result.data));
  } catch (error) {
    console.log(error);
    dispatch(errors());
  }
};

export const createJob = async (data, dispatch, navigate, userID) => {
  dispatch(createJobStart());
  try {
    const result = await API.post("/api/jobs/createJob", data, {
      withCredentials: true,
    });
    dispatch(createJobSuccess(result.data));

    navigate(`/dashboard/myjobs/${userID}`);
  } catch (error) {
    console.log(error);
    dispatch(createJobError(error.response));
  }
};
export const fetchMyJobs = async (userid, dispatch) => {
  dispatch(startfetch());
  try {
    const result = await API.get(`api/jobs/myjobs/${userid.id}`, {
      withCredentials: true,
    });
    dispatch(successfetchMyJobs(result.data));
  } catch (error) {
    dispatch(fetcherrors(error.response));
    console.log(error);
  }
};

export const filterJobs = async (data, dispatch) => {
  dispatch(startfetch());
  try {
    const result = await API.post("/api/jobs/getCustomJobs", data, {
      withCredentials: true,
    });
    dispatch(successfetchMyJobs(result.data));
  } catch (error) {
    dispatch(fetcherrors(error.response));
    console.log(error);
  }
};

export const filterApplicants = async (page, data) => {
  // dispatch(startfetch());
  try {
    const result = await API.post(
      `/api/zyvka/getCustomApplicants/page/${page}`,
      data,
      {
        withCredentials: true,
      }
    );
    return result.data;
  } catch (error) {
    // dispatch(fetcherrors(error.response));
    console.log(error);
  }
};

export const getAllApplicants = async (dispatch, page) => {
  dispatch(startfetch);
  try {
    const result = await API.get(`/api/zyvka/getAllApplicants/page/${page}`, {
      withCredentials: true,
    });
    let val = [];
    result?.data?.map((data, idx) => {
      val.push({
        ...data,
        key: idx + 1,
      });
    });

    dispatch(successfetchAllApplicants(val));
  } catch (error) {
    dispatch(fetcherrors(error.response));
    console.log(error);
  }
};

// export const getAllApplicantsForPartners = async (dispatch) => {
//   dispatch(startfetch);
//   try {
//    const result = await API.get(
//      "/api/partner/getApplicantsForPartnersAdminORPartners",
//      {
//        withCredentials: true,
//      }
//    );
//    let val = [];
//     result?.data?.map((data, idx) => {
//       val.push({
//         ...data,
//         key: idx + 1,
//       });
//     });

//     dispatch(successfetchAllApplicants(val));
//   console.log(result)
//   //dispatch(successFetchMyApplicant(result.data));
//  } catch (error) {
//    console.log(error);
//  }
// };

export const AddGlobalSkills = async (data, dispatch) => {
  dispatch(addGlobalSkillsStart());
  try {
    const result = await API.post("/api/zyvka/addGlobalSkills", data, {
      withCredentials: true,
    });
    dispatch(addGlobalSkillsSuccess(result.data));
  } catch (error) {
    dispatch(addGlobalSkillsErrors(error.response));
    console.log(error);
  }
};

export const createJobProfile = async (
  data,
  dispatch,
  setFormValues = (e) => { }
) => {
  dispatch(createJobProfileStart());
  try {
    const result = await API.post("/api/zyvka/mapSkillToJobProfile", data, {
      withCredentials: true,
    });
    console.log(result);
    dispatch(createJobProfileSuccess(result.data));
    setFormValues({
      jobProfile: "",
      skills: [],
    });
  } catch (error) {
    dispatch(createJobProfileErrors());
    console.log(error);
  }
};

export const UpdateJobProfile = async (
  data,
  dispatch,
  setFormValues = (e) => { }
) => {
  dispatch(updateProfileStart());
  try {
    const result = await API.post(
      "/api/zyvka/mapSkillToJobProfileUpdate",
      data,
      {
        withCredentials: true,
      }
    );
    dispatch(updateProfileSuccess(result.data));
    setFormValues({
      profile: "",
      skills_req: [],
    });
  } catch (error) {
    dispatch(updateProfileErrors());
    console.log(error);
  }
};

export const CreateNewClient = async (
  data,
  dispatch,
  setFormValues = (e) => { }
) => {
  dispatch(createClientStart());
  try {
    const result = await API.post("/api/client/createClient", data, {
      withCredentials: true,
    }, { headers: { "Content-Type": "multipart/form-data" } }
    );
    dispatch(createClientSuccess(result.data));
    setFormValues({
      name: "",
      email: "",
      confirmEmail: "",
      mobileNum: "",
      countryCode: defaultcCode,
      url: "",
      linkedIn_URL: "",
      company_news: "",
      country: "",
      state: "",
      city: "",
      address: "",
      contact_person_name: "",
      contact_person_email: "",
      contact_person_mobileNum: "",
      contact_person_designation: "",
      contact_person_countryCode: defaultcCode,
    });
  } catch (error) {
    dispatch(createClientError(error.response));
    console.log(error);
  }
};
export const getAllClients = async (dispatch, setcolumnData = (val) => { }) => {
  dispatch(startfetch());
  try {
    const result = await API.get("/api/client/getAllClients", {
      withCredentials: true,
    });
    let val = [];
    result.data.map((item, idx) => {
      val.push({
        key: idx + 1,
        id: item.id,
        name: item.name,
        email: item.email,
        country: item.country,
        state: item.state,
        city: item.city,
        revenue: item.revenue,
        type: item.type,
        lastConnectDate: item.lastConnectDate,
        country_code: item.country_code,
        mobileNum: item.mobileNum,
        url: item.url,
        address: item.address,
        linkedIn_URL: item.linkedIn_URL,
        company_news: item.company_news,
        contact_person: item.contact_person,
        remarks: item.remarks,
        Positions_available: item.Positions_available,
        sourcingVertical: item.sourcingVertical,
        clientContract: item.clientContract
      });
    });

    dispatch(successfetchClients(result.data));

    setcolumnData(val);
  } catch (error) {
    console.log(error);
    dispatch(fetcherrors(error.response));
  }
};

export const getClientFromId = async (dispatch, data) => {
  dispatch(startfetch());
  try {
    const result = await API.get(`/api/client/getClient/${data?.id}`, {
      withCredentials: true,
    });
    dispatch(successfetch());
    return result.data;
  } catch (error) {
    console.log(error);
    dispatch(fetcherrors(error.response));
  }
};

export const getAllUnApprovedClients = async (setcolumnData) => {
  // dispatch(startfetch());
  try {
    const result = await API.get("/api/client/getAllUnapprovedClientRevenue", {
      withCredentials: true,
    });
    let val = [];
    // result.data.map((item, idx) => {
    //   val.push({
    //     key: idx + 1,
    //     id: item.id,
    //     name: item.name,
    //     email: item.email,
    //     country: item.country,
    //     mobileNum: item.country_code
    //       ? `${item.country_code} ${item.mobileNum}`
    //       : item.mobileNum,
    //     url: item.url,
    //     address: item.address,
    //     linkedIn_URL: item.linkedIn_URL,
    //     company_news: item.company_news,
    //     contact_person: item.contact_person,
    //   });
    // });
    setcolumnData(result.data);
    // dispatch(successfetchClients(result.data));

    return result.data;
  } catch (error) {
    console.log(error);
    // dispatch(fetcherrors(error.response));
  }
};

export const getAllUnapprovedPartnerForJob = async (
  setcolumnData,
  dispatch
) => {
  dispatch(startfetch());
  try {
    const result = await API.get("/api/zyvka/pendingJobClaim", {
      withCredentials: true,
    });
    setcolumnData(result.data);
    dispatch(successfetch());
    return result.data;
  } catch (error) {
    console.log(error);
    dispatch(fetcherrors(error.response));
  }
};

export const AskQuery = async (data, dispatch) => {
  dispatch(queryOpenStart());
  try {
    const result = await API.post("/api/query/queryRaised", data, {
      withCredentials: true,
    });
    dispatch(qeuryOpenSuccess(result.data));
  } catch (error) {
    dispatch(queryOpenError());
    console.log(error);
  }
};

export const GetHotJobs = async (data, dispatch) => {
  dispatch(startfetch());
  try {
    const result = await API.get(
      `/api/jobs/getHotJobs/${data.active_status}?myjobs=${data.myjobs}&internalJobs=${data.internalJobs}`,
      {
        withCredentials: true,
      }
    );
    dispatch(hotJobsFetchSuccess(result.data));
  } catch (error) {
    dispatch(fetcherrors(error.response));
    console.log(error);
  }
};

export const GetTechJobs = async (data, dispatch) => {
  dispatch(startfetch());
  try {
    const result = await API.get(
      `/api/jobs/getTechJobs/${data.active_status}?myjobs=${data.myjobs}&internalJobs=${data.internalJobs}`,
      {
        withCredentials: true,
      }
    );
    dispatch(TechJobsFetchSuccess(result.data));
  } catch (error) {
    dispatch(fetcherrors(error.response));
    console.log(error);
  }
};
export const GetNonTechJobs = async (data, dispatch) => {
  dispatch(startfetch());
  try {
    const result = await API.get(
      `/api/jobs/getNonTechJobs/${data.active_status}?myjobs=${data.myjobs}&internalJobs=${data.internalJobs}`,
      {
        withCredentials: true,
      }
    );
    dispatch(NonTechJobsFetchSuccess(result.data));
  } catch (error) {
    dispatch(fetcherrors(error.response));
    console.log(error);
  }
};

// partner side job positions
export const GetPartnerNonTechJobs = async (dispatch) => {
  dispatch(startfetch());
  try {
    const result = await API.get(`/api/jobs/getAllopenNonTechJobsForClaim`, {
      withCredentials: true,
    });
    dispatch(partnerNonTechJobsFetchSuccess(result.data));
  } catch (error) {
    dispatch(fetcherrors(error.response));
    console.log(error);
  }
};

export const GetPartnerHotJobs = async (dispatch) => {
  dispatch(startfetch());
  try {
    const result = await API.get(`/api/jobs/getAllopenHotJobsForClaim`, {
      withCredentials: true,
    });
    dispatch(partnerHotJobsFetchSuccess(result.data));
  } catch (error) {
    dispatch(fetcherrors(error.response));
    console.log(error);
  }
};

export const GetPartnerTechJobs = async (dispatch) => {
  dispatch(startfetch());
  try {
    const result = await API.get(`/api/jobs/getAllopenTechJobsForClaim`, {
      withCredentials: true,
    });
    dispatch(partnerTechJobsFetchSuccess(result.data));
  } catch (error) {
    dispatch(fetcherrors(error.response));
    console.log(error);
  }
};

export const getSearchedJob = async (data, dispatch) => {
  dispatch(startfetch());
  try {
    const result = await API.post("/api/jobs/searchJobsForZyvkaUser", data, {
      withCredentials: true,
    });
    dispatch(successfetchMyJobs(result.data));
    return result?.data
  } catch (error) {
    dispatch(fetcherrors(error.response));
    console.log(error);
  }
};
export const ChangeApplicantStatus = async (data, dispatch) => {
  dispatch(editStatusStart());
  try {
    const result = await API.post("/api/zyvka/editApplicantStatus", data, {
      withCredentials: true,
    });
    // console.log(result.data);
    dispatch(editStatusSuccess(result.data));
  } catch (error) {
    console.log(error);
    dispatch(editStatusErrors());
  }
};

export const UpdatePartnerAdminProfile = async (data, dispatch) => {
  dispatch(startUpdatePartnerAdmin());
  try {
    const result = await API.post(
      "/api/partner/updateProfilePartnerAdmin",
      data,
      { withCredentials: true }
    );
    dispatch(succesUpdatePartnerAdmin(result.data));
  } catch (error) {
    console.log(error);
    dispatch(ErrorsUpdateParnterAdmin());
  }
};

export const createZyvkaUser = async (
  data,
  dispatch,
  setFormValues = (e) => { }
) => {
  dispatch(createZyvkaUserStart());
  try {
    const result = await API.post("/api/zyvka/createZyvkaUser", data, {
      withCredentials: true,
    });
    dispatch(createZyvkaUserSuccess(result.data));
    setFormValues({
      firstName: "",
      lastName: "",
      countryCode: defaultcCode,
      confirmEmail: "",
      email: "",
      mobileNum: "",
      isAdmin: false,
      linkedIn_URL: "",
      isSuperAdmin: false,
    });
  } catch (error) {
    console.log(error);
    dispatch(createZyvkaUserError(error.response));
  }
};

export const getAllZyvkaUsers = async (
  dispatch,
  setAllZyvkaUsers = (e) => { }
) => {
  dispatch(startfetch());
  try {
    const result = await API.get("/api/zyvka/getAllZyvkaUser", {
      withCredentials: true,
    });
    dispatch(fetchAllZyvkaUsersSuccess(result.data));

    let tmp = [];
    result.data.forEach((element) => {
      tmp.push({
        key: element.id,
        text: `${element.firstName} ${element.lastName} (${element.email})`,
        value: element.id,
        image: {
          avatar: true,
          src: profilePicURL(element?.pfp_urn),
        },
      });
    });
    setAllZyvkaUsers(tmp);
  } catch (error) {
    console.log(error);
    dispatch(fetcherrors(error.response));
  }
};
export const UpdateZyvkaProfile = async (data, dispatch) => {
  dispatch(StartUpdateZyvka());
  try {
    const result = await API.post("/api/zyvka/updateZyvka", data, {
      withCredentials: true,
    });
    dispatch(SuccessUpdateZyvka(result.data));
  } catch (error) {
    console.log(error);
    dispatch(ErrorsUpdateZyvka());
  }
};

export const updateJob = async (data, dispatch) => {
  dispatch(updateJobStart());
  try {
    const result = await API.post("/api/zyvka/updateJob", data, {
      withCredentials: true,
    },
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    dispatch(updateJobSuccess(result.data));
  } catch (error) {
    console.log(error);
    dispatch(updateJobErrors());
  }
};
export const updateJobStatus = async (data, dispatch) => {
  dispatch(jobStatusUpdateStart());
  try {
    const result = await API.post("/api/zyvka/editJobStatus", data, {
      withCredentials: true,
    });
    dispatch(jobStatusUpdateSuccess(result.data));
  } catch (error) {
    console.log(error);
    dispatch(jobStatusUpdateErrors());
  }
};

export const getAskedQuery = async (data, dispatch) => {
  dispatch(QueryAskedInJobsStart());
  try {
    const result = await API.get(`/api/query/getQueryAsked/${data.id}`, {
      withCredentials: true,
    });
    // console.log(result.data);
    dispatch(QueryAskedInJobsSuccess(result.data));
  } catch (error) {
    console.log(error);
    dispatch(QueryAskedInJobsErrors(error.response));
  }
};
export const disablePartnerAdmin = async (data, dispatch) => {
  dispatch(DisablePartnerStart());
  try {
    const result = await API.get(`/api/zyvka/disablePartnerAdmin/${data?.id}`, {
      withCredentials: true,
    });
    dispatch(DisablePartnerSuccess(result?.data));
  } catch (error) {
    console.log(error);
    dispatch(DisablePartnerError(error.response));
  }
};

export const enablePartneradmin = async (data, dispatch) => {
  dispatch(EnablePartnerStart());
  try {
    const result = await API.get(`/api/zyvka/enablePartnerAdmin/${data?.id}`, {
      withCredentials: true,
    });
    dispatch(EnablePartnerSuccess(result.data));
  } catch (error) {
    console.log(error);
    dispatch(DisablePartnerError(error.response));
  }
};

export const fetchDisabledPartnerAdmin = async (dispatch) => {
  dispatch(startfetch());

  try {
    const result = await API.get("/api/zyvka/getDisabledPartnerAdmin", {
      withCredentials: true,
    });
    // console.log(result.data);
    dispatch(getDisabledPartnerAdminSuccess(result.data));
  } catch (error) {
    console.log(error);
    dispatch(fetcherrors(error.response));
  }
};

export const makeAdmin = async (data, dispatch, setCalls) => {
  dispatch(makeAdminStart());
  try {
    console.log(data.enable);
    const result = await API.post(
      `/api/zyvka/updateZyvkaUserRole/${data?.id}`,
      data,
      {
        withCredentials: true,
      }
    );
    dispatch(makeAdminSuccess(result.data));
    setCalls((call) => !call);
  } catch (error) {
    console.log(error);
    dispatch(makeAdminError(error.response));
  }
};

export const ArchiveJob = async (data, dispatch) => {
  dispatch(archiveJobStart());

  try {
    const result = await API.post(
      "/api/jobs/archiveJob",
      {
        jobID: data?.id,
      },
      { withCredentials: true }
    );
    dispatch(archiveJobSuccess(result.data));
  } catch (error) {
    console.log(error);
    dispatch(archiveJobError(error.response));
  }
};

export const UnArchiveJob = async (data, dispatch) => {
  dispatch(unarchiveJobStart());

  try {
    const result = await API.post(
      "/api/jobs/unarchiveJob",
      {
        jobID: data?.id,
      },
      { withCredentials: true }
    );
    dispatch(unarchiveJobSuccess(result.data));
  } catch (error) {
    console.log(error);
    dispatch(unarchiveJobError(error.response));
  }
};

export const AddClientContactPerson = async (data, dispatch, setFlag) => {
  dispatch(AddClientContactPersonStart());
  try {
    const result = await API.post("/api/client/addClientContactPerson", data, {
      withCredentials: true,
    });
    dispatch(AddClientContactPersonSuccess(result.data));
    setFlag((flag) => !flag);
  } catch (error) {
    console.log(error);
    dispatch(AddClientContactPersonError(error.response));
  }
};
export const updateClient = async (
  dispatch,
  data,
  setcolumnData = (val) => { }
) => {
  dispatch(startfetch());
  try {
    const result = await API.post("/api/client/updateClient", data, {
      withCredentials: true,
    });
    if (Array.isArray(result?.data)) {
      let val = result.data.map((item, idx) => {
        return {
          key: idx + 1,
          id: item.id,
          name: item.name,
          email: item.email,
          country: item.country,
          state: item.state,
          city: item.city,
          mobileNum: item.country_code
            ? `${item.country_code} ${item.mobileNum}`
            : item.mobileNum,
          url: item.url,
          address: item.address,
          linkedIn_URL: item.linkedIn_URL,
          company_news: item.company_news,
          contact_person: item.contact_person,
        };
      });
      dispatch(successfetchClients(result.data));
      setcolumnData(val);
    } else {
      console.error(result?.data);
      setcolumnData([]);
    }
  } catch (error) {
    console.error("Error in updateClient:", error);
    dispatch(fetcherrors(error.response));
  }
};

export const updateContract = async (
  dispatch,
  data) => {
  dispatch(startfetch());
  try {
    const result = await API.post("/api/client/updateContract", data, {
      withCredentials: true,
    },
      { headers: { "Content-Type": "multipart/form-data" } }
    );
  } catch (error) {
    console.error("Error in updateClient:", error);
  }
};

export const updateClientContactPerson = async (dispatch, data) => {
  dispatch(startfetch());
  try {
    const result = await API.post(
      "/api/client/updateClientContactPerson",
      data,
      {
        withCredentials: true,
      }
    );
    dispatch(successfetch());
  } catch (error) {
    console.log(error);
    dispatch(fetcherrors(error.response));
  }
};

export const approveClient = async (setFlag, id) => {
  try {
    const result = await API.get(`/api/client/approvedClientRevenue/${id}`, {
      withCredentials: true,
    });
    setFlag((flag) => !flag);
  } catch (error) {
    console.log(error);
  }
};

export const getPreviousVideoResume = async (data) => {
  try {
    const result = await API.get(`/api/video/previousVideoResume/${data?.id}`, {
      withCredentials: true,
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const approvePartnerJobProposal = async (data, setFlag) => {
  try {
    const result = await API.post(`/api/zyvka/claimJobApproval`, data, {
      withCredentials: true,
    });
    setFlag((flag) => !flag);
  } catch (error) {
    console.log(error);
  }
};

export const ApplicantRemarkMethod = async (data) => {
  try {
    console.log(data);
    //* this route to the updateClientRemark controller
    const result = await API.post(
      "/api/client/privillageAccess/updateClientRemark",
      data,
      {
        withCredentials: true,
      }
    );
    // console.log(result.data);
  } catch (error) {
    console.log(error);
  }
};

export const editApplicantStatusThroughClient = async (data) => {
  try {
    const result = await API.post(
      "/api/client/privillageAccess/editApplicantStatus",
      data,
      {
        withCredentials: true,
      }
    );
    // console.log(result.data);
  } catch (error) {
    console.log(error);
  }
};

export const updateClosedStatusJob = async (data) => {
  try {
    const result = await API.post("/api/zyvka/updateClosedJob", data, {
      withCredentials: true,
    });
  } catch (error) {
    console.log(error);
  }
};

export const makePSM = async (data, dispatch, setCalls) => {
  dispatch(makePSMStart());
  try {
    const result = await API.get(`/api/zyvka/updatePSM/${data.id}`, {
      withCredentials: true,
    });
    dispatch(makePSMSuccess(result.data));
    setCalls((call) => !call);
  } catch (error) {
    console.log(error);
    dispatch(makeAdminError(error.response));
  }
};

export const ChangeJobAfterResign = async (data, dispatch) => {
  dispatch(editStatusStart());
  try {
    const result = await API.post("/api/zyvka/resignJobUpdate", data, {
      withCredentials: true,
    });
    // console.log(result.data);
    dispatch(editStatusSuccess(result.data));
  } catch (error) {
    console.log(error);
    dispatch(editStatusErrors());
  }
};
export const fetchJobData = async (id) => {
  try {
    const result = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/jobs/getJobByID/${id}`,
      { withCredentials: true }
    );
    return result?.data;
  } catch (error) {
    console.log(error);
  }
};
export const SendEmailToPartners = async (data, dispatch) => {
  try {
    const result = await API.post(
      "/api/jobs/sendEmailToPartnerOnJobUpdate",
      data,
      {
        withCredentials: true,
      }
    );
    return result;
    // console.log(result.data);
  } catch (error) {
    console.log(error);
  }
};


export const updateJobJD = async (data, prop) => {
  try {
    console.log(prop)
    const result = await API.post(`/api/zyvka/updateJDForJob`,
      data,
      {
        withCredentials: true,
      },
      {
        headers: { "Content-Type": "multipart/form-data" }
      }
    );
    return true
  } catch (error) {
    console.log(error);
  }
};




export const getPartnerFromId = async (dispatch, data) => {
  console.log(data, "data here")
  dispatch(startfetch());
  try {
    const result = await API.get(`/api/partner/getPartnerFromId/${data?.id}`,
      data,
      {
        withCredentials: true,
      });
    dispatch(successfetch());
    return result.data;
  } catch (error) {
    console.log(error);
    dispatch(fetcherrors(error.response));
  }
};


export const updatePartnerFromDetailsPage = async (data, dispatch) => {
  dispatch(startfetch());
  try {
    const result = await API.post(
      `/api/partner/updatePartner/${data?.id}`,
      data,
      {
        withCredentials: true,
      }
    );
    dispatch(successfetch());
  } catch (error) {
    console.log(error);
    dispatch(fetcherrors(error.response));
  }
};


export const updatePartnerContractFromDetailsPage = async (data, dispatch) => {
  dispatch(startfetch());
  try {
    const result = await API.post(
      `/api/partner/updateContract`,
      data,
      {
        withCredentials: true,
      },
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    dispatch(successfetch());
  } catch (error) {
    console.log(error);
    dispatch(fetcherrors(error.response));
  }
};




export const getVerticalSourcingData = async (dispatch) => {
  dispatch(start())
  try {
    const result = await API.get(`/api/zyvka/getSourcingVerticals`, {
      withCredentials: true,
    });
    dispatch(setSourcingVerticalData(result?.data?.sourcingVerticals))
    return result?.data;
  } catch (error) {
    dispatch(fetchErrors())
    console.log(error);
  }
};


export const getGeogrpahyData = async (dispatch) => {
  try {
    const result = await API.get(`/api/zyvka/getGeography`, {
      withCredentials: true,
    });
    return result?.data;
  } catch (error) {
    console.log(error);
  }
};


export const raiseFlag = async (data) => {
  try {
    const result = await API.post("api/zyvka/raiseFlag", data, {
      withCredentials: true,
    });
    return result?.data
  } catch (error) {
    console.log(error)
  }
};


export const dropFlag = async (data) => {
  try {
    const result = await API.post("api/zyvka/resolvedRaiseFlag", data, {
      withCredentials: true,
    });
    return result?.data;
  } catch (error) {
    console.log(error)
  }
};


export const getAllUnApprovedJobs = async (data) => {
  try {
    const result = await API.get("api/jobs/allNewJobsForSuperAdminApproval", {
      withCredentials: true,
    });
    return result?.data;
  } catch (error) {
    console.log(error)
  }
};



export const approveJobBySuperAdmin = async (data) => {
  try {
    const result = await API.post("api/jobs/newJobApprovedBySuperAdmin", data, {
      withCredentials: true,
    });
    return result?.data;
  } catch (error) {
    console.log(error)
  }
};


export const fetchPartnerRecomm = async (data) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/jobs/partnerRecommendation`,
      data,
      { withCredentials: true }
    );
    console.log(result?.data);
    let tmp = [];
    result.data?.forEach((element) => {
      tmp.push({
        key: element.id,
        text: `${element.firstName} ${element.lastName} (${element.email}) - 🪄`,
        value: element.id,
        image: {
          avatar: true,
          src: profilePicURL(element.pfp_urn),
        },
        content: (
          <span>
            {`${element.firstName} ${element.lastName} (${element.email})`} -
            <span style={{ color: 'white', background: '#6855F6', fontWeight: "bolder", borderRadius: '4px', padding: '0px 4px' }}> Recommended✨ </span>
          </span>
        ),
      });
    });
    return tmp;
    // return result?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getIndustryData = async () => {
  try {
    const result = await API.get(`/api/zyvka/getIndustry`, {
      withCredentials: true,
    });
    return result?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getPrimarySkillsData = async () => {
  try {
    const result = await API.get(`/api/zyvka/getPrimarySkills`, {
      withCredentials: true,
    });
    return result?.data;
  } catch (error) {
    console.log(error);
  }
};