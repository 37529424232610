import { Button, Space, Table, Tag, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PartnerState, selectPartners } from "../features/partnersSlice";
import { selectUser } from "../features/userSlice";
import { getPartners, deletePartner } from "../api/partner";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { UpdatePartnerProfile } from "../api/partner";

import { useDispatch } from "react-redux";
const { Column, ColumnGroup } = Table;
// const data = [];

const DeletePartner = () => {
  const dispatch = useDispatch();
  const partnerData = useSelector(selectPartners);
  const partnerState = useSelector(PartnerState);
  const [calls, setCalls] = useState(false);
  const [updateOpen, setUpdateOpen] = useState("");

  const [newFirstName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newMobileNo, setNewMobileNumber] = useState("");

  const fetchPartners = async () => {
    try {
      getPartners(dispatch);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchPartners();
  }, [calls]);

  const deleteHandle = (id) => {
    if (
      window.confirm("Are You Sure You want to delete This Partner ?") === true
    ) {
      deletePartner(id, dispatch);
      if (calls === true) setCalls(false);
      else setCalls(true);
    }
  };
  const updateHandler = async (id) => {
    if (window.confirm(`Are you sure you want to Update details`)) {
      await UpdatePartnerProfile(
        {
          id: id,
          newFirstName: newFirstName,
          newLastName: newLastName,
          newEmail: newEmail,
          newMobileNum: newMobileNo,
        },
        dispatch
      );
      setUpdateOpen("");
    }
    if (calls === false) setCalls(true);
    else setCalls(false);
  };
  // this is for search
  const [searchValue, setSearchValue] = useState("");

  return (
    <div className="tableDiv">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexWrap: "wrap",
          gap: "10px",
        }}
      // className="searchbox"
      >
        <Space direction="horizontal" wrap={true}>
          <Input
            placeholder="Search Here"
            style={{
              minWidth: 200,
              maxWidth: 400,
              paddingLeft: 10,
            }}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
          <Button style={{ width: 80 }}>Search</Button>
        </Space>
      </div>

      <Table
        scroll={{ x: true }}
        dataSource={partnerData}
        loading={partnerState?.isPending}
        bordered={true}
      >
        <Column
          title="First Name"
          // dataIndex="name"
          key="name"
          filteredValue={[searchValue]}
          onFilter={(value, record) => {
            return (
              String(record?.firstName)
                ?.toLowerCase()
                .includes(value.toLocaleLowerCase()) ||
              String(record?.lastName)
                ?.toLowerCase()
                .includes(value.toLocaleLowerCase()) ||
              String(record?.email)
                ?.toLowerCase()
                .includes(value.toLocaleLowerCase()) ||
              String(record?.mobileNum)
                ?.toLowerCase()
                .includes(value.toLocaleLowerCase())
            );
          }}
          render={(_, record) => {
            return (
              <>
                {updateOpen === record.id ? (
                  <div class="input-wrapper" key={record.id}>
                    <label for="first">First Name</label>
                    <input
                      type="text"
                      value={newFirstName}
                      onChange={(e) => setNewFirstName(e.target.value)}
                    ></input>
                  </div>
                ) : (
                  <h1>{record.firstName}</h1>
                )}
              </>
            );
          }}
        />
        <Column
          title="Last Name"
          // dataIndex="name"
          key="name"
          render={(_, record) => {
            return (
              <>
                {updateOpen === record.id ? (
                  <div class="input-wrapper" key={record.id}>
                    <label for="first">Last Name</label>
                    <input
                      value={newLastName}
                      onChange={(e) => setNewLastName(e.target.value)}
                    ></input>
                  </div>
                ) : (
                  <span key={record.id}>{record.lastName}</span>
                )}
              </>
            );
          }}
        />
        <Column
          title="email"
          render={(_, record) => {
            return (
              <>
                {updateOpen === record.id ? (
                  <div class="input-wrapper" key={record.id}>
                    <label for="first">Email</label>
                    <input
                      value={newEmail}
                      onChange={(e) => setNewEmail(e.target.value)}
                    ></input>
                  </div>
                ) : (
                  <span key={record.id}>{record.email}</span>
                )}
              </>
            );
          }}
        />
        <Column
          title="mobileNum"
          render={(_, record) => {
            return (
              <>
                {updateOpen === record.id ? (
                  <div class="input-wrapper" key={record.id}>
                    <label for="first">Mobile</label>
                    <input
                      value={newMobileNo}
                      onChange={(e) => setNewMobileNumber(e.target.value)}
                    ></input>
                  </div>
                ) : (
                  <span key={record.id}>{record.mobileNum}</span>
                )}
              </>
            );
          }}
        />
        {/* <Column
        title="Tags"
        dataIndex="tags"
        key="tags"
        render={(tags) => (
          <>
            {tags.map((tag) => (
              <Tag color="blue" key={tag}>
                {tag}
              </Tag>
            ))}
          </>
        )}
      /> */}
        <Column
          title="Action"
          key="action"
          render={(_, record) => (
            <Space size="middle" key={record.id}>
              {updateOpen === record.id ? (
                <Button type="primary" onClick={() => updateHandler(record.id)}>
                  Save
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setUpdateOpen(record.id);
                    setNewEmail(record.email);
                    setNewFirstName(record.firstName);
                    setNewLastName(record.lastName);
                    setNewMobileNumber(record.mobileNum);
                  }}
                >
                  <EditOutlined />
                </Button>
              )}

              <Button
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => deleteHandle(record.id)}
              >
                <DeleteOutlined />
              </Button>
            </Space>
          )}
        />
      </Table>
    </div>
  );
};

export default DeletePartner;
