import { UserState } from "../features/userSlice";

import userImg from "../assets/images/user-icon.svg";
export const isPresentinArray = (arr, val) => {
  return arr.includes(val);
};

// for checking if the given member is a team member for the specific job
export const IsZyvkaTeamMember = (jobData, UserState) => {
  const a = jobData?.zyvka_team_members?.includes(UserState.userInfo.id);
  const b = jobData?.zyvka_userID === UserState?.userInfo?.id;
  return (
    (UserState?.role === "superAdmin")||
    ((UserState.role === "zyvkaAdmin" ||
      UserState.role === "zyvkaUser" ) &&(a || b))
  );
};

export const profilePicURL = (pfp_urn) => {
  if (pfp_urn) {
    return `${process.env.REACT_APP_SERVER_URL}/api/files/profile/img/${pfp_urn}`;
  } else {
    return userImg;
  }
};

export const profilePicID = (id) => {
  console.log(id);
  if (id) {
    return `${process.env.REACT_APP_SERVER_URL}/api/files/profile/img/user/${id}`;
  } else {
    return userImg;
  }
};

export const getJdFromURL = (jd_urn) => {
  if (jd_urn) {
    return `${process.env.REACT_APP_SERVER_URL}/api/files/jd/${jd_urn}`;
  } else {
    return "";
  }
};
