import { Button, Space, Table, Tag, Input } from "antd";
const { Column, ColumnGroup } = Table;

const data = [
  {
    key: "1",
    name: "John Brown",
    email: "johnbrown@email.com",
    mobile: "+91 1234567890",
    addedBy: "Creator name",
    position: <a>Frontend Developer</a>,
    statusChangedOn: "12-12-22",
  },
  {
    key: "2",
    name: "Jim Green",
    email: "jimgreen@email.com",
    mobile: "+91 1234567890",
    addedBy: "User name",
    position: <a>Chartered Accountant</a>,
    statusChangedOn: "03-12-22",
  },
  {
    key: "3",
    name: "Joe Black",
    email: "joeblack@email.com",
    mobile: "+91 1234567890",
    addedBy: "Another user",
    position: <a>Frontend Developer</a>,
    statusChangedOn: "19-12-22",
  },
];

const ActionRequired = () => {
  return (
    <>
      <br></br>
      <Table dataSource={data}>
        <Column title="Name" dataIndex="name" key="name" />
        <Column title="Email" dataIndex="email" key="email" />
        <Column title="Mobile" dataIndex="mobile" key="mobile" />
        <Column title="Added By" dataIndex="addedBy" key="addedBy" />
        <Column title="Changed on" dataIndex="statusChangedOn" key="statusChangedOn" />
        <Column title="Applied Position" dataIndex="position" key="position" />
        <Column
          title="Action"
          key="action"
          render={(_, record) => (
            <Space size="middle">
              <Button>Email</Button>
              <Button danger>Ignore</Button>
            </Space>
          )}
        />
      </Table>
    </>
  );
};

export default ActionRequired;
