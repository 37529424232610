import React, { useState, useEffect } from "react";
import {
    Button,
    Typography,
    Spin,
    Divider,
} from "antd";
import {
    FireOutlined,
    LaptopOutlined,
    BarChartOutlined,
    LeftOutlined, RightOutlined
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import JobAssignDetails from "./JobAssignDetails";
import {
    GetPartnerHotJobs,
    GetPartnerTechJobs,
    GetPartnerNonTechJobs,
} from "../api/ZyvkaMember";
import { fetchAllSkills, getAllJobProfiles } from "../api/auth";
import { UserState } from "../features/userSlice";
import { selectPartnerHotJobs, selectPartnerNonTechJobs, selectPartnerTechJobs } from "../features/partnersSlice";

const { Title } = Typography;
const PartnerJobList = () => {
    const [flag, setFlag] = useState(false);
    const [skillOption, setSkillOption] = useState([]);
    const userState = useSelector(UserState);
    const dispatch = useDispatch();
    const ChangeFlag = () => {
        setFlag((flag) => !flag);
    };

    const fetchHotJobs = async () => {
        try {
            await GetPartnerHotJobs(dispatch);
        } catch (error) {
            console.log(error);
        }
    };
    const fetchTechJobs = async () => {
        try {
            await GetPartnerTechJobs(dispatch);
        } catch (error) {
            console.log(error);
        }
    };
    const fetchNonTechJobs = async () => {
        try {
            await GetPartnerNonTechJobs(dispatch);
        } catch (error) {
            console.log(error);
        }
    };
    const fetchJobProfiles = async () => {
        try {
            await getAllJobProfiles(dispatch);
        } catch (error) {
            console.log(error);
        }
    };
    const fetchSkills = async () => {
        try {
            await fetchAllSkills(dispatch, setSkillOption);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchHotJobs();
        fetchNonTechJobs();
        fetchTechJobs();
        fetchJobProfiles();
        fetchSkills();
    }, [flag]);
    const HotJobsData = useSelector(selectPartnerHotJobs);
    const TechJobsData = useSelector(selectPartnerTechJobs);
    const NonTechJobsData = useSelector(selectPartnerNonTechJobs);
    return (
        <div style={{ height: "100vh", overflow: "scroll" }}>
            <br></br>


            {userState.isPending === true ? (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                    }}
                >
                    <Spin size="large" />
                </div>
            ) : (
                <>
                    {" "}
                    <Divider orientation="left">
                        <Title level={3} prefix={<FireOutlined />}>
                            <FireOutlined style={{ color: "orange" }} /> Hot Jobs
                        </Title>
                    </Divider>
                    <div className="job-carousel" id="1" style={{ display: "flex", justifyItems: "center", position: "relative" }}>
                        <Button style={{
                            position: "sticky",
                            top: '50%',
                            zIndex: 10,
                            left: 0,
                            width: "40px",
                            paddingLeft: "5px",
                            borderColor: "#6855f6",
                            boxShadow: "1px 2px 9px #B3A8FF"
                        }} onClick={() => {
                            document.getElementById('1').scrollLeft -= 400;
                        }}
                            // type="primary"
                            icon={<LeftOutlined style={{
                                color: "#6855f6", width: "40px",
                            }} />}
                        />
                        {HotJobsData.map((data, index) => {
                            return (
                                <div style={{ marginLeft: index === 0 ? '-25px' : '0px' }} >
                                    <JobAssignDetails
                                        data={data}
                                        width={370}
                                        ChangeFlag={ChangeFlag}
                                        skills={skillOption}
                                    />
                                </div>
                            );
                        })}
                        <Button style={{
                            position: "sticky",
                            width: "40px",
                            paddingLeft: "5px",
                            right: 20,
                            top: '50%',
                            zIndex: 10,
                            borderColor: "#6855f6",
                            boxShadow: "1px 2px 9px #B3A8FF"
                        }} onClick={() => {
                            document.getElementById('1').scrollLeft += 400;
                        }}
                            icon={<RightOutlined style={{
                                color: "#6855f6", width: "40px",
                            }} />}
                        />
                        {/* </Carousel> */}
                    </div>
                    <Divider orientation="left">
                        <Title level={3}>
                            <LaptopOutlined style={{ color: "green" }} /> Tech Jobs
                        </Title>
                    </Divider>
                    <div className="job-carousel" id="2" style={{ display: "flex", justifyItems: "center", position: "relative" }}>
                        <Button style={{
                            position: "sticky",
                            top: '50%',
                            zIndex: 10,
                            left: 0,
                            width: "40px",
                            paddingLeft: "5px",
                            borderColor: "#6855f6",
                            boxShadow: "1px 2px 9px #B3A8FF"
                        }} onClick={() => {
                            document.getElementById('2').scrollLeft -= 400;
                        }}
                            // type="primary"
                            icon={<LeftOutlined style={{
                                color: "#6855f6", width: "40px",
                            }} />}
                        />
                        {TechJobsData.map((data, index) => {
                            return (
                                <div style={{ marginLeft: index === 0 ? '-25px' : '0px' }}>
                                    <JobAssignDetails
                                        data={data}
                                        width={370}
                                        ChangeFlag={ChangeFlag}
                                        skills={skillOption}
                                    />
                                </div>
                            );
                        })}
                        <Button style={{
                            position: "sticky",
                            width: "40px",
                            paddingLeft: "5px",
                            right: 20,
                            top: '50%',
                            zIndex: 10,
                            borderColor: "#6855f6",
                            boxShadow: "1px 2px 9px #B3A8FF"
                        }} onClick={() => {
                            document.getElementById('2').scrollLeft += 400;
                        }}
                            icon={<RightOutlined style={{
                                color: "#6855f6", width: "40px",
                            }} />}
                        />
                    </div>
                    <Divider orientation="left">
                        <Title level={3}>
                            <BarChartOutlined style={{ color: "blue" }} /> Non-Tech Jobs
                        </Title>
                    </Divider>
                    <div className="job-carousel" id="3" style={{ display: "flex", justifyItems: "center", position: "relative" }}>
                        <Button style={{
                            position: "sticky",
                            top: '50%',
                            zIndex: 10,
                            left: 0,
                            width: "40px",
                            paddingLeft: "5px",
                            borderColor: "#6855f6",
                            boxShadow: "1px 2px 9px #B3A8FF"
                        }} onClick={() => {
                            document.getElementById('3').scrollLeft -= 400;
                        }}
                            // type="primary"
                            icon={<LeftOutlined style={{
                                color: "#6855f6", width: "40px",
                            }} />}
                        />
                        {NonTechJobsData.map((data, index) => {
                            return (
                                <div style={{ marginLeft: index === 0 ? '-25px' : '0px' }}>
                                    <JobAssignDetails
                                        data={data}
                                        width={350}
                                        ChangeFlag={ChangeFlag}
                                        skills={skillOption}
                                    />
                                </div>
                            );
                        })}
                        <Button style={{
                            position: "sticky",
                            width: "40px",
                            paddingLeft: "5px",
                            right: 20,
                            top: '50%',
                            zIndex: 10,
                            borderColor: "#6855f6",
                            boxShadow: "1px 2px 9px #B3A8FF"
                        }} onClick={() => {
                            document.getElementById('3').scrollLeft += 400;
                        }}
                            icon={<RightOutlined style={{
                                color: "#6855f6", width: "40px",
                            }} />}
                        />
                    </div>
                </>

            )}
        </div>
    );
};

export default PartnerJobList;
