import axios from "axios";

import React, { useState, useEffect } from "react";
import {
    Card,
    Avatar,
    Typography,
    Button,
    Descriptions,
    Tag,
    Space,
    Tooltip,
    Popconfirm,
    message
    // Table
} from "antd";
import countryCode, { defaultcCode } from "../Data/CountryCode";

import {
    UserOutlined,
    LinkedinFilled,
    GlobalOutlined,
    EditOutlined,
    DownloadOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { Modal, Form, Icon } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchAllSkills,
    getupdateByID,
    updateCanidateDetails,
} from "../api/auth";
import { UserState, sourcingVerticalOptions } from "../features/userSlice";
import { countryCodeSemantic } from "../Data/CountryCode";
import Currency, {
    ctcMap,
    CurrencySymbols,
    defaultCurrency,
} from "../Data/Currency";
import ErrorMessage from "../Error/ErrorMessage";
import SuccessMessage from "../Small-Components/SuccessMessage";
import { Menu, Table } from 'semantic-ui-react';
import { EyeOutlined } from "@ant-design/icons";
import { email } from "../features/clientSlice";
import { getAllClients, getClientFromId, updateClient, updateClientContactPerson, getVerticalSourcingData, updateContract, getIndustryData } from "../api/ZyvkaMember";
import { ZyvkaMemberState } from "../features/ZyvkaMemberSlice";
import { useLocation } from "react-router-dom";

// import Column from "antd/lib/table/Column";

const { Title, Paragraph } = Typography;
const { Column } = Table;
const API = axios.create({ baseURL: process.env.REACT_APP_SERVER_URL });


const ClientDetails = () => {
    const dispatch = useDispatch();
    const userState = useSelector(UserState);
    const zyvkaState = useSelector(ZyvkaMemberState);
    const { id } = useParams();
    const [clientUserId, setClientUserId] = useState(null);
    const [ClientID, setClientID] = useState(id);
    const [lcDate, setLcDate] = useState();
    const [remark, setRemark] = useState();
    const [flag, setFlag] = useState(false);
    const [isAddNewModalOpen, setIsAddNewModalOpen] = useState(false);
    const [isUpdateDateModalOpen, setIsUpdateDateModalOpen] = useState(false);
    const [isClientUpdateModalOpen, setIsClientUpdateModalOpen] = useState(false);
    const [update, setUpdate] = useState({});
    const [countryOption, setCountryOption] = useState([]);
    const [stateOption, setStateOption] = useState([]);
    const [cityOption, setCityOption] = useState([]);
    const [state, setState] = useState(null);
    const [city, setCity] = useState();
    const [country, setCountry] = useState("");
    const [prevstate, setPrevstate] = useState();
    const [prevcity, setPrevity] = useState();
    const [prevcountry, setPrevcountry] = useState("");
    const [updateUser, setUpdateUser] = useState({});
    const [isClientUserModalOpen, setIsClientUserModalOpen] = useState(false);
    const [fileObj, setFileObj] = useState()
    const [isDefaultRevenue, setIsDefaultRevenue] = useState(true);
    const [openContractModal, setOpenContractModal] = useState(false)
    const [industryOptions, setIndustryOptions] = useState([]);
    const fetchIndustryData = async () => {
        try {
            const res = await getIndustryData();
            setIndustryOptions(res?.industry);
        } catch (e) {
            console.error(e)
        }
    }
    const clientType = [
        { id: 1, text: "Hot", value: "Hot" },
        { id: 2, text: "Warm", value: "Warm" },
        { id: 3, text: "Cold", value: "Cold" },
    ];
    const Positions_available = [
        { id: 1, text: "Yes", value: true },
        { id: 2, text: "No", value: false }
    ];
    const currencyOptions = [
        { id: 1, text: "INR", value: "INR" },
        { id: 2, text: "USD", value: "USD" },
    ];
    const modelOptions = [
        { id: 1, text: "Percentage", value: "Percentage" },
        { id: 2, text: "Fixed Price", value: "Fixed Price" },
    ];
    const repModel = [
        { id: 1, text: "Replacement", value: "Replacement" },
        { id: 2, text: "Refund", value: "Refund" },
    ];
    const verticlalOptions = useSelector(sourcingVerticalOptions)
    useEffect(() => {
        const getVerticalData = async () => {
            await getVerticalSourcingData(dispatch)
        }
        getVerticalData()
        fetchIndustryData()
    }, [])
    function formatDateToYYYYMMDD(originalDateString) {
        const originalDate = new Date(originalDateString);

        // Get the individual components
        const year = originalDate.getFullYear();
        const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
        const day = originalDate.getDate().toString().padStart(2, '0');

        // Format the date as "yyyy-mm-dd"
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }
    const contractUpdateHandler = async () => {
        try {
            if (!fileObj) {
                message.error('Please upload contract')
            } else {
                const formdata = new FormData()
                formdata.append("file", fileObj)
                formdata.append("oldFile", update?.clientContract)
                const formvalue = { id };
                formdata.append("formvalue", JSON.stringify(formvalue))
                const res = await updateContract(dispatch, formdata)
                setFlag((flag) => !flag);
                setOpenContractModal(false)
            }
        } catch (e) {
            console.log(e)
        }
    }
    const fetchCountry = async () => {
        try {
            const result = await axios.get(
                "https://api.countrystatecity.in/v1/countries",
                {
                    headers: {
                        "X-CSCAPI-KEY":
                            "eVNMbzF6NEVPNFFld1IyMXlvVlN5OVpMaExVWFN1RG82elc2SFBscg==",
                    },
                }
            );
            setCountryOption(result.data);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        const fetchClient = async () => {
            const res = await getClientFromId(dispatch, { id: id });
            setUpdate(res);
            setPrevcountry(res?.country);
            setPrevity(res?.city);
            setPrevstate(res?.state);
            if (res?.revenue?.length === 0) {
                setIsDefaultRevenue(true);
            }
        };
        fetchClient();
    }, [dispatch, flag]);
    useEffect(() => {
        fetchCountry();
    }, []);

    useEffect(() => {
        const fetchState = async () => {
            try {
                if (country) {
                    const result = await axios.get(
                        `https://api.countrystatecity.in/v1/countries/${country}/states`,
                        {
                            headers: {
                                "X-CSCAPI-KEY":
                                    "eVNMbzF6NEVPNFFld1IyMXlvVlN5OVpMaExVWFN1RG82elc2SFBscg==",
                            },
                        }
                    );
                    setStateOption(result.data);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchState();
    }, [dispatch, flag, country]);
    useEffect(() => {
        const fetchCity = async () => {
            try {
                if (state) {
                    const result = await axios.get(
                        `https://api.countrystatecity.in/v1/countries/${country}/states/${state}/cities`,
                        {
                            headers: {
                                "X-CSCAPI-KEY":
                                    "eVNMbzF6NEVPNFFld1IyMXlvVlN5OVpMaExVWFN1RG82elc2SFBscg==",
                            },
                        }
                    );
                    setCityOption(result.data);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchCity();
    }, [state]);

    const nameOnClick = async (value) => {
        setIsClientUpdateModalOpen(true)
    };

    const handleOkClientUpdate = async () => {
        console.log(new Date(update?.lastConnectDate)?.toISOString());
        let selectedCountryName = '';
        let selectedStateName = '';
        let selectedCityName = '';
        if (update?.country) {
            const selectedCountry = countryOption?.find((e) => e.iso2 === update?.country);
            selectedCountryName = selectedCountry ? selectedCountry.name : '';
        }
        if (update?.state) {
            const selectedState = stateOption?.find((e) => e.iso2 === update?.state);
            selectedStateName = selectedState ? selectedState.name : '';
        }
        if (update?.city) {
            const selectedCity = cityOption?.find((e) => e.iso2 === update?.city);
            selectedCityName = selectedCity ? selectedCity.name : '';
        }
        console.log(
            {
                clientID: id,
                name: update?.name,
                email: update?.email,
                countryCode: update?.country_code,
                mobileNum: update?.mobileNum,
                url: update?.url,
                address: update?.address,
                linkedIn_URL: update?.linkedIn_URL,
                company_news: update?.company_news,
                contact_person: update?.contact_person,
                charge_value: update?.revenue?.[0]?.Value ? parseFloat(update?.revenue?.[0]?.Value) : 10,
                country: selectedCountryName || update?.country,
                state: selectedStateName || update?.state,
                city: selectedCityName || update?.city,
                payment_terms: update?.revenue?.[0]?.PaymentTerms ? parseFloat(update?.revenue?.[0]?.PaymentTerms) : 30,
                invoicing_terms: update?.revenue?.[0]?.InvoicingTerms ? parseFloat(update?.revenue?.[0]?.InvoicingTerms) : 10,
                replacement_model: update?.revenue?.[0]?.ReplacementModel ? update?.revenue?.[0]?.ReplacementModel : "Replacement",
                replacement_timeline: update?.revenue?.[0]?.ReplacementTimeline ? parseFloat(update?.revenue?.[0]?.ReplacementTimeline) : 60,
                currency: update?.revenue?.[0]?.Currency ? update?.revenue?.[0]?.Currency : "INR",
                revenue_model: update?.revenue?.[0]?.RevenueMode ? update?.revenue?.[0]?.RevenueMode : "Percentage",
                lastConnectDate: update?.lastConnectDate,
                remarks: update?.remarks,
                clientType: update?.type,
                Positions_available: update?.Positions_available,
                sourcingVertical: update?.sourcingVertical,
            })
        const datavalue = {
            clientID: id,
            name: update?.name,
            email: update?.email,
            countryCode: update?.country_code,
            mobileNum: update?.mobileNum,
            url: update?.url,
            address: update?.address,
            linkedIn_URL: update?.linkedIn_URL,
            company_news: update?.company_news,
            contact_person: update?.contact_person,
            charge_value: update?.revenue?.[0]?.Value ? parseFloat(update?.revenue?.[0]?.Value) : 10,
            country: selectedCountryName || update?.country,
            state: selectedStateName || update?.state,
            city: selectedCityName || update?.city,
            payment_terms: update?.revenue?.[0]?.PaymentTerms ? parseFloat(update?.revenue?.[0]?.PaymentTerms) : 30,
            invoicing_terms: update?.revenue?.[0]?.InvoicingTerms ? parseFloat(update?.revenue?.[0]?.InvoicingTerms) : 10,
            replacement_model: update?.revenue?.[0]?.ReplacementModel ? update?.revenue?.[0]?.ReplacementModel : "Replacement",
            replacement_timeline: update?.revenue?.[0]?.ReplacementTimeline ? parseFloat(update?.revenue?.[0]?.ReplacementTimeline) : 60,
            currency: update?.revenue?.[0]?.Currency ? update?.revenue?.[0]?.Currency : "INR",
            revenue_model: update?.revenue?.[0]?.RevenueMode ? update?.revenue?.[0]?.RevenueMode : "Percentage",
            lastConnectDate: update?.lastConnectDate,
            remarks: update?.remarks,
            clientType: update?.type,
            Positions_available: update?.Positions_available,
            sourcingVertical: update?.sourcingVertical,
            industry: update?.industry
        }
        await updateClient(
            dispatch,
            datavalue
        );
        setIsClientUpdateModalOpen(false);
        setFlag((flag) => !flag);
        setUpdate();
        setCity();
        setCountry();
        setState();
        setPrevcountry();
        setPrevity();
        setPrevstate();
        setStateOption();
        setCityOption();
        // setIsAddNewModalOpen(false);
    };
    const handleOkClientUserUpdate = async () => {
        await updateClientContactPerson(
            dispatch,
            {
                ClientContactPersonID: clientUserId,
                contact_person_name: updateUser?.name,
                contact_person_email: updateUser?.email,
                contact_person_designation: updateUser?.designation,
                contact_person_mobileNum: updateUser?.mobileNum,
                cCode_contact_person: updateUser?.country_code,
            }
        );
        setIsClientUserModalOpen(false);
        setFlag((flag) => !flag);

        // setIsAddNewModalOpen(false);
    };
    const location = useLocation();
    const formattedUrl = (url) => url && !url?.includes('://') ? `https://${url}` : url;
    useEffect(() => {
        if (location.state && location.state.showModal) {
            setIsClientUpdateModalOpen(true);
        } else if (location.state && location.state.showClientModal) {
            setIsClientUserModalOpen(true); setClientUserId(location.state.clientId); setUpdateUser(location.state.clientData);
        }
    }, [location.state]);
    console.log(update, "data here")
    return (
        <>
            <br></br>
            <div>
                <Card
                    loading={userState?.isPending}
                    style={{
                        width: "100%",
                    }}
                    title={
                        <div className="applicant-header">
                            <Avatar
                                size={{ xs: 50, sm: 50, md: 50, lg: 50, xl: 50, xxl: 60 }}
                                style={{ backgroundColor: "#6855f6" }}
                                icon={<UserOutlined />}
                            />

                            <Title style={{ display: "flex", justifyContent: "center", alignItems: "center" }} level={2}>
                                {update?.name}
                                {
                                    update?.type &&
                                    <span style={{
                                        border: update?.type === "Hot" ? "0.15px solid #BC3F33" : (update?.type === "Warm" ? "0.15px solid orange" : "0.15px solid #6855f5"),
                                        background: update?.type === "Hot" ? "#BC3F33" : (update?.type === "Warm" ? "orange" : "#6855f5"),
                                        color: update?.type === "Warm" ? "black" : "white",
                                        borderRadius: "4px", marginLeft: "10px", paddingLeft: "3px", paddingRight: "3px", fontSize: "13px", fontWeight: "bold"
                                    }} >{update?.type}</span>
                                }
                            </Title>
                        </div>
                    }
                    extra={
                        <div className="applicant-header-extra">
                            <Icon
                                name="edit outline"
                                onClick={() => setIsClientUpdateModalOpen(true)}
                                style={{
                                    cursor: "pointer", fontSize: "20px"
                                }}
                            ></Icon>
                            <a href={formattedUrl(update?.linkedIn_URL)} target="_blank" rel="noreferrer">
                                <LinkedinFilled
                                    style={{
                                        fontSize: "40px",
                                        color: "rgb(75,79,181)",
                                        cursor: "pointer",
                                    }}
                                />
                            </a>
                            <a href={update?.clientContract ? `${update?.clientContract?.split(" ").length > 1
                                ? update?.resume_urn?.split(" ")[1] : `${process.env.REACT_APP_SERVER_URL}/api/files/jd/${update?.clientContract}`}` : "#"
                            }
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Tooltip title={!update?.clientContract ? "No contract available.Please upload one ." : ""} >
                                    <Button
                                        type="primary"
                                        disabled={!update?.clientContract}
                                    >
                                        <Icon
                                            name="download"
                                            onClick={() => setOpenContractModal(true)}
                                            style={{
                                                cursor: "pointer", fontSize: "12px"
                                            }}
                                        ></Icon>

                                        Download Contract
                                    </Button>
                                </Tooltip>
                            </a>

                            <Tooltip
                                title={!update?.clientContract ? "No contract available.Upload new contract." : "Contract already exists.Edit contract"}
                            >
                                <Button
                                    primary
                                    type="primary"
                                    onClick={() => setOpenContractModal(true)}
                                // disabled={!update?.clientContract}
                                >
                                    {
                                        update?.clientContract ?
                                            <Icon
                                                name="edit"
                                                onClick={() => setOpenContractModal(true)}
                                                style={{
                                                    cursor: "pointer", fontSize: "12px"
                                                }}
                                            ></Icon> :
                                            <Icon name="upload"
                                                onClick={() => setOpenContractModal(true)}
                                                style={{
                                                    cursor: "pointer", fontSize: "12px"
                                                }} ></Icon>
                                    }

                                    <span>
                                        {
                                            !update?.clientContract ? "Upload " : "Edit "
                                        }
                                        Contract
                                    </span>
                                </Button>
                            </Tooltip>

                            <a href={formattedUrl(update?.url)} target="_blank" rel="noreferrer">
                                <GlobalOutlined
                                    style={{
                                        fontSize: "40px",
                                        color: "rgb(75,79,181)",
                                        cursor: "pointer",
                                    }}
                                />
                            </a>
                        </div>
                    }
                >
                    <div className="applicant-details-content">
                        <Descriptions title="" layout="vertical" bordered>
                            <Descriptions.Item label="Email">
                                {update?.email || "—"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Mobile">
                                {update?.country_code} {update?.mobileNum}
                            </Descriptions.Item>
                            <Descriptions.Item label="Url">
                                <a href={update?.url} target="_blank" rel="noreferrer">Go to URL</a>
                            </Descriptions.Item>
                            <Descriptions.Item label="Country">
                                {update?.country || "—"}
                            </Descriptions.Item>
                            <Descriptions.Item label="State ">
                                {update?.state || "—"}
                            </Descriptions.Item>
                            <Descriptions.Item label="City">
                                {update?.city || "—"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Address" span={3}>
                                {/* Add function to fetch skills one by one and return them in tags */}
                                {update?.address
                                    || "—"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Company News" span={3}>
                                {/* Add function to fetch skills one by one and return them in tags */}
                                {update?.company_news || "—"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Sourcing Verticals" span={3}>
                                {update?.sourcingVertical?.map((data, idx) => {
                                    return <Tag key={idx}>{data}</Tag>;
                                })}
                            </Descriptions.Item>
                            <Descriptions.Item label="Remarks" span={2}>
                                {update?.remarks}
                            </Descriptions.Item>
                            <Descriptions.Item label="Last Connect Date">
                                {formatDateToYYYYMMDD(update?.lastConnectDate)}
                            </Descriptions.Item>
                            {
                                (update?.revenue && update?.revenue?.length > 0) &&
                                (
                                    <>
                                        <Descriptions.Item label="Revenue Status" span={1}>
                                            {
                                                update?.revenue[0]?.Value} {update?.revenue[0]?.RevenueMode === "Percentage" ? "%" :
                                                    update?.revenue[0]?.RevenueMode === "Precentage" ? "%" :
                                                        `${update?.revenue[0]?.Currency}`
                                            }
                                        </Descriptions.Item>

                                        <Descriptions.Item label="Super Admin Approved ?" span={1}>
                                            {update?.revenue[0]?.superAdminApproved === true ? "Yes" : "No"}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Payment Terms" span={1}>
                                            {`${update?.revenue[0]?.PaymentTerms} days` || "—"}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Invoicing Terms" span={1}>
                                            {`${update?.revenue[0]?.InvoicingTerms} days` || "—"}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Replacement Terms" span={1}>
                                            {`${update?.revenue[0]?.ReplacementTimeline} days` || "—"}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Replacement Model" span={1}>
                                            {update?.revenue[0]?.ReplacementModel || "—"}
                                        </Descriptions.Item>
                                    </>
                                )
                            }
                            <Descriptions.Item label="Contact Details" span={4}>
                                <Table celled  >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Designation</Table.HeaderCell>
                                            <Table.HeaderCell>Name</Table.HeaderCell>
                                            <Table.HeaderCell>Email</Table.HeaderCell>
                                            <Table.HeaderCell>Mobile</Table.HeaderCell>
                                            <Table.HeaderCell># of Jobs</Table.HeaderCell>
                                            <Table.HeaderCell>Edit Client User</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            update?.contact_person?.map((obj, idx) => {
                                                return (
                                                    <>
                                                        <Table.Row key={idx} >
                                                            <Table.Cell>
                                                                {obj?.designation || "-"}
                                                            </Table.Cell>
                                                            <Table.Cell>{obj?.name || "—"}</Table.Cell>
                                                            <Table.Cell>{obj?.email || "—"}</Table.Cell>
                                                            <Table.Cell>{obj?.country_code || "—"} - {obj?.mobileNum || "—"}</Table.Cell>
                                                            <Table.Cell>{obj?.jobs?.length || "—"}</Table.Cell>
                                                            <Table.Cell>{
                                                                <Button onClick={(e) => {
                                                                    setIsClientUserModalOpen(true); setClientUserId(obj?.id); setUpdateUser(obj);
                                                                }}  >
                                                                    <EditOutlined />
                                                                </Button>
                                                            }</Table.Cell>
                                                        </Table.Row>
                                                    </>
                                                )
                                            }
                                            )
                                        }
                                    </Table.Body >
                                    <Table.Footer >
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='5'>
                                                <Menu floated='right' pagination>
                                                    <Menu.Item as='a' icon>
                                                        <Icon name='chevron left' />
                                                    </Menu.Item>
                                                    <Menu.Item as='a'>1</Menu.Item>
                                                    <Menu.Item as='a'>2</Menu.Item>
                                                    <Menu.Item as='a'>3</Menu.Item>
                                                    <Menu.Item as='a'>4</Menu.Item>
                                                    <Menu.Item as='a' icon>
                                                        <Icon name='chevron right' />
                                                    </Menu.Item>
                                                </Menu>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Footer>
                                </Table>
                            </Descriptions.Item>
                        </Descriptions>
                    </div>
                </Card >

                <Modal
                    dimmer="inverted"
                    size="large"
                    centered
                    zIndex={90}
                    onClose={() => {
                        setIsClientUpdateModalOpen(false);
                    }}
                    open={isClientUpdateModalOpen}
                    footer={null}

                >
                    <Modal.Header>Update {update?.name}'s Info</Modal.Header>
                    <Modal.Content scrolling>
                        <Form onSubmit={handleOkClientUpdate} loading={userState?.isPending} >
                            <Form.Group fluid width={16} >
                                <Form.Input
                                    value={update?.name}
                                    label="Name"
                                    name="name"
                                    onChange={(_e, data) => {
                                        setUpdate({
                                            ...update,
                                            [data.name]: data.value,
                                        });
                                    }}
                                    width={8}
                                    required={true}
                                    error={
                                        update?.name === null || update?.name === "" ?
                                            true : false
                                    }
                                ></Form.Input>
                                <Form.Select
                                    fluid
                                    label="Client Type"
                                    name="type"
                                    options={clientType}
                                    value={update?.type}
                                    onChange={(_e, data) => {
                                        setUpdate({
                                            ...update,
                                            [data.name]: data.value,
                                        });
                                    }}
                                    width={4}
                                    required={true}
                                    error={
                                        update?.clientType === null || update?.clientType === "" ?
                                            true : false
                                    }
                                />
                                <Form.Select
                                    fluid
                                    label="Positions Available"
                                    name="Positions_available"
                                    options={Positions_available}
                                    value={update?.Positions_available}
                                    onChange={(_e, data) => {
                                        setUpdate({
                                            ...update,
                                            [data.name]: data.value,
                                        });
                                    }}
                                    width={4}
                                    required={true}
                                    error={
                                        update?.Positions_available === null || update?.Positions_available === "" ?
                                            true : false
                                    }
                                />
                            </Form.Group>
                            <Form.Group fluid width={16} >
                                <Form.Input
                                    value={update?.email}
                                    label="Client Email"
                                    name="email"
                                    onChange={(e) => {
                                        setUpdate({
                                            ...update,
                                            [e.target.name]: e.target.value,
                                        });
                                    }}
                                    width={8}
                                    required={true}
                                    error={
                                        update?.email === null || update?.email === "" ?
                                            true : false
                                    }
                                ></Form.Input>
                                <Form.Select
                                    fluid
                                    label="Country Code"
                                    name="country_code"
                                    required={true}
                                    value={update?.country_code}
                                    options={countryCodeSemantic}
                                    placeholder="C Code"
                                    onChange={(_e, data) => {
                                        setUpdate({
                                            ...update,
                                            [data.name]: data.value,
                                        });
                                    }}
                                    width={3}
                                    error={
                                        update?.country_code === null || update?.country_code === "" ?
                                            true : false
                                    }
                                />
                                <Form.Input
                                    type="number"
                                    name="mobileNum"
                                    label="Mobile Number"
                                    value={update?.mobileNum}
                                    fluid
                                    error={
                                        update?.mobileNum
                                            ? update?.mobileNum.length > 10 &&
                                            "Mobile Number should be 10 digits only"
                                            : false
                                    }
                                    onChange={(e) => {
                                        setUpdate({ ...update, [e.target.name]: e.target.value })
                                    }}
                                    placeholder="mobile number"
                                    width={5}
                                    required={true}
                                />
                            </Form.Group>
                            <Form.Group fluid width={16} >
                                <Form.Input
                                    label="Company Url"
                                    name="url"
                                    value={update?.url}
                                    onChange={(e) => {
                                        setUpdate({
                                            ...update,
                                            [e.target.name]: e.target.value,
                                        });
                                    }}
                                    width={8}
                                    type="text"
                                    required={true}
                                    error={
                                        update?.url === null || update?.url === "" ?
                                            true : false
                                    }
                                ></Form.Input>
                                <Form.Input
                                    label="Linkedin Url"
                                    name="linkedIn_URL"
                                    value={update?.linkedIn_URL}
                                    onChange={(e) => {
                                        setUpdate({
                                            ...update,
                                            [e.target.name]: e.target.value,
                                        });
                                    }}
                                    width={8}
                                    type="text"
                                    required={true}
                                    error={
                                        update?.linkedIn_URL === null || update?.linkedIn_URL === "" ?
                                            true : false
                                    }
                                ></Form.Input>
                            </Form.Group>
                            <Form.Group fluid width={16}>
                                <Form.Select
                                    search
                                    label="Sourcing Verticals"
                                    multiple
                                    name="sourcingVertical"
                                    value={update?.sourcingVertical}
                                    onChange={(e, data) => {
                                        setUpdate({ ...update, [data.name]: data.value });
                                    }}
                                    options={verticlalOptions?.map((id, el) => {
                                        return {
                                            text: id?.sourcingVertical,
                                            value: id?.sourcingVertical,
                                            id: id?.id
                                        }
                                    })}
                                    placeholder="Select sourcing verticals"
                                    required={true}
                                    error={
                                        update?.sourcingVertical?.length <= 0 ?
                                            true : false
                                    }
                                    width={16}
                                />
                                <Form.Select
                                    label="Client Industry"
                                    search
                                    options={industryOptions?.map((data, idx) => {
                                        return { key: idx, value: data.name, text: data.name };
                                    })}
                                    placeholder="Select Client Industry"
                                    name="industry"
                                    value={update?.industry}
                                    onChange={(e, data) => {
                                        setUpdate({ ...update, [data.name]: data.value });
                                    }}
                                    error={
                                        update?.industry?.length <= 0 ?
                                            true : false
                                    }
                                ></Form.Select>
                            </Form.Group>
                            <Form.Group fluid width={16} >
                                <Form.Input
                                    label="Company News"
                                    name="company_news"
                                    value={update?.company_news}
                                    onChange={(e) => {
                                        setUpdate({
                                            ...update,
                                            [e.target.name]: e.target.value,
                                        });
                                    }}
                                    width={16}
                                    required={true}
                                    error={
                                        update?.company_news === null || update?.company_news === "" ?
                                            true : false
                                    }
                                ></Form.Input>
                            </Form.Group>
                            <Form.Group fluid width={16} >
                                <Form.Select
                                    search
                                    fluid
                                    label="Location"
                                    name="country"
                                    width={5}
                                    placeholder="Select country"
                                    value={country}
                                    onChange={(e, data) => {
                                        setCountry(data?.value);
                                        setState();
                                        setCity();
                                        setUpdate({
                                            ...update,
                                            [data.name]: data?.value,
                                        });
                                    }}
                                    options={countryOption?.map((data, idx) => {
                                        return {
                                            text: `${data.name + "  (" + data.iso2 + ")"}`,
                                            value: `${data.iso2}`,
                                            key: `${idx}`,
                                        };
                                    })}
                                    required={true}

                                />
                                <Form.Select
                                    search
                                    fluid
                                    label="‎ "
                                    width={5}
                                    name="state"
                                    placeholder="Select State"
                                    value={state || null}
                                    onChange={(e, data) => {
                                        console.log(data);
                                        setState(data.value);
                                        setCity();
                                        setUpdate({
                                            ...update,
                                            [data.name]: data?.value,
                                        });
                                    }}
                                    options={stateOption?.map((data, idx) => {
                                        return {
                                            text: `${data.name + "  (" + data.iso2 + ")"}`,
                                            value: `${data.iso2}`,
                                            key: `${idx}`,
                                        };
                                    })}
                                    required={true}
                                />
                                <Form.Select
                                    fluid
                                    search
                                    label="‎" name="city"
                                    width={5}
                                    value={city || null}
                                    onChange={(e, data) => {
                                        setUpdate({ ...update, [data.name]: data?.value });
                                        setCity(data?.value);
                                    }}
                                    placeholder="Select City"
                                    options={cityOption?.map((data, idx) => {
                                        return {
                                            text: `${data.name}`,
                                            value: `${data.name}`,
                                            key: `${idx}`,
                                        };
                                    })}
                                    required={true}
                                />
                                <Form.Input
                                    search
                                    fluid
                                    width={5}
                                    label="Previous Location"
                                    name="country"
                                    value={prevcountry}

                                />
                                <Form.Input
                                    search
                                    fluid
                                    width={5}
                                    label="‎ "
                                    name="state"
                                    value={prevstate}
                                />
                                <Form.Input
                                    fluid
                                    search
                                    width={5}
                                    label="‎" name="city"
                                    value={prevcity}
                                />

                            </Form.Group>
                            <Form.Group fluid width={18} >
                                <Form.Input
                                    fluid
                                    type="text"
                                    name="address" label="Address"
                                    value={update?.address}
                                    onChange={(e) => {
                                        setUpdate({
                                            ...update,
                                            [e.target.name]: e.target.value,
                                        })
                                    }}
                                    placeholder="Enter Company's Street Address (max limit : 120 words)"
                                    required={false}
                                    width={10}
                                />
                                {/* <Form.Input
                                    type="file"
                                    label="Update Contract"
                                    accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    name="file"
                                    onChange={(e) => {
                                        setFileObj(
                                            e.target.files[0],
                                        )
                                    }}
                                    width={6}
                                ></Form.Input> */}
                            </Form.Group>
                            <Form.Group fluid width={16}  >
                                <Form.Input
                                    label="Remark"
                                    name="remarks"
                                    value={update?.remarks}
                                    type="text"
                                    onChange={(e) => {
                                        setUpdate({
                                            ...update,
                                            [e.target.name]: e.target.value,
                                        });
                                    }}
                                    width={8}
                                ></Form.Input>
                                <Form.Input
                                    type="date"
                                    name="lastConnectDate"
                                    label="last_connected_date"
                                    value={formatDateToYYYYMMDD(update?.lastConnectDate)}
                                    onChange={(e) => {
                                        setUpdate({
                                            ...update,
                                            [e.target.name]: e.target.value,
                                        });
                                    }}
                                    max={new Date().toISOString().split('T')[0]}
                                    width={8}
                                ></Form.Input>
                            </Form.Group>
                            {
                                update?.revenue?.length > 0 ? (
                                    <>
                                        <div
                                            style={{
                                                margin: "20px",
                                                backgroundColor: "#f0f0f090",
                                            }}
                                        >
                                            <p
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                    textAlign: "center",
                                                    padding: "20px",
                                                    border: "2px solid #6855F6",
                                                    borderRadius: "10px",
                                                }}
                                            >
                                                Change Revenue Info
                                            </p>
                                        </div>
                                        <Form.Group fluid width={16} >
                                            <Form.Select
                                                label="Revenue Model"
                                                name="RevenueMode"
                                                placeholder="Percentage"
                                                value={update?.revenue[0]?.RevenueMode}
                                                onChange={(e, data) => {
                                                    setUpdate({
                                                        ...update, revenue: update?.revenue?.map((item, index) => {
                                                            if (index === 0) {
                                                                return {
                                                                    ...item,
                                                                    [data?.name]: data?.value,
                                                                };
                                                            }
                                                            return item;
                                                        })
                                                    });
                                                }}
                                                options={modelOptions}
                                                width={8}
                                            ></Form.Select>

                                            <Form.Input
                                                fluid
                                                name="Value"
                                                type="number"
                                                placeholder="10%"
                                                value={update?.revenue[0]?.Value}
                                                onChange={(e, data) => {
                                                    setUpdate({
                                                        ...update, revenue: update?.revenue?.map((item, index) => {
                                                            if (index === 0) {
                                                                return {
                                                                    ...item,
                                                                    [data?.name]: data?.value,
                                                                };
                                                            }
                                                            return item;
                                                        })
                                                    });
                                                }}
                                                label="Charge Value"
                                                width={8}

                                            />
                                        </Form.Group>
                                        <Form.Group fluid width={16} >
                                            <Form.Input
                                                label="Payment Terms (in days)"
                                                name="PaymentTerms"
                                                placeholder="30 days"
                                                value={update?.revenue[0]?.PaymentTerms}
                                                onChange={(e, data) => {
                                                    setUpdate({
                                                        ...update, revenue: update?.revenue?.map((item, index) => {
                                                            if (index === 0) {
                                                                return {
                                                                    ...item,
                                                                    [e.target?.name]: e.target?.value,
                                                                };
                                                            }
                                                            return item;
                                                        })
                                                    });
                                                }}
                                                type="number"
                                                width={8}

                                            ></Form.Input>
                                            <Form.Input
                                                label="Invoicing Terms (in days)"
                                                name="InvoicingTerms"
                                                placeholder="10 days"
                                                value={update?.revenue[0]?.InvoicingTerms}
                                                onChange={(e, data) => {
                                                    setUpdate({
                                                        ...update, revenue: update?.revenue?.map((item, index) => {
                                                            if (index === 0) {
                                                                return {
                                                                    ...item,
                                                                    [data?.name]: data?.value,
                                                                };
                                                            }
                                                            return item;
                                                        })
                                                    });
                                                }}
                                                type="number"
                                                width={8}

                                            ></Form.Input>
                                            <Form.Input
                                                label="Replacement Timeline (in days)"
                                                name="ReplacementTimeline"
                                                placeholder="60 days"
                                                value={update?.revenue[0]?.ReplacementTimeline}
                                                onChange={(e, data) => {
                                                    setUpdate({
                                                        ...update, revenue: update?.revenue?.map((item, index) => {
                                                            if (index === 0) {
                                                                return {
                                                                    ...item,
                                                                    [e.target.name]: e.target.value,
                                                                };
                                                            }
                                                            return item;
                                                        })
                                                    });
                                                }}
                                                type="number"
                                                width={8}

                                            ></Form.Input>
                                        </Form.Group>
                                        <Form.Group fluid width={16} >
                                            <Form.Select
                                                label="Replacement Model "
                                                name="ReplacementModel"
                                                placeholder="Replacement"
                                                value={update?.revenue[0]?.ReplacementModel}
                                                onChange={(e, data) => {
                                                    setUpdate({
                                                        ...update, revenue: update?.revenue?.map((item, index) => {
                                                            if (index === 0) {
                                                                return {
                                                                    ...item,
                                                                    [data?.name]: data?.value,
                                                                };
                                                            }
                                                            return item;
                                                        })
                                                    });
                                                }}
                                                options={repModel}
                                                width={8}

                                            ></Form.Select>
                                            <Form.Select
                                                label="Currency"
                                                name="Currency"
                                                placeholder="INR"
                                                value={update?.revenue[0]?.Currency}
                                                onChange={(e, data) => {
                                                    setUpdate({
                                                        ...update, revenue: update?.revenue?.map((item, index) => {
                                                            if (index === 0) {
                                                                return {
                                                                    ...item,
                                                                    [data?.name]: data?.value,
                                                                };
                                                            }
                                                            return item;
                                                        })
                                                    });
                                                }}
                                                options={currencyOptions}
                                                width={8}

                                            ></Form.Select>
                                        </Form.Group>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            style={{
                                                margin: "20px",
                                                backgroundColor: "#f0f0f090",
                                            }}
                                        >
                                            <p
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                    textAlign: "center",
                                                    padding: "20px",
                                                    border: "2px solid #6855F6",
                                                    borderRadius: "10px",
                                                }}
                                            >
                                                Change Revenue Info
                                            </p>
                                        </div>
                                        <Form.Group fluid width={16} >
                                            <Form.Select
                                                label="Revenue Model "
                                                name="RevenueMode"
                                                placeholder="Percentage"
                                                value={update?.revenue?.[0]?.RevenueMode || "Percentage"}
                                                onChange={(e, data) => {
                                                    console.log(data?.value, data?.name, "data")
                                                    setUpdate({
                                                        ...update,
                                                        revenue: [
                                                            {
                                                                ...update?.revenue?.[0],
                                                                [data?.name]: data?.value,
                                                            },
                                                        ],
                                                    });
                                                }}
                                                options={modelOptions}
                                                width={8}
                                            ></Form.Select>

                                            <Form.Input
                                                fluid
                                                name="Value"
                                                type="number"
                                                placeholder="10%"
                                                value={update?.revenue?.[0]?.Value || 10}
                                                onChange={(e, data) => {
                                                    setUpdate({
                                                        ...update, revenue: [
                                                            {
                                                                ...update?.revenue?.[0],
                                                                [data?.name]: data?.value,
                                                            },
                                                        ],
                                                    });
                                                }}
                                                label="Charge Value"
                                                width={8}

                                            />
                                        </Form.Group>
                                        <Form.Group fluid width={16} >
                                            <Form.Input
                                                label="Payment Terms (in days)"
                                                name="PaymentTerms"
                                                placeholder="30 days"
                                                value={update?.revenue?.[0]?.PaymentTerms || 30}
                                                onChange={(e, data) => {
                                                    setUpdate({
                                                        ...update, revenue: [
                                                            {
                                                                ...update?.revenue?.[0],
                                                                [data?.name]: data?.value,
                                                            },
                                                        ],
                                                    });
                                                }}
                                                type="number"
                                                width={8}

                                            ></Form.Input>
                                            <Form.Input
                                                label="Invoicing Terms (in days)"
                                                name="InvoicingTerms"
                                                placeholder="10 days"
                                                value={update?.revenue?.[0]?.InvoicingTerms || 10}
                                                onChange={(e, data) => {
                                                    setUpdate({
                                                        ...update, revenue: [
                                                            {
                                                                ...update?.revenue?.[0],
                                                                [data?.name]: data?.value,
                                                            },
                                                        ],
                                                    });
                                                }}
                                                type="number"
                                                width={8}

                                            ></Form.Input>
                                            <Form.Input
                                                label="Replacement Timeline (in days)"
                                                name="ReplacementTimeline"
                                                placeholder="60 days"
                                                value={update?.revenue?.[0]?.ReplacementTimeline || 60}
                                                onChange={(e, data) => {
                                                    setUpdate({
                                                        ...update, revenue: [
                                                            {
                                                                ...update?.revenue?.[0],
                                                                [data?.name]: data?.value,
                                                            },
                                                        ],
                                                    });
                                                }}
                                                type="number"
                                                width={8}

                                            ></Form.Input>
                                        </Form.Group>
                                        <Form.Group fluid width={16} >
                                            <Form.Select
                                                label="Replacement Model "
                                                name="ReplacementModel"
                                                placeholder="Replacement"
                                                value={update?.revenue?.[0]?.ReplacementModel || "Replacement"}
                                                onChange={(e, data) => {
                                                    setUpdate({
                                                        ...update, revenue: [
                                                            {
                                                                ...update?.revenue?.[0],
                                                                [data?.name]: data?.value,
                                                            },
                                                        ],
                                                    });
                                                }}
                                                options={repModel}
                                                width={8}

                                            ></Form.Select>
                                            <Form.Select
                                                label="Currency"
                                                name="Currency"
                                                placeholder="INR"
                                                value={update?.revenue?.[0]?.Currency || "INR"}
                                                onChange={(e, data) => {
                                                    setUpdate({
                                                        ...update, revenue: [
                                                            {
                                                                ...update?.revenue?.[0],
                                                                [data?.name]: data?.value,
                                                            },
                                                        ],
                                                    });
                                                }}
                                                options={currencyOptions}
                                                width={8}

                                            ></Form.Select>
                                        </Form.Group>
                                    </>
                                )
                            }

                            <Button
                                style={{
                                    background: "#6855f6",
                                    color: "#ffffff",
                                }}
                                htmlType="submit"
                                disabled={zyvkaState.isPending === true}
                            >
                                Save
                            </Button>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={() => setIsClientUpdateModalOpen(false)}>
                            Cancel
                        </Button>
                    </Modal.Actions>
                </Modal >

                <Modal
                    dimmer="inverted"
                    size="medium"
                    centered
                    zIndex={80}
                    onClose={() => {
                        setIsClientUserModalOpen(false);
                    }}
                    open={isClientUserModalOpen}
                    footer={null}

                >
                    <Modal.Header>Update {update?.name}'s Client Info</Modal.Header>
                    <Modal.Content scrolling>
                        <Form onSubmit={handleOkClientUserUpdate} loading={userState?.isPending} >
                            <Form.Group fluid width={16} >
                                <Form.Input
                                    value={updateUser?.name}
                                    label="Name"
                                    name="name"
                                    onChange={(_e, data) => {
                                        setUpdateUser({
                                            ...updateUser,
                                            [data.name]: data.value,
                                        });
                                    }}
                                    width={8}
                                    required={true}
                                    error={
                                        updateUser?.name === null || updateUser?.name === "" ?
                                            true : false
                                    }
                                ></Form.Input>
                                <Form.Input
                                    value={updateUser?.email}
                                    label="Email"
                                    name="email"
                                    onChange={(e) => {
                                        setUpdateUser({
                                            ...updateUser,
                                            [e.target.name]: e.target.value,
                                        });
                                    }}
                                    width={8}
                                    required={true}
                                    error={
                                        updateUser?.email === null || updateUser?.email === "" ?
                                            true : false
                                    }
                                ></Form.Input>
                            </Form.Group>
                            <Form.Group fluid width={16} >
                                <Form.Input
                                    fluid
                                    label="Company Designation"
                                    name="designation"
                                    value={updateUser?.designation}
                                    onChange={(e) => {
                                        setUpdateUser({
                                            ...updateUser,
                                            [e.target.name]: e.target.value,
                                        });
                                    }}
                                    width={8}
                                    required={true}
                                    error={
                                        updateUser?.designation === null || updateUser?.designation === "" ?
                                            true : false
                                    }
                                />

                                <Form.Select
                                    fluid
                                    label="Country Code"
                                    name="country_code"
                                    value={updateUser?.country_code}
                                    options={countryCodeSemantic}
                                    placeholder="C Code"
                                    onChange={(_e, data) => {
                                        setUpdateUser({
                                            ...updateUser,
                                            [data.name]: data.value,
                                        });
                                    }}
                                    width={3}
                                    required={true}
                                    error={
                                        updateUser?.country_code === null || updateUser?.country_code === "" ?
                                            true : false
                                    }
                                />
                                <Form.Input
                                    type="number"
                                    name="mobileNum"
                                    label="Mobile Number"
                                    fluid
                                    value={updateUser?.mobileNum}
                                    onChange={(e, data) => {
                                        setUpdateUser({
                                            ...updateUser,
                                            [e.target.name]: e.target.value,
                                        });
                                    }}
                                    error={
                                        updateUser?.mobileNum
                                            ? updateUser?.mobileNum.length > 10 &&
                                            "Mobile Number should be 10 digits only"
                                            : false
                                    }
                                    required={true}
                                    placeholder="mobile number"
                                    width={5}
                                />
                            </Form.Group>
                            <Button
                                style={{
                                    background: "#6855f6",
                                    color: "#ffffff",
                                }}
                                htmlType="submit"
                                disabled={zyvkaState.isPending === true}
                            >
                                Save
                            </Button>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={() => setIsClientUserModalOpen(false)}>
                            Cancel
                        </Button>
                    </Modal.Actions>
                </Modal >


                {/* contract modal */}
                <Modal
                    dimmer="inverted"
                    size="mini"
                    centered
                    zIndex={80}
                    onClose={() => {
                        setOpenContractModal(false);
                    }}
                    open={openContractModal}
                    footer={null}
                    closeOnDimmerClick={true}
                    closeIcon
                >
                    <Modal.Header>
                        Update/Replace your Client Contract {update?.name}
                    </Modal.Header>
                    <Modal.Content>
                        <Form loading={userState?.isPending} onSubmit={contractUpdateHandler} >
                            <Form.Input
                                type="file"
                                accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                name="file"
                                onChange={(e) => {
                                    setFileObj(
                                        e.target.files[0],
                                    )
                                }}
                            >
                            </Form.Input>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={() => setOpenContractModal(false)}>
                            Cancel
                        </Button>
                        <Popconfirm
                            primary
                            title="Are you sure you want to update the client contract?"
                            onConfirm={contractUpdateHandler}
                            onCancel={() => setOpenContractModal(false)}
                            okText="Yes"
                            cancelText="No"

                        >
                            <Button type="primary" style={{ marginLeft: "30px" }}
                                icon={<EditOutlined />}
                            >
                                update
                            </Button>
                        </Popconfirm>
                    </Modal.Actions>
                </Modal>

            </div >
        </>
    );
};

export default ClientDetails;