import React, { useState, useEffect } from "react";
import { Form, Card, Grid, Dropdown, Label, LabelGroup } from "semantic-ui-react";
import { AddGlobalSkills } from "../api/ZyvkaMember";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import axios from "axios";
import { UserState } from "../features/userSlice";
import { createJobProfile, UpdateJobProfile } from "../api/ZyvkaMember";
import { getAllJobProfiles, fetchAllSkills } from "../api/auth";
import Fuse from 'fuse.js'
const AddProfileAndSkill = () => {
  const [flag, setFlag] = useState(false);

  const [formValues1, setFormValues1] = useState({
    jobProfile: "",
    skills: [],
  });
  const [formValues2, setFormValues2] = useState({
    skills: [],
  });
  const [formValues3, setFormValues3] = useState({
    profile: "",
    skills_req: "",
  });

  const fetchAll = async () => {
    try {
      await fetchAllSkills(dispatch);
      await getAllJobProfiles(dispatch);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAll();
  }, [flag]);
  const userState = useSelector(UserState);

  const dispatch = useDispatch();
  const AllSkills = userState.gSkills;
  const AllProfiles = userState.gProfile;
  const [profile, setProfile] = useState(null);
  const [options, setOptions] = useState([]);
  const [ExistingSkillsForJobProfile, setExistingSkillsForJobProfile] =
    useState([]);

  const onFinish = async (e) => {
    e.preventDefault();
    await createJobProfile(
      { jobProfile: formValues1.jobProfile, skills: formValues1.skills },
      dispatch,
      setFormValues1
    );
    setExistingSkillsForJobProfile([]);
    setProfile(null);
    setFlag((flag) => !flag);
  };

  const onFinish1 = async (e) => {
    e.preventDefault();
    await AddGlobalSkills(
      { skills: formValues2.skills },
      dispatch,
    );
    setExistingSkillsForJobProfile([]);
    setFormValues2({
      skills: [],
    })
    similarSkills = [];
    existingSkills = [];
    setOptions()
    setProfile(null);
    setFlag((flag) => !flag);
  };
  const onFinish2 = async (e) => {
    const uniqueSkillsReq = Array.from(new Set(formValues3.skills_req));
    try {
      e.preventDefault();
      console.log(uniqueSkillsReq);
      await UpdateJobProfile(
        {
          id: formValues3?.profile,
          skills: uniqueSkillsReq,
          skills: uniqueSkillsReq,
        },
        dispatch,
        setFormValues3
      );

      setExistingSkillsForJobProfile([]);
      setProfile(null);
      setFlag((flag) => !flag);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSkillforPosition = async () => {
    if (profile) {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/zyvka/getSkillForJobProfile/${profile}`,
        { withCredentials: true }
      );
      setExistingSkillsForJobProfile(result.data);
      setFormValues3({ ...formValues3, skills_req: result?.data });
    }
  };
  useEffect(() => {
    fetchSkillforPosition();
  }, [profile]);
  const changeHandlerProfile = (e) => {
    setProfile(e);
  };
  const fuseOptions = {
    keys: ['skill'],
    includeScore: true,
    threshold: 0.7,
  };
  const fuse = new Fuse(AllSkills, fuseOptions);

  //update the code form below 
  // make it state var and call it under a function and refine itand call it when onAddItem ad OnChange

  let similarSkills = [];
  let existingSkills = [];
  const cleanSkill = (skill) => {
    return skill.trim().replace(/[^a-zA-Z0-9\s]/g, "").toLowerCase();
  };
  existingSkills = AllSkills.filter((skill) => {
    const cleanedSkill = skill.skill.trim().toLowerCase();
    return formValues2.skills.some((enteredSkill) => {
      const cleanedEnteredSkill = enteredSkill.replace(/[^a-zA-Z0-9\s]/g, "").trim().toLowerCase();
      return (
        cleanedEnteredSkill === cleanedSkill || skill.skill.toLowerCase() === enteredSkill.toLowerCase() || skill.skill.replace(/\s+/g, '').toLowerCase() === enteredSkill.replace(/\s+/g, '').toLowerCase()
      );
    });
  });
  formValues2?.skills.forEach((enteredSkill) => {
    const searchResult = fuse.search(enteredSkill);
    console.log(searchResult, "searched")
    console.log(enteredSkill.trim().length)
    let filteredResults
    if (enteredSkill.trim().split(/\s+/).length === 0) {
      return
    }
    if (enteredSkill.trim().split(/\s+/).length === 1) {
      if (enteredSkill.trim().length === 1) {
        filteredResults = searchResult.filter((obj) => obj.score <= 0.0009);
      } else if (enteredSkill.trim().length <= 2) {
        filteredResults = searchResult.filter((obj) => obj.score <= 0.01);
      } else if (enteredSkill.trim().length <= 3) {
        filteredResults = searchResult.filter((obj) => obj.score <= 0.2);
      } else if (enteredSkill.trim().length <= 4) {
        filteredResults = searchResult.filter((obj) => obj.score <= 0.35);
      } else if (enteredSkill.trim().length <= 5) {
        filteredResults = searchResult.filter((obj) => obj.score <= 0.4);
      }
      else if (enteredSkill.trim().length <= 6) {
        filteredResults = searchResult.filter((obj) => obj.score <= 0.45);
      } else {
        filteredResults = searchResult.filter((obj) => obj.score <= 0.63);
      }
    } else if (enteredSkill.trim().split(/\s+/).length === 2) {
      filteredResults = searchResult.filter((obj) => obj.score <= 0.65);
    } else {
      filteredResults = searchResult.filter((obj) => obj.score <= 0.67);
    }
    console.log(filteredResults, "filterRes")
    if (filteredResults.length > 0) {
      filteredResults.forEach((element) => {
        console.log(element, "pushed element")
        similarSkills?.push(element.item.skill);
      });
    }

  });
  formValues2.skills.forEach((enteredSkill) => {
    const cleanedEnteredSkill = cleanSkill(enteredSkill);
    const similarSkill = AllSkills.find((skill) => {
      const cleanedSkill = cleanSkill(skill.skill);
      return (
        cleanedEnteredSkill === cleanedSkill ||
        cleanedEnteredSkill.includes(cleanedSkill)
      );
    });
    console.log(similarSkill, "similar")
    if (similarSkill) {
      if (!similarSkills.includes(similarSkill.skill)) {
        similarSkills?.push(similarSkill.skill);
      }
    }
  });
  similarSkills = Array.from(new Set(similarSkills));

  console.log(similarSkills, "updated skills")

  return (
    <div>
      <br></br>
      <Grid columns={"2"} stackable padded>
        <Grid.Column>
          <Card
            headStyle={{ fontSize: "25px", fontWeight: "bold" }}
            bordered={false}
            style={{
              width: "auto",
              borderRadius: "10px",
              maxWidth: "900px",
            }}
          >
            <Card.Content header="Add Profile and Skills"></Card.Content>
            <Card.Content>
              <Form onSubmit={onFinish}>
                <Form.Input
                  label="Profile Name"
                  name="jobProfile"
                  type="text"
                  value={formValues1.jobProfile}
                  required={true}
                  onChange={(e) => {
                    setFormValues1({
                      ...formValues1,
                      [e.target.name]: e.target.value,
                    });
                  }}
                  placeholder="Add profile name, Ex: Frontend Developer"
                ></Form.Input>
                <Form.Select
                  multiple
                  search
                  label="Skills"
                  name="skills"
                  value={formValues1?.skills}
                  required={true}
                  onChange={(e, data1) => {
                    setFormValues1({
                      ...formValues1,
                      [data1.name]: data1.value,
                    });
                  }}
                  options={AllSkills?.map((data, idx) => {
                    return { key: idx, text: data?.skill, value: data?.skill };
                  })}
                  placeholder="Requied Skills"
                ></Form.Select>
                <Form.Button
                  type="submit"
                  style={{
                    backgroundColor: "#6855f6",
                    color: "white",
                  }}
                >
                  {" "}
                  Add Profile{" "}
                </Form.Button>
              </Form>
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column>
          <Card
            headStyle={{ fontSize: "25px", fontWeight: "bold" }}
            bordered={false}
            style={{
              width: "auto",
              borderRadius: "10px",
              maxWidth: "900px",
            }}
          >
            <Card.Content header="Update Existing Profile"></Card.Content>
            <Card.Content>
              <Form onSubmit={onFinish2}>
                <Form.Select
                  search
                  label="Job Profile"
                  placeholder="Select Profile to Update"
                  name="profile"
                  value={formValues3?.profile}
                  onChange={(e, data) => {
                    changeHandlerProfile(data.value);
                    setFormValues3({ ...formValues3, [data.name]: data.value });
                    setFlag((flag) => !flag);
                  }}
                  options={AllProfiles.map((data, idx) => {
                    return { key: idx, value: data?.id, text: data.profile };
                  })}
                ></Form.Select>
                {formValues3?.profile &&
                  AllSkills &&
                  formValues3?.skills_req && (
                    <Form.Select
                      search
                      multiple
                      required={true}
                      label="Skills"
                      name="skills_req"
                      placeholder="Skills"
                      value={formValues3?.skills_req}
                      options={AllSkills.map((data, idx) => {
                        return {
                          key: idx,
                          value: data.skill,
                          text: data.skill,
                        };
                      })}
                      onChange={(e, data) => {
                        setFormValues3({
                          ...formValues3,
                          [data.name]: data.value,
                        });
                      }}
                    ></Form.Select>
                  )}

                <Form.Button
                  type="submit"
                  style={{
                    backgroundColor: "#6855f6",
                    color: "white",
                  }}
                >
                  {" "}
                  Update Profile{" "}
                </Form.Button>
              </Form>
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column>
          <Card
            headStyle={{ fontSize: "25px", fontWeight: "bold" }}
            bordered={false}
            style={{
              width: "auto",
              borderRadius: "10px",
              maxWidth: "900px",
            }}
          >
            <Card.Content header="Add New Skills"></Card.Content>
            <Card.Content>
              <Form onSubmit={onFinish1}>
                <Dropdown
                  search
                  additionLabel=""
                  selection
                  multiple
                  style={{
                    marginBottom: "20px",
                  }}
                  fluid
                  allowAdditions
                  options={options}
                  name="skills"
                  value={formValues2?.skills}
                  onAddItem={(e, { value }) => {

                    let tmp = options ? [...options] : [];
                    tmp.push({
                      key: options?.length + 1,
                      value: value,
                      text: value,
                    });
                    setOptions(tmp);
                  }}
                  onChange={(e, data) => {
                    setFormValues2({ ...formValues2, [data.name]: data.value });
                  }}
                  placeholder="Add Global Skills.."
                ></Dropdown>
                {existingSkills?.length > 0 && (
                  <LabelGroup>
                    <Label style={{ fontSize: '14px' }} color="red">
                      The skill(s) {existingSkills.map((skill) => skill.skill).join(", ")} already exist
                    </Label>
                  </LabelGroup>
                )}
                {similarSkills?.length > 0 && (
                  <LabelGroup >
                    <Label style={{ fontSize: '14px' }} color="orange">
                      The skill(s) {similarSkills.map((skill) => skill).join(", ")} are similar to the skills entered
                    </Label>
                  </LabelGroup>
                )}

                <Form.Button
                  type="submit"
                  disabled={existingSkills?.length > 0 && true}
                  style={{
                    backgroundColor: "#6855f6",
                    color: "white",
                  }}
                >
                  {
                    similarSkills?.length > 0 ? "Add anyways" : "Add Skills"
                  }
                </Form.Button>
              </Form>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    </div >
  );
};

export default AddProfileAndSkill;
