import React, { useState } from 'react'
import {
    Button,
    Card,
    Divider,
    Descriptions,
    Col,
    Row,
    Drawer,
    Tag,
    Typography,
    message,
    Popconfirm
} from 'antd'
import { Link } from "react-router-dom"
import parse from "html-react-parser"
import { DownloadOutlined, EditOutlined } from "@ant-design/icons"
import Currency, { ctcMap, CurrencySymbols } from "../Data/Currency"
import axios from "axios";
const API = axios.create({ baseURL: process.env.REACT_APP_SERVER_URL });
const { Title } = Typography;


const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper">
        <p className="site-description-item-profile-p-label">{title}:</p>
        {content}
    </div>
);

const JobListDrawer = ({ opendrawer, setOpenDrawer, onClose, id, data, userState, archived = false,
    closed = false, }) => {
    const [loading, setLoading] = useState(false)
    const handleshowPdf = async () => {
        try {
            if (data?.isZyvkaJdAiEnable) {
                setLoading(true)
                const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/files/jd/generate/${data?.job_title.split(" ").join("-")}`, {
                    params: {
                        jobDescription: data?.job_description,
                        name: userState?.userInfo?.firstName + " " + userState?.userInfo?.lastName,
                        mobile: userState?.userInfo?.mobileNum,
                        email: userState?.userInfo?.email
                    },
                    responseType: 'blob',
                    withCredentials: true
                });
                const url = URL.createObjectURL(response.data);
                window.open(url, '_blank');
                setLoading(false)
            } else {
                const url = `${process.env.REACT_APP_SERVER_URL}/api/files/jd/${data.jd_urn}`
                window.open(url, '_blank')
            }
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    const handleshowPdfResume = async () => {
        try {
            setLoading(true)
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/files/resume/generate/${data.job_title.split(" ").join("-")}`, {
                params: {
                    jobID: data?.id
                },
                responseType: 'blob',
                withCredentials: true
            });
            const url = URL.createObjectURL(response.data);
            window.open(url, '_blank');
            setLoading(false)
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <>
            <Drawer
                key={id}
                id={id}
                width={window.innerWidth > 740 ? 740 : window.innerWidth - 0}
                placement="right"
                onClose={onClose}
                visible={opendrawer === id}
                title="Job Details"
            >
                {/* hello there */}
                <Card
                    title={data.job_title}
                    headStyle={{ fontSize: "18px", fontWeight: "bold" }}
                >
                    <Row>
                        <Col span={12}>
                            <DescriptionItem
                                title="Company Name"
                                Link={"https://www.google.com"}
                                content={
                                    <a href={data?.client?.url} style={{ color: "inherit" }}>
                                        {data?.company_name}
                                    </a>
                                }
                            />
                        </Col>
                        <Col span={12}>
                            <DescriptionItem
                                title="Total Rounds"
                                content={data.number_of_rounds}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <DescriptionItem
                                title="Experience"
                                content={
                                    data.min_experience_req +
                                    " - " +
                                    data.max_experience_req +
                                    " Years"
                                }
                            />
                        </Col>
                        <Col span={12}>
                            <DescriptionItem
                                title="CTC"
                                content={`${CurrencySymbols[data.currencyType]}
            ${data.min_CTC}${ctcMap[data.currencyType ? data.currencyType : "INR"]
                                    } - ${data.max_CTC}${ctcMap[data.currencyType ? data.currencyType : "INR"]
                                    } P.A.`}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ paddingTop: "13px" }}>
                            <DescriptionItem
                                title="Skills Required"
                                labelStyle
                                content={
                                    <div
                                        style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
                                    >
                                        {data.skills_req.map((data1, key) => {
                                            return (
                                                <Tag key={key} color="blue">{data1.toUpperCase() + "  "}</Tag>
                                            );
                                        })}
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Card>
                {/* INTERNAL TEAM JOB / VIDEO RESUME REQUIRED CARD  */}
                {data?.openToInternalTeamOnly || data?.isVideoResumeRequired ? (
                    <Card style={{ marginTop: "30px" }}>
                        {data?.openToInternalTeamOnly && (
                            <Tag color="#6855f6">Internal Team Job </Tag>
                        )}
                        {data?.isVideoResumeRequired && (
                            <Tag color="#87d068">Video Resume Required </Tag>
                        )}
                    </Card>
                ) : (
                    <></>
                )}


                <Card style={{ marginTop: "30px" }}>
                    <Row>
                        <Col span={24}>
                            <DescriptionItem
                                title={<b> Job Description</b>}
                                content={
                                    <>
                                        {data?.job_description[0] === "<" ? (
                                            <div>{parse(data?.job_description)}</div>
                                        ) : (
                                            <pre
                                                style={{
                                                    color: "black",
                                                    whiteSpace: "pre-wrap",
                                                    fontFamily: "monospace",
                                                }}
                                            >
                                                {data?.job_description}
                                            </pre>
                                        )}
                                    </>
                                }
                            />
                        </Col>
                        <Divider />
                        <Col span={6}>

                            <Button type="primary" icon={<DownloadOutlined />} onClick={handleshowPdf}>
                                Download JD
                            </Button>
                        </Col>
                        {
                            data?.isZyvkaJdAiEnable && (
                                <Col span={8}>
                                    <Button type="primary" icon={<DownloadOutlined />} onClick={handleshowPdfResume}>
                                        Download Ideal Resume
                                    </Button>
                                </Col>
                            )
                        }
                    </Row>
                </Card>
                <Card style={{ marginTop: "30px" }}>
                    <Row>
                        <Col span={24}>
                            <DescriptionItem
                                title={<b>Hiring Process / Round Details</b>}
                                content={data?.hiring_process?.map((val, _idx) => {
                                    return (
                                        <div key={_idx} >
                                            <Title level={5}>{JSON.parse(val).name} :</Title>
                                            <pre
                                                style={{
                                                    whiteSpace: "pre-wrap",
                                                    fontFamily: "inherit",
                                                }}
                                            >
                                                {JSON.parse(val).description}
                                            </pre>
                                        </div>
                                    );
                                })}
                            />
                        </Col>
                    </Row>
                </Card>
                <Card style={{ marginTop: "30px" }}>
                    {data?.status === "open" && (
                        <>
                            <p className="site-description-item-profile-p">Actions</p>
                            <Row gutter={[16, 16]}>
                                <Col span={8}>
                                    {" "}
                                    <Link
                                        target="_blank"
                                        to={`/dashboard/referNewApplicant/${data.id}/?job_title=${data.job_title}`}
                                    >
                                        <Button type="primary" style={{ width: "170px" }}>
                                            New Candidate
                                        </Button>
                                    </Link>
                                </Col>
                                <Col span={8}>
                                    <Link
                                        target="_blank"
                                        to={`/dashboard/existingApplicant/${data.id}/?company_name=${data.company_name}&job_title=${data.job_title}`}
                                    >
                                        <Button type="primary" style={{ width: "170px" }}>
                                            Refer Existing Candidate
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        </>
                    )}
                </Card>
            </Drawer>
        </>
    )
}

export default JobListDrawer