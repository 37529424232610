import { Space, Table, Button, Input, message, Popconfirm } from "antd";
import React from "react";

import { useEffect, useState } from "react";
import {
  getAllZyvkaUsers,
  makeAdmin,
  makePSM,
  UpdateZyvkaProfile,
} from "../api/ZyvkaMember";
import { useDispatch } from "react-redux";
import { ZyvkaMemberState } from "../features/ZyvkaMemberSlice";
import { useSelector } from "react-redux";
import { selectUser, UserState } from "../features/userSlice";
import { EditOutlined } from "@ant-design/icons";
const { Column, ColumnGroup } = Table;

const ModifyZyvkaMembers = () => {
  const [calls, setCalls] = useState(false);
  const dispatch = useDispatch();
  const userState = useSelector(UserState);
  const zyvkaState = useSelector(ZyvkaMemberState);
  const [data, setData] = useState([]);
  const user = useSelector(selectUser);

  const updateHandler = async (id) => {
    if (window.confirm(`Are you sure you want to Update details`)) {
      await UpdateZyvkaProfile(
        {
          id: id,
          newFirstName: newFirstName,
          newLastName: newLastName,
          newEmail: newEmail,
          newMobileNum: newMobileNo,
        },
        dispatch
      );
      setUpdateOpen("");
    }
    if (calls === false) setCalls(true);
    else setCalls(false);
  };

  const setDataFun = () => {
    if (zyvkaState.isPending === false) {
      setData(zyvkaState?.zyvkaMembers);
    }
  };

  const fetchZyvkaMembers = async () => {
    try {
      await getAllZyvkaUsers(dispatch);
      setDataFun();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchZyvkaMembers();
  }, [calls]);
  const [mob, setMob] = useState();
  const [updateOpen, setUpdateOpen] = useState("");

  const [newFirstName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newMobileNo, setNewMobileNumber] = useState("");

  const MakeUserToAdmin = async (id) => {
    if (
      window.confirm("Are you sure you want to make this user Admin") === true
    ) {
      await makeAdmin({ id: id, enable: true }, dispatch, setCalls);
    }
  };
  const RemoveAdmin = async (id) => {
    if (
      window.confirm("Are you sure you want to remove this user Admin") === true
    ) {
      await makeAdmin({ id: id, enable: false }, dispatch, setCalls);
    }
  };

  //Popup controls for Making PSM
  const confirm = (id) => {
    console.log(id);
    MakeUserPSM(id);
    message.success("PSM Allocation successful");
  };
  const cancel = (e) => {
    message.error("PSM Allocation cancelled");
  };

  const MakeUserPSM = async (id) => {
    // if (
    //   window.confirm("Are you sure you want to make this user a PSM ?") === true
    // ) {
    await makePSM({ id: id }, dispatch, setCalls);
    //}
  };

  // this is for search
  const [searchValue, setSearchValue] = useState("");

  return (
    <div className="tableDiv">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        <Space direction="horizontal" wrap={true}>
          <Input
            placeholder="Search Here"
            style={{
              minWidth: 200,
              maxWidth: 400,
              paddingLeft: 10,
            }}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
          <Button style={{ width: 80 }}>Search</Button>
        </Space>
      </div>
      <Table
        scroll={{ x: true }}
        dataSource={zyvkaState?.zyvkaMembers?.filter(
          (e) => e.id !== userState?.userInfo?.id
        )}
        loading={zyvkaState?.isPending}
        bordered={true}
      >
        <Column
          title="First Name"
          // dataIndex="name"
          key="firstName"
          render={(_, record) => {
            return (
              <>
                {updateOpen === record.id ? (
                  <div class="input-wrapper" key={record.id}>
                    <label for="first">First Name</label>
                    <input
                      type="text"
                      value={newFirstName}
                      onChange={(e) => setNewFirstName(e.target.value)}
                    ></input>
                  </div>
                ) : (
                  <span>{record.firstName}</span>
                )}
              </>
            );
          }}
          filteredValue={[searchValue]}
          onFilter={(value, record) => {
            return (
              String(record?.firstName)
                ?.toLowerCase()
                .includes(value.toLocaleLowerCase()) ||
              String(record?.lastName)
                ?.toLowerCase()
                .includes(value.toLocaleLowerCase()) ||
              String(record?.email)
                ?.toLowerCase()
                .includes(value.toLocaleLowerCase()) ||
              String(record?.mobileNum)
                ?.toLowerCase()
                .includes(value.toLocaleLowerCase())
            );
          }}
        />
        <Column
          title="Last Name"
          key="name"
          render={(_, record) => {
            return (
              <>
                {updateOpen === record.id ? (
                  <div class="input-wrapper" key={record.id}>
                    <label for="first">Last Name</label>
                    <input
                      value={newLastName}
                      onChange={(e) => setNewLastName(e.target.value)}
                    ></input>
                  </div>
                ) : (
                  <span key={record.id}>{record.lastName}</span>
                )}
              </>
            );
          }}
        />
        <Column
          title="Role"
          // dataIndex="name"
          key="name"
          render={(_, record) => {
            return (
              <>
                {record.isSuperAdmin === true ? (
                  <p>Super Admin</p>
                ) : record.isAdmin === true ? (
                  <p>Admin</p>
                ) : (
                  <p>User</p>
                )}
              </>
            );
          }}
        />
        <Column
          title="email"
          render={(_, record) => {
            return (
              <>
                {updateOpen === record.id ? (
                  <div class="input-wrapper" key={record.id}>
                    <label for="first">Email</label>
                    <input
                      value={newEmail}
                      onChange={(e) => setNewEmail(e.target.value)}
                    ></input>
                  </div>
                ) : (
                  <span key={record.id}>{record.email}</span>
                )}
              </>
            );
          }}
        />
        <Column
          title="mobileNum"
          render={(_, record) => {
            return (
              <>
                {updateOpen === record.id ? (
                  <div class="input-wrapper" key={record.id}>
                    <label for="first">Mobile</label>
                    <input
                      value={newMobileNo}
                      onChange={(e) => setNewMobileNumber(e.target.value)}
                    ></input>
                  </div>
                ) : (
                  <span key={record.id}>{record.mobileNum}</span>
                )}
              </>
            );
          }}
        />
        <Column
          title="Action"
          key="action"
          render={(_, record) => (
            <Space size="middle" key={record.id}>
              {updateOpen === record.id ? (
                <Button type="primary" onClick={() => updateHandler(record.id)}>
                  Save
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setUpdateOpen(record.id);
                    setNewEmail(record.email);
                    setNewFirstName(record.firstName);
                    setNewLastName(record.lastName);
                    setNewMobileNumber(record.mobileNum);
                  }}
                >
                  <EditOutlined />
                </Button>
              )}

              {record?.isAdmin === false && record?.isSuperAdmin === false && (
                <Button
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={() => MakeUserToAdmin(record?.id)}
                >
                  Make Admin
                </Button>
              )}
              {record?.isAdmin === true && (
                <Button
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={() => RemoveAdmin(record?.id)}
                >
                  remove from Admin
                </Button>
              )}
              {record?.isSuccessManager === false && (
                <>
                  <Popconfirm
                    title="Confirm to make this user a PSM ?"
                    onConfirm={() => confirm(record?.id)}
                    onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      style={{ color: "red", cursor: "pointer" }}
                      //onClick={() => MakeUserPSM(record?.id)}
                    >
                      Make PSM
                    </Button>
                  </Popconfirm>
                </>
              )}
            </Space>
          )}
        />
      </Table>
    </div>
  );
};

export default ModifyZyvkaMembers;
