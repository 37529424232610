import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    Card,
    Avatar,
    Typography,
    Button,
    Descriptions,
    Tag,
    Space,
} from "antd";
import {
    UserOutlined,
    LinkedinFilled,
    DownloadOutlined,
} from "@ant-design/icons";
import { Modal, Icon } from "semantic-ui-react";
import { Menu, Table } from "semantic-ui-react";
import { UserState } from "../features/userSlice";
import { fetchAllSkills } from "../api/auth";
import Currency, {
    ctcMap,
    CurrencySymbols,
    defaultCurrency,
} from "../Data/Currency";
import ErrorMessage from "../Error/ErrorMessage";
import SuccessMessage from "../Small-Components/SuccessMessage";
import { email } from "../features/clientSlice";

// API setup
const API = axios.create({ baseURL: process.env.REACT_APP_SERVER_URL });

const { Title, Paragraph } = Typography;
const { Column } = Table;

const AppDetail = () => {
    const { id } = useParams();
    const userState = useSelector(UserState);
    const dispatch = useDispatch();

    // State variables
    const [ApplicantDetails, setApplicantDetails] = useState({});
    const [skillOption, setSkillOption] = useState([]);
    const [table, setTable] = useState();
    const options = { day: "numeric", month: "long", year: "numeric" };
    const [questionAnswerModal, setQuestionAnswerModal] = useState("");
    const clEmail = useSelector(email);

    // Fetch all skills
    const fetchSkills = async () => {
        try {
            await fetchAllSkills(dispatch, setSkillOption);
        } catch (error) {
            console.log(error);
        }
    };

    // Fetch applicant data
    const getApplicantData = async () => {
        try {
            const result = await API.post(
                `/api/client/privillageAccess/applicant/${id}`,
                {
                    email: clEmail,
                },
                { withCredentials: true }
            );
            setApplicantDetails(result.data);
            // console.log(result.data);
        } catch (error) {
            console.info(error);
        }
    };
    const getData = async () => {
        try {
            const result = await API.post(
                `api/client/privillageAccess/getCandidateData`,
                {
                    email: clEmail,
                },
                {
                    withCredentials: true,
                }
            );
            setTable(result.data.quans);
            console.log(result.data.quans);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchSkills();
        getApplicantData();
        getData();
    }, []);

    const OpenQuestionAnswerModal = (id) => {
        setQuestionAnswerModal(id);
        console.log(id);
    };

    console.log(table);
    return (
        <>
            <div>
                <Card
                    loading={userState?.isPending}
                    style={{
                        width: "100%",
                    }}
                    title={
                        <div className="applicant-header">
                            <Avatar
                                size={{ xs: 50, sm: 50, md: 50, lg: 50, xl: 50, xxl: 60 }}
                                style={{ backgroundColor: "#6855f6" }}
                                icon={<UserOutlined />}
                            />

                            <Title level={2}>
                                {ApplicantDetails?.firstName + " " + ApplicantDetails?.lastName}
                            </Title>
                        </div>
                    }
                    extra={
                        <div className="applicant-header-extra">
                            <a
                                href={ApplicantDetails?.linkedIn_URL}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <LinkedinFilled
                                    style={{
                                        fontSize: "40px",
                                        color: "rgb(75,79,181)",
                                        cursor: "pointer",
                                    }}
                                />
                            </a>
                            <a
                                href={`${ApplicantDetails?.resume_urn?.split(" ").length > 1
                                    ? ApplicantDetails?.resume_urn?.split(" ")[1]
                                    : `${process.env.REACT_APP_SERVER_URL}/api/files/resume/${ApplicantDetails?.resume_urn}`
                                    }`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Button type="primary" icon={<DownloadOutlined />}>
                                    Download Resume
                                </Button>
                            </a>
                        </div>
                    }
                >
                    <div className="applicant-details-content">
                        <Descriptions title="" layout="vertical" bordered>
                            <Descriptions.Item label="Email">
                                {ApplicantDetails?.email}
                            </Descriptions.Item>
                            <Descriptions.Item label="Mobile">
                                {ApplicantDetails?.country_code} {ApplicantDetails?.mobileNum}
                            </Descriptions.Item>
                            <Descriptions.Item label="Experience">
                                {ApplicantDetails?.total_experience} Years
                            </Descriptions.Item>
                            <Descriptions.Item label="Current CTC">
                                {`${ApplicantDetails?.salary_currency
                                    ? CurrencySymbols[ApplicantDetails?.salary_currency]
                                    : CurrencySymbols["INR"]
                                    } ${ApplicantDetails?.current_salary}${ApplicantDetails?.salary_currency
                                        ? ctcMap[ApplicantDetails?.salary_currency]
                                        : ctcMap["INR"]
                                    } P.A.`}
                            </Descriptions.Item>
                            <Descriptions.Item label="Expected CTC">
                                {`${ApplicantDetails?.salary_currency
                                    ? CurrencySymbols[ApplicantDetails?.salary_currency]
                                    : CurrencySymbols["INR"]
                                    } ${ApplicantDetails?.expected_salary}${ApplicantDetails?.salary_currency
                                        ? ctcMap[ApplicantDetails?.salary_currency]
                                        : ctcMap["INR"]
                                    } P.A.`}
                            </Descriptions.Item>
                            <Descriptions.Item label="Notice Period">
                                {ApplicantDetails?.notice_period} days
                            </Descriptions.Item>
                            <Descriptions.Item label="Skills" span={3}>
                                {/* Add function to fetch skills one by one and return them in tags */}
                                {userState.isPending === false &&
                                    ApplicantDetails?.key_skills?.map((data, idx) => {
                                        return (
                                            <Tag color="#6855F6" key={idx}>
                                                {data}
                                            </Tag>
                                        );
                                    })}
                            </Descriptions.Item>
                            <Descriptions.Item label="Current Company" span={2}>
                                {ApplicantDetails?.current_company
                                    ? ApplicantDetails?.current_company
                                    : "NA"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Location">
                                {ApplicantDetails?.location ? ApplicantDetails?.location : "NA"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Added By" span={2}>
                                {ApplicantDetails?.creator?.firstName +
                                    " " +
                                    ApplicantDetails?.creator?.lastName}
                            </Descriptions.Item>
                            <Descriptions.Item label="Source" span={3}>
                                {ApplicantDetails?.candidateSource
                                    ? ApplicantDetails?.candidateSource
                                    : "NA"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Current status" span={4}>
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Position</Table.HeaderCell>
                                            <Table.HeaderCell>Company</Table.HeaderCell>
                                            <Table.HeaderCell>Round Status</Table.HeaderCell>
                                            <Table.HeaderCell>Last Updated</Table.HeaderCell>
                                            <Table.HeaderCell>Reject Reason</Table.HeaderCell>
                                            <Table.HeaderCell>Required Question </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {table &&
                                            table?.map((obj, idx) => {
                                                if (obj.applicantID === id) {
                                                    return (
                                                        <>
                                                            <Table.Row key={idx}>
                                                                <Table.Cell>{obj.jobs.job_title}</Table.Cell>
                                                                <Table.Cell>{obj.jobs.company_name}</Table.Cell>
                                                                <Table.Cell>{obj.current_round_num}</Table.Cell>
                                                                <Table.Cell>
                                                                    <p>
                                                                        {obj?.lastUpdated
                                                                            ? new Date(
                                                                                obj?.lastUpdated
                                                                            ).toLocaleDateString("en-GB", options)
                                                                            : "NA"}
                                                                    </p>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {obj?.reject_reason || "—"}
                                                                </Table.Cell>
                                                                {/* <Table.Cell>
                                                                    {
                                                                        (obj?.spquans?.length !== 0 && obj.spquans[0]?.question) ? (
                                                                            <>
                                                                                <Button
                                                                                    onClick={(e) =>
                                                                                        OpenQuestionAnswerModal(obj?.id)
                                                                                    }
                                                                                >
                                                                                    {" "}
                                                                                    see responses{" "}
                                                                                </Button>
                                                                                <Modal
                                                                                    dimmer="blurring"
                                                                                    size="small"
                                                                                    onClose={(e) => {
                                                                                        setQuestionAnswerModal(false);
                                                                                    }}
                                                                                    closeOnDimmerClick={false}
                                                                                    open={
                                                                                        questionAnswerModal === obj?.id
                                                                                    }
                                                                                    closeIcon
                                                                                >
                                                                                    <Modal.Content>
                                                                                        <Space direction="vertical" size={16}>
                                                                                            <Title level={4}>
                                                                                                Required Questions!
                                                                                            </Title>
                                                                                            {
                                                                                                obj?.spquans?.map((el) => {
                                                                                                    if (el?.question) {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <Title level={5}>
                                                                                                                    Q -{" "}
                                                                                                                    {el?.question
                                                                                                                        .charAt(0)
                                                                                                                        .toUpperCase() +
                                                                                                                        el?.question.slice(1)}
                                                                                                                </Title>
                                                                                                                <Paragraph>
                                                                                                                    {el?.ans?.answere ||
                                                                                                                        "Not Answered Yet"}
                                                                                                                </Paragraph>
                                                                                                            </>
                                                                                                        );
                                                                                                    }
                                                                                                })}
                                                                                        </Space>
                                                                                    </Modal.Content>
                                                                                </Modal>
                                                                            </>
                                                                        ) : (
                                                                            <p>No questions Provided</p>
                                                                        )}
                                                                </Table.Cell> */}
                                                                <Table.Cell>
                                                                    {(obj?.spquans?.length !== 0 && obj.spquans[0]?.question) ? (
                                                                        <>
                                                                            <Button onClick={(e) => OpenQuestionAnswerModal(obj?.id)}>
                                                                                See Responses
                                                                            </Button>
                                                                            <Modal
                                                                                dimmer="blurring"
                                                                                size="small"
                                                                                onClose={(e) => setQuestionAnswerModal(false)}
                                                                                closeOnDimmerClick={false}
                                                                                open={questionAnswerModal === obj?.id}
                                                                                closeIcon
                                                                            >
                                                                                <Modal.Content>
                                                                                    <Space direction="vertical" size={16}>
                                                                                        <Title level={4}>Required Questions!</Title>
                                                                                        {obj.spquans.map((el) => {
                                                                                            if (el.question) {
                                                                                                return (
                                                                                                    <React.Fragment key={el.id}>
                                                                                                        <Title level={5}>
                                                                                                            Q - {el.question.charAt(0).toUpperCase() + el.question.slice(1)}
                                                                                                        </Title>
                                                                                                        <Paragraph>
                                                                                                            {el.ans?.answere || "Not Answered Yet"}
                                                                                                        </Paragraph>
                                                                                                    </React.Fragment>
                                                                                                );
                                                                                            }
                                                                                            return null;
                                                                                        })}
                                                                                    </Space>
                                                                                </Modal.Content>
                                                                            </Modal>
                                                                        </>
                                                                    ) : (
                                                                        <p>No questions provided</p>
                                                                    )}
                                                                </Table.Cell>

                                                            </Table.Row>
                                                        </>
                                                    );
                                                }
                                            })}
                                    </Table.Body>
                                    <Table.Footer>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan="5">
                                                <Menu floated="right" pagination>
                                                    <Menu.Item as="a" icon>
                                                        <Icon name="chevron left" />
                                                    </Menu.Item>
                                                    <Menu.Item as="a">1</Menu.Item>
                                                    <Menu.Item as="a">2</Menu.Item>
                                                    <Menu.Item as="a">3</Menu.Item>
                                                    <Menu.Item as="a">4</Menu.Item>
                                                    <Menu.Item as="a" icon>
                                                        <Icon name="chevron right" />
                                                    </Menu.Item>
                                                </Menu>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Footer>
                                </Table>
                            </Descriptions.Item>
                        </Descriptions>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default AppDetail;
