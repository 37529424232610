import React, { useEffect, useState } from "react";
import {
  Button,
  Space,
  Table,
  Select,
  Modal,
  Form,
  Input,
  DatePicker,
  InputNumber,
  Radio,
  Dropdown,
  Checkbox
} from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { ChangeApplicantStatus, ChangeJobAfterResign, updateJobStatus } from "../api/ZyvkaMember";
import { useParams, useNavigate, Link } from "react-router-dom";
import { ZyvkaMemberState } from "../features/ZyvkaMemberSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
const { Column, ColumnGroup } = Table;
const { Option } = Select;
const { TextArea } = Input;
const API = axios.create({ baseURL: process.env.REACT_APP_SERVER_URL });


export const AppliedApplicant = () => {

  const [applicants, setApplicants] = useState([]);
  const [statusOption, setStatusOption] = useState([]);
  const [flag, setFlag] = useState(false);
  const [currencyType, setCurrencyType] = useState("INR");
  const [join_date, setJoinDate] = useState("");

  const { id } = useParams();
  const zyvkaState = useSelector(ZyvkaMemberState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // updates whole status list in 1 state - contains {id:appId, status: appStatus}
  const [status, setStatus] = useState([]);
  const [round, setRound] = useState();
  const [value, setValue] = useState([]);
  const [tempID, setTempID] = useState("");

  //Joining modal states and functions
  const [isModalOpen, setIsModalOpen] = useState("");
  const [isRejectModalOpen, setIsRejectModalOpen] = useState("");
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  //search 
  const [searchValue, setSearchValue] = useState("");
  const [emailOption, setEmailOption] = useState();
  const [checkerModal, setCheckerModal] = useState(false);
  const [shareEmailOption, setShareEmailOption] = useState();
  const [email, setEmail] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [shareModal, setShareModal] = useState();
  const stat_array = ["Candidate Joined"];

  const onSelectChange = (newSelectedRowKeys) => {
    // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const resignHandler = async () => {
    await ChangeJobAfterResign(
      {
        jobID: id,
      },
      dispatch
    );

  }

  const startHandler = () => {
    setShareModal(true);
  }
  const startCancel = () => {
    setShareModal(false);
  }
  const start = async () => {
    try {
      const rep = {
        jobID: id,
        applicantData: selectedRowKeys,
        //clientEmail: selectedValues,
      };
      console.info(rep);
      // console.log(selectedRowKeys, "here is the data");
      setShareModal(false);
      const res = await API.post('/api/applicant/getApplicantsDataForClientEmail', {
        jobID: id,
        applicantData: selectedRowKeys,
        clientEmail: selectedValues,
        // clientEmail: ["contact.yasier@gmail.com"]
      }, { withCredentials: true })
      // console.log(res)
    } catch (e) {
      console.log(e.message)
    }
  };
  const hasSelected = selectedRowKeys.length > 0;


  const handleModalOpen = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setSelectedValues([]);
  };


  const handleOptionChange = (event) => {
    const { value } = event.target;
    const updatedValues = [...selectedValues];

    if (event.target.checked) {
      updatedValues.push(value);
    } else {
      const index = updatedValues.indexOf(value);
      if (index > -1) {
        updatedValues.splice(index, 1);
      }
    }

    setSelectedValues(updatedValues);
  };
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      handleModalClose();
      const res = await API.post('api/client/sendEmailToClientContactPerson', {
        // emails: ['siddharthjoshi111997@gmail.com'],
        emails: selectedValues,
        jobID: id
      }, {
        withCredentials: true
      })
      // console.log(selectedValues);
    } catch (e) {
      console.log(e);
    }
  };
  // data fetcher 
  const fetchData = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/jobs/getApplicants/${id}`,
        { withCredentials: true }
      );

      setApplicants(result.data);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(applicants, "data")
  const fetchJobData = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/jobs/getJobByID/${id}`,
        { withCredentials: true }
      );

      let arr = [];
      result?.data?.hiring_process?.forEach((element) => {
        arr.push(JSON.parse(element)?.name);
      });
      getClientEmail(result?.data?.company_name);
      setStatusOption(arr);
    } catch (error) {
      console.log(error);
    }
  };


  //modal handlers
  const onRejectModalFinish = async (data, status) => {
    console.log(status);
    const upValue = getValue(isRejectModalOpen);
    console.log(upValue);
    let statusSplitArray = upValue.split(" ");
    try {
      if (window.confirm(`You Want to Reject the candidate?`)) {
        const applicationID = isRejectModalOpen;
        const upValue = getValue(applicationID);
        statusUpdate(applicationID, upValue);
        const res = parseInt(statusSplitArray[1]) || 0
        // const res = parseInt(upValue?.split(" ")[0].substring(1));
        // console.log(status);
        rejectModalCancel();
        console.log(res);
        await ChangeApplicantStatus(
          {
            applicationID: applicationID,
            status: upValue,
            reject_reason: data.reject_reason,
            round_number: res,
            isLastRound:
              (parseInt(upValue?.split(" ")[1]) === statusOption.length)
                ? true
                : false,
          },
          dispatch
        );
        setFlag((flag) => !flag);
      }
      // console.log(data.reject_reason, "reason is here");
    } catch (error) {
      console.log(error);
    }
  };
  const rejectModalCancel = () => {
    setIsRejectModalOpen("");
  }
  const onRejectModalFail = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const statusChangeHandler = async (status, applicationID, date) => {
    console.log(status)
    let statusSplitArray = status.split(" ");
    valueUpdate(applicationID, status);
    //! setValue(status); --> 

    // statusUpdate(applicationID, status);
    if (status === "Offer Accepted") {
      // statusUpdate(applicationID, status);
      setIsModalOpen(applicationID);
    } else if ((stat_array.includes(status)) && date === null) {
      setCheckerModal({ id: applicationID, status: status });
    }
    else if (statusSplitArray[2] === "Rejected" || statusSplitArray[1] === "Rejected") {
      // statusUpdate(applicationID, status);
      setIsRejectModalOpen(applicationID);
    }
    // candidateJoinedStatusChangeHandlerPreChecker
    else {
      if (window.confirm(`You Want to change Status to ${status}`)) {
        if (statusSplitArray[2] === "Selected") {
          statusUpdate(applicationID, status);
          const res = parseInt(status.split(" ")[1]) || 0
          // const new = status.split(" ")[3]
          setRound(res);
          await ChangeApplicantStatus(
            {
              applicationID: applicationID,
              status: status,
              round_number: res,
              isLastRound:
                (parseInt(status?.split(" ")[1]) === statusOption.length)
                  ? true
                  : false,
            },
            dispatch
          );
          // console.log("updated", round, "~");
        } else if (statusSplitArray[1] === "Resigned") {
          statusUpdate(applicationID, status);
          setRound(round);
          await ChangeApplicantStatus(
            {
              applicationID: applicationID,
              status: status,
            },
            dispatch
          );
          resignHandler();
        }
        else {
          statusUpdate(applicationID, status);
          setRound(round);
          await ChangeApplicantStatus(
            {
              applicationID: applicationID,
              status: status,
            },
            dispatch
          );
        }
      }
      setFlag((flag) => !flag);
    }
  };
  const handleCancel = () => {
    setIsModalOpen("");
  };
  const showStatusModal = (id) => {
    setIsStatusModalOpen(id);
  };
  const handleOkStatus = (id, date) => {
    setIsStatusModalOpen(false);
    // statusUpdate(tempID, value);
    const upValue = getValue(id);
    const status = getState(id);
    if (upValue !== status) {
      statusChangeHandler(upValue, id, date);
    }
  };
  const handleCancelStatus = (id) => {
    setIsStatusModalOpen(false);
    valueRevert(id);
  };

  const candidateJoinedStatusChangeHandler = async (values) => {
    try {
      if (window.confirm(`You Want to change Status to Offer Accepted `)) {
        const applicationID = isModalOpen;
        statusUpdate(applicationID, "Candidate Joined");
        const currency_type = currencyType;
        const joining_date = join_date;
        handleCancel();
        setCurrencyType("INR");
        setJoinDate("");

        await ChangeApplicantStatus(
          {
            applicationID: applicationID,
            status: "Offer Accepted",
            final_salary: values.final_salary,
            currencyType: currency_type,
            joining_date: joining_date,
          },
          dispatch
        );

        setFlag((flag) => !flag);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const candidateJoinedStatusChangeHandlerPreChecker = async (values) => {
    try {
      if (window.confirm(`Please specify the details of joining `)) {
        const applicationID = checkerModal?.id;
        statusUpdate(applicationID, checkerModal?.status);
        const currency_type = currencyType;
        const joining_date = join_date;
        setCheckerModal(false);
        setCurrencyType("INR");
        setJoinDate("");

        await ChangeApplicantStatus(
          {
            applicationID: applicationID,
            status: checkerModal?.status,
            final_salary: values.final_salary,
            currencyType: currency_type,
            joining_date: joining_date,
          },
          dispatch
        );

        setFlag((flag) => !flag);
      }
    } catch (error) {
      console.log(error);
    }
    setCheckerModal(false);
  };

  const candidateJoinedStatusFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // job status setter and getter function 
  const statusUpdate = (stateId, newStatus) => {
    const existingStateIndex = status.findIndex((item) => item.id === stateId);

    if (existingStateIndex !== -1) {
      // If an object with stateId exists, update its status
      const updatedStateArray = [...status];
      updatedStateArray[existingStateIndex] = { id: stateId, status: newStatus };
      setStatus(updatedStateArray);
    } else {
      // If an object with stateId doesn't exist, add a new object to the status
      const newStateItem = { id: stateId, status: newStatus };
      setStatus([...status, newStateItem]);
    }
  }
  const getState = (id, nStatus) => {
    const existingObject = status.find(el => el.id === id);
    if (existingObject) {
      // console.log(nStatus, "exists");
      return existingObject.status;
    } else {
      statusUpdate(id, nStatus);
      // console.log(nStatus, "update");
      return null;
    }
  };

  //change and update value 
  const valueUpdate = (id, newValue) => {
    const index = value.findIndex((item) => item.id === id);
    if (index === -1) {
      // Add new object to state array
      setValue([...value, { id, value: newValue }]);
    } else {
      // Update existing object in state array
      const updatedData = [...value];
      updatedData[index] = { id, value: newValue };
      setValue(updatedData);
    }
  };
  const getValue = (id) => {
    const item = value?.find((item) => item?.id === id);
    return item?.value || "";
  };
  const valueRevert = (id) => {
    const item = status.find((item) => item.id === id);
    const idx = value.findIndex((item) => item.id === id);
    // console.log(item);
    const updateValue = [...value];
    updateValue[idx] = { id: id, value: item?.status };
    setValue(updateValue);
  }

  // button and radio handler
  const onChangeRadio = (e, id) => {
    // if (e.target.value !== status) {
    //   setValue(e.target.value);
    // }
    valueUpdate(id, e.target.value);
    // setValue(e.target.value);

  };
  const handleButtonClick = (id, status) => {
    // console.log(id);
    setTempID(id);
    //* setStatus(status)
    // setStaus(status);
    valueUpdate(id, status);
    // setValue(status);
    showStatusModal(id);
  }
  const onChange = (date, dateString) => {
    setJoinDate(dateString);
  };
  const clickHandler = (id1) => {
    const url = `/ dashboard / applicant / job / ${id1}`;
    navigate(url);
  };
  const currencySelector = (
    <Form.Item name="currency_type" noStyle>
      <Select
        defaultValue={currencyType}
        rules={[
          {
            required: true,
            message: "This is Required",
          },
        ]}
        onChange={(e) => setCurrencyType(e)}
      >
        <Option value="USD">$</Option>
        <Option value="INR">₹</Option>
      </Select>
    </Form.Item>
  );

  const getClientEmail = async (comp_name) => {
    try {
      const res = await API.post('api/client/getClientContactPerson', {
        name: comp_name
      }, {
        withCredentials: true
      })
      setEmailOption(res.data?.contact_person);
    }
    catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchData();
    fetchJobData();
  }, [flag, status]);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    selectedRowKeys: selectedRowKeys,
  };

  const handleHeaderCheckboxChange = (e) => {
    if (e.target.checked) {
      const keys = applicants.map((record) => record.applicantID);
      setSelectedRowKeys(keys);
      setSelectedRows(applicants);
    } else {
      setSelectedRowKeys([]);
      setSelectedRows([]);
    }
    // console.log(selectedRows, "selected rows ")
  };

  const handleRowCheckboxChange = (e, record) => {
    const keys = [...selectedRowKeys];
    const rows = [...selectedRows];
    if (e.target.checked) {
      keys.push(record.applicantID);
      rows.push(record);
    } else {
      const index = keys.indexOf(record.applicantID);
      keys.splice(index, 1);
      rows.splice(index, 1);
    }
    setSelectedRowKeys(keys);
    setSelectedRows(rows);
    // console.log(selectedRows, "selected row ")

  };
  const columns = [
    {
      title: () => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          Name
        </div>
      ),
      key: "id",
      align: "center",
      render: (_, record) => (
        <div
          style={{ display: "flex", alignItems: "center", maxWidth: "250px" }}
        >
          <Link
            to={`/dashboard/applicant/${record.applicant.id}`}
            size="middle"
            key={record.id}
          >
            {record.applicant.firstName + " " + record.applicant.lastName}
          </Link>
          {record.applicantOtherInfo !== "" && (
            <span title={record.applicantOtherInfo} style={{ cursor: "pointer", color: "red" }}>&nbsp; !</span>
          )}
        </div>
      ),
    },
    {
      title: "Email",
      key: "email",
      filteredValue: [searchValue],
      onFilter: (value, record) => {
        return (
          String(
            record?.applicant?.firstName + " " + record?.applicant?.lastName
          )
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record?.applicant?.lastName)
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record?.applicant?.email)
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record?.applicant?.mobileNum)
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record?.current_status)
            ?.toLowerCase()
            .includes(value.toLowerCase())
        );
      },
      render: (_, record) => (
        <Space size="middle" key={record.id}>
          {record.applicant.email}
        </Space>
      ),
    },
    {
      title: "Mobile No",
      key: "mobileNum",
      render: (_, record) => (
        <Space size="middle" key={record.id}>
          {record.applicant.mobileNum}
        </Space>
      ),
    },
    {
      title: "Status",
      key: "job_status",
      render: (_, record) => (
        <>
          {record?.current_status.toLowerCase().includes("reject") ? (
            <Button
              onClick={() =>
                handleButtonClick(record?.id, record?.current_status)
              }
              danger
            >
              {record?.current_status}
            </Button>
          ) : getState(record?.id, record?.current_status) ===
            "Candidate Joined" ? (
            <Button
              type="primary"
              ghost
              onClick={() =>
                handleButtonClick(record?.id, record?.current_status)
              }
            >
              {getState(record?.id, record?.current_status) ||
                record?.current_status}
            </Button>
          ) : (
            <Button
              onClick={() =>
                handleButtonClick(record?.id, record?.current_status)
              }
            >
              {getState(record?.id, record?.current_status) ||
                record?.current_status}
            </Button>
          )}
          <Modal
            title="Change Status"
            visible={isStatusModalOpen === record?.id}
            onOk={(e) => handleOkStatus(record?.id, record?.joining_date)}
            onCancel={(e) => handleCancelStatus(record?.id)}
          >
            <Radio.Group
              onChange={(e) => onChangeRadio(e, record?.id)}
              value={getValue(record?.id)}
            >
              <Space direction="vertical" key={record?.id}>
                <Radio value="Under Review">Under Review</Radio>
                <Radio value="Zyvka Shortlisted">Zyvka Shortlisted</Radio>
                <Radio value="Zyvka Rejected">Zyvka Rejected</Radio>
                <Radio value="Client Shortlisted">Client Shortlisted</Radio>
                <Radio value="Client Rejected">Client Rejected</Radio>
                {statusOption.map((data, idx) => {
                  return (
                    <React.Fragment key={record?.id}>
                      <Radio value={data + " Selected"}>
                        {data + " Selected"}
                      </Radio>
                      <Radio value={data + " Rejected"}>
                        {data + " Rejected"}
                      </Radio>
                    </React.Fragment>
                  );
                })}
                <Radio value="Assignment Pending"> Assignment Pending </Radio>
                <Radio value="Assignment Rejected"> Assignment Rejected </Radio>
                <Radio value="Offer Discussion"> Offer Discussion </Radio>
                <Radio value="Offer rolled out"> Offer rolled out </Radio>
                <Radio value="Offer Accepted"> Offer Accepted </Radio>
                <Radio value="Offer Rejected"> Offer Rejected </Radio>
                <Radio value="Candidate Withdrawn"> Candidate Withdrawn </Radio>
                <Radio value="Didn't turn up for Drive">
                  {" "}
                  Didn't turn up for Drive{" "}
                </Radio>
                <Radio value="Candidate Joined"> Candidate Joined </Radio>
                <Radio value="Candidate Resigned"> Candidate Resigned </Radio>
                <Radio value="Position Closed"> Position Closed </Radio>
              </Space>
            </Radio.Group>
          </Modal>
          <Modal
            title="Joining Details"
            visible={isModalOpen === record?.id}
            // onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
          >
            <Form
              name="basic"
              onFinish={candidateJoinedStatusChangeHandler}
              onFinishFailed={candidateJoinedStatusFailed}
              autoComplete="off"
              style={{
                display: "flex",
                flexDirection: "column",

                justifyContent: "center",
              }}
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 20,
              }}
            >
              <Form.Item
                label="Salary"
                name="final_salary"
                rules={[
                  {
                    required: true,
                    message: "Salary Information Is Required",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  addonBefore={currencySelector}
                />
              </Form.Item>

              <Form.Item
                label="Joining Date"
                name="joining_date"
                rules={[
                  {
                    required: true,
                    message: "Please input joining date!",
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} onChange={onChange} />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 0,
                  span: 24,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",

                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <Button type="danger" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Update Status
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Modal>

          <Modal
            title="Joining Details"
            visible={checkerModal?.id === record?.id}
            onCancel={() => setCheckerModal(null)}
            footer={null}
          >
            <Form
              name="basic"
              onFinish={candidateJoinedStatusChangeHandlerPreChecker}
              onFinishFailed={() => setCheckerModal(null)}
              autoComplete="off"
              style={{
                display: "flex",
                flexDirection: "column",

                justifyContent: "center",
              }}
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 20,
              }}
            >
              <Form.Item
                label="Salary"
                name="final_salary"
                rules={[
                  {
                    required: true,
                    message: "Salary Information Is Required",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  addonBefore={currencySelector}
                />
              </Form.Item>

              <Form.Item
                label="Joining Date"
                name="joining_date"
                rules={[
                  {
                    required: true,
                    message: "Please input joining date!",
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} onChange={onChange} />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 0,
                  span: 24,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",

                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <Button type="danger" onClick={() => setCheckerModal(null)}>
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Update Status
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Modal>

          {/* Reject MODAL */}
          <Modal
            title="Reject Reason"
            visible={isRejectModalOpen === record?.id}
            // onOk={handleOk}
            onCancel={rejectModalCancel}
            footer={null}
          >
            <Form
              name="basic"
              onFinish={onRejectModalFinish}
              onFinishFailed={onRejectModalFail}
              autoComplete="off"
            >
              <Form.Item
                label="Reason"
                name="reject_reason"
                rules={[
                  {
                    required: true,
                    message: "Please input reason for Rejection",
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>
              <div
                style={{
                  display: "flex",
                  width: "100%",

                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <Button type="danger" onClick={rejectModalCancel}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Submit Reason
                </Button>
              </div>
            </Form>
          </Modal>
        </>
      ),
    },
    {
      title: "Round Number",
      key: "current_round_num",
      render: (_, record) => (
        <>
          <span key={record?.applicantID}>{record?.current_round_num}</span>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <a
          href={`/dashboard/applicant/job/${record?.id}`}
          rel="noreferrer"
          target="_blank"
          style={{
            textDecoration: "none",
          }}
        >
          <Space
            size="middle"
            key={record.id}
            style={{ cursor: "pointer", fontSize: "17px" }}
          >
            <EyeOutlined />
          </Space>
        </a>
      ),
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('http://localhost:5000/api/zyvka/data', {
          headers: {
            'api-key': 'comwaliwebsite', // Replace with your API key
          },
        });
        const data = await response.json();
      } catch (error) {
        console.error('Error:', error.message);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="tableDiv" style={{ overflow: "scroll" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          alignItems: "center"
          // gap: "10px",
        }}
      >
        <Space direction="horizontal" wrap={true}>
          <div style={{ display: "flex", placeContent: "center" }} >
            <div>
              <Button type="primary" onClick={handleModalOpen}>
                Share to
              </Button>
              <Modal
                title="Select Emails"
                visible={modalVisible}
                onCancel={handleModalClose}
                footer={[
                  <Button key="cancel" onClick={handleModalClose}>
                    Cancel
                  </Button>,
                  <Button key="submit" type="primary" onClick={(e) => handleSubmit(e)}>
                    Submit
                  </Button>,
                ]}
              >
                {emailOption?.map((option) => (
                  <div key={option.email}>
                    <Checkbox
                      value={option.email}
                      checked={selectedValues.includes(option.email)}
                      onChange={handleOptionChange}
                    >
                      {option.email}
                    </Checkbox>
                  </div>
                ))}
              </Modal>
            </div>
            <div
              style={{
                marginBottom: 16,
                marginLeft: 20
              }}
            >
              {/* <Button type="primary" onClick={startHandler} disabled={!hasSelected}>
                Share Info
              </Button> */}
              <Modal
                title="Select Emails & share info to them"
                visible={shareModal}
                onCancel={startCancel}
                footer={[
                  <Button key="cancel" onClick={startCancel}>
                    Cancel
                  </Button>,
                  <Button key="submit" type="primary" onClick={start}>
                    Submit
                  </Button>,
                ]}
              >
                {emailOption?.map((option) => (
                  <div key={option.email}>
                    <Checkbox
                      value={option.email}
                      checked={selectedValues.includes(option.email)}
                      onChange={handleOptionChange}
                    >
                      {option.email}
                    </Checkbox>
                  </div>
                ))}
              </Modal>
              <span
                style={{
                  marginLeft: 8,
                }}
              >
                {hasSelected ? `Selected ${selectedRowKeys.length} applicant data` : ''}
              </span>
            </div>
          </div>
        </Space>
        <Space direction="horizontal" style={{ marginRight: "10px" }} wrap={true}>
          <Input
            placeholder="Search Here"
            style={{
              minWidth: 200,
              maxWidth: 400,
              paddingLeft: 10,
            }}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
          <Button style={{ width: 80 }}>Search</Button>
        </Space>
      </div>

      <Table
        // rowSelection={rowSelection}
        scroll={{ x: true }}
        dataSource={applicants}
        bordered={true}
        rowKey={(record) => record?.applicantID}
        key="table"
        columns={columns}
      />
    </div>
  );
};
