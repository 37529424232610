import React from 'react';
import { Avatar, useChatContext } from 'stream-chat-react';

const TeamChannelPreview = ({ setActiveChannel, setIsCreating, setIsEditing, setToggleContainer, channel, type }) => {
    const { channel: activeChannel, client } = useChatContext();

    const ChannelPreview = () => {
        return (
            <>
            {client.user.role==="guest"?(
                  <div className="channel-preview__item single">
                          
                              <Avatar
                                  image={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaDB2VkFjyONT6tE5LCryj22rkMWOaLmj3tA&s"}
                                  name={"Zyvka"}
                                  size={24}
                              />
                              <p>{"Zyvka"}</p>
                    </div>
            ):(
             <div className="channel-preview__item single">
                          
                              <Avatar
                                  image={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaDB2VkFjyONT6tE5LCryj22rkMWOaLmj3tA&s"}
                                  name= {channel?.data?.name || channel?.data?.id}
                                  size={24}
                              />
                              <p> {channel?.data?.name || channel?.data?.id}</p>
                    </div>
            )}
       
        </>
    )};


    const DirectPreview = () => {
        const members = Object.values(channel.state.members).filter(({ user }) => user.id !== client.userID);
        return (
            <div className="channel-preview__item single">
                {
                    client.user.role == "guest"
                        ?
                        <>
                            <Avatar
                                image={"https://i.ibb.co/vZs5YmC/logozyvk.png"}
                                name={"Zyvka"}
                                size={24}
                            />
                            <p>{"Zyvka"}</p>
                        </>
                        :
                        <>
                            <Avatar
                                image={members[0]?.user?.image}
                                name={members[0]?.user?.username || members[0]?.user?.email}
                                size={24}
                            />
                            <p>{members[0]?.user?.username || members[0]?.user?.email}</p>
                        </>
                }
            </div>
        )
    }

    return (
        <div className={
            channel?.id === activeChannel?.id
                ? 'channel-preview__wrapper__selected'
                : 'channel-preview__wrapper'
        }
            onClick={() => {
                setIsCreating(false);
                setIsEditing(false);
                setActiveChannel(channel);
                if (setToggleContainer) {
                    setToggleContainer((prevState) => !prevState)
                }
            }}
        >
            {type === 'team' ? <ChannelPreview /> : <DirectPreview />}
        </div>
    );
}

export default TeamChannelPreview