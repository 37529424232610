import React, { useEffect, useRef } from 'react';
import { Avatar, useChatContext } from 'stream-chat-react';

const CustomAvatar = ({ name, size }) => {
  const initials = name ? name.charAt(0).toUpperCase() : '?';
  
  return (
    <div 
      className="custom-avatar"
      style={{
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: '100%',
        backgroundColor: '#6856f5',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: `${size / 2}px`,
        fontWeight: 'bold',
      }}
    >
      {initials}
    </div>
  );
};

const channelByUser = async ({ client, setActiveChannel, channel, setChannel }) => {
  const filters = {
    type: 'messaging',
    member_count: 2,
    members: { $eq: [client.user.id, channel.id] },
  };

  const [existingChannel] = await client.queryChannels(filters);

  if (existingChannel) return setActiveChannel(existingChannel);

  const newChannel = client.channel('messaging', { members: [client.user.id, channel.id] });
  
  setChannel(newChannel)
  return setActiveChannel(newChannel);
};

const SearchResult = ({ channel, focusedId, type, setChannel, setToggleContainer }) => {
  const { client, setActiveChannel } = useChatContext();

  if (type === 'channel') {
    return (
      <div
        onClick={() => {
          setChannel(channel)
          if(setToggleContainer) {
            setToggleContainer((prevState) => !prevState)   
          }
        }}
        className={focusedId === channel.id ? 'channel-search__result-container__focused' : 'channel-search__result-container' }
      >
        <div className='result-hashtag'> 
             <Avatar
              image={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaDB2VkFjyONT6tE5LCryj22rkMWOaLmj3tA&s"}
              shape='circle'
              name= {(channel?.data?.name || channel?.data?.id)}
              size={32}
          />
          </div>
        <p className='channel-search__result-text'>{channel.state._channel.data.name}</p>
      </div>
    );
  }

  const otherMember = Object.values(channel.state.members).find(
    member => member.user.id !== client.userID
  );

  return (
    <div
      onClick={async () => {
        channelByUser({ 
          client, 
          setActiveChannel, 
          channel: otherMember.user, 
          setChannel 
        })
        if(setToggleContainer) {
            setToggleContainer((prevState) => !prevState)
        }
      }}
      className={focusedId === channel.id ? 'channel-search__result-container__focused' : 'channel-search__result-container' }
    >
      <div className='channel-search__result-user'>
        <CustomAvatar name={`${otherMember?.user?.Firstname} ${otherMember?.user?.Lastname}`} size={30} />
        <p className='channel-search__result-text'>{`${otherMember?.user?.Firstname} ${otherMember?.user?.Lastname}`}</p>
      </div>
    </div>
  );
};

const ResultsDropdown = ({ teamChannels, directChannels, focusedId, loading, setChannel, setToggleContainer, setQuery }) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setQuery('');
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setQuery]);

  return (
    <div className='channel-search__results' ref={wrapperRef}>
      <p className='channel-search__results-header'>Channels</p>
      {loading && !teamChannels.length && (
        <p className='channel-search__results-header'>
          <i>Loading...</i>
        </p>
      )}
      {!loading && !teamChannels.length ? (
        <p className='channel-search__results-header'>
          <i>No channels found</i>
        </p>
      ) : (
        teamChannels?.map((channel, i) => (
          <SearchResult
            channel={channel}
            focusedId={focusedId}
            key={i}
            setChannel={setChannel}
            type='channel'
            setToggleContainer={setToggleContainer}
          />
        ))
      )}
      <p className='channel-search__results-header'>Users</p>
      {loading && !directChannels.length && (
        <p className='channel-search__results-header'>
          <i>Loading...</i>
        </p>
      )}
      {!loading && !directChannels.length ? (
        <p className='channel-search__results-header'>
          <i>No direct messages found</i>
        </p>
      ) : (
        directChannels?.map((channel, i) => (
          <SearchResult
            channel={channel}
            focusedId={focusedId}
            key={i}
            setChannel={setChannel}
            type='user'
            setToggleContainer={setToggleContainer}
          />
        ))
      )}
    </div>
  );
};

export default ResultsDropdown;