import React from 'react'
import ChatComponent from './ChatComponent'
const ChatPages = () => {
  return (
    <div style={{
      marginLeft: '-25px',
      marginRight: '-38px',
      marginBottom: '-20px',
      height: 'calc(86.5vh + 22px)', 
      width: 'calc(100% + 66px)', 
      overflow: 'hidden'
    }}>
      <ChatComponent />
    </div>
  )
}

export default ChatPages