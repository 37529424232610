import React, { Component } from "react";
import {
  Layout,
  Button,
  Typography,
  Card,
  Form,
  Input,
  notification,
} from "antd";

import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import HeaderComponent from "../components/HeaderComponent";
import { useParams } from "react-router-dom";
import { forgotResetPassword } from "../api/auth";
import { useDispatch } from "react-redux";
import { UserState } from "../features/userSlice";
import { useSelector } from "react-redux";
const { Title } = Typography;

const { Content } = Layout;

const ResetPass = () => {
  const { id, token } = useParams();
  // const userState = useSelector(UserState);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: type,
      description: message,
    });
  };
  const onFinish = async (values) => {
    await forgotResetPassword(
      {
        id: id,
        token: token,
        newPassword: values.password,
        confirmedPassword: values.confirm,
      },
      dispatch,
      navigate
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <HeaderComponent />
        <br />
        <div
          style={{
            width: "100%",
            marginTop: "150px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="layout-default ant-layout layout-sign-up">
            <Content className="p-0">
              <div className="sign-up-header">
                <div className="content">
                  <Title>Reset Password</Title>
                </div>
              </div>

              <Card
                className="card-signup header-solid h-half ant-card pt-3"
                bordered={true}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  padding: "0px",
                  // border: "solid 2px black",
                }}
              >
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  className="row-col"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    // maxWidth: "500px",
                    width: "100%",

                    // border: "solid 2px black",
                  }}
                >
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input new password!",
                      },
                    ]}
                    style={{ maxWidth: "500px", width: "100%" }}
                    hasFeedback
                  >
                    <Input.Password placeholder="Enter new password" />
                  </Form.Item>

                  <Form.Item
                    name="confirm"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password placeholder="Enter password again" />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      style={{ width: "100%" }}
                      type="primary"
                      htmlType="submit"
                    >
                      Reset
                    </Button>
                  </Form.Item>
                </Form>
                <p className="font-semibold text-muted text-center">
                  Already have an account?{" "}
                  <Link to="/login" className="font-bold text-dark">
                    Sign In
                  </Link>
                </p>
              </Card>
            </Content>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPass;
