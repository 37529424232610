import { createSlice } from "@reduxjs/toolkit";
import {
    getDashboardApplicant,
    getDashboardJobs,
    getPartnerData,
    getUserMonitorData,
    getZyvkaInternalData,
    getZyvkaAiData,
} from "../api/MetabaseFetch";
import {
    applicantAnalyticLocalCache,
    getApplicantAnalyticLocalCache,
    getZyvkaInternalLocalCache,
    getZyvkaAiLocalCache,
    getUserActivityLocalCache,
    getPositionAnalyticLocalCache,
    positionAnalyticLocalCache,
    partnerAnalyticsLocalCache,
    getPartnerAnalyticsLocalCache,
    userActivityLocalCache,
    zyvkaInternalLocalCache,
    zyvkaAiLocalCache,
} from "../cache/localStorage";

const initialState = {
    applicantAnalytics: getApplicantAnalyticLocalCache(),
    positionAnalytics: getPositionAnalyticLocalCache(),
    partnerAnalytics: getPartnerAnalyticsLocalCache(),
    zyvkaInternal: getZyvkaInternalLocalCache(),
    userActivity: getUserActivityLocalCache(),
    zyvkaAi: getZyvkaAiLocalCache(),
    isLoading: false,
    error: null,
};

const analyticsSlice = createSlice({
    name: "analytics",
    initialState,
    reducers: {
        setLoading: (state) => {
            state.isLoading = true;
        },
        setError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        setApplicantAnalytics: (state, action) => {
            state.isLoading = false;
            state.applicantAnalytics = action.payload;
        },
        setPositionAnalytics: (state, action) => {
            state.isLoading = false;
            state.positionAnalytics = action.payload;
        },
        setPartnerAnalytics: (state, action) => {
            state.isLoading = false;
            state.partnerAnalytics = action.payload;
        },
        setUserActivity: (state, action) => {
            state.isLoading = false;
            state.userActivity = action.payload;
        },
        setZyvkaInternal: (state, action) => {
            state.isLoading = false;
            state.zyvkaInternal = action.payload;
        },
        setZyvkaAiAnalytics: (state, action) => {
            state.isLoading = false;
            state.zyvkaAi = action.payload;
        }
    },
});

export const {
    setLoading,
    setError,
    setApplicantAnalytics,
    setPositionAnalytics,
    setPartnerAnalytics,
    setUserActivity,
    setZyvkaInternal,
    setZyvkaAiAnalytics,
} = analyticsSlice.actions;

export const fetchApplicantAnalytics = () => {
    return async (dispatch) => {
        let applicantAnalytics = getApplicantAnalyticLocalCache();
        try {
            dispatch(setLoading());
            if (!applicantAnalytics) {
                applicantAnalytics = await getDashboardApplicant();
                applicantAnalyticLocalCache(applicantAnalytics);
            }
            dispatch(setApplicantAnalytics(applicantAnalytics));
        } catch (e) {
            dispatch(setError(e.message));
        }
    };
};

export const fetchPositionAnalytics = () => {
    return async (dispatch) => {
        let positionAnalytics = getPositionAnalyticLocalCache();
        try {
            dispatch(setLoading());
            if (!positionAnalytics) {
                positionAnalytics = await getDashboardJobs(); // if cache doesn't exist
                positionAnalyticLocalCache(positionAnalytics);
            }
            dispatch(setPositionAnalytics(positionAnalytics));
        } catch (e) {
            dispatch(setError(e.message));
        }
    };
};

export const fetchPartnerAnalytics = () => {
    return async (dispatch) => {
        let partnerAnalytics = getPartnerAnalyticsLocalCache();
        try {
            dispatch(setLoading());
            if (!partnerAnalytics) {
                partnerAnalytics = await getPartnerData();
                partnerAnalyticsLocalCache(partnerAnalytics);
            }
            dispatch(setPartnerAnalytics(partnerAnalytics));
        } catch (e) {
            dispatch(setError(e.message));
        }
    };
};

export const fetchUserActivity = () => {
    return async (dispatch) => {
        let userActivity = getUserActivityLocalCache();
        try {
            dispatch(setLoading());
            if (!userActivity) {
                userActivity = await getUserMonitorData();
                userActivityLocalCache(userActivity);
            }
            dispatch(setUserActivity(userActivity));
        } catch (e) {
            dispatch(setError(e.message));
        }
    };
};

export const fetchZyvkaInternal = () => {
    return async (dispatch) => {
        let zyvkaInternal = getZyvkaInternalLocalCache();
        try {
            dispatch(setLoading());
            if (!zyvkaInternal) {
                zyvkaInternal = await getZyvkaInternalData();
                zyvkaInternalLocalCache(zyvkaInternal);
            }
            dispatch(setZyvkaInternal(zyvkaInternal));
        } catch (e) {
            dispatch(setError(e.message));
        }
    };
};

export const fetchZyvkaAi = () => {
    return async (dispatch) => {
        let zyvkaAi = getZyvkaAiLocalCache();
        try {
            dispatch(setLoading());
            if (!zyvkaAi) {
                const zyvkaAi = await getZyvkaAiData();
                zyvkaAiLocalCache(zyvkaAi);
            }
            dispatch(setZyvkaAiAnalytics(zyvkaAi));
        } catch (e) {
            dispatch(setError(e.message));
        }
    };
};

export const applicantAnalytics = (state) => state.analytics.applicantAnalytics;
export const positionAnalytics = (state) => state.analytics.positionAnalytics;
export const partnerAnalytics = (state) => state.analytics.partnerAnalytics;
export const zyvkaInternal = (state) => state.analytics.zyvkaInternal;
export const userActivity = (state) => state.analytics.userActivity;
export const aiAnalytics = (state) => state.analytics.zyvkaAi;

export default analyticsSlice.reducer;
