import React, { useState } from 'react';
import { 
  MessageList, 
  MessageInput, 
  Thread, 
  Window, 
  useChannelActionContext, 
  Avatar, 
  useChannelStateContext, 
  useChatContext 
} from 'stream-chat-react';
import { ChannelInfo } from '../assets';
import { Tooltip, message } from 'antd';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { UserState } from '../../../features/userSlice';
import axios from 'axios';

export const GiphyContext = React.createContext({});

const ChannelInner = ({ setIsEditing }) => {
  const [giphyState, setGiphyState] = useState(false);
  const { sendMessage } = useChannelActionContext();

  const overrideSubmitHandler = (message) => {
    let updatedMessage = {
      attachments: message.attachments,
      mentioned_users: message.mentioned_users,
      parent_id: message.parent?.id,
      parent: message.parent,
      text: message.text,
    };

    if (giphyState) {
      updatedMessage = { ...updatedMessage, text: `/giphy ${message.text}` };
    }

    if (sendMessage) {
      sendMessage(updatedMessage);
      setGiphyState(false);
    }
  };

  return (
    <GiphyContext.Provider value={{ giphyState, setGiphyState }}>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
        <TeamChannelHeader setIsEditing={setIsEditing} />
        <Window>
          <MessageList />
          <MessageInput overrideSubmitHandler={overrideSubmitHandler} />
        </Window>
        <Thread />
      </div>
    </GiphyContext.Provider>
  );
};

const TeamChannelHeader = ({ setIsEditing }) => {
  const { channel, watcher_count } = useChannelStateContext();
  
  const { client, setActiveChannel } = useChatContext();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/chat/deleteChannel`, {
        data: {
            channelId: channel.id,
            userId: client.userID,
            channelType:"messaging"
        },
        withCredentials: true
    });
    const filter = { members: { $in: [client.userID] } };
            const sort = { last_message_at: -1 };
            const channels = await client.queryChannels(filter, sort, {
                watch: true,
                state: true,
            });

            if (channels.length > 0) {
                setActiveChannel(channels[0]);
            } else {
                setActiveChannel(null);
            }
    


      
      message.success('Chat deleted SuccessFully!');
    } catch (error) {
      console.error('Error deleting chat:', error);
      message.error('Failed to delete chat. Please try again later.');
    } finally {
      setIsDeleting(false);
    }
  };

  const MessagingHeader = () => {
    const members = Object.values(channel.state.members).filter(({ user }) => user.id !== client.userID);
    const additionalMembers = members.length - 3;
    
    const userState = useSelector(UserState);
    const deleteAllowed = userState.role !== 'partner' && userState.role !== 'partnerAdmin';

    if (channel.type === 'messaging') {
      return (
        <div className='team-channel-header__name-wrapper'>
          {members.map(({ user }, i) => (
            <div key={i} className='team-channel-header__name-multi'>
              {client.user?.role === "guest" ? (
                <>
                  <Avatar
                    image={"https://i.ibb.co/vZs5YmC/logozyvk.png"}
                    name={"Zyvka"}
                    size={32}
                  />
                  <p>{"Zyvka"}</p>
                </>
              ) : (
                <>
                  <Avatar image={user.image} name={user.username || user.email} size={32} />
                  <p className='team-channel-header__name user'>{user.username || user.email}</p>
                </>
              )}
            </div>
          ))}

          {additionalMembers > 0 && <p className='team-channel-header__name user'>and {additionalMembers} more</p>}
          {deleteAllowed && (
             <Tooltip title="Delete Chat">
             {isDeleting ? (
               <LoadingOutlined
                 style={{ 
                   fontSize: '18px',
                   color: '#1890ff'
                 }}
               />
             ) : (
               <DeleteOutlined
                 className="delete-chat-icon"
                 onClick={handleDelete}
                 style={{  
                   fontSize: '18px', 
                   cursor: 'pointer',
                   transition: 'color 0.3s',
                 }}
               />
             )}
           </Tooltip>
          )}
          
        </div>
      );
    }

    return (
      <div className='team-channel-header__channel-wrapper'>
        <p className='team-channel-header__name'> 
          <Avatar
              image={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaDB2VkFjyONT6tE5LCryj22rkMWOaLmj3tA&s"}
              name= {client.user?.role === "guest" ?"Zyvka" :(channel?.data?.name || channel?.data?.id)}
              size={32}
          />
          <p> {client.user?.role === "guest" ?"Zyvka" :(channel?.data?.name || channel?.data?.id)}</p> </p>
        <span style={{ display: 'flex' }} onClick={() => setIsEditing(true)}>
          <ChannelInfo />
        </span>
      </div>
    );
  };

  // const getWatcherText = (watchers) => {
  //   if (!watchers) return 'no user online';
  //   if (watchers === 1) return '1 user online';
  //   return `${watchers} users online`;
  // };

  return (
    <div className='team-channel-header__container'>
      <MessagingHeader />
      <div className='team-channel-header__right'>
        <p className='team-channel-header__right-text'>online:{watcher_count}</p>
      </div>
    </div>
  );
};

export default ChannelInner;