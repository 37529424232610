import React, { useEffect, useState } from 'react'
import { Card, Form, Item, Icon, Button, Dropdown, Modal, Loader, Dimmer } from "semantic-ui-react"
import { getAllJobProfiles, fetchAllSkills } from "../api/auth";
import {
    Button as AntButton,
    Divider,
    Descriptions,
    Col,
    Row,
    Drawer,
    Tag,
    Typography,
    message,
    Popconfirm,
    Card as AntCard
} from "antd";
import {
    createJob,
    getAllClients,
    fetchAllPartnerAdmins,
    getAllZyvkaUsers,
    fetchJobData,
    updateJobStatus,
    ArchiveJob,
    UnArchiveJob,
    updateClosedStatusJob,
    updateJob,
    updateJobJD,
    SendEmailToPartners,
    fetchPartnerRecomm,
    getPrimarySkillsData,
} from "../api/ZyvkaMember";
import { UserState, selectUser } from "../features/userSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
    selectAllPartnerAdminsDetails,
    ZyvkaMemberState,
} from "../features/ZyvkaMemberSlice";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import RichTextEditor from "../Small-Components/RichTextEditor";
import { IsZyvkaTeamMember, profilePicURL } from "../utils/functions";
import Currency, { defaultCurrency } from "../Data/Currency";
import ErrorMessage from "../Error/ErrorMessage";
import SuccessMessage from "../Small-Components/SuccessMessage";
import QuestionsForJob from "./QuestionsForJob";
import data from "../Data/Data";
import { EditOutlined, DownloadOutlined } from '@ant-design/icons'
import { ClipLoader } from "react-spinners"
const { Title } = Typography;


const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper">
        <p className="site-description-item-profile-p-label">{title}:</p>
        {content}
    </div>
);

const GetJobDetails = () => {
    const { id } = useParams();
    const [updateOpen, setUpdateOpen] = useState(false);
    const partnerAdmins = useSelector(selectAllPartnerAdminsDetails);
    const [primarySkillsOptions, setPrimarySkillsOptions] = useState([]);
    const [AllPartnerAdmins, setAllPartnerAdmins] = useState([]);
    const fetchPrimarySkills = async () => {
        try {
            const res = await getPrimarySkillsData();
            setPrimarySkillsOptions(res?.primarySkills);
        } catch (e) {
            console.error(e)
        }
    }
    const [partnerRecommendations, setPartnerRecommendations] = useState([{
        key: 1,
        text: 'Jhon Doe - 🪄',
        value: 1,
        image: {
            avatar: false,
        }
    }]);
    const [notiForm, setNotiForm] = useState({
        title: "",
        body: "",
    });
    const [data, setData] = useState({})
    const [jobData, setJobData] = useState({
        job_title: null,
        currency: null,
        skills_req: null,
        min_CTC: null,
        max_CTC: null,
        min_experience_req: null,
        max_experience_req: null,
        currencyType: null,
        zyvka_team_members: null,
        assigned_partners: null,
        pending_assignment: null,
        status: null,
        number_of_positions: null,
        internalTeamWorking: null,
        openToInternalTeamOnly: null,
        isZyvkaAiEnable: null,
        hotJob: null,
        invoicing_term: null,
        payment_term: null,
        replacement_model: null,
        replacement_timeline: null,
        revenue_model: null,
        charge_value: null,
        jd_urn: null,
        notice_period: null,
        job_type: null, 
        isZyvkaJdAiEnable: null, 
        partner_admins: null, 
        isVideoResumeRequired: null,
        job_description: null,
        openToAll: null,
        IsInternalTeamWorking: null,
        new_job_description: null,
        industry: null,
        primary_roles: null,
        jobType: null,
        isCommit: null,
        commitDate: null,
    })
    const revenueOptions = [{ id: 1, value: "Percentage", text: "Percentage" }, { id: 2, value: "Fixed Price", text: "Fixed Price" }]

    const [emailForm, setEmailForm] = useState({
        subject: "",
        body: "",
    });
    const zyvkaState = useSelector(ZyvkaMemberState);
    const userState = useSelector(UserState);
    const [flag, setFlag] = useState(false);
    const [emailModal, setEmailModal] = useState(false);
    const [isAddNewModalOpen, setIsAddNewModalOpen] = useState(false);
    const [fileObj, setFileObj] = useState();
    const [getNewJd, setnewJd] = useState("");
    const user = useSelector(selectUser);
    const [jdUpdateModal, setJdUpdateModal] = useState(false)
    const getValue = (value) => {
        setEmailForm({ ...emailForm, body: value });
    };
    const success = () => {
        message.success("notification sent successfully ");
    };
    const error = () => {
        message.error("some error occurred while sending the notification");
    };
    const info = () => {
        message?.info(
            "this job doesnt have any assigned partners, please add them first"
        );
    };
    const sendNotificationToIdsGroup = async (data) => {
        await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/notification/sendIndividualNotification`, data, {
            withCredentials: true,
        });
    };
    const openJDUpdateModal = () => {
        setJdUpdateModal(true)
    }



    const [loading, setLoading] = useState(false)
    const handleshowPdf = async () => {
        try {
            if (data?.isZyvkaJdAiEnable) {
                setLoading(true)
                const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/files/jd/generate/${data.job_title.split(" ").join("-")}`, {
                    params: {
                        jobDescription: data?.job_description,
                        name: user?.firstName + " " + user?.lastName,
                        mobile: user?.mobileNum,
                        email: user?.email
                    },
                    responseType: 'blob',
                    withCredentials: true
                });
                const url = URL.createObjectURL(response.data);
                window.open(url, '_blank');
                setLoading(false)
            } else {
                const url = `${process.env.REACT_APP_SERVER_URL}/api/files/jd/${data.jd_urn}`
                window.open(url, '_blank')
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
        setLoading(false)
    }
    const handleshowPdfResume = async () => {
        try {
            setLoading(true)
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/files/resume/generate/${data.job_title.split(" ").join("-")}`, {
                params: {
                    jobID: data?.id
                },
                responseType: 'blob',
                withCredentials: true
            });
            const url = URL.createObjectURL(response.data);
            window.open(url, '_blank');
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
        setLoading(false)
    }
    const handleEmailSubmit = async () => {
        const isTitleValid = emailForm?.subject?.trim() !== "";
        const isBodyValid = emailForm?.body?.trim() !== "";
        if (!isBodyValid || !isTitleValid) {
            message.error("Please fill out all required fields");
        } else {
            const idArray = jobData?.assigned_partners?.filter((el) => el !== "");
            try {
                if (idArray?.length === 0) {
                    info();
                } else {
                    const res = await SendEmailToPartners({
                        emailBody: emailForm?.body,
                        partnerAdminIds: idArray,
                        emailSubject: emailForm?.subject,
                    });
                    setEmailModal(false);
                    message.success("Email sent successfully ");
                }
            } catch (e) {
                message.error("some error occurred while sending the emails");
                setEmailModal(false);
            }
            setFlag((el) => !el)
        }
    };
    const handleSubmit = async () => {
        const isTitleValid = notiForm?.title?.trim() !== "";
        const isBodyValid = notiForm?.body?.trim() !== "";
        if (!isBodyValid || !isTitleValid) {
            message.error("Please fill out all required fields");
        } else {
            await sendNotificationToJobIds();
        }
        setFlag((el) => !el)
    };
    const sendNotificationToJobIds = async () => {
        const idArray = jobData?.assigned_partners?.filter((el) => el !== "");

        try {
            if (idArray?.length === 0) {
                info();
            } else {
                await sendNotificationToIdsGroup({
                    title: notiForm?.title,
                    ids: idArray,
                    body: notiForm?.body,
                });
                setIsAddNewModalOpen(false);
                success();
            }
        } catch (e) {
            error();
            setIsAddNewModalOpen(false);
        }
        setFlag((el) => !el)
    };
    const handleCancelNotificationModal = () => {
        setIsAddNewModalOpen(false);
    };
    const handleOkNotificationModal = () => {
        setIsAddNewModalOpen(false);
    };
    const dispatch = useDispatch();
    // const partnerOptions = AllPartnerAdmins?.filter(
    //     (el) => !data?.pending_assignment?.includes(el?.key)
    // );

    const sendNotificationToIds = async (data) => {
        return await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/notification/sendIndividualNotification`, data, {
            withCredentials: true,
        });
    };

    const updateJd = async () => {
        // console.log()
        try {
            if (data?.isZyvkaJdAiEnable) {
                const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/zyvka/updateJDForJobText`, {
                    jobID: data?.id || "",
                    job_description: getNewJd,
                }, { withCredentials: true })
                if (res) {
                    message.success('JD Updated Successfully ')
                }
            } else {
                const jobData = FormData();
                jobData.append("oldFile", jobData?.jd_urn)
                jobData.append("file", fileObj)
                jobData.append("jobID", jobData?.jobID)
                if (fileObj) {
                    await updateJobJD(
                        jobData
                    )
                    message.success('JD Updated Successfully ')
                } else {
                    message.error('please submit a JD to update ')
                }
            }
        } catch (error) {
            console.log(error);
        }
        setJdUpdateModal(false)
        setFlag((el) => !el)
    }
    const onFinish = async (e) => {
        e.preventDefault();
        const zid = data?.zyvka_team_members;
        const newzid = jobData?.zyvka_team_members;
        const additions_zid = newzid.filter((item) => !zid.includes(item));
        const pid = data?.assigned_partners;
        const newpid = jobData?.assigned_partners;
        const additions_pid = newpid.filter((item) => !pid.includes(item));
        const combinedAdditions = [...additions_zid, ...additions_pid];
        console.log(jobData?.skills_req?.map((el) => (el)));

        if (combinedAdditions?.length > 0) {
            await sendNotificationToIds({
                title: `New Position Assigned: ${data?.company_name},${data?.job_title}`,
                ids: combinedAdditions,
                body: `Dear Team, we are delighted to inform you that you have been assigned a new position: ${data?.company_name}, ${data?.job_title}. Please view the deatils from Postions > Pending Assigned Positions tab to accept or decline the allocation.`,
            });
        }
        console.log({
            new_job_description: jobData?.new_job_description,
            jobID: id,
            skills_req: jobData?.skills_req,
            min_CTC: jobData?.min_CTC,
            max_CTC: jobData?.max_CTC,
            min_experience_req: jobData?.min_experience_req,
            max_experience_req: jobData?.max_experience_req,
            currencyType: jobData?.currencyType,
            zyvka_team_members: jobData?.zyvka_team_members,
            assigned_partners: jobData?.assigned_partners,
            pending_assignment: jobData?.pending_assignment,
            numberOfPositions: parseInt(jobData?.number_of_positions),
            internalTeamWorking: jobData?.internalTeamWorking,
            openToInternalTeamOnly: jobData?.openToInternalTeamOnly,
            isZyvkaAiEnable: jobData?.isZyvkaAiEnable,
            isZyvkaJdAiEnable: jobData?.isZyvkaJdAiEnable,
            openToAll: jobData?.openToAll,
            isVideoResumeRequired: jobData?.isVideoResumeRequired,
            hotJob: jobData?.hotJob,
            job_title: jobData?.job_title,
            revenue_model: jobData?.revenue_model,
            currency: jobData?.currency,
            charge_value: jobData?.charge_value,
            replacement_model: jobData?.replacement_model,
            replacement_timeline: jobData?.replacement_timeline,
            payment_term: jobData?.payment_term,
            invoicing_term: jobData?.invoicing_term,
            primary_roles: jobData?.primary_roles,
            isCommit: jobData?.isCommit,
            commitDate: jobData?.commitDate,
        }, "data here");
        await updateJob(
            {
                new_job_description: jobData?.new_job_description,
                jobID: id,
                skills_req: jobData?.skills_req,
                min_CTC: jobData?.min_CTC,
                max_CTC: jobData?.max_CTC,
                min_experience_req: jobData?.min_experience_req,
                max_experience_req: jobData?.max_experience_req,
                currencyType: jobData?.currencyType,
                zyvka_team_members: jobData?.zyvka_team_members,
                assigned_partners: jobData?.assigned_partners,
                pending_assignment: jobData?.pending_assignment,
                numberOfPositions: parseInt(jobData?.number_of_positions),
                internalTeamWorking: jobData?.internalTeamWorking,
                openToInternalTeamOnly: jobData?.openToInternalTeamOnly,
                isZyvkaAiEnable: jobData?.isZyvkaAiEnable,
                isZyvkaJdAiEnable: jobData?.isZyvkaJdAiEnable,
                openToAll: jobData?.openToAll,
                isVideoResumeRequired: jobData?.isVideoResumeRequired,
                hotJob: jobData?.hotJob,
                job_title: jobData?.job_title,
                revenue_model: jobData?.revenue_model,
                currency: jobData?.currency,
                charge_value: jobData?.charge_value,
                replacement_model: jobData?.replacement_model,
                replacement_timeline: jobData?.replacement_timeline,
                payment_term: jobData?.payment_term,
                invoicing_term: jobData?.invoicing_term,
                primary_roles: jobData?.primary_roles,
                isCommit: jobData?.isCommit,
                commitDate: jobData?.commitDate,
            },
            dispatch
        );

        await updateClosedStatusJob({
            newStatus: jobData?.status,
            jobID: id,
            numberOfPositions: parseInt(jobData?.number_of_positions),
        });

        setUpdateOpen(false);
        setFlag((el) => !el)
    };

    const JobStatusChange = async (status) => {
        try {
            if (window.confirm(`You Want to change Status to ${status}`)) {
                await updateJobStatus(
                    {
                        jobID: id,
                        jobStatus: status,
                    },
                    dispatch
                );
                setFlag((el) => !el)
            }
        } catch (error) {
            console.log(error);
        }
    };

    const archiveJobHandler = async (id) => {
        if (
            window.confirm("Are you sure you want to archive this job ?") === true
        ) {
            await ArchiveJob(
                {
                    id: id,
                },
                dispatch
            );
            setFlag((el) => !el)
        }
    };
    const unarchiveJobHandler = async (id) => {
        if (
            window.confirm("Are you sure you want to unarchive this job ?") === true
        ) {
            await UnArchiveJob(
                {
                    id: id,
                },
                dispatch
            );
            setFlag((el) => !el)
        }
    };


    useEffect(() => {
        setLoading(true);
        const fetchJobProfiles = async () => {
            try {
                await getAllJobProfiles(dispatch);
            } catch (error) {
                console.log(error);
            }
        };
        const fetchClients = async () => {
            try {
                await getAllClients(dispatch);
            } catch (error) {
                console.log(error);
            }
        };
        const getZyvkaUsers = async () => {
            try {
                await getAllZyvkaUsers(dispatch);
            } catch (error) {
                console.log(error);
            }
        };
        const fetchGlobalSkills = async () => {
            try {
                await fetchAllSkills(dispatch);
            } catch (error) {
                console.log(error);
            }
        };
        try {
            // const fetchSkills = async () => {
            //     try {
            //         if (profile) {
            //             const result = await axios.get(
            //                 `${process.env.REACT_APP_SERVER_URL}/api/zyvka/getSkillForJobProfile/${profile}`,
            //                 { withCredentials: true }
            //             );
            //             setSkillOptions(result.data);
            //         }
            //     } catch (error) {
            //         console.log(error);
            //     }
            // };
            fetchJobProfiles();
            // fetchSkills();
            fetchClients();
            fetchPrimarySkills();
            // GetAllPartnerAdmins();
            getZyvkaUsers();
            fetchGlobalSkills();
        } catch (e) {
            console.log(e)
            setLoading(false)
        } finally {
            setLoading(false)
        }
    }, [dispatch]);

    function combineRecommendedAndNormalLists(recommendedList, rekt, pending_assignment) {
        if (recommendedList?.length === 0) {
            return rekt?.filter(el => !pending_assignment?.includes(el?.key));
        }

        const recommendedKeys = new Set(recommendedList?.map(item => item.key));

        const uniqueNormalList = rekt?.filter(el =>
            !recommendedKeys?.has(el.key) && !pending_assignment?.includes(el?.key)
        );

        const combinedList = [...recommendedList, ...uniqueNormalList];
        return combinedList;
    }

    const zyvkaMembers = zyvkaState?.zyvkaMembers.filter(function (elem) {
        if (elem.id !== user?.id) {
            return elem;
        }
    });
    const currencyOptions = [
        { id: 1, text: "INR", value: "INR" },
        { id: 2, text: "USD", value: "USD" },
    ];
    const modelOptions = [
        { id: 1, text: "Percentage", value: "Percentage" },
        { id: 2, text: "Fixed Price", value: "Fixed Price" },
    ];
    const repModel = [
        { id: 1, text: "Replacement", value: "Replacement" },
        { id: 2, text: "Refund", value: "Refund" },
    ];
    const AllProfiles = userState.gProfile;
    const AllSkills = userState.gSkills;
    const getJobDetail = async (id) => {
        const res = await fetchJobData(id)
        setData(res);
        const rekt = await fetchAllPartnerAdmins(dispatch, setAllPartnerAdmins);
        const resp = await fetchPartnerRecomm({ clientIndustry: res?.client?.industry, jobsPrimaryRoles: res?.primaryRoles, jobType: res?.jobType });
        const rep = combineRecommendedAndNormalLists(resp, rekt, res?.pending_assignment);
        console.log(rekt, "all admin", rep, "filtered admin", resp, "partner recommen");
        setPartnerRecommendations(rep);
        setJobData({
            job_title: res?.job_title,
            skills_req: res?.skills_req,
            min_CTC: res?.min_CTC,
            max_CTC: res?.max_CTC,
            min_experience_req: res?.min_experience_req,
            max_experience_req: res?.max_experience_req,
            currencyType: res?.currencyType,
            zyvka_team_members: res?.zyvka_team_members,
            assigned_partners: res?.assigned_partners,
            pending_assignment: res?.pending_assignment,
            status: res?.status,
            number_of_positions: res?.number_of_positions,
            internalTeamWorking: res?.internalTeamWorking,
            openToInternalTeamOnly: res?.openToInternalTeamOnly,
            isZyvkaAiEnable: res?.isZyvkaAiEnable,
            hotJob: res?.hotJob,
            // revenue
            invoicing_term: res?.positionRevenue?.[0]?.InvoicingTerms,
            payment_term: res?.positionRevenue?.[0]?.PaymentTerms,
            replacement_model: res?.positionRevenue?.[0]?.ReplacementModel,
            replacement_timeline: res?.positionRevenue?.[0]?.ReplacementTimeline,
            revenue_model: res?.positionRevenue?.[0]?.RevenueMode,
            charge_value: res?.positionRevenue?.[0]?.Value,
            currency: res?.positionRevenue?.[0]?.Currency,
            industry: res?.prefered_industry,
            primary_roles: res?.primaryRoles,
            jobType: res?.jobType,
            jd_urn: res?.jd_urn,
            notice_period: res?.notice_period, job_type: res?.job_type, 
            isZyvkaJdAiEnable: res?.isZyvkaJdAiEnable, 
            partner_admins: res?.partner_admins, 
            isVideoResumeRequired: res?.isVideoResumeRequired,
            job_description: res?.job_description,
            new_job_description: res?.job_description,
            openToAll: res?.openToAll,
            IsInternalTeamWorking: res?.IsInternalTeamWorking,
            file: null,
            prevJD: res?.jd_urn,
            number_of_rounds: res?.number_of_rounds,
            remoteJob: res?.isRemote,
            esop: res?.ESOP,
            drive: res?.Drive,
            isCommit: res?.isCommit || false,
            commitDate: res?.commitDate || null,
        })
        console.log(jobData)
    }
    useEffect(() => {
        setLoading(true)
        try {
            getJobDetail(id);
        } catch (E) {
            setLoading(false)
        } finally {
            setLoading(false)
        }
    }, [dispatch, flag]);
    return (
        <>
            {
                !loading ?
                    <>
                        <AntCard
                            hoverable
                            title={
                                <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                                    <p style={{ fontWeight: "bolder", fontSize: "28px", color: "#020202" }} >Job Detail</p>
                                    <div style={{ marginLeft: "30px" }} >
                                        {jobData?.openToInternalTeamOnly && (
                                            <Tag style={{ borderRadius: "7px", fontWeight: "bold", fontSize: "12px" }} color="#6855f6">Internal Team Job</Tag>
                                        )}
                                        {jobData?.isVideoResumeRequired && (
                                            <Tag style={{ borderRadius: "7px", fontWeight: "bold", fontSize: "12px" }} color="#87d068">Video Resume Required</Tag>
                                        )}
                                        {jobData?.isZyvkaAiEnable && (
                                            <Tag style={{ borderRadius: "7px", fontWeight: "bold", fontSize: "12px" }} color="#EC5F33">AI Enabled</Tag>
                                        )}
                                        {jobData?.isCommit && (
                                            <Tag style={{ borderRadius: "7px", fontWeight: "bold", fontSize: "12px" }} color="#007bff">Committ</Tag>
                                        )}
                                    </div>
                                </div>
                            }
                            style={{
                                width: "100%",
                                padding: "15px",
                                marginTop: "25px"
                            }}
                            extra={
                                jobData?.status !== "closed" && IsZyvkaTeamMember(data, userState) ? (
                                    <>
                                        <div style={{ display: "flex" }}>
                                            <div>
                                                {
                                                    (jobData?.status !== "archived" ? (
                                                        <Form.Select
                                                            fluid
                                                            style={{ cursor: "pointer", width: "120px" }}
                                                            search
                                                            options={[
                                                                { key: 1, text: "Open", value: "open" },
                                                                {
                                                                    key: 2,
                                                                    text: "Candidate offered",
                                                                    value: "candidate offered",
                                                                },
                                                                {
                                                                    key: 3,
                                                                    text: " Candidate accepted",
                                                                    value: "candidate accepted",
                                                                },
                                                                {
                                                                    key: 4,
                                                                    text: "Candidate joined",
                                                                    value: "candidate joined",
                                                                },
                                                                { key: 5, text: "On hold", value: "on hold" },
                                                                {
                                                                    key: 6,
                                                                    text: "No more submissions",
                                                                    value: "no more submissions",
                                                                },
                                                                { key: 7, text: "Closed", value: "closed" },
                                                            ]}
                                                            // defaultValue={jobData?.status}
                                                            value={jobData?.status}
                                                            onChange={(_e, data) => {
                                                                JobStatusChange(data.value);
                                                            }}
                                                        />
                                                    ) : (
                                                        <></>
                                                    ))}
                                            </div>
                                            <div>
                                                {jobData?.status === "archived" &&
                                                    <>
                                                        <Button
                                                            style={{ color: "green", border: "solid 1px green" }}
                                                            onClick={() => unarchiveJobHandler(data?.id)}
                                                        >
                                                            Unarchive
                                                        </Button>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {IsZyvkaTeamMember(data, userState) ? (
                                            <Button
                                                style={
                                                    data?.status !== "closed"
                                                        ? { border: "solid 1px orange", color: "orange" }
                                                        : {
                                                            color: "white",
                                                            background: "red",
                                                        }
                                                }
                                            // onClick={() => setUpdateOpen(true)}
                                            >
                                                {data.status}
                                            </Button>
                                        ) : (
                                            <Button
                                                style={
                                                    jobData?.status !== "closed"
                                                        ? { border: "solid 1px orange", color: "orange" }
                                                        : {
                                                            color: "white",
                                                            background: "red",
                                                        }
                                                }
                                            >
                                                {data.status}
                                            </Button>
                                        )}
                                    </>
                                )
                            }
                        >


                        </AntCard>

                        <AntCard
                            hoverable
                            title={
                                <p style={{ fontWeight: "bolder", fontSize: "16px", color: "#020202" }} >Update Info</p>
                            }
                            size="small"
                            headStyle={{ fontSize: "25px", fontWeight: "bold" }}
                            style={{ marginTop: "30px", width: "100%", borderRadius: "5px" }}
                        >
                            <Form onSubmit={onFinish} style={{ paddingTop: "15px" }} >
                                <Form.Group widths={"16"}>
                                    <Form.Input
                                        label="Job Title"
                                        name="job_title"
                                        value={jobData?.job_title}
                                        error={jobData?.job_title ? null : "Job Title is Required"}
                                        required={true}
                                        width={"12"}
                                        onChange={(e, data) => {
                                            setJobData({ ...jobData, [data.name]: data.value });
                                        }}
                                        placeholder="Select Job Profile"
                                    ></Form.Input>
                                    <Form.Field                                  >
                                        <label htmlFor="Download JD">‎ ‎ </label>
                                        <AntButton type="primary" onClick={handleshowPdf} icon={<DownloadOutlined />}>
                                            Download JD
                                        </AntButton>
                                    </Form.Field>
                                    {
                                        jobData?.isZyvkaJdAiEnable ?
                                            <Form.Field  >
                                                <label htmlFor="Download JD">‎ ‎ </label>
                                                <AntButton type="primary" color="#orange" onClick={handleshowPdfResume} icon={<DownloadOutlined />}>
                                                    Download Ideal Resume
                                                </AntButton>
                                            </Form.Field>
                                            :
                                            <Form.Field  >
                                                <label htmlFor="Download JD">‎ ‎ </label>
                                                <AntButton type="primary" onClick={openJDUpdateModal} icon={<EditOutlined />}>
                                                    Update JD
                                                </AntButton>
                                            </Form.Field>
                                    }

                                </Form.Group>
                                <Form.Group widths={"equal"}>
                                    <Form.Select
                                        search
                                        label="Zyvka Team"
                                        name="zyvka_team_members"
                                        placeholder="Select Zyvka Team"
                                        error={jobData?.zyvka_team_members ? null : "Zyvka Team Members are Required"}
                                        required={true}
                                        multiple
                                        options={zyvkaMembers?.map((data, idx) => {
                                            return {
                                                key: idx,
                                                text: `${data.firstName} ${data.lastName} (${data.email})`,
                                                value: data.id,
                                                image: { avatar: true, src: profilePicURL(data.pfp_urn) },
                                            };
                                        })}
                                        value={jobData?.zyvka_team_members}
                                        defaultValue={jobData?.zyvka_team_members}
                                        onChange={(e, _data) => {
                                            setJobData({ ...jobData, [_data.name]: _data.value });
                                        }}
                                    ></Form.Select>
                                </Form.Group>

                                <Form.Group widths={"equal"}>
                                <Form.Field >
                                        <label htmlFor="">Select Skills <span style={{ color: "red", fontWeight: "medium" }} >*</span> </label>
                                        <Dropdown
                                            multiple
                                            fluid
                                            required={true}
                                            selection
                                            error={jobData?.skills_req ? null : "Skills  are Required"}
                                            name="skills_req"
                                            value={jobData?.skills_req || []}
                                            options={AllSkills?.map((data, idx) => {
                                                return { key: idx, value: data.skill, text: data.skill };
                                            })}
                                            onChange={(e, data) => {
                                                setJobData({ ...jobData, [data.name]: data.value });
                                                console.log(data.value?.[1], jobData?.skills_req?.[1], "res")
                                            }}

                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths={"equal"}>
                                    {jobData.openToInternalTeamOnly === false && (
                                        <>
                                            <Form.Field>
                                                <label>Pending Claim/Assigned</label>
                                                <Dropdown
                                                    placeholder="Select Partners"
                                                    fluid
                                                    search
                                                    multiple
                                                    selection
                                                    defaultValue={jobData?.pending_assignment}
                                                    name="pending_assignment"
                                                    onChange={(e, data) => {
                                                        setJobData({ ...jobData, [data.name]: data.value });
                                                    }}
                                                    options={AllPartnerAdmins}
                                                    disabled={true}
                                                />
                                            </Form.Field>
                                            
                                        </>
                                    )}

                                </Form.Group>

                                <Form.Group widths="equal">
                                    {jobData.openToInternalTeamOnly === false && (
                                        <>
                                            
                                            <Form.Field>
                                                <label>Select Partner Admins</label>
                                                <Dropdown
                                                    placeholder="Select Partners"
                                                    fluid
                                                    search
                                                    error={jobData?.assigned_partners ? null : "Partner Admins are Required"}
                                                    multiple
                                                    selection
                                                    defaultValue={jobData?.assigned_partners}
                                                    name="assigned_partners"
                                                    onChange={(e, data) => {
                                                        setJobData({ ...jobData, [data.name]: data.value });
                                                    }}
                                                    options={partnerRecommendations}
                                                />
                                            </Form.Field>
                                        </>
                                    )}

                                </Form.Group>
                                <Form.Group widths="equal" >
                                    <Form.Input
                                        type="number"
                                        name="min_experience_req"
                                        required={true}
                                        label="Minimum experience"
                                        value={jobData?.min_experience_req}
                                        onChange={(e) => {
                                            setJobData({ ...jobData, [e.target.name]: e.target.value })
                                        }}
                                        error={
                                            jobData?.min_experience_req !== null && jobData?.min_experience_req !== undefined
                                                ? null
                                                : "This Field is Required"
                                        }
                                        placeholder="Min exp. required (in years)"
                                    ></Form.Input>
                                    <Form.Input
                                        type="number"
                                        name="max_experience_req"
                                        required={true}
                                        label="Maximum experience"
                                        error={
                                            jobData?.max_experience_req !== null && jobData?.max_experience_req !== undefined
                                                ? null
                                                : "This Field is Required"
                                        }
                                        value={jobData?.max_experience_req}
                                        onChange={(e) => {
                                            setJobData({ ...jobData, [e.target.name]: e.target.value })
                                        }}
                                        placeholder="Max. exp. required (in years)"
                                    ></Form.Input>

                                     <Form.Checkbox
                                        toggle
                                        label="Hot Job"
                                        name="hotJob"
                                        style={{ marginTop: "30px",marginLeft:"30px" }}
                                        checked={jobData?.hotJob}
                                        onChange={(e, data) => {
                                            setJobData({ ...jobData, [data.name]: data.checked });
                                        }}
                                    ></Form.Checkbox>
                                    <Form.Checkbox
                                        toggle
                                        label="Zyvka JdAi"
                                        style={{ marginTop: "30px" }}
                                        checked={jobData?.isZyvkaJdAiEnable}
                                        onChange={(e, data) => {
                                            setJobData({ ...jobData, [data.name]: data.checked });
                                        }}
                                        name="isZyvkaJdAiEnable"
                                    ></Form.Checkbox>
                                </Form.Group>
                                <label style={{ fontWeight: "bolder", marginBottom: "10px" }}>Job Description <span style={{ color: "red", fontWeight: "medium" }} >*</span></label>
                                <RichTextEditor
                                    style={{ zIndex: "90", marginBottom: "10px" }}
                                    initialValue={jobData?.new_job_description}
                                    required={true}
                                    getValue={(e) => {
                                        setJobData({ ...jobData, new_job_description: e });
                                    }}
                                />
                                <br />


                                <Form.Group >
                                    <Form.Input
                                        style={{width:"23.5rem",marginRight:"1rem"}}
                                        name="number_of_positions"
                                        label="Open Positions"
                                        placeholder="Enter Number of Open Positions"
                                        value={jobData?.number_of_positions}
                                        error={
                                            jobData?.number_of_positions !== null && jobData?.number_of_positions !== undefined
                                                ? null
                                                : "This Field is Required"
                                        }
                                        type="number"
                                        required={true}
                                        onChange={(e, data) => {
                                            setJobData({ ...jobData, [e.target.name]: e.target.value })
                                        }}
                                    ></Form.Input>
                                    <Form.Checkbox
                                        toggle
                                        style={{ marginTop: "30px",marginRight:"1rem" }}
                                        name="openToAll"
                                        label="Open to all ?"
                                        checked={jobData?.openToAll}
                                        onChange={(e, data) => {
                                            setJobData({ ...jobData, [data.name]: data.checked });
                                        }}
                                    ></Form.Checkbox>
                                    <Form.Checkbox
                                        toggle
                                        style={{ marginTop: "30px",marginRight:"1rem" }}
                                        name="openToInternalTeamOnly"
                                        label="Open to internalTeam only ?"
                                        checked={jobData?.openToInternalTeamOnly}
                                        onChange={(e, data) => {
                                            data?.checked ? setJobData({ ...jobData, [data.name]: data.checked, IsInternalTeamWorking: true }) :
                                                setJobData({ ...jobData, [data.name]: data.checked });
                                        }}
                                    ></Form.Checkbox>
                                    <Form.Checkbox
                                        toggle
                                        style={{ marginTop: "30px" }}
                                        name="IsInternalTeamWorking"
                                        label="Internal Team Working ?"
                                        checked={jobData?.IsInternalTeamWorking}
                                        onChange={(e, data) => {
                                            setJobData({ ...jobData, [data.name]: data.checked });
                                        }}
                                    ></Form.Checkbox>
                                </Form.Group>


                                <Form.Group widths="equal">
                                    <Form.Select
                                        options={Currency}
                                        label="Currency Type"
                                        required={true}
                                        error={
                                            jobData?.currencyType !== null && jobData?.currencyType !== undefined
                                                ? null
                                                : "This Field is Required"
                                        }
                                        name="currencyType"
                                        value={jobData?.currencyType}
                                        onChange={(e, data) => {
                                            setJobData({ ...jobData, [data.name]: data.value });
                                        }}
                                    ></Form.Select>
                                    <Form.Input
                                        type="number"
                                        label={`Minimum CTC ( In ${jobData?.currencyType === "INR" ? "LPA" : "KPA"
                                            })`}
                                        required={true}
                                        name="min_CTC"
                                        placeholder={`Enter Minimum ctc (in ${jobData?.currencyType === "INR" ? "LPA" : "KPA"
                                            })`}
                                        value={jobData?.min_CTC}
                                        error={
                                            jobData?.min_CTC !== null && jobData?.min_CTC !== undefined
                                                ? null
                                                : "This Field is Required"
                                        }
                                        onChange={(e, data) => {
                                            setJobData({ ...jobData, [data.name]: data.value });
                                        }}
                                    ></Form.Input>
                                    <Form.Input
                                        type="number"
                                        label={`Maximum CTC ( In ${jobData?.currencyType === "INR" ? "LPA" : "KPA"
                                            })`}
                                        required={true}
                                        placeholder={`Enter maximum ctc (in ${jobData?.currencyType === "INR" ? "LPA" : "KPA"
                                            })`}
                                        name="max_CTC"
                                        error={
                                            jobData?.max_CTC !== null && jobData?.max_CTC !== undefined
                                                ? null
                                                : "This Field is Required"
                                        }
                                        value={jobData?.max_CTC}
                                        onChange={(e, data) => {
                                            setJobData({ ...jobData, [data.name]: data.value });
                                        }}
                                    ></Form.Input>
                                </Form.Group>
                                <Form.Group widths="equal">
                                    <Form.Input
                                        type="number"
                                        name="notice_period"
                                        required={true}
                                        label="Notice Period"
                                        placeholder="Enter notice period (in days)"
                                        error={
                                            jobData?.notice_period !== null && jobData?.notice_period !== undefined
                                                ? null
                                                : "This Field is Required"
                                        }
                                        value={jobData?.notice_period}
                                        onChange={(e) => {
                                            setJobData({ ...jobData, [e.target.name]: e.target.value })
                                        }}
                                    ></Form.Input>
                                      <Form.Select
                                        search
                                        options={primarySkillsOptions?.map((data, idx) => {
                                            return { key: idx, value: data.name, text: data.name };
                                        })}
                                        placeholder="Select Primary Roles"
                                        name="primary_roles"
                                        value={jobData?.primary_roles}
                                        onChange={(e, data) => {
                                            setJobData({ ...jobData, [data.name]: data.value });
                                        }}
                                        label="Primary Skills"
                                    ></Form.Select>
                                    <Form.Checkbox
                                        toggle
                                        style={{ marginTop: "30px" }}
                                        name="isVideoResumeRequired"
                                        label="Video Resume Required ?"
                                        checked={jobData?.isVideoResumeRequired}
                                        onChange={(e, data) => {
                                            setJobData({ ...jobData, [data.name]: data.checked });
                                        }}
                                    ></Form.Checkbox>
                                    <Form.Checkbox
                                        toggle
                                        style={{ marginTop: "30px" }}
                                        name="isZyvkaAiEnable"
                                        label="Enable ZyvkaAI ?"
                                        checked={jobData?.isZyvkaAiEnable}
                                        onChange={(e, data) => {
                                            setJobData({ ...jobData, [data.name]: data.checked });
                                        }}
                                    ></Form.Checkbox>
                                   
                                  
                                </Form.Group>
                                <Form.Group widths={"equal"}>

                                  <Form.Checkbox
                                     toggle
                                      style={{ marginTop: "30px" }}
                                     label="Commit"
                                     checked={jobData.isCommit}
                                     onChange={(e, data) => setJobData({ ...jobData, isCommit: data.checked })}
                                   />
                                   
                                     <Form.Input
                                       type="date"
                                       label="Commit Date"
                                       value={jobData.commitDate}
                                       onChange={(e) => setJobData({ ...jobData, commitDate: e.target.value })}
                                       min={new Date().toISOString().split('T')[0]}
                                       disabled={!jobData.isCommit}
                                     />
                                   
                                  </Form.Group>
                                <>
                                    <Divider horizontal>Revenue Parameters</Divider>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            margin: "20px",
                                            backgroundColor: "#f0f0f0",
                                            border: "2px solid #007bff50",
                                            borderRadius: "10px",
                                        }}
                                    >
                                        <p
                                            style={{
                                                fontSize: "16px",
                                                textAlign: "center",
                                                padding: "20px",
                                                maxWidth: "600px",
                                                fontWeight: "bold"

                                            }}
                                        >
                                            {
                                                jobData?.client?.revenue?.length > 0 ?
                                                    "These parameters will be filled with selected client company's revenue model. You can change the parameters for a specific job too" : "This Client Company has no revenue model, please create one !!!"
                                            }
                                        </p>
                                    </div>
                                    <Form.Group widths={"equal"}>
                                        <Form.Select
                                            required={true}
                                            label="Revenue Model "
                                            name="revenue_model"
                                            placeholder="Choose Revenue Model"
                                            value={jobData?.revenue_model}
                                            error={
                                                jobData?.revenue_model !== null && jobData?.revenue_model !== undefined
                                                    ? null
                                                    : "This Field is Required"
                                            }
                                            onChange={(e, data) => {
                                                console.log(data.name, data.value)
                                                setJobData({ ...jobData, [data.name]: data.value });

                                            }}
                                            options={modelOptions}
                                        ></Form.Select>

                                        <Form.Input
                                            fluid
                                            required={true}
                                            name="charge_value"
                                            type="number"
                                            placeholder="10%"
                                            value={jobData?.charge_value}
                                            error={
                                                jobData?.charge_value !== null && jobData?.charge_value !== undefined
                                                    ? null
                                                    : "This Field is Required"
                                            }
                                            onChange={(e, data) => {
                                                console.log(data.name, data.value)
                                                setJobData({ ...jobData, [data.name]: data.value });
                                            }}
                                            label="Charge Value"
                                        />
                                    </Form.Group>
                                    <Form.Group widths={"equal"}>
                                        <Form.Input
                                            required={true}
                                            label="Payment Terms (in days)"
                                            name="payment_term"
                                            placeholder="30 days"
                                            error={
                                                jobData?.payment_term !== null && jobData?.payment_term !== undefined
                                                    ? null
                                                    : "This Field is Required"
                                            }
                                            value={jobData?.payment_term}
                                            onChange={(e, data) => {
                                                console.log(data.name, data.value)
                                                setJobData({ ...jobData, [data.name]: data.value });
                                            }}
                                            type="number"
                                        ></Form.Input>
                                        <Form.Input required={true}
                                            label="Invoicing Terms (in days)"
                                            name="invoicing_term"
                                            placeholder="10 days"
                                            value={jobData?.invoicing_term}
                                            error={
                                                jobData?.invoicing_term !== null && jobData?.invoicing_term !== undefined
                                                    ? null
                                                    : "This Field is Required"
                                            }
                                            onChange={(e, data) => {
                                                console.log(data.name, data.value)
                                                setJobData({ ...jobData, [data.name]: data.value });
                                            }}
                                            type="number"
                                        ></Form.Input>
                                        <Form.Input
                                            label="Replacement Timeline (in days)"
                                            name="replacement_timeline" required={true}
                                            placeholder="60 days"
                                            value={jobData?.replacement_timeline}
                                            error={
                                                jobData?.replacement_timeline !== null && jobData?.replacement_timeline !== undefined
                                                    ? null
                                                    : "This Field is Required"
                                            }
                                            onChange={(e, data) => {
                                                console.log(data.name, data.value)
                                                setJobData({ ...jobData, [data.name]: data.value });
                                            }}
                                            type="number"
                                        ></Form.Input>
                                    </Form.Group>
                                    <Form.Group widths={"equal"}>
                                        <Form.Select
                                            label="Replacement Model " required={true}
                                            name="replacement_model"
                                            placeholder="Choose Replacement Model"
                                            value={jobData?.replacement_model}
                                            error={
                                                jobData?.replacement_model !== null && jobData?.replacement_model !== undefined
                                                    ? null
                                                    : "This Field is Required"
                                            }
                                            onChange={(e, data) => {
                                                console.log(data?.value, data?.name);
                                                setJobData({ ...jobData, [data.name]: data.value });
                                            }}
                                            options={repModel}
                                        ></Form.Select>
                                        <Form.Select
                                            label="Currency"
                                            name="currency"
                                            placeholder="Choose Currency"
                                            required={true}
                                            value={jobData?.currency}
                                            error={
                                                jobData?.currency !== null && jobData?.currency !== undefined
                                                    ? null
                                                    : "This Field is Required"
                                            }
                                            onChange={(e, data) => {
                                                console.log(data?.value, data?.name);
                                                setJobData({ ...jobData, [data.name]: data.value });
                                            }}
                                            options={currencyOptions}
                                        ></Form.Select>
                                    </Form.Group>
                                </>
                                <Form.Group  style={{ marginTop: "40px" }} >
                                    <Form.Button
                                        type="submit"
                                        width={"16"}
                                        style={{
                                            margin: "auto",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            backgroundColor: "#6855f6", color: "#fff",
                                            width: "350px"
                                        }}
                                    >
                                        Submit
                                    </Form.Button>
                                </Form.Group>
                            </Form>
                        </AntCard>
                        {/* <AntCard
                            hoverable
                            title={
                                <p style={{ fontWeight: "bolder", fontSize: "16px", color: "#020202" }} >Hiring Process</p>
                            }
                            size="small"
                            headStyle={{ fontSize: "25px", fontWeight: "bold" }}
                            style={{ marginTop: "30px", width: "100%", borderRadius: "5px" }}
                        >
                            <Row style={{ paddingTop: "10px" }} >
                                <Col span={24}>
                                    <DescriptionItem
                                        content={
                                            jobData?.hiring_process?.length > 0 ? (
                                                jobData?.hiring_process?.map((val, _idx) => {
                                                    return (
                                                        (
                                                            <div key={_idx}>
                                                                <Title level={5}>{JSON.parse(val).name}</Title>
                                                                <pre
                                                                    style={{
                                                                        whiteSpace: 'pre-wrap',
                                                                        fontFamily: 'inherit',
                                                                    }}
                                                                >
                                                                    {JSON.parse(val).description}
                                                                </pre>
                                                            </div>
                                                        )
                                                    );
                                                })
                                            ) : (
                                                '-'
                                            )
                                        }
                                    />
                                </Col>
                            </Row>
                        </AntCard> */}
                        {(userState?.role === "zyvkaUser" ||
                            userState?.role === "zyvkaAdmin" ||
                            userState?.role === "superAdmin") && (
                                <>
                                    {" "}
                                    <AntCard
                                        hoverable
                                        title={
                                            <p style={{ fontWeight: "bolder", fontSize: "16px", color: "#020202" }} >Client Contact</p>
                                        }
                                        size="small"
                                        headStyle={{ fontSize: "25px", fontWeight: "bold" }}
                                        style={{ marginTop: "30px", width: "100%", borderRadius: "5px", }}
                                    >
                                        <Row justify="center" style={{ paddingTop: "10px" }} >
                                            <Col span={12}>
                                                <DescriptionItem
                                                    title="Email"
                                                    content={data?.job_incharge?.email}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <DescriptionItem
                                                    title="Phone Number"
                                                    content={`${data?.job_incharge?.country_code} ${data?.job_incharge?.mobileNum}`}
                                                />
                                            </Col>
                                        </Row>
                                    </AntCard>
                                </>
                            )}
                        <AntCard
                            hoverable
                            title={
                                <p style={{ fontWeight: "bolder", fontSize: "16px", color: "#020202" }} >Action</p>
                            }
                            style={{ marginTop: "30px", width: "100%", borderRadius: "5px" }}

                        >
                            <Row gutter={[16, 16]} style={{ paddingTop: "15px" }} >
                                {jobData?.status !== "closed" &&
                                    jobData?.status !== "archived" &&
                                    data.status !== "on hold" &&
                                    data.status !== "no more submissions" && (
                                        <>
                                            <Col span={8}>
                                                {" "}
                                                <Link
                                                    target="_blank"
                                                    to={`/dashboard/referNewApplicant/${data.id}/?job_title=${data.job_title}`}
                                                >
                                                    <AntButton type="primary" style={{ width: "170px" }}>
                                                        New Candidate
                                                    </AntButton>
                                                </Link>
                                            </Col>
                                            <Col span={8}>
                                                <Link
                                                    target="_blank"
                                                    to={`/dashboard/existingApplicant/${data.id}/?company_name=${data.company_name}&job_title=${data.job_title}`}
                                                >
                                                    <AntButton type="primary" style={{ width: "170px" }}>
                                                        Refer Existing Candidate
                                                    </AntButton>
                                                </Link>
                                            </Col>
                                        </>
                                    )}

                                {(IsZyvkaTeamMember(data, userState) ||
                                    userState?.role === "superAdmin") && (
                                        <>
                                            <Col span={8}>
                                                <Link to={`/dashboard/myjobs1/${data.id}`} target="_blank">
                                                    <AntButton type="primary" style={{ width: "170px" }}>
                                                        View All Candidates
                                                    </AntButton>
                                                </Link>
                                            </Col>
                                            <Col span={8}>
                                                <Link to={`/clone/${data.id}`} target="_blank">
                                                    <AntButton type="primary" style={{ width: "170px" }}>
                                                        Clone Job
                                                    </AntButton>
                                                </Link>
                                            </Col>
                                            <Col span={8}>
                                                <AntButton
                                                    type="primary"
                                                    style={{ width: "170px" }}
                                                    onClick={() => {
                                                        setIsAddNewModalOpen(true);
                                                    }}
                                                >
                                                    Send Notification
                                                </AntButton>
                                            </Col>
                                            <Col span={8}>
                                                <AntButton
                                                    type="primary"
                                                    style={{ width: "170px" }}
                                                    onClick={() => {
                                                        setEmailModal(true);
                                                    }}
                                                >
                                                    Send Email To Partners
                                                </AntButton>
                                            </Col>
                                            {jobData?.status !== "archived" && (
                                                <Col span={8}>
                                                    <AntButton
                                                        type="primary"
                                                        style={{ width: "170px" }}
                                                        onClick={() => archiveJobHandler(data?.id)}
                                                    >
                                                        Archive
                                                    </AntButton>
                                                </Col>
                                            )}
                                        </>
                                    )}
                            </Row>
                        </AntCard>
                        <Modal
                            width={700}
                            centered
                            visible={isAddNewModalOpen}
                            onCancel={handleCancelNotificationModal}
                            size="small"
                            onClose={() => {
                                setIsAddNewModalOpen(false);
                            }}
                            onOk={handleSubmit}
                            open={isAddNewModalOpen}
                        >
                            <Modal.Header>
                                Send Group Notification for {data?.job_title}
                            </Modal.Header>
                            <Modal.Content scrolling>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Input
                                        type="text"
                                        fluid
                                        label="Notification Title"
                                        name="title"
                                        required={true}
                                        value={notiForm?.title}
                                        onChange={(e) => {
                                            setNotiForm({
                                                ...notiForm,
                                                [e.target.name]: e.target.value,
                                            });
                                        }}
                                        placeholder="Title"
                                        width={16}
                                    />
                                    <Form.TextArea
                                        type="text"
                                        fluid
                                        label="Notification Text"
                                        name="body"
                                        required={true}
                                        value={notiForm?.body}
                                        onChange={(e) => {
                                            setNotiForm({
                                                ...notiForm,
                                                [e.target.name]: e.target.value,
                                            });
                                        }}
                                        placeholder="Enter Notification Message here"
                                        width={16}
                                    />
                                    <Form.Field>
                                        <label>Notification will be sent to</label>
                                        <Dropdown
                                            placeholder="Select Partners"
                                            fluid
                                            search
                                            multiple
                                            selection
                                            defaultValue={jobData?.assigned_partners}
                                            options={AllPartnerAdmins}
                                            name="pending_assignment"
                                            disabled={true}
                                        />
                                    </Form.Field>
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative onClick={() => setIsAddNewModalOpen(false)}>
                                    Cancel
                                </Button>
                                <Button
                                    style={{ marginLeft: "30px" }}
                                    type="primary"
                                    onClick={handleSubmit}
                                >
                                    Send
                                </Button>
                            </Modal.Actions>
                        </Modal>

                        <Modal
                            size={data?.isZyvkaJdAiEnable ? "fullscreen" : "mini"}
                            onClose={() => {
                                setJdUpdateModal(false);
                            }}
                            closeOnDimmerClick={true}
                            open={jdUpdateModal}
                            closeIcon
                        >
                            <Modal.Header>
                                Update/Replace your Job Description for {data?.job_title}
                            </Modal.Header>
                            <Modal.Content>
                                <Form loading={userState?.isPending} onSubmit={updateJd} >
                                    <Form.Input
                                        type="file"
                                        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        name="file"
                                        onChange={(e) => {
                                            setFileObj(
                                                e.target.files[0],
                                            )
                                        }}
                                    >
                                    </Form.Input>
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative onClick={() => setJdUpdateModal(false)}>
                                    Cancel
                                </Button>
                                <Popconfirm
                                    primary
                                    title="Are you sure you want to update the job description?"
                                    onConfirm={updateJd}
                                    onCancel={() => setJdUpdateModal(false)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="primary" style={{ marginLeft: "30px" }}
                                        icon={<EditOutlined />}
                                    >
                                        update
                                    </Button>
                                </Popconfirm>
                            </Modal.Actions>
                        </Modal>

                        <Modal
                            width={700}
                            centered
                            visible={emailModal}
                            onCancel={() => setEmailModal(false)}
                            size="small"
                            onClose={() => setEmailModal(false)}
                            onOk={handleEmailSubmit}
                            open={emailModal}
                        >
                            <Modal.Header>
                                Send Group Email to Partners for {data?.job_title}
                            </Modal.Header>
                            <Modal.Content scrolling>
                                <Form onSubmit={handleEmailSubmit}>
                                    <Form.Input
                                        type="text"
                                        fluid
                                        label="Email Subject"
                                        name="subject"
                                        required={true}
                                        value={emailForm?.subject}
                                        onChange={(e) => {
                                            setEmailForm({
                                                ...emailForm,
                                                [e.target.name]: e.target.value,
                                            });
                                        }}
                                        placeholder="Title"
                                        width={16}
                                    />
                                    <label style={{ fontWeight: "bolder" }}>Job Description</label>
                                    <RichTextEditor initialValue="" getValue={getValue} />
                                    <br />
                                    <br />
                                    <br />
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative onClick={() => setEmailModal(false)}>
                                    Cancel
                                </Button>
                                <Button
                                    style={{ marginLeft: "30px", marginBottom: "15px" }}
                                    type="primary"
                                    onClick={handleEmailSubmit}
                                >
                                    Send
                                </Button>
                            </Modal.Actions>
                        </Modal>
                    </>
                    :
                    <Dimmer active>
                        <ClipLoader text='loading' />
                    </Dimmer>
            }


        </>
    )
}

export default GetJobDetails