import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Alert,
  Modal,
} from "antd";
import { forgotPass } from "../api/auth";
import signinbg from "../assets/login-img.png";

import data from "../Data/Data";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginUser } from "../api/auth";
import { useSelector } from "react-redux";
import { UserState } from "../features/userSlice";
import HeaderComponent from "../components/HeaderComponent";

const { Title } = Typography;
const { Footer, Content } = Layout;

export const LogIn = () => {
  const dispatch = useDispatch();
  const userState = useSelector(UserState);
  const navigate = useNavigate();

  //Modal states
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("");

  const loginData = data.Login;

  useEffect(() => {
    if (userState.isLoggedIn === true) {
      navigate("/dashboard");
    }
  }, []);

  const onFinish = async (values) => {
    await LoginUser(
      {
        email: values.email,
        password: values.password,
      },
      dispatch,
      navigate
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //Modal functions
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setModalText(
      "Password reset link has been sent to your registered email id."
    );
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const forgotPassHandler = (values) => {
    forgotPass({ email: values.email }, dispatch);

    if (userState.isPending === false && userState.isErrors === false) {
      handleOk();
    }
    if (userState.isPending === false && userState.isErrors === true) {
    }
  };
  const forgotPassFail = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Layout
        className="layout-default layout-signin"
        style={{ background: "white" }}
      >
        <HeaderComponent />
        {/* Header for the sign in page */}
        <Content className="signin" style={{ marginTop: "63px" }}>
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title className="mb-15">{loginData.title}</Title>
              <Title className="font-regular text-muted" level={5}>
                {loginData.instruction}
              </Title>
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label={loginData.em}
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: loginData.emError,
                    },
                  ]}
                >
                  <Input placeholder={loginData.em} type="email" />
                </Form.Item>

                <Form.Item
                  className="password"
                  label={loginData.pass}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: loginData.passError,
                    },
                  ]}
                >
                  <Input placeholder="Password" type="password" />
                </Form.Item>

                {/* <Form.Item
                  name="changeUserType"
                  className="aligin-center"
                  valuePropName="checked"
                >
                  <Switch onChange={onChange} />
                  Login as <b>Zyvka Member</b>
                </Form.Item> */}
                <br></br>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                    disabled={userState.isPending ? true : false}
                  >
                    {loginData.title}
                  </Button>

                  {userState.isErrors && (
                    <>
                      <br></br>
                      <br></br>
                      <Alert
                        message="Wrong Email/Password"
                        type="error"
                        showIcon
                        closable
                      />
                    </>
                  )}
                </Form.Item>
                <p className="font-semibold text-muted">
                  Forgot password?{" "}
                  <a className="text-dark font-bold" onClick={showModal}>
                    Click here
                  </a>
                </p>
              </Form>
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img src={signinbg} loading="lazy" alt="" />
            </Col>
          </Row>
        </Content>
        <Footer style={{ paddingTop: "20px" }}>
          <p className="copyright">
            {" "}
            Copyright © {loginData.year}
            <a href="#zyvka"> {loginData.company_name} </a>{" "}
          </p>
        </Footer>
      </Layout>

      <Modal visible={open} footer={null} onCancel={handleCancel}>
        <h2>Forgot Password ?</h2>
        <p>{modalText}</p>
        <Form
          name="basic"
          onFinish={forgotPassHandler}
          onFinishFailed={forgotPassFail}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email.",
              },
              {
                type: "email",
                message: "Enter a Valid Email.",
              },
            ]}
          >
            <Input placeholder="Enter email" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" style={{ width: "100%" }} htmlType="submit">
              Submit
            </Button>
          </Form.Item>
          <Form.Item>
            <Button style={{ width: "100%" }} onClick={handleCancel}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
