import React from "react";
import Mahakumbh from "../assets/mahakumbh.png";
import Zyvka from "../assets/zyvka.png";
import X from "../assets/x.png";
import Circ from "../assets/circ-zyvka.png";
import Icon1 from "../assets/icon-1.png";
import Icon2 from "../assets/icon-2.png";
import Icon3 from "../assets/icon-3.png";
import Icon4 from "../assets/icon-4.png";
import Benfit from "../assets/benefit-2.png";
import Logos from "../assets/logos.png";
import { Layout, Typography } from "antd";
import HeroImage from "../assets/hero-image.png";
import LeftHero from "../assets/hero-image-left.png";
import RightHero from "../assets/hero-image-right.png";
import { Link } from "react-router-dom";
import "../components/Header.css";
import "./your-hr.css";

const { Title } = Typography;
const { Header } = Layout;

const YourHR = () => {
  const menuItems = [
    {
      key: "home",
      label: <a href="https://www.zyvka.com">Home</a>,
      route: "https://www.zyvka.com",
    },
    {
      key: "login",
      label: <Link to="/dashboard">Login</Link>,
      route: "/dashboard",
    },
  ];

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "white",
      }}
      className="layout"
    >
      <div
        style={{
          cursor: "pointer",
          backgroundColor: "white",
          boxShadow:
            "0 4px 8px 0 rgba(104, 85, 246, 0.67), 0 6px 20px 0 rgba(104, 85, 246, 0.67)",
          zIndex: 50,
          justifyContent: "center",
          // maxWidth: 1400,
          width: "100%",
          margin: "0 auto",
          display: "flex",
          justifyItems: "center",
        }}
      >
        <div
          theme="dark"
          className="header-main"
          style={{
            width: "100%",
            margin: "0 auto",
            boxShadow:
              "10px 0.5px 20px 0 rgba(104, 85, 246, 0.17), 0 2px 20px 0 rgba(104, 85, 246, 0.47)",
            display: "flex",
            justifyItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            height: "60px",
          }}
        >
          <div
            theme="dark"
            className="header-main"
            style={{
              width: "100%",

              margin: "0 auto",
              display: "flex",
              maxWidth: "1100px",
              justifyItems: "center",
              justifyContent: "space-between",
              backgroundColor: "white",
              height: "60px",
            }}
          >
            <div
              className="header-items flex-d "
              style={{
                backgroundColor: "white",
                // width: "70%",
                display: "flex",
                // flexBasis: "100%",
              }}
            >
              <Title
                level={2}
                className=" heading"
                style={{ color: "#6855f6", paddingLeft: "5px" }}
              >
                ZYVKA
              </Title>
            </div>
            <div
              className="flex-d2  "
              style={{
                backgroundColor: "white",
                // flexBasis: "20%",
                paddingTop: "1.4rem",
                // width: "30%",
                height: "100%",
                margin: "0 auto",
                display: "flex",
                justifyContent: "space-around",
                justifyItems: "center",
                fontWeight: 600,
                fontSize:
                  " clamp(16px, calc(16px + (24 - 20) * (100vw - 768px)/(1920 - 768)), 38px)",
                color: "#6855f6",
              }}
            >
              <a href="https://www.zyvka.com" style={{ color: "#6855f6" }}>
                Home
              </a>{" "}
              <Link
                to="/dashboard"
                style={{ color: "#6855f6", marginLeft: "30px" }}
              >
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* <Navbar /> */}
      <main style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div
          style={{
            position: "relative",
            justifyContent: "space-evenly",
            flexDirection: "column",
            width: "100%",
            margin: "0 auto",
            height: "100%",
            display: "flex",
            justifyItems: "center",
            overflow: "hidden",
            marginTop: "60px",
            paddingTop: "50px",
            paddingBottom: "80px",
          }}
        >
          <div
            style={{
              position: "absolute",
              justifyContent: "center",
              margin: "0 auto",
              display: "flex",
              justifyItems: "center",
              bottom: "0px",
              right: "0px",
            }}
          >
            <img
              className="hero-image"
              src={RightHero}
              style={{
                // height: "50px",
                aspectRatio: "unset",
                width: "auto",
                objectFit: "cover",
              }}
              alt="hero"
            />
          </div>
          <div
            style={{
              position: "absolute",
              justifyContent: "center",
              margin: "0 auto",
              display: "flex",
              justifyItems: "center",
              top: "0px",
              left: "0px",
            }}
          >
            <img
              className="hero-image"
              src={LeftHero}
              style={{
                // height: "50px",
                aspectRatio: "unset",
                width: "auto",
                objectFit: "contain",
              }}
              alt="hero"
            />
          </div>
          <div
            className="img-container"
            style={{
              justifyContent: "center",
              width: "100%",
              display: "flex",
              justifyItems: "center",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <img
              src={Mahakumbh}
              alt=""
              className="dynamic-image"
              style={{
                heigt: "150px",
                aspectRatio: "unset",
                width: "auto",
                objectFit: "contain",
              }}
            />

            <img
              src={X}
              alt=""
              className="dynamic-image"
              style={{
                heigt: "150px",
                width: "auto",
                aspectRatio: "unset",
                objectFit: "contain",
                objectPosition: "center",
              }}
            />
            <img
              src={Zyvka}
              alt=""
              className="dynamic-image leftm"
              style={{
                heigt: "150px",
                width: "auto",
                aspectRatio: "unset",
                objectFit: "contain",
              }}
            />
          </div>
          <div
            className="mt-top"
            style={{
              justifyContent: "center",
              margin: "0 auto",
              display: "flex",
              justifyItems: "center",
              // marginTop: "50px",
              // marginTop: "200px",
              marginTop: "35px",
            }}
          >
            <p
              className="heading-hero"
              style={{
                justifyContent: "center",
                margin: "0 auto",
                display: "flex",
                justifyItems: "center",
                fontWeight: "bolder",
                width: "100%",
                height: "100%",
                border: "2px solid #6855f6",
                padding: "6px",
                borderRadius: "5px",
                margin: "10px",
                flexWrap: "wrap",
                maxWidth: "600px",
              }}
            >
              <span
                style={{
                  color: "#6855f6",
                  margin: "0px 10px",
                }}
              >
                All delegates
              </span>{" "}
              are eligible to avail a{" "}
              <span
                style={{
                  color: "#6855f6",
                  margin: "0px 10px",
                }}
              >
                special discount{" "}
              </span>{" "}
              on our services
            </p>
          </div>
        </div>
        <div
          class="flex items-center justify-center w-full h-full"
          style={{
            justifyContent: "space-evenly",
            flexDirection: "column",
            width: "100%",
            margin: "0 auto",
            height: "100%",
            display: "flex",
            justifyItems: "center",
            textAlign: "center",
          }}
        >
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSfTIH7Yei7q7kPxE2hAYINIBuW19fUv0Ck69Kez73nc9CtbbA/viewform"
            className="heading"
            style={{
              margin: "50px 0px",
              fontWeight: "900",
              color: "#6855f6",
              textUnderlinePosition: "under",
              textUnderlineOffset: "1",
              textDecoration: "underline",
              textDecorationColor: "#6855f6",
              textDecorationThickness: "3px",
            }}
            target="_blank"
            rel="noreferrer"
          >
            Click here to claim your discount
          </a>
        </div>
        <div
          class="flex items-center justify-center w-full h-full min-h-screen"
          style={{
            justifyContent: "space-evenly",
            flexDirection: "column",
            width: "100%",
            margin: "0 auto",
            height: "100%",
            display: "flex",
            justifyItems: "center",
            minHeight: "90vh",
            overflow: "hidden",
          }}
        >
          <div
            class="flex items-center justify-center w-full h-full"
            style={{
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
              display: "flex",
              justifyItems: "center",
              height: "100%",
              textAlign: "center",
            }}
          >
            <img
              src={Circ}
              alt=""
              className="circ"
              style={{
                aspectRatio: "unset",
                objectFit: "contain",
              }}
            />
            <p style={{ fontSize: "2.4rem" }}>We Provide</p>
          </div>
          <div
            className="grid-container"
            style={{
              display: "grid",
              alignContent: "space-evenly",
              placeContent: "space-evenly",
              gap: "20px",
              // gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
              // "@media (minWidth: 768px)": {
              //   gridTemplateColumns: "repeat(2, minmax(0, 1fr))", // Corrected value
              // },
              padding: "20px",
              margin: "0 auto",
              maxWidth: "1200px",
            }}
          >
            <div
              style={{
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
                display: "flex",
                justifyItems: "start",
                height: "100%",
                backgroundColor: "#6855f6",
                borderRadius: "10px",
                padding: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  justifyItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    width: "60px",
                    height: "60px",
                    padding: "10px",
                    borderRadius: "9999px",
                    backgroundColor: "white",
                    marginRight: "10px",
                  }}
                >
                  <img
                    src={Icon1}
                    alt="Icon 1"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
                <h2 style={{ height: "100%", color: "white" }}>HR On Demand</h2>
              </div>
              <div style={{ color: "white" }}>
                <p>
                  Unlock comprehensive HR support with our on-demand service.
                  From payroll management and employee engagement to
                  policy-making and candidate management, we handle every aspect
                  of HR so you can focus on growing your business.
                </p>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
                display: "flex",
                justifyItems: "start",
                height: "100%",
                backgroundColor: "#6855f6",
                borderRadius: "10px",
                padding: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  justifyItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    width: "60px",
                    height: "60px",
                    padding: "10px",
                    borderRadius: "9999px",
                    backgroundColor: "white",
                    marginRight: "10px",
                  }}
                >
                  <img
                    src={Icon2}
                    alt="Icon 2"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
                <h2 style={{ height: "100%", color: "white" }}>
                  Recruiter On Demand
                </h2>
              </div>
              <div style={{ color: "white" }}>
                <p>
                  Streamline your hiring process with our recruiter-on-demand
                  service. From sourcing the best talent to to ensurimg a smooth
                  and effective hiring , we handle every step, ensuring you find
                  the perfect fit for your team
                </p>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
                display: "flex",
                justifyItems: "start",
                height: "100%",
                backgroundColor: "#6855f6",
                borderRadius: "10px",
                padding: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  justifyItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    width: "60px",
                    height: "60px",
                    padding: "10px",
                    borderRadius: "9999px",
                    backgroundColor: "white",
                    marginRight: "10px",
                  }}
                >
                  <img
                    src={Icon3}
                    alt="Icon 3"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
                <h2 style={{ height: "100%", color: "white" }}>
                  Legal On Demand
                </h2>
              </div>
              <div style={{ color: "white" }}>
                <p>
                  Access on-demand legal expertise tailored to your business
                  needs. From contract review to compliance guidance, our team
                  is here to provide comprehensive legal support, ensuring peace
                  of mind for your business.
                </p>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
                display: "flex",
                justifyItems: "start",
                height: "100%",
                backgroundColor: "#6855f6",
                borderRadius: "10px",
                padding: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  justifyItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    width: "60px",
                    height: "60px",
                    padding: "10px",
                    borderRadius: "9999px",
                    backgroundColor: "white",
                    marginRight: "10px",
                  }}
                >
                  <img
                    src={Icon4}
                    alt="Icon 4"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
                <h2 style={{ height: "100%", color: "white" }}>
                  Accountant On Demand
                </h2>
              </div>
              <div style={{ color: "white" }}>
                <p>
                  Empower your business with on-demand accounting expertise.
                  From financial analysis, GST compliance, and expense
                  management to tax advisory and bookkeeping services, our team
                  delivers tailored solutions to optimize your financial
                  management, driving growth and success.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex items-center justify-center w-full h-full min-h-screen"
          style={{
            justifyContent: "space-evenly",
            flexDirection: "column",
            width: "100%",
            margin: "0 auto",
            height: "100%",
            display: "flex",
            justifyItems: "center",
            overflow: "hidden",
            maxWidth: "1200px",
            padding: "15px",
          }}
        >
          <div
            className="border-container"
            style={{
              justifyContent: "space-evenly",
              width: "100%",
              margin: "0 auto",
              height: "100%",
              display: "flex",
              justifyItems: "center",
              overflow: "hidden",
              border: "2px solid #6855f6",
              borderRadius: "10px",
              minHeight: "70vh",
              padding: "30px",
            }}
          >
            <div
              style={{
                justifyContent: "space-between",
                flexDirection: "column",
                width: "100%",
                margin: "0 auto",
                height: "100%",
                display: "flex",
                justifyItems: "center",
              }}
            >
              <h4
                className="heading-2"
                style={{
                  lineHeight: "4.5rem",
                }}
              >
                What do you get?
              </h4>
              <ul style={{ color: "#6855f6", marginTop: "20px" }}>
                <li
                  className="heading"
                  style={{
                    height: "100%",
                    lineHeight: "1.5",
                  }}
                >
                  Flat Monthly Pricing
                </li>
                <li
                  className="heading"
                  style={{
                    height: "100%",
                    lineHeight: "1.5",
                  }}
                >
                  On Demand Executives
                </li>
                <li
                  className="heading"
                  style={{
                    height: "100%",
                    lineHeight: "1.5",
                  }}
                >
                  Custom Built Solution
                </li>
                <li
                  className="heading"
                  style={{
                    height: "100%",
                    lineHeight: "1.5",
                  }}
                >
                  Global presence in India, US and Canada
                </li>
                <li
                  className="heading"
                  style={{
                    height: "100%",
                    lineHeight: "1.5",
                  }}
                >
                  Dedicated team and Call / Email / Chat support
                </li>
              </ul>
            </div>
            <div
              className=""
              style={{
                justifyContent: "space-between",
                flexDirection: "column",
                width: "100%",
                margin: "0 auto",
                height: "100%",
                display: "flex",
                justifyItems: "center",
                backgroundColor: "red",
              }}
            >
              <img
                src={Benfit}
                alt="benefit of your-hr"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
        <div
          class="flex items-center justify-center w-full h-full"
          style={{
            justifyContent: "space-evenly",
            flexDirection: "column",
            width: "100%",
            margin: "0 auto",
            height: "100%",
            display: "flex",
            justifyItems: "center",
            textAlign: "center",
          }}
        >
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSfTIH7Yei7q7kPxE2hAYINIBuW19fUv0Ck69Kez73nc9CtbbA/viewform"
            className="heading"
            style={{
              margin: "50px 0px",
              fontWeight: "900",
              color: "#6855f6",
              textUnderlinePosition: "under",
              textUnderlineOffset: "1",
              textDecoration: "underline",
              textDecorationColor: "#6855f6",
              textDecorationThickness: "3px",
            }}
            target="_blank"
            rel="noreferrer"
          >
            Click here to claim your discount
          </a>
        </div>
        <div
          style={{
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
            margin: "0 auto",
            height: "100%",
            display: "flex",
            justifyItems: "center",
            overflow: "hidden",
            padding: "15px",
            marginTop: "50px",
            marginBottom: "50px",
            backgroundColor: "#614FF7",
          }}
        >
          <div
            className="border-container"
            style={{
              justifyContent: "space-around",
              width: "100%",
              margin: "0 auto",
              height: "100%",
              display: "flex",
              justifyItems: "center",
              overflow: "hidden",
              padding: "30px",
              maxWidth: "1200px",
              textAlign: "center",
            }}
          >
            <div
              style={{
                color: "white",
                fontWeight: "800",
              }}
            >
              <div className="heading-3">Assisted</div>
              <div
                className="heading-2"
                style={{
                  lineHeight: "1.2",
                  fontWeight: "900",
                  color: "yellow",
                }}
              >
                125+
              </div>
              <div className="heading-3">Startups</div>
            </div>
            <div
              className="pdtop"
              style={{
                color: "white",
                fontWeight: "800",
              }}
            >
              <div className="heading-3">Access to</div>
              <div
                className="heading-2"
                style={{
                  lineHeight: "1.2",
                  fontWeight: "900",
                  color: "yellow",
                }}
              >
                200+
              </div>
              <div className="heading-3">Pre Vetted Partners</div>
            </div>
          </div>
        </div>
        <div
          className="trust-container"
          style={{
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
            margin: "0 auto",
            height: "100%",
            display: "flex",
            justifyItems: "center",
            overflow: "hidden",
            maxWidth: "1200px",
            // padding: "15px",
          }}
        >
          <div
            className="trust-inner"
            style={{
              justifyContent: "center",
              width: "100%",
              margin: "0 auto",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyItems: "center",
              overflow: "hidden",
              // minHeight: "70vh",
            }}
          >
            <h5
              className="heading-2"
              style={{ color: "#6855f6", textAlign: "center" }}
            >
              Trusted By
            </h5>
            <div
              className="trust-image"
              style={{
                justifyContent: "center",
                width: "100%",
                margin: "0 auto",
                height: "100%",
                display: "flex",
                justifyItems: "center",
                overflow: "hidden",
              }}
            >
              <img
                src={Logos}
                alt="sad"
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
          </div>
        </div>
      </main>
      <footer
        className="footer-container"
        style={{
          display: "flex",
          paddingLeft: "3rem",
          paddingRight: "3rem",
          paddingBottom: "1rem",
          paddingTop: "3rem",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          fontWeight: 500,
          backgroundColor: "#614FF7",
          // marginTop: "100px",
        }}
      >
        <div
          className="flex items-center justify-between w-[80%] border-container "
          class="border-container"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            width: "80%",
            height: "100%",
            margin: "0 auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              justifyItems: "center",
              alignItems: "center",
              color: "#ffffff",
              flexBasis: "45%",
              width: "100%",
              height: "100%",
            }}
          >
            <div
              className="flex flex-col gap-2 text-base md:text-lg "
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                height: "100%",
                alignItems: "flex-start",
              }}
            >
              <p
                className="text-white select-none font-semibold heading-3 "
                style={{
                  fontWeight: 600,
                  userSelect: "none",
                  color: "#ffffff75",
                }}
              >
                Link
              </p>
              <a
                className="logo-container heading-3"
                style={{
                  textDecoration: "none",
                  color: "white",
                  lineHeight: "1.6",
                  padding: "6px",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "start",
                  justifyItems: "center",
                  alignItems: "center",
                  width: "40px",
                  height: "40px",
                }}
                href="https://zyvka.com/recruiter/"
                target="_blank"
                rel="noreferrer"
              >
                Recruiter
              </a>
              <a
                style={{
                  textDecoration: "none",
                  color: "white",
                  lineHeight: "1.6",
                  padding: "6px",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "start",
                  justifyItems: "center",
                  alignItems: "center",
                  width: "40px",
                  height: "40px",
                }}
                href="https://zyvka.com/employer/"
                target="_blank"
                rel="noreferrer"
                className="logo-container heading-3"
              >
                Employer
              </a>
              <a
                style={{
                  textDecoration: "none",
                  color: "white",
                  lineHeight: "1.6",
                  padding: "6px",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "start",
                  justifyItems: "center",
                  alignItems: "center",
                  width: "40px",
                  height: "40px",
                }}
                href="https://zyvka.com/about-us/"
                target="_blank"
                rel="noreferrer"
                className="logo-container heading-3"
              >
                About
              </a>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <p
                className="text-white select-none font-semibold heading-3"
                style={{
                  fontWeight: 600,
                  userSelect: "none",
                  color: "#ffffff75",
                }}
              >
                Socials
              </p>
              <a
                className="logo-container"
                style={{
                  textDecoration: "none",
                  color: "white",
                  lineHeight: "1.6",
                  padding: "6px",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  justifyItems: "center",
                  alignItems: "center",
                  width: "40px",
                  height: "40px",
                }}
                href="https://www.linkedin.com/company/zyvka/"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  fill="#0077b5"
                  viewBox="0 0 1920 1920"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M478.234 600.75V1920H.036V600.75h478.198Zm720.853-2.438v77.737c69.807-45.056 150.308-71.249 272.38-71.249 397.577 0 448.521 308.666 448.521 577.562v737.602h-480.6v-700.836c0-117.867-42.173-140.215-120.15-140.215-74.134 0-120.151 23.55-120.151 140.215v700.836h-480.6V598.312h480.6ZM239.099 0c131.925 0 239.099 107.294 239.099 239.099s-107.174 239.099-239.1 239.099C107.295 478.198 0 370.904 0 239.098 0 107.295 107.294 0 239.099 0Z"
                    fill-rule="evenodd"
                  />
                </svg>
              </a>
              <a
                className="logo-container"
                style={{
                  textDecoration: "none",
                  color: "white",
                  lineHeight: "1.6",
                  padding: "6px",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  justifyItems: "center",
                  alignItems: "center",
                  width: "40px",
                  height: "40px",
                }}
                href="https://twitter.com/ZyvkaGlobal"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  fill="black"
                  viewBox="-4 0 32 32"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>twitter</title>
                  <path d="M24 8.531c-0.688 1-1.5 1.844-2.469 2.563 0.031 0.219 0.031 0.438 0.031 0.656 0 6.5-4.938 14-14 14-2.781 0-5.375-0.844-7.563-2.219 0.375 0.031 0.781 0.094 1.188 0.094 2.313 0 4.406-0.813 6.094-2.125-2.188-0.031-3.969-1.5-4.594-3.438 0.281 0.063 0.625 0.094 0.938 0.094 0.438 0 0.906-0.063 1.313-0.188-2.281-0.438-3.969-2.406-3.969-4.781v-0.063c0.688 0.344 1.406 0.563 2.219 0.594-1.313-0.906-2.188-2.406-2.188-4.094 0-0.906 0.25-1.75 0.656-2.5 2.438 2.969 6.063 4.969 10.156 5.156-0.063-0.344-0.125-0.75-0.125-1.125 0-2.719 2.188-4.938 4.906-4.938 1.438 0 2.719 0.625 3.625 1.594 1.125-0.219 2.156-0.656 3.094-1.219-0.344 1.156-1.125 2.156-2.125 2.75 1-0.125 1.906-0.406 2.813-0.813z"></path>
                </svg>
              </a>
              <a
                className="logo-container"
                style={{
                  textDecoration: "none",
                  color: "white",
                  lineHeight: "1.6",
                  padding: "6px",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  justifyItems: "center",
                  alignItems: "center",
                  width: "40px",
                  height: "40px",
                }}
                href="https://www.facebook.com/zyvkaglobalservices"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  fill="#0081fb"
                  viewBox="0 0 1920 1920"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1168.737 487.897c44.672-41.401 113.824-36.889 118.9-36.663l289.354-.113 6.317-417.504L1539.65 22.9C1511.675 16.02 1426.053 0 1237.324 0 901.268 0 675.425 235.206 675.425 585.137v93.97H337v451.234h338.425V1920h451.234v-789.66h356.7l62.045-451.233H1126.66v-69.152c0-54.937 14.214-96.112 42.078-122.058"
                    fill-rule="evenodd"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div
            className="flex items-center justify-between h-full flex-col space-y-6 "
            style={{
              display: "flex",
              marginTop: "1.5rem",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div
              className="flex items-start flex-col "
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <p
                className="font-semibold text-white/30 text-sm heading-3"
                style={{
                  lineHeight: "1.25rem",
                  fontWeight: 600,
                  color: "#ffffff70",
                }}
              >
                Contact:
              </p>
              <p
                className="max-w-[300px] font-medium heading-3"
                style={{ fontWeight: 500, maxWidth: "300px", color: "white" }}
              >
                Prasad Routray - 9686805400, prasad@zyvka.com
              </p>
              <p
                className="max-w-[300px] font-medium heading-3"
                style={{ fontWeight: 500, maxWidth: "300px", color: "white" }}
              >
                Sanjana Jashnani - 9667685345, sanjana @zyvka.com
              </p>
            </div>
            {/* <div
              className="flex items-start flex-col "
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginTop: "30px",
              }}
            >
              <p
                className="font-semibold text-white/30 text-sm heading-3"
                style={{
                  lineHeight: "1.25rem",
                  fontWeight: 600,
                  color: "#ffffff70",
                }}
              >
                {" "}
                India:
              </p>
              <p
                className="max-w-[300px] font-medium heading-3"
                style={{ fontWeight: 500, maxWidth: "300px", color: "white" }}
              >
                3400 Cottage Way,Ste G2#7513 Sacramento, CA-95825
              </p>
            </div> */}
          </div>
        </div>
        <div
          style={{
            marginBottom: "1rem",
            marginTop: "2rem",
            borderRadius: "9999px",
            height: "2px",
            width: "90%",
            "@media (min-width: 768px)": {
              marginBottom: "1.25rem",
              marginTop: "3rem",
            },
          }}
          className="bg-white/30 h-[2px] w-[90%] mx-auto mb-4 md:mb-5 mt-8 md:mt-12 rounded-full "
        ></div>
        <div
          className="flex items-center justify-around w-full  "
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            className="flex items-center "
            style={{
              display: "flex",
              alignItems: "center",
              color: "#ffffff75",
            }}
          >
            <p>&copy; Zyvka - All right reserved </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default YourHR;
