import React, { useEffect, useState } from "react";
import { Form, Input } from "semantic-ui-react";
import { InboxOutlined } from '@ant-design/icons';
import {
  Space,
  Table,
  Typography,
  List,
  Button,
  DatePicker,
  message, Upload, Checkbox
} from "antd";
import { PlusCircleOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { getMyApplicant, getMyApplicantForNonPartners } from "../api/partner";
import { useSelector } from "react-redux";
import { UserState } from "../features/userSlice";
import { ZyvkaMemberState } from "../features/ZyvkaMemberSlice";
import { useDispatch } from "react-redux";
import { selectUser } from "../features/userSlice";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { selectMyApplicants } from "../features/partnersSlice";
import { referApplicant } from "../api/partner";
import { fetchJobData, getAllClients, getPreviousVideoResume } from "../api/ZyvkaMember";
import { fetchALlPartnerCompanies, fetchAllSkills, fetchAllCurrentComp } from "../api/auth";
import { Modal } from "semantic-ui-react";
import "../styles/customStyle.css";
import { filterCandidateForNonPartner, filterCandidateForPartner } from '../api/partner';
import axios from "axios";
const { Column } = Table;
const { Title } = Typography;
const { Dragger } = Upload;


export const ReferMyApplicant = ({ location }) => {
  const userState = useSelector(UserState);
  const [searchParams, setSearchParams] = useSearchParams();
  const { jobId } = useParams();
  const [flag, setFlag] = useState(false);
  const dispatch = useDispatch();
  const applicants = useSelector(selectMyApplicants);
  const AllSkills = userState.gSkills;
  const AllCompany = userState.gCompany;
  const zyvkaState = useSelector(ZyvkaMemberState);
  const AllClients = zyvkaState.AllClients;
  const [keySkills, setKeySkills] = useState();
  const [current_company, set_current_company] = useState();
  const [not_current_company, set_not_current_company] = useState();
  const [clientName, setClientName] = useState();
  const [compName, setCompName] = useState();
  const roundOptions = ["Applicant Applied", "Under Review", "Zyvka Shortlisted", "Zyvka Rejected", "Client Shortlisted", "Client Rejected", "Round 1 Selected", "Round 1 Rejected", "Round 2 Selected", "Round 2 Rejected", "Round 3 Selected", "Round 3 Rejected", "Round 4 Selected", "Round 4 Rejected", "Round 5 Selected", "Round 5 Rejected", "Assignment Pending", "Assignment Rejected", "Offer Discussion", "Offer rolled out", "Offer Accepted", "Offer Rejected", "Candidate Withdrawn", "Didn't turn up for Drive", "Candidate Joined", "Candidate Resigned", "Position Closed"]
  const [filterData, setFilterData] = useState();
  const [filterFlag, setFilterFlag] = useState(false);
  const [filterState, setFilterState] = useState({
    min_total_experience: undefined,
    max_total_experience: undefined,
    salary_type: undefined,
    max_current_salary: undefined,
    max_expected_salary: undefined,
    max_notice_period: undefined,
    partner_company_name: undefined,
    applicant_date_creation: undefined,
    current_job_status: undefined,
    current_company: undefined,
    location: undefined
  })

  const [opendrawer, setOpenDrawer] = useState(false);
  const [newStory, setNewStory] = useState(false);
  const [newVideo, setNewVideo] = useState(false)
  const [flag3, setFlag3] = useState(false)
  const [prevVResume, setPrevVResume] = useState([])
  const [videoResume, setVideoResume] = useState(null)
  const [videoRequired, setVideoRequired] = useState(false)
  const [candidateStory, setCandidateStory] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false)
  const [flag2, selFlag] = useState("0");

  const getJobDetail = async (id) => {
    try {
      const res = await fetchJobData(id)
      setVideoRequired(res?.isVideoResumeRequired)
    } catch (e) {
      console.log(e)
    }
  }
  const fetchAll = async () => {
    try {
      await fetchAllSkills(dispatch);
      await getJobDetail(jobId)
      await fetchAllCurrentComp(dispatch);
      await getAllClients(dispatch);
      if (userState.role !== 'partnerAdmin' && userState.role !== 'partner') {
        const res = await fetchALlPartnerCompanies()
        setCompName(res);
      }
    } catch {
      return
    }
  }
  //Date Formatter
  const formatDate = (date) => {
    if (!date) return null;
    const year = date?.getFullYear();
    const month = String(date?.getMonth() + 1).padStart(2, '0');
    const day = String(date?.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}T00:00:00.000Z`;

  };
  //console.log(ApplicantData);
  const onFinish = async () => {
    let res;
    try {
      setCurrentPage(1);
      if (userState.role === 'partnerAdmin' || userState.role === 'partner') {
        res = await filterCandidateForPartner(jobId,
          {
            min_total_experience: filterState?.min_total_experience,
            max_total_experience: filterState?.max_total_experience,
            max_current_salary: filterState?.max_current_salary,
            max_expected_salary: filterState?.max_expected_salary,
            max_notice_period: filterState?.max_notice_period,
            key_skills: keySkills || [],
            current_companies: current_company || [],
            notIn_current_companies: not_current_company || [],
            client_name: clientName || [],
            applicant_date_creation: filterState?.createdAt?._d ? formatDate(filterState?.applicant_date_creation?._d) : null,
            salary_type: filterState?.salary_type,
            current_job_status: filterState?.current_job_status,
            current_company: filterState?.current_company,
            location: filterState?.location,
            searchValue: searchValue?.toString().trim()
          }, 1, dispatch);
        setTotalItems(res?.count);
        setFilterData(res?.data);
      }
      else {
        res = await filterCandidateForNonPartner(jobId,
          {
            min_total_experience: filterState?.min_total_experience,
            max_total_experience: filterState?.max_total_experience,
            max_current_salary: filterState?.current_ctc,
            max_expected_salary: filterState?.expected_ctc,
            max_notice_period: filterState?.max_notice_period,
            key_skills: keySkills || null,
            current_companies: current_company || [],
            notIn_current_companies: not_current_company || [],
            client_name: clientName || [],
            applicant_date_creation: filterState?.createdAt?._d ? formatDate(filterState?.createdAt?._d) : null,
            salary_type: filterState?.salary_type,
            current_job_status: filterState?.current_job_status,
            current_company: filterState?.current_company,
            partner_company_name: filterState?.partner_company_name,
            location: filterState?.location,
            searchValue: searchValue?.toString().trim()
          }, 1, dispatch);
        setTotalItems(res?.count);
        setFilterData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
    setOpenDrawer(false);

  };
  const fetchFilteredUpdates = async (page) => {
    let res;
    setCurrentPage(page);
    try {
      if (userState.role === 'partnerAdmin' || userState.role === 'partner') {
        res = await filterCandidateForPartner(jobId,
          {
            min_total_experience: filterState?.min_total_experience,
            max_total_experience: filterState?.max_total_experience,
            max_current_salary: filterState?.max_current_salary,
            max_expected_salary: filterState?.max_expected_salary,
            max_notice_period: filterState?.max_notice_period,
            key_skills: keySkills || [],
            current_companies: current_company || [],
            notIn_current_companies: not_current_company || [],
            client_name: clientName || [],
            applicant_date_creation: filterState?.applicant_date_creation?._d ? formatDate(filterState?.applicant_date_creation?._d) : null,
            salary_type: filterState?.salary_type,
            current_job_status: filterState?.current_job_status,
            current_company: filterState?.current_company,
            location: filterState?.location,
            searchValue: searchValue?.toString().trim()
          }, page, dispatch);
        setTotalItems(res?.count);
        setFilterData(res?.data);
      }
      else {
        res = await filterCandidateForNonPartner(jobId,
          {
            min_total_experience: filterState?.min_total_experience,
            max_total_experience: filterState?.max_total_experience,
            max_current_salary: filterState?.current_ctc,
            max_expected_salary: filterState?.expected_ctc,
            max_notice_period: filterState?.max_notice_period,
            key_skills: keySkills || null,
            current_companies: current_company || [],
            notIn_current_companies: not_current_company || [],
            client_name: clientName || [],
            applicant_date_creation: filterState?.applicant_date_creation?._d ? formatDate(filterState?.applicant_date_creation?._d) : null,
            salary_type: filterState?.salary_type,
            current_job_status: filterState?.current_job_status,
            current_company: filterState?.current_company,
            partner_company_name: filterState?.partner_company_name,
            location: filterState?.location,
            searchValue: searchValue?.toString().trim()
          }, page, dispatch);
        setTotalItems(res?.count);
        setFilterData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
    setOpenDrawer(false);
  }
  const fetchIntialData = async () => {
    let res;
    setCurrentPage(1);
    try {
      if (userState.role === 'partnerAdmin' || userState.role === 'partner') {
        res = await filterCandidateForPartner(jobId,
          {
            min_total_experience: filterState?.min_total_experience,
            max_total_experience: filterState?.max_total_experience,
            max_current_salary: filterState?.max_current_salary,
            max_expected_salary: filterState?.max_expected_salary,
            max_notice_period: filterState?.max_notice_period,
            key_skills: keySkills || [],
            current_companies: current_company || [],
            notIn_current_companies: not_current_company || [],
            client_name: clientName || [],
            applicant_date_creation: filterState?.applicant_date_creation?._d ? formatDate(filterState?.applicant_date_creation?._d) : null,
            salary_type: filterState?.salary_type,
            current_job_status: filterState?.current_job_status,
            current_company: filterState?.current_company,
            location: filterState?.location,
            searchValue: searchValue?.toString().trim()
          }, 1, dispatch);
        setTotalItems(res?.count);
        setFilterData(res?.data);
      }
      else {
        res = await filterCandidateForNonPartner(jobId,
          {
            min_total_experience: filterState?.min_total_experience,
            max_total_experience: filterState?.max_total_experience,
            max_current_salary: filterState?.current_ctc,
            max_expected_salary: filterState?.expected_ctc,
            max_notice_period: filterState?.max_notice_period,
            key_skills: keySkills || [],
            current_companies: current_company || [],
            notIn_current_companies: not_current_company || [],
            client_name: clientName || [],
            applicant_date_creation: filterState?.applicant_date_creation?._d ? formatDate(filterState?.applicant_date_creation?._d) : null,
            salary_type: filterState?.salary_type,
            current_job_status: filterState?.current_job_status,
            current_company: filterState?.current_company,
            partner_company_name: filterState?.partner_company_name,
            location: filterState?.location,
            searchValue: searchValue?.toString().trim()
          }, 1, dispatch);
        setTotalItems(res?.count);
        setFilterData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
    setOpenDrawer(false);
  }
  const getPreviousResumevideo = async (id) => {
    setFlag3(true)
    try {
      const res = await getPreviousVideoResume(
        { id: id },
        dispatch
      );
      const vidMap = res?.data?.map((el) => el?.videoResume)
      console.log(vidMap, "here it is")
      setPrevVResume(vidMap)
    } catch (e) {
      console.log(e)
    }
    setFlag3(false)
  };
  useEffect(() => {
    fetchAll();
    fetchIntialData();
  }, []);
  useEffect(() => {
    fetchFilteredUpdates(currentPage);
  }, [filterFlag, flag]);

  const resetFilters = () => {
    setFilterState({
      min_total_experience: undefined,
      max_total_experience: undefined,
      salary_type: undefined,
      max_current_salary: undefined,
      max_expected_salary: undefined,
      max_notice_period: undefined,
      partner_company_name: undefined,
      applicant_date_creation: undefined,
      current_job_status: undefined,
      current_company: undefined,
      location: undefined
    });
    setKeySkills([]);
    set_current_company([]);
    set_not_current_company([]);
    setClientName([]);
    setFilterData(null);
    setSearchValue("");
    setFilterFlag((filterFlag) => !filterFlag); // To refetch data without filters
  };

  const [isReferable, setIsReferable] = useState(null)
  const [isReferableOpen, setIsReferableOpen] = useState(false)
  const referHandler = async (id, candidateStory, newStory, newVideo, videoResume) => {
    try {
      if (
        window.confirm("Are You Sure You want to Refer this Applicant") === true
      ) {
        console.log({
          applicantID: id,
          jobID: jobId,
          applicant_story: candidateStory,
          isNewApplicantStory: newStory,
          newVideo: newVideo,
          videoResume: videoResume
        })
        const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/jobs/isApplicantReferable`, {
          applicantID: id,
          jobID: jobId
        })
        if (!res.data?.isReferable) {
          setIsReferableOpen(true)
          setIsReferable(res?.data?.data)
        } else {
          await referApplicant(
            {
              applicantID: id,
              jobID: jobId,
              applicant_story: candidateStory,
              isNewApplicantStory: newStory,
              isNewVideo: newVideo,
              videoResume
            },
            dispatch
          );
        }
        setFlag((flag) => !flag);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showDrawer = () => {
    setOpenDrawer(!opendrawer);
  };
  const onClose = () => {
    setOpenDrawer(false);
  };


  const ChangeFlag = () => {
    setFlag((flag) => !flag);
  };

  const onChange2 = (id) => {
    selFlag(id);
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'video/mp4' || file.type === 'video/ogg' || file.type === 'video/x-msvideo' || file.type === 'video/mpeg' || file.type === 'video/quicktime' || file.type === 'video/x-ms-wmv' || file.type === 'video/x-flv' || file.type === 'video/x-matroska';
    if (!isJpgOrPng) {
      message.error('You can only upload video file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 15;
    if (!isLt2M) {
      message.error('Image must smaller than 15MB!');
    }
    const isLessThan400KB = file.size / 1024 > 400;
    if (!isLessThan400KB) {
      message.error('File must be greater than 400KB!');
    }
    return isJpgOrPng && isLt2M && isLessThan400KB;
  };
  const props = {
    name: 'file',
    action: `${process.env.REACT_APP_SERVER_URL}/api/video/uploadVideoResume/${jobId}`,
    beforeUpload: beforeUpload,
    onChange(info) {
      const { status } = info.file;
      setLoading(true)
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        setVideoResume(info?.file?.response?.urn)
        setNewVideo(true)
        console.log(info?.file?.response?.urn)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
      setLoading(false)
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };
  const [modalVisible, setModalVisible] = useState(false);

  const handleCheckboxChange = (checked, item) => {
    if (checked) {
      setVideoResume(item);
      setNewVideo(false);
    } else {
      setVideoResume(null);
      setNewVideo(true);
    }
  };

  const handleListItemClick = (item) => {
    setVideoResume(item);
    setModalVisible(true);
    setNewVideo(false);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "90%",
          display: "flex",
          alignItems: "center",
          padding: "30px",
          paddingLeft: "10px",
          paddingRight: "10px",
          justifyContent: "space-between",
          //   textAlign: "center",
        }}
      >
        <Title>{searchParams.get("job_title")}</Title>
        <p style={{ fontSize: "20px" }}>{searchParams.get("company_name")}</p>
      </div>
      <div
        style={{
          width: "90%",
          marginTop: "50px",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          <Space direction="horizontal" wrap={true}>
            <Button onClick={showDrawer}>Filter</Button>
            <Input
              placeholder="Search Here"
              style={{
                position: "relative",
                width: 150,
                padding: "3px",
                paddingTop: "6px",
                marginTop: "10px",
                marginBottom: "10px",
                lineHeight: "4ex",
                minWidth: 200,
                maxWidth: 400,
                paddingLeft: 10,
              }}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              value={searchValue}
            />
            <Button onClick={() => { fetchFilteredUpdates(currentPage) }} style={{ width: 80 }}>Search</Button>
          </Space>
        </div>
      </div>
      {
        opendrawer && (
          <div style={{ width: "100%", padding: "10px" }}>
            <Form
              labelCol={{
                span: 4,
              }}
              layout="horizontal"
              onSubmit={onFinish}
            >
              <Form.Group widths={"equal"} fluid style={{ display: "flex", flexDirection: "flexWrap" }} >
                <Form.Input fluid name="min_experience" placeholder="min experience (in years)" type="number" value={filterState?.min_total_experience} onChange={(e) => {
                  setFilterState({
                    ...filterState,
                    min_total_experience: e.target.value,
                  });
                }} style={{ minWidth: "150px", minHeight: "30px", height: "40px" }} />
                <Form.Input fluid name="max_experience" placeholder="max experience(in years)" type="number" value={filterState?.max_total_experience} onChange={(e) => {
                  setFilterState({
                    ...filterState,
                    max_total_experience: e.target.value,
                  });
                }} style={{ minWidth: "150px", minHeight: "30px", height: "40px" }} />
                <Form.Input name="max_notice_period" placeholder="notice period(in days)" type="number" value={filterState?.max_notice_period} onChange={(e) => {
                  setFilterState({
                    ...filterState,
                    [e.target.name]: e.target.value,
                  });
                }} style={{ minWidth: "150px", minHeight: "30px", height: "40px" }} />
                <Form.Select
                  name="salary_type"

                  placeholder="salary currency"
                  value={filterState?.salary_type}
                  onChange={(e, { value }) => {
                    console.log(e.target.value, value, "nope");
                    setFilterState({
                      ...filterState,
                      salary_type: value,
                    });
                  }}
                  selection
                  options={[
                    { key: 1, text: "$ USD", value: "USD" },
                    { key: 2, text: "₹ INR", value: "INR" },
                    { key: 3, text: "€ EURO", value: "EURO" },
                  ]}
                />
                <Form.Input name="current_ctc" placeholder="current ctc" type="number" value={filterState?.max_current_salary} onChange={(e) => {
                  setFilterState({
                    ...filterState,
                    max_current_salary: e.target.value,
                  });
                }} style={{ minWidth: "150px", minHeight: "30px", height: "40px" }} />
              </Form.Group>
              <Form.Group widths={"equal"} style={{ display: "flex", flexDirection: "wrap" }} >
                <Form.Input name="expected_salary" placeholder="expected salary" type="number" value={filterState?.max_expected_salary} onChange={(e) => {
                  setFilterState({
                    ...filterState,
                    max_expected_salary: e.target.value,
                  });
                }} style={{ minWidth: "150px", minHeight: "30px", height: "40px" }} />
                <Form.Input
                  name="location"
                  value={filterState?.location}
                  onChange={(e, { value }) => {
                    setFilterState({
                      ...filterState,
                      location: value
                    })
                  }}
                  type="text"
                  placeholder="City, Country"
                  fluid
                  style={{ minWidth: "150px", minHeight: "30px", height: "40px" }}
                />
                <Form.Select
                  name="candidate_status"

                  placeholder="candidate status"
                  fluid
                  selection
                  value={filterState?.current_job_status}
                  style={{ minWidth: "150px", minHeight: "30px", height: "40px" }}
                  options={roundOptions?.map((data, idx) => {
                    return {
                      key: idx,
                      value: data,
                      text: data,
                    }
                  })}
                  onChange={(e, { value }) => {
                    setFilterState({
                      ...filterState,
                      current_job_status: value,
                    });
                    console.log(filterState)
                  }}
                ></Form.Select>
                <Form.Select
                  additionLabel={null}
                  selection
                  fluid
                  search
                  placeholder="Key skills"
                  name="key_skills"

                  multiple
                  value={keySkills || []}
                  onChange={(event, { value }) => { setKeySkills(value) }}
                  options={
                    AllSkills?.map((data, idx) => {
                      return {
                        key: idx,
                        text: data.skill,
                        value: data.skill
                      }
                    })
                  }
                  style={{
                    minWidth: "150px", minHeight: "30px", height: "40px"
                  }}
                />
              </Form.Group>
              <Form.Group widths={"equal"} style={{ display: "flex", flexDirection: "wrap" }} >
                {
                  (userState.role !== 'partnerAdmin' && userState.role !== 'partner') && (
                    <Form.Select
                      name="partner_company_name"

                      placeholder="partner company name"
                      search
                      style={{ minWidth: "150px" }}
                      value={filterState?.partner_company_name}
                      onChange={(e, { value }) => {
                        setFilterState({
                          ...filterState,
                          partner_company_name: value,
                        });
                      }}
                      options={
                        compName?.map((data, idx) => {
                          return { key: idx, value: data.company_name, text: data.company_name }
                        })
                      }
                    />
                  )
                }
                <Form.Select
                  additionLabel={null}
                  selection
                  fluid
                  search
                  name="current_comapny"
                  placeholder="companies"
                  multiple
                  value={current_company || []}
                  onChange={(event, { value }) => { set_current_company(value) }}
                  options={
                    AllCompany?.map((data, idx) => {
                      return {
                        key: idx,
                        text: data.current_company,
                        value: data.current_company
                      }
                    })
                  }
                />
                <Form.Select
                  additionLabel={null}
                  selection
                  fluid
                  search
                  name="not_current_comapny"
                  placeholder="exclude these companies"
                  multiple
                  value={not_current_company || []}
                  onChange={(event, { value }) => { set_not_current_company(value) }}
                  options={
                    AllCompany?.map((data, idx) => {
                      return {
                        key: idx,
                        text: data.current_company,
                        value: data.current_company
                      }
                    })
                  }
                />
                <Form.Select
                  additionLabel={null}
                  selection
                  fluid
                  search
                  name="clientName"
                  placeholder="clientName"
                  multiple
                  value={clientName || []}
                  onChange={(event, { value }) => { setClientName(value) }}
                  options={
                    AllClients?.map((data, idx) => {
                      return {
                        key: idx,
                        text: data.name,
                        value: data.name
                      }
                    })
                  }
                />
                {/* <Form.Input
                  fluid
                  style={{ minWidth: "150px", minHeight: "30px", height: "40px" }}
                  name="current_company"
                  type="text"
                  value={filterState?.current_company}
                  onChange={(e, { value }) => {
                    setFilterState({
                      ...filterState,
                      current_company: value,
                    });
                    console.log(filterState)
                  }}
                  placeholder="company"
                /> */}
                <Form.Field  >
                  <DatePicker
                    placeholder="created At"
                    value={filterState?.applicant_date_creation}
                    onChange={(e) => {
                      console.log(e?._d, "data");
                      setFilterState({
                        ...filterState,
                        applicant_date_creation: e,
                      });
                    }}
                    format="YYYY-MM-DD"
                    style={{ width: "100%", border: "none", background: "transparent", minHeight: "30px", height: "40px" }}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group  >
                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginRight: "10px" }}>
                  <Button type="primary" htmlType="submit" style={{ minWidth: "12%", maxWidth: "200px", marginLeft: "20px" }}>
                    Filter
                  </Button>
                  <Button
                    style={{ minWidth: "12%", maxWidth: "200px", marginLeft: "20px" }}
                    dashed
                    type="button"
                    onClick={() => {
                      resetFilters();
                    }}
                  >
                    Reset All Filters
                  </Button>
                  <Button
                    type="button"
                    style={{ minWidth: "12%", maxWidth: "200px", marginLeft: "20px" }}
                    onClick={() => {
                      setOpenDrawer(false);
                    }}
                  >
                    Hide Filters
                  </Button>
                </div>
              </Form.Group>
            </Form>
          </div >
        )
      }
      <Table
        scroll={{ x: true }}
        // dataSource={applicants}
        dataSource={filterData}
        key={applicants.id}
        bordered={true}
        pagination={
          UserState?.role === "partnerAdmin" ? {} :
            {
              current: currentPage,
              pageSize: 10,
              total: totalItems,
              onChange: (page) => {
                setFlag(!flag);
                setCurrentPage(page);
                fetchFilteredUpdates(page);
              },
            }}
        style={{
          width: "100%",
          marginLeft: "10px"
        }}
      >
        <Column
          title="Name"
          render={(_, record) => {
            return (
              <Link to={`/dashboard/applicant/${record?.id}`}>
                {record.firstName + " " + record.lastName}
              </Link>
            );
          }}
        />
        <Column
          title="Email"
          dataIndex="email"
          key="email"
        />
        <Column title="Mobile Number" dataIndex="mobileNum" key="mobileNum" />
        <Column
          title="Action"
          key="action"
          render={(_, record) => (
            <Space size="middle" key={record.id}>
              {flag2 !== record.id && (
                <Button
                  style={{ color: "Blue", cursor: "pointer" }}
                  onClick={async () => {
                    onChange2(record.id);
                    setCandidateStory("");
                    console.log(record?.id, "here it is")
                    await getPreviousResumevideo(record?.id)
                  }}
                  disabled={userState.isPending ? true : false}
                >
                  Refer Applicant
                </Button>
              )}

              {/* <Button1 /> */}
              {flag2 === record.id && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    width: "100%",
                    maxWidth: "500px"
                  }}
                >
                  <div
                    style={{
                      maxWidth: "400px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                    }}
                  >
                    {/* <h1>View you previous </h1> */}
                    {newStory === false ?
                      (
                        record.applicant_story.length > 0 && (
                          <>
                            <Title level={3}>
                              Previous Candidate's Highlights
                            </Title>
                            <List
                              size="small"
                              bordered
                              style={{
                                maxHeight: 300,
                                overflow: "scroll",
                                display: "flex",
                                flexDirection: "column",
                              }}
                              // style={{ fontFamily: "sans-serif" }}
                              dataSource={record.applicant_story}
                              renderItem={(item) => (
                                <div
                                  onClick={() => setCandidateStory(item)}
                                  className="lstitem"
                                  style={{ padding: "10px", width: "100%" }}
                                >
                                  {candidateStory === item ? (
                                    <div style={{ color: "red" }}>{item}</div>
                                  ) : (
                                    item
                                  )}
                                </div>
                              )}
                            />
                          </>
                        )
                      ) : (
                        <textarea
                          type="text"
                          style={{
                            minWidth: "400px",
                            height: "250px",
                            padding: "10px",
                            outline: "none",
                            width: "auto",
                            border: "none",
                            color: "black",
                            fontSize: "16px",
                          }}
                          value={candidateStory}
                          onChange={(e) => setCandidateStory(e.target.value)}
                          placeholder="Enter New Candidate's Highlight"
                        ></textarea>
                      )}
                  </div>

                  {newStory === true ? (
                    <div
                      style={{ fontSize: "20px", cursor: "pointer" }}
                      onClick={() => {
                        setNewStory((newStory) => !newStory);
                        setCandidateStory("");
                      }}
                    >
                      {" "}
                      <MenuFoldOutlined /> Use Previous Used Highlights
                    </div>
                  ) : (
                    <div
                      style={{ fontSize: "20px", cursor: "pointer" }}
                      onClick={() => {
                        setNewStory((newStory) => !newStory);
                        setCandidateStory("");
                      }}
                    >
                      {" "}
                      <PlusCircleOutlined /> Add new highlight
                    </div>
                  )}
                  {
                    videoRequired && (
                      newVideo === false ? (
                        <>
                          <Title level={3}>Previous Candidate's Video Resume</Title>
                          <List
                            size="small"
                            bordered
                            style={{
                              maxHeight: 300,
                              overflow: "scroll",
                              display: "flex",
                              flexDirection: "column",
                            }}
                            dataSource={prevVResume?.slice(0, 3) || []}
                            renderItem={(item) => (
                              // <div
                              //   onClick={() => setVideoResume(item)}
                              //   className="lstitem"
                              //   style={{ padding: "10px", width: "100%" }}
                              // >
                              //   {videoResume === item ? (
                              //     <div style={{ color: "red" }}>{item}</div>
                              //   ) : (
                              //     <div >{item}</div>
                              //   )}
                              // </div>
                              <div
                                className="lstitem"
                                style={{ padding: "10px", width: "100%" }}
                              >
                                <Checkbox
                                  checked={videoResume === item}
                                  onChange={(e) => handleCheckboxChange(e.target.checked, item)}
                                />
                                <span
                                  onClick={() => handleListItemClick(item)}
                                  style={{ color: videoResume === item ? "red" : "inherit", marginLeft: 10, cursor: 'pointer' }}
                                >
                                  {item}
                                </span>
                              </div>
                            )}
                          />
                        </>
                      ) : (
                        <div style={{
                          width: "100%",
                          maxHeight: 300,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                          overflow: "clip",
                          flexWrap: "wrap",
                        }}>
                          <p>Please Upload compressed video below.
                            <br />
                            know more: <a href="https://www.freeconvert.com/video-compressor" style={{ color: "#6E5CF6", textDecoration: "none", fontWeight: "bolder" }} target="_blank" rel="noreferrer">videoconvertor.com</a> </p>
                          <Dragger {...props} style={{ marginTop: "3rem", paddingLeft: "3rem", paddingRight: "3rem", width: "100%" }}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint" style={{ color: "#aeaaae" }}>
                              Upload video under 15Mb
                            </p>
                          </Dragger>
                        </div>
                      )
                    )
                  }
                  <Modal
                    visible={modalVisible}
                    onCancel={handleCloseModal}
                    footer={null}
                    closeIcon
                    dimmer="blurring"
                    size="small"
                    onClose={handleCloseModal}
                    closeOnDimmerClick={false}
                    open={modalVisible}
                  >
                    <Modal.Content>
                      <video controls width="100%">
                        <source src={`${process.env.REACT_APP_SERVER_URL}/api/files/resume/${videoResume}`} type="" />
                        Your browser does not support the video tag.
                      </video>
                    </Modal.Content>
                  </Modal>
                  {
                    videoRequired && (
                      newVideo === true ? (
                        <div
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={() => {
                            setNewVideo((newVideo) => !newVideo);
                            setVideoResume(null);
                          }}
                        >
                          <MenuFoldOutlined /> Use Previous Used Video Resume
                        </div>
                      ) : (
                        <div
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={() => {
                            setNewVideo((newVideo) => !newVideo);
                            setVideoResume(null);
                          }}
                        >
                          <PlusCircleOutlined /> Add new Video Resume
                        </div>
                      )
                    )
                  }

                  <Button
                    onClick={() =>
                      referHandler(record.id, candidateStory, newStory, newVideo, videoResume)
                    }
                    disabled={(candidateStory === "" || (videoRequired && !videoResume) || loading)}
                    style={{ color: "blue" }}
                  >
                    {" "}
                    Submit{" "}
                  </Button>
                  <Button
                    danger
                    onClick={() => {
                      setFlag("");
                      setCandidateStory("");
                    }}
                  >
                    {" "}
                    Cancel
                  </Button>
                  <Modal
                    visible={isReferableOpen}
                    onCancel={() => setIsReferableOpen(false)}
                    footer={null}
                    closeIcon
                    dimmer="blurring"
                    size="small"
                    onClose={() => setIsReferableOpen(false)}
                    closeOnDimmerClick={false}
                    open={isReferableOpen}
                  >
                    <Modal.Header>Review Applicant Referral</Modal.Header>
                    <Modal.Content>
                      <p>Please review the following issues before proceeding with the referral:</p>
                      <ul style={{ color: "red" }}>
                        {isReferable?.ctcIssue && <li>{isReferable.ctcIssue}</li>}
                        {isReferable?.experienceIssue && <li>{isReferable.experienceIssue}</li>}
                        {isReferable?.maxCtcIssue && <li>{isReferable.maxCtcIssue}</li>}
                        {isReferable?.noticePeriodIssue && <li>{isReferable.noticePeriodIssue}</li>}
                      </ul>
                      <p>Are you sure you want to refer this applicant despite the above issues?</p>
                    </Modal.Content>
                    <Modal.Actions >
                      <Button danger style={{ backgroundColor: "red", color: "white", marginRight: "10px" }} onClick={() => setIsReferableOpen(false)}>
                        Disagree
                      </Button>
                      <Button style={{ backgroundColor: "green", color: "white" }}
                        onClick={async()=>{
                          await referApplicant(
                            {
                              applicantID: record.id,
                              jobID: jobId,
                              applicant_story: candidateStory,
                              isNewApplicantStory: newStory,
                              isNewVideo: newVideo,
                              videoResume
                            },
                            dispatch
                          );
                          setFlag((flag) => !flag);
                          setIsReferableOpen(false)
                        }}
                      >
                        Agree
                      </Button>
                    </Modal.Actions>
                  </Modal>
                </div>
              )}
            </Space>
          )}
        />
      </Table>
    </div >
  );
};