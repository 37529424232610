import React, { useState } from "react";
import {
    Tag,
    Button,
    Modal,
    Popconfirm,
    message
} from "antd";
import { Icon, Menu, Table, Form } from 'semantic-ui-react';
import { dropFlag, raiseFlag } from "../api/ZyvkaMember";
import { useSelector } from "react-redux";
import { UserState } from "../features/userSlice";
import { Link } from "react-router-dom";


export const ApplicantInJob = ({ id, data, setFlag, jobStatus, partnerList }) => {
    console.log(data)
    const userState = useSelector(UserState);
    const [modalData, setModalData] = useState(false)
    const [flagModal, setFlagModal] = useState(false)
    const [raisedFlagModal, setRaisedFlagModal] = useState(false)
    const [flagText, setFlagText] = useState("")
    function formatDateTime(ts) {
        const date = new Date(ts);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const formattedHours = String(hours).padStart(2, '0');

        const formattedDate = `${day}-${month}-${year}`;
        const formattedTime = `${formattedHours}:${minutes}${ampm}`;

        return `${formattedDate} - ${formattedTime}`;
    }
    const handleFlagModalOk = async (id) => {
        try {
            const result = await raiseFlag({
                applicantJobStatusId: id, message: flagText, raisedBy: `${userState?.userInfo?.firstName} ${userState?.userInfo?.lastName}`,
                raiseById: `${userState?.userInfo?.id}`
            });
            message?.success("Flag raised successfully, our internal team will start working on it as soon as possible")
            setFlag((el) => !el)
        } catch (e) {
            console.log(e)
            message?.error("some error occurred, please try after some time")
        }
        setFlagModal(false);
    };
    const findAddedBy = (cid) => {
        return partnerList?.find(el => el?.id === cid)
    }
    return (
        <div>
            <Table celled  >
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Contact</Table.HeaderCell>
                        <Table.HeaderCell>Added By</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Evaluation</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        data?.map((obj, idx) => {
                            return (
                                <>
                                    <Table.Row key={obj.id} >
                                        <Table.Cell>
                                            <a
                                                href={`/dashboard/applicant/${obj?.applicant?.id}`}
                                                rel="noreferrer"
                                                target="_blank"
                                                style={{
                                                    textDecoration: "none",
                                                }}
                                            >
                                                {obj?.applicant?.firstName + " " + obj?.applicant?.lastName}
                                            </a>
                                        </Table.Cell>
                                        <Table.Cell>{obj?.applicant?.email + " - " + obj?.applicant?.mobileNum}</Table.Cell>
                                        <Table.Cell>{findAddedBy(obj.applicant?.creatorId)?.firstName + " " + findAddedBy(obj.applicant?.creatorId)?.lastName}</Table.Cell>
                                        <Table.Cell >
                                            {
                                                <Tag
                                                    color={'orange'}
                                                    style={{
                                                        padding: "4px",
                                                        paddingLeft: "7px",
                                                        paddingRight: "7px",
                                                    }}
                                                >
                                                    {obj.current_status}
                                                </Tag>
                                            }

                                        </Table.Cell>

                                        <Table.Cell key={obj.id}
                                        >
                                            {obj?.zyvkaAiScore ?
                                                <>
                                                    <Button onClick={() => setModalData(obj?.id)} >See Evalution</Button>
                                                    <Modal
                                                        title="Candidate Evaluation"
                                                        visible={modalData === obj?.id}
                                                        onCancel={() => { setModalData(null) }}
                                                        footer={[
                                                            <Button key="back" onClick={() => { setModalData(null) }}>
                                                                Go Back
                                                            </Button>
                                                            ,
                                                            <Button
                                                                danger
                                                                key="submit"
                                                                type="primary"
                                                                disabled={!(obj?.zyvkaAiEvaluation && Object.keys(obj?.zyvkaAiEvaluation)?.length > 0)}
                                                                onClick={() => {
                                                                    if ((obj?.raiseFlag?.length > 0) && (obj?.zyvkaAiEvaluation && Object.keys(obj.zyvkaAiEvaluation).length > 0)) {
                                                                        setRaisedFlagModal(obj?.raiseFlag?.[0]?.id)
                                                                        setModalData(null)
                                                                    }
                                                                }} >
                                                                See Already Raised Flag
                                                            </Button>,
                                                            (obj?.raiseFlag?.[obj?.raiseFlag?.length - 1]?.isActive === false) && (
                                                                <Button onClick={() => {
                                                                    if (obj?.zyvkaAiEvaluation && Object.keys(obj?.zyvkaAiEvaluation)?.length > 0) {
                                                                        setModalData(null)
                                                                        setFlagModal(obj?.id)
                                                                    }
                                                                }} >
                                                                    Raise Flag
                                                                </Button>
                                                            )
                                                        ]}
                                                    >
                                                        <div>
                                                            <b>Evaluation: </b>
                                                            {obj?.zyvkaAiEvaluation?.reason}
                                                        </div>
                                                        <div>
                                                            <b>AI Score: </b>
                                                            {obj?.zyvkaAiEvaluation?.match_score}
                                                        </div>
                                                        <div>
                                                            <b>Decision: </b>
                                                            {obj?.zyvkaAiEvaluation?.decision}
                                                        </div>
                                                    </Modal>
                                                    <Modal
                                                        title="Raise Flag"
                                                        visible={flagModal === obj?.id}
                                                        footer={[

                                                            <Button key="back" onClick={() => setFlagModal(false)}>
                                                                Cancel
                                                            </Button>
                                                            ,
                                                            <Popconfirm
                                                                danger
                                                                title="Are you sure you want to riase a flag against AI evaluation ?"
                                                                onConfirm={() => { setModalData(null); handleFlagModalOk(obj?.id) }}
                                                                okText="Yes"
                                                                cancelText="No"
                                                                disabled={obj?.raiseFlag?.length > 0}
                                                            >
                                                                <Button danger key="submit" disabled={obj?.raiseFlag?.length > 0}
                                                                    type="primary" >
                                                                    Raise Flag
                                                                </Button>
                                                            </Popconfirm>,
                                                        ]}
                                                    >
                                                        <Form loading={userState?.isPending}>
                                                            <Form.TextArea
                                                                fluid
                                                                name="flagText"
                                                                required={true}
                                                                placeholder="Enter Your Concern"
                                                                label="Message"
                                                                value={flagText}
                                                                onChange={(e) => setFlagText(e.target.value)}
                                                            ></Form.TextArea>
                                                        </Form>
                                                    </Modal>
                                                    <Modal
                                                        title="Drop Flag"
                                                        visible={raisedFlagModal === obj?.raiseFlag?.[0]?.id}
                                                        onCancel={() => { setRaisedFlagModal(false) }}
                                                        footer={[
                                                            <Button key="back" onClick={() => setRaisedFlagModal(false)}>
                                                                Go Back
                                                            </Button>,
                                                            obj?.raiseFlag?.[obj?.raiseFlag?.length - 1]?.isActive === false &&
                                                            <Button>
                                                                <Link to={`/dashboard/applicant/${obj?.applicantID}`} >Raise Flag Through "Details Page"</Link>
                                                            </Button>
                                                        ]}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%"
                                                            }}>
                                                            {
                                                                obj?.raiseFlag?.map((el, idx) => (
                                                                    <>
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                backgroundColor: "#f0f0f0",
                                                                                border: "2px solid #007bff50",
                                                                                borderRadius: "10px",
                                                                            }}
                                                                        >
                                                                            <p
                                                                                style={{
                                                                                    fontSize: "16px",
                                                                                    textAlign: "center",
                                                                                    padding: "20px",
                                                                                    maxWidth: "600px"

                                                                                }}
                                                                            >
                                                                                {obj?.raiseFlag?.[0]?.message}
                                                                            </p>
                                                                        </div>
                                                                        <div style={{ display: 'flex', justifyContent: "space-between", width: "100%", alignItems: "center", marginTop: "20px" }}>
                                                                            <p style={{ fontSize: "18px", color: obj?.raiseFlag?.[0]?.isActive ? "red" : "green", fontWeight: "bolder" }}>
                                                                                {obj?.raiseFlag?.[0]?.isActive ? "Under Scrutiny" : (
                                                                                    <span>
                                                                                        Dropped at <br /> {formatDateTime(obj?.raiseFlag?.[0]?.droppedAt)}
                                                                                    </span>
                                                                                )}
                                                                            </p>

                                                                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                                                                <div style={{ fontSize: "16px", color: "black", fontWeight: "bold" }} >
                                                                                    {obj?.raiseFlag?.[0]?.raisedBy}
                                                                                </div>
                                                                                <div style={{ fontSize: "14px", color: "black", fontWeight: "medium" }} >
                                                                                    {formatDateTime(obj?.raiseFlag?.[0]?.raisedAt)}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))
                                                            }
                                                        </div>
                                                    </Modal>
                                                </> : "—"}
                                        </Table.Cell>
                                    </Table.Row >
                                </>
                            )
                        })
                    }

                </Table.Body >
                <Table.Footer >
                    <Table.Row>
                        <Table.HeaderCell colSpan='5'>
                            <Menu floated='right' pagination>
                                <Menu.Item as='a' icon>
                                    <Icon name='chevron left' />
                                </Menu.Item>
                                <Menu.Item as='a'>1</Menu.Item>
                                <Menu.Item as='a'>2</Menu.Item>
                                <Menu.Item as='a'>3</Menu.Item>
                                <Menu.Item as='a'>4</Menu.Item>
                                <Menu.Item as='a' icon>
                                    <Icon name='chevron right' />
                                </Menu.Item>
                            </Menu>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table >
        </div >
    );
};