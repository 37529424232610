import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    userActivity,
    fetchUserActivity,
} from "../features/metabaseSlice";
import {
    getUserActivityLocalCache,
} from "../cache/localStorage";

const MetabaseAnalyticsUser = () => {
    const [url, setUrl] = useState("");
    const dispatch = useDispatch();
    const userActivities = useSelector(userActivity);
    useEffect(() => {
        const getData = async () => {
            const posUrl = getUserActivityLocalCache();
            if (!posUrl) {
                dispatch(fetchUserActivity());
                setUrl(userActivities);
            } else {
                setUrl(posUrl);
            }
        }
        getData()
    }, [
        dispatch,
        userActivities,
        url, setUrl
    ]);

    return (
        <div style={{ width: "98%", maxWidth: "80vw" }} >
            <iframe
                loading="eager"
                src={url}
                style={{
                    width: "100%",
                    height: "100%",
                    minHeight: "80vh",
                    border: "2px solid #4C577370"
                }}
                title="User Analytics"
            />
        </div>
    )
};

export default MetabaseAnalyticsUser;
