import React, { useState } from "react";

import { useSelector } from "react-redux";
import { createZyvkaUser } from "../api/ZyvkaMember";
import { useDispatch } from "react-redux";
import { selectUser } from "../features/userSlice";
import { UserState } from "../features/userSlice";
import { Form, Card } from "semantic-ui-react";
import data from "../Data/Data";
import { ZyvkaMemberState } from "../features/ZyvkaMemberSlice";
import { defaultcCode } from "../Data/CountryCode";
import ErrorMessage from "../Error/ErrorMessage";
import { countryCodeSemantic } from "../Data/CountryCode";
const AddZyvkaMember = () => {
  const zyvkaState = useSelector(ZyvkaMemberState);
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    countryCode: defaultcCode,
    confirmEmail: "",
    email: "",
    mobileNum: "",
    isAdmin: false,
    linkedIn_URL: "",
    isSuperAdmin: false,
  });

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userState = useSelector(UserState);

  const AddPAdmin = data.Dashboard.zyvka_partner_management.AddPartnerAdmin;
  const changeHandler = (e) => {
    console.log(e);
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const submitForm = async (e) => {
    try {
      e.preventDefault();
      console.log(formValues);

      await createZyvkaUser(
        {
          firstName: formValues?.firstName,
          lastName: formValues?.lastName,
          zyvkaUserID: user.id,
          countryCode: formValues?.countryCode,
          email: formValues?.email,
          mobileNum: formValues?.mobileNum,
          isAdmin: formValues.isAdmin,
          linkedIn_URL: formValues?.linkedIn_URL,
          isSuperAdmin: formValues?.isSuperAdmin,
        },
        dispatch,
        setFormValues
      );
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  return (
    <div className="site-card-border-less-wrapper">
      <Card
        headStyle={{ fontSize: "25px", fontWeight: "bold" }}
        bordered={false}
        style={{
          width: "auto",
          marginTop: "10px",
          padding: "15px",
          maxWidth: "900px",
        }}
      >
        <Card.Content header={"Add Zyvka Member"}></Card.Content>
        <Card.Content>
          <Form onSubmit={submitForm}>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                placeholder="First name"
                name="firstName"
                value={formValues?.firstName}
                onChange={changeHandler}
                required={AddPAdmin.form.inputs.fname.required}
                label={"First Name"}
              />
              <Form.Input
                fluid
                name="lastName"
                value={formValues?.lastName}
                onChange={changeHandler}
                label={"Last Name"}
                placeholder="Last name"
                required={AddPAdmin.form.inputs.lname.required}
              />
            </Form.Group>
            <Form.Input
              fluid
              name="email"
              type="email"
              value={formValues?.email}
              onChange={changeHandler}
              label={AddPAdmin.form.inputs.email.label}
              placeholder="Enter member's email"
              required={AddPAdmin.form.inputs.email.required}
            />
            <Form.Input
              fluid
              name="confirmEmail"
              label="Confirm Email"
              value={formValues?.confirmEmail}
              onChange={changeHandler}
              error={
                formValues?.confirmEmail
                  ? formValues?.confirmEmail !== formValues?.email &&
                    "Both Email should be same"
                  : false
              }
              placeholder="Confirm Email"
              required={true}
            />

            <Form.Group widths={"18"}>
              <Form.Select
                label="Country Code"
                name="countryCode"
                value={formValues?.countryCode}
                onChange={(e, data) => {
                  setFormValues({ ...formValues, [data.name]: data.value });
                }}
                placeholder="country code"
                options={countryCodeSemantic}
              ></Form.Select>
              <Form.Input
                type="text"
                fluid
                label={AddPAdmin.form.inputs.mobile.label}
                name="mobileNum"
                value={formValues?.mobileNum}
                onChange={changeHandler}
                error={
                  formValues?.mobileNum
                    ? formValues?.mobileNum.length > 10 &&
                      "Mobile Number should be 10 digits only"
                    : false
                }
                required={AddPAdmin.form.inputs.mobile.required}
                placeholder="Enter mobile number"
                width={12}
              />
            </Form.Group>

            <Form.Input
              fluid
              placeholder="Enter Linked In URL"
              name="linkedIn_URL"
              value={formValues?.linkedIn_URL}
              onChange={changeHandler}
              required={false}
              label={"LinkedIn URL"}
            />
            <Form.Group widths={"equal"}>
              {" "}
              <Form.Checkbox
                toggle
                name="isAdmin"
                checked={formValues?.isAdmin}
                onChange={(e, data) => {
                  setFormValues({ ...formValues, [data.name]: data.checked });
                }}
                label="Zyvka Admin"
              ></Form.Checkbox>
              {userState?.role === "superAdmin" && (
                <Form.Checkbox
                  toggle
                  name="isSuperAdmin"
                  checked={formValues?.isSuperAdmin}
                  onChange={(e, data) => {
                    setFormValues({ ...formValues, [data.name]: data.checked });
                  }}
                  label="Zyvka Super Admin"
                ></Form.Checkbox>
              )}
            </Form.Group>

            {zyvkaState?.isErrors && (
              <ErrorMessage
                header={"Error Message"}
                content={zyvkaState?.FormErrorMessage?.zyvkaMemberCreate}
              />
            )}

            <Form.Button
              type="submit"
              // floated="right"
              style={{
                backgroundColor: "#6855f6",
                color: "white",
                marginTop: "10px",
              }}
            >
              {AddPAdmin.form.inputs.submit.label}
            </Form.Button>
          </Form>
        </Card.Content>
      </Card>
    </div>
  );
};

export default AddZyvkaMember;
