import axios from "axios";
import {
  createPartnerError,
  createPartnerStart,
  createPartnerSuccess,
} from "../features/partnersSlice";
const API = axios.create({ baseURL: process.env.REACT_APP_SERVER_URL });

export const createPartner = async (partner, dispatch, form) => {
  dispatch(createPartnerStart());
  try {
    const result = await API.post("api/partner/createPartner", partner, {
      withCredentials: true,
    });
    dispatch(createPartnerSuccess(result.data));
    form.resetFields();
  } catch (error) {
    console.log(error);
    dispatch(createPartnerError(error.response));
  }
};
