const Currency = [
  {
    key: 1,
    text: "$",
    value: "USD",
  },
  {
    key: 2,
    text: "₹",
    value: "INR",
  },
  {
    key: 3,
    text: "€",
    value: "EURO",
  },
];

export const CurrencySymbols = {
  INR: "₹",
  USD: "$",
  EURO: "€",
};
export const ctcMap = {
  INR: "L",
  USD: "K",
  EURO: "K",
};
export default Currency;
export const defaultCurrency = "INR";
