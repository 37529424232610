import React from "react";
import { Message } from "semantic-ui-react";

const ErrorMessage = ({ header, content }) => {
  return (
    <Message negative>
      <Message.Header>{header}</Message.Header>
      <p>{content}</p>
    </Message>
  );
};

export default ErrorMessage;
