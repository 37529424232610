import { useSelector } from 'react-redux';
import {
    Chat,
    useCreateChatClient,
} from 'stream-chat-react';
import "./ChatApp.css"
// import { UserState } from "../../features/userSlice"
import ChannelListContainer from './ChannelListContainer';
import ChannelContainer from './ChannelContainer';
import { useState } from 'react';


import 'stream-chat-react/dist/css/v2/index.css';
import { useLocation } from 'react-router-dom';
const AdminChat = () => {
    const location = useLocation();
    const user = location?.state?.useer;
    console.log("dsfsg",user);
    
    const apiKey = `${process.env.REACT_APP_StreamChat_PROJECT_ID}`;
    const userId = user?.userID;
    const token = user?.chatToken;

    const [createType, setCreateType] = useState('');
    const [isCreating, setIsCreating] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const client = useCreateChatClient({
        apiKey,
        tokenOrProvider: token,
        userData: { id: userId },
    });

    if (!client) return <div>Loading...</div>;

    return <>

        <div
            style={{
                display: "flex",
                height: "83vh",
                marginTop: "30px"
            }}
        >
            <div className="app__wrapper">
                <Chat client={client} theme="team light">
                    <ChannelListContainer
                        isCreating={isCreating}
                        setIsCreating={setIsCreating}
                        setCreateType={setCreateType}
                        setIsEditing={setIsEditing}
                    />
                    <ChannelContainer
                        isCreating={isCreating}
                        setIsCreating={setIsCreating}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        createType={createType}
                    />
                </Chat>
            </div>

        </div>
    </>
};

export default AdminChat