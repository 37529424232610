import React from "react";
import { Message } from "semantic-ui-react";

const SuccessMessage = ({ header, content }) => {
  return (
    <Message positive>
      <Message.Header>{header}</Message.Header>
      <p>{content}</p>
    </Message>
  );
};

export default SuccessMessage;
