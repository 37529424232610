import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    fetchPositionAnalytics,
    positionAnalytics,
} from "../features/metabaseSlice";
import {
    getPositionAnalyticLocalCache,
} from "../cache/localStorage";

const MetabaseAnalyticsPosition = () => {
    const [url, setUrl] = useState("");
    const dispatch = useDispatch();
    const positionAnalytic = useSelector(positionAnalytics);
    useEffect(() => {
        const getData = async () => {
            const posUrl = getPositionAnalyticLocalCache();
            if (!posUrl) {
                dispatch(fetchPositionAnalytics());
                setUrl(positionAnalytic);
            } else {
                setUrl(posUrl);
            }
        }
        getData()
    }, [
        dispatch,
        positionAnalytic,
        url, setUrl
    ]);

    return (
        <div style={{ width: "98%", maxWidth: "80vw" }} >
            <iframe
                loading="eager"
                src={url}
                style={{
                    width: "100%",
                    height: "100%",
                    minHeight: "80vh",
                    border: "2px solid #4C577370"
                }}
                title="Position Analytics"
            />
        </div>
    )
};

export default MetabaseAnalyticsPosition;
