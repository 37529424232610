import React from "react";
import { Alert } from "antd";
export const AlertErrorMessage = ({ message }) => {
  return (
    <div
      style={{
        padding: "10px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <p
        style={{
          fontSize: "12px",
          color: "red",
          letterSpacing: "3px",
          background: "pink",
          padding: "5px",
        }}
      >
        Error : {message || "Some Error Occured"}
      </p>
      {/* <Alert message={`Error : ${message}`} type="error" showIcon closable /> */}
    </div>
  );
};
