import React, { useState, useEffect } from "react";
// import backendimg from "../assets/backend-dev.png";
import {
  Button,
  Typography,
  message,
  Input,
  Form,
  Select,
  Spin,
  Row,
  Col,
  List,
  Drawer,
  Divider,
} from "antd";
import {
  FireOutlined,
  LaptopOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import { getAllJobs } from "../api/auth";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  selectMyJobs,
  ZyvkaMemberState,
  selectHotJobs,
  selectNonTechJobs,
  selectTechJobs,
} from "../features/ZyvkaMemberSlice";
import ViewJobDetails from "./ViewJobDetails";
import { useParams } from "react-router-dom";
import {
  fetchMyJobs,
  GetHotJobs,
  GetTechJobs,
  GetNonTechJobs,
  getSearchedJob,
} from "../api/ZyvkaMember";
import { filterJobs } from "../api/ZyvkaMember";
import { fetchAllSkills, getAllJobProfiles } from "../api/auth";
import { UserState } from "../features/userSlice";

const { Title } = Typography;
const { Option } = Select;
const { Search } = Input;
//Prints search value (only for testing purpose)
const onSearch = (value) => message.info(`Searched for: ${value}`);
//Search Bar ends

const exp = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26, 27, 28, 29, 30,
];

const statusMenu = [
  {
    label: "open",
    value: "open",
  },
  {
    label: "Candidate Offered",
    value: "candidate offered",
  },
  {
    label: "Candidate Accepted",
    value: "candidate accepted",
  },
  {
    label: "Closed",
    value: "closed",
  },
];

const ClosedJobs = () => {
  const [form] = Form.useForm();
  const [skillOption, setSkillOption] = useState([]);

  const [flag, setFlag] = useState(false);
  const zyvkaState = useSelector(ZyvkaMemberState);
  const userState = useSelector(UserState);
  const dispatch = useDispatch();
  const [filterOpen, setFilterOpen] = useState(false);

  const showDrawer = () => {
    setOpenDrawer(true);
    // setOpen((open) => !open);
  };
  const onClose = () => {
    setOpenDrawer(false);
    // setOpen((open) => !open);
  };

  //More Details Drawer
  const [opendrawer, setOpenDrawer] = useState(false);
  //Drawer Ends

  const ChangeFlag = () => {
    setFlag((flag) => !flag);
  };

  const fetchHotJobs = async () => {
    try {
      await GetHotJobs({ myjobs: false, internalJobs: false, active_status: 4 }, dispatch);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchTechJobs = async () => {
    try {
      await GetTechJobs({ myjobs: false, internalJobs: false, active_status: 4 }, dispatch);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchNonTechJobs = async () => {
    try {
      await GetNonTechJobs({ myjobs: false, internalJobs: false, active_status: 4 }, dispatch);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchJobProfiles = async () => {
    try {
      await getAllJobProfiles(dispatch);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchSkills = async () => {
    try {
      await fetchAllSkills(dispatch, setSkillOption);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // fetchJobs(id);
    fetchHotJobs();
    fetchNonTechJobs();
    fetchTechJobs();
    fetchJobProfiles();
    fetchSkills();
  }, [flag]);
  const onFinish = async (values) => {
    await filterJobs(
      {
        job_title: values.job_title,
        min_experience_req: values.min_experience_req,
        max_experience_req: values.max_experience_req,
        status: values.status,
        skills_req: values.skills_req,
        active_status: 4,
        myjobs: false,
        internalJobs: false,
      },
      dispatch
    );
    setFilterOpen(true);
  };
  const AllSkills = userState.gSkills;
  const AllProfiles = userState.gProfile;

  const onFormLayoutChange = ({ size }) => {
    setComponentSize(size);
  };
  const [componentSize, setComponentSize] = useState("default");

  const JobData = useSelector(selectMyJobs);
  const HotJobsData = useSelector(selectHotJobs);
  const TechJobsData = useSelector(selectTechJobs);
  const NonTechJobsData = useSelector(selectNonTechJobs);
  const onSearch = async (value) => {
    if (value === "") {
      setFilterOpen(false);
      setFlag((flag) => !flag);
    } else {
      await getSearchedJob(
        { job: value, myjobs: false, internalJobs: false, active_status: 4 },
        dispatch
      );
      setFilterOpen(true);
    }
  };
  return (
    <div style={{ height: "100vh" }}>
      <br></br>
      <div></div>

      <Drawer
        width={320}
        breakpoint="lg"
        placement="right"
        onClose={onClose}
        visible={opendrawer}
        title="Job Filter"
        initialValues={{
          size: componentSize,
        }}
        onValuesChange={onFormLayoutChange}
        size={componentSize}
      >
        <div style={{ width: "100%", padding: "10px" }}>
          <Form
            form={form}
            labelCol={{
              span: 4,
            }}
            style={{ width: "100%" }}
            layout="horizontal"
            onFinish={onFinish}
          >
            <label>Min Experience </label>
            <Form.Item name="min_experience_req">
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
              >
                {exp.map((data, index) => {
                  return (
                    <Option key={index} value={data}>
                      {data}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <label>Max Experience </label>
            <Form.Item name="max_experience_req">
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
              >
                {exp.map((data, index) => {
                  return (
                    <Option key={index} value={data}>
                      {data}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <label>Profile</label>
            <Form.Item name="job_title">
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
              >
                {AllProfiles.map((data, idx) => {
                  return (
                    <>
                      <Option key={idx} value={data.profile}>
                        {data.profile}
                      </Option>
                    </>
                  );
                })}
              </Select>
            </Form.Item>
            <label>Skills</label>
            <Form.Item name="skills_req">
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
              >
                {AllSkills.map((data, idx) => {
                  return (
                    <>
                      <Option key={idx} value={data.skill}>
                        {data.skill}
                      </Option>
                    </>
                  );
                })}
              </Select>
            </Form.Item>
            <label>Status</label>
            <Form.Item name="status">
              <Select
                style={{
                  width: "100%",
                }}
                options={statusMenu}
              ></Select>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Filter
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                style={{ width: "100%" }}
                onClick={() => {
                  form.resetFields();
                  setFlag((flag) => !flag);
                  setFilterOpen(false);
                }}
              >
                Reset All Filters
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Drawer>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "10px",
        }}
        className="searchbox"
      >
        <Button onClick={showDrawer}>Filter</Button>
        <Search
          placeholder="input search text"
          allowClear
          autoFocus
          autoSize
          enterButton="Search"
          onSearch={onSearch}
          onChange={(e) => {
            if ((e.target.value = "")) {
              setFlag((flag) => !flag);
              setFilterOpen(false);
            }
          }}
          style={{
            maxWidth: 400,
          }}
        />
      </div>

      {zyvkaState.isPending === true ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          {/* Hot Jobs Section         */}
          {filterOpen === false ? (
            <>
              {" "}
              <Divider orientation="left">
                <Title level={3}>
                  <FireOutlined style={{ color: "orange" }} /> Hot Jobs
                </Title>
              </Divider>
              <div className="job-carousel">
                {HotJobsData.map((data) => {
                  return (
                    <div>
                      {/* // <div style={{minHeight:"350px" ,minWidth:"270px", marginLeft:"10px", marginRight:"10px"}} key={data.id}> */}
                      <ViewJobDetails
                        data={data}
                        width={370}
                        ChangeFlag={ChangeFlag}
                        skills={skillOption}
                        closed={true}
                      />
                    </div>
                  );
                })}
              </div>
              <Divider orientation="left">
                <Title level={3}>
                  <LaptopOutlined style={{ color: "green" }} /> Tech Jobs
                </Title>
              </Divider>
              <div className="job-carousel">
                {TechJobsData.map((data) => {
                  return (
                    <div>
                      {/* // <div style={{minHeight:"350px" ,minWidth:"270px", marginLeft:"10px", marginRight:"10px"}} key={data.id}> */}
                      <ViewJobDetails
                        data={data}
                        width={370}
                        ChangeFlag={ChangeFlag}
                        skills={skillOption}
                        closed={true}
                      />
                    </div>
                  );
                })}
              </div>
              <Divider orientation="left">
                <Title level={3}>
                  <BarChartOutlined style={{ color: "blue" }} /> Non-Tech Jobs
                </Title>
              </Divider>
              <div className="job-carousel">
                {NonTechJobsData.map((data) => {
                  return (
                    <div>
                      {/* // <div style={{minHeight:"350px" ,minWidth:"270px", marginLeft:"10px", marginRight:"10px"}} key={data.id}> */}
                      <ViewJobDetails
                        data={data}
                        width={350}
                        ChangeFlag={ChangeFlag}
                        skills={skillOption}
                        closed={true}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <Row>
              <Col flex={1.5}></Col>
              <Col flex={7}>
                <List
                  grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 3,
                    xl: 3,
                    xxl: 3,
                  }}
                  dataSource={JobData}
                  renderItem={(item) => (
                    <List.Item>
                      <ViewJobDetails
                        data={item}
                        ChangeFlag={ChangeFlag}
                        skills={skillOption}
                        closed={true}
                      />
                      {/* <JobCard data={item} /> */}

                      {/* <Card hoverable bordered={false} title={item.title} style={{borderRadius:"5px"}}>{item.description}</Card> */}
                    </List.Item>
                  )}
                />
              </Col>
              <Col flex={1.5}></Col>
            </Row>
          )}

          {/* <Button onClick={()=>{
            document.getElementsByClassName('job-carousel').scrollLeft -= 100;
            }}>Right</Button> */}

          {/* All Jobs section */}
        </>
      )}
    </div>
  );
};

export default ClosedJobs;
