import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

import {
  Row,
  Col,
  Card,
  Button,
  Descriptions,
  Avatar,
  Radio,
  Statistic,
  Badge,
  notification,
} from "antd";
import { Form, Icon, Modal } from "semantic-ui-react";
import { FireOutlined, BookOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { countryCodeSemantic } from "../Data/CountryCode";
import { selectUser, UserState } from "../features/userSlice";

import {
  UserOutlined,
  TeamOutlined,
  FileDoneOutlined,
  LinkedinOutlined,
  LinkedinFilled,
} from "@ant-design/icons";
import { CSVLink } from "react-csv";

import BgProfile from "../assets/images/bg-profile.jpg";
import { useDispatch } from "react-redux";
import {
  fetchHotJobCountStats,
  fetchJobCountStats,
  fetchQCAcceptedClosedCountStats,
  fetchTotalCandidatesCountStats,
  updateUserProfile,
  getApplicantsDataForExcel,
  forgotPass,
  updateProfilePicture,
  fetchTotalOffersClosedCountStats,
} from "../api/auth";
import ErrorMessage from "../Error/ErrorMessage";
import { profilePicURL } from "../utils/functions";
// import { AlertErrorMessage } from "../Error/AlertErrorMessage";
// import TextArea from "antd/lib/input/TextArea";

// const countryCodeSelector = ({ defaultCode }) => (
//   <Form.Item name="cCode" noStyle>
//     <Select options={countryCode} defaultValue={defaultCode}></Select>
//   </Form.Item>
// );

const Profile = () => {
  const user = useSelector(selectUser);
  const userState = useSelector(UserState);
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const [userDetails, setUserDetails] = useState({
    firstName: user?.firstName,
    lastName: user?.lastName,
    profile_info: user?.profile_info,
    email: user?.email,
    linkedIn_URL: user?.linkedIn_URL,
    country_code: user?.country_code,
    mobileNum: user?.mobileNum,
    password: "",
  });

  /* Data For Excel Sheet */
  const fetchData = async () => {
    if (
      userState.role === "zyvkaAdmin" ||
      userState.role === "zyvkaUser" ||
      userState?.role === "superAdmin"
    ) {
      await fetchQCAcceptedClosedCountStats(dispatch, refresh);
      await fetchJobCountStats(dispatch, refresh);
      await fetchHotJobCountStats(dispatch, refresh);
    }
    await fetchTotalCandidatesCountStats(dispatch, refresh);
    //await fetchQCAcceptedClosedCountStats(dispatch, refresh);
    await fetchTotalOffersClosedCountStats(dispatch, refresh);
    await getApplicantsDataForExcel(dispatch);

    setRefresh(false);
  };

  useEffect(() => {
    fetchData();
  }, [refresh]);
  const pencil = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
        className="fill-gray-7"
      ></path>
      <path
        d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
        className="fill-gray-7"
      ></path>
    </svg>,
  ];

  //EDIT PROFILE MODAL FUNCTIONS
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setUserDetails({
      firstName: user?.firstName,
      lastName: user?.lastName,
      profile_info: user?.profile_info,
      email: user?.email,
      linkedIn_URL: user?.linkedIn_URL,
      country_code: user?.country_code,
      mobileNum: user?.mobileNum,
      password: "",
    });
  };
  const editProfile = async (e) => {
    e.preventDefault();
    await updateUserProfile(
      {
        email: userDetails?.email,
        password: userDetails?.password,
        firstName: userDetails?.firstName,
        lastName: userDetails?.lastName,
        mobileNum: userDetails?.mobileNum,
        country_code: userDetails?.country_code,
        profile_info: userDetails?.profile_info,
        linkedIn_URL: userDetails?.linkedIn_URL,
        id: user.id,
      },
      dispatch,
      handleCancel
    );
  };
  const editProfileFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const fileUploader = (e) => {
    const tmp = e.target.files[0];
    const tmp1 = URL.createObjectURL(e.target.files[0]);
    setPreviewImage(tmp1);
    setFile(tmp);
  };

  const profilePicUploadHandle = async () => {
    const formdata = new FormData();
    formdata.append("file", file);
    await updateProfilePicture(formdata, dispatch);
    setPreviewImage(null);
    setFile(null);
  };

  /* Data Headers/Columns for excel file  */
  /* 1. For Applicant/Candidate Data on Excel */
  const HeadersApplicantDataExcel = [
    {
      label: "Application ID",
      key: "applicationID",
    },
    {
      label: "Candidate ID",
      key: "applicantID",
    },

    {
      label: "First Name",
      key: "firstName",
    },
    {
      label: "Last Name",
      key: "lastName",
    },
    {
      label: "Email",
      key: "email",
    },
    {
      label: "Position ID",
      key: "jobID",
    },
    {
      label: "Posting Date",
      key: "jobPostDate",
    },
    {
      label: "Position Title",
      key: "job_title",
    },
    {
      label: "Company",
      key: "company_name",
    },
    {
      label: "Country",
      key: "country",
    },
    {
      label: "City",
      key: "city",
    },
    {
      label: "Remarks",
      key: "remarks",
    },
    {
      label: "Current Status",
      key: "status",
    },
    {
      label: "Candidate Highlights",
      key: "applicant_story",
    },
    {
      label: "Resume URL",
      kay: "resume_urn",
    },
    {
      label: "Date Applied",
      key: "date",
    },
  ];
  const resetMyPassword = async () => {
    try {
      await forgotPass({ email: user?.email }, dispatch);
    } catch (error) {
      console.log(error);
    }
  };

  const changeHandler = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };
  return (
    <>
      <div className="profile-nav-bg-top">
        <div className="profile-nav-bg-container">
          <div className="profile-nav-bg" >
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
          </div>
        </div>
        <Card
          className="card-profile-head"
          bodyStyle={{ display: "none" }}
          title={
            <Row
              justify="space-between"
              align="middle"
              gutter={[24, 0]}
              style={{ display: "flex", flexWrap: "wrap", rowGap: "30px" }}
            >
              <Col span={24} md={12} className="col-info">
                <Avatar.Group>
                  <input
                    type="file"
                    onChange={fileUploader}
                    accept="image/*"
                    id="profilepic"
                    style={{ cursor: "pointer", display: "none" }}
                  ></input>

                  <label
                    htmlFor="profilepic"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <Avatar
                      size={80}
                      style={{
                        backgroundColor: "#6855f6",
                        cursor: "pointer",
                      }}
                      id="image"
                      icon={
                        user.pfp_urn === null && previewImage === null ? (
                          <UserOutlined />
                        ) : (
                          <img
                            src={
                              previewImage
                                ? previewImage
                                : profilePicURL(user.pfp_urn)
                            }
                            style={{
                              objectFit: "cover",
                            }}
                            alt="prof-pic"
                          ></img>
                        )
                      }
                    />
                    {previewImage && (
                      <button
                        style={{
                          fontSize: "10px",
                          padding: "1px",
                          border: "none",
                          marginTop: "10px",
                          cursor: "pointer",
                          background: "#6855f6",
                          color: "white",
                          borderRadius: "10px",
                        }}
                        onClick={() => {
                          profilePicUploadHandle();
                        }}
                      >
                        save
                      </button>
                    )}
                  </label>

                  <div className="avatar-info">
                    <h4 className="font-semibold m-0">
                      {user.firstName + " " + user.lastName}
                    </h4>
                    <p>{user.email}</p>
                  </div>
                </Avatar.Group>
              </Col>
              <Col
                span={24}
                md={8}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Radio.Group>
                  <Radio.Button value="a" onClick={showModal}>
                    EDIT PROFILE
                  </Radio.Button>
                  <Radio.Button
                    value="a"
                    onClick={() => {
                      setRefresh(true);
                    }}
                  >
                    <Icon name="refresh" />
                  </Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
          }
        ></Card>

        {/* This section has been divided into two parts, on the left is the profile details section and to the right are the statistics and other relevant links */}

        {/* SECTION 1 */}
        <Row gutter={[24, 16]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Card
              bordered={false}
              title={<h6 className="font-semibold m-0">Profile Information</h6>}
              className="header-solid h-full card-profile-information profile-card"
              extra={
                <Button type="link" onClick={showModal}>
                  {pencil}
                </Button>
              }
              bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
            >
              <pre
                className="text-dark"
                style={{ fontFamily: "inherit", "white-space": "pre-wrap" }}
              >
                {user?.profile_info}
              </pre>
              <hr className="my-25" />
              <Descriptions title="Profile Details">
                <Descriptions.Item label="Full Name" span={3}>
                  {user.firstName + " " + user.lastName}
                </Descriptions.Item>
                <Descriptions.Item label="Mobile" span={3}>
                  {user.country_code + " " + user.mobileNum}
                </Descriptions.Item>
                <Descriptions.Item label="Email" span={3}>
                  {user.email}
                </Descriptions.Item>
                <Descriptions.Item label="Social" span={3}>
                  {user?.linkedIn_URL ? (
                    <a
                      href={user?.linkedIn_URL}
                      target="_blank"
                      rel="noreferrer"
                      className="mx-7 px-7"
                    >
                      {
                        <LinkedinFilled
                          style={{ color: "#0A66C2", fontSize: "17px" }}
                        />
                      }
                    </a>
                  ) : (
                    <span
                      style={{
                        fontWeight: "lighter",
                        fontSize: "13px",
                      }}
                    >
                      NOT PROVIDED
                    </span>
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>

          {/* SECTION 2 */}

          <Col xs={24} sm={24} md={12} lg={12} xl={16}>
            <div>
              <Row gutter={[24, 16]}>
                {/* {(userState?.role === "zyvkaAdmin" ||
                  userState?.role === "zyvkaUser" ||
                  userState?.role === "superAdmin") && (
                  <Col xs={24} sm={12} md={24} lg={12} xl={12}>
                    <Link to={"/actionrequired"}>
                      <Card
                        bodyStyle={{}}
                        hoverable={true}
                        className="profile-card"
                      >
                        <p style={{ color: "gray" }}>Action required</p>
                        <Badge>
                          <a href="/actionrequired">View list</a>
                        </Badge>
                      </Card>
                    </Link>
                  </Col> 
                )} */}
                <Col xs={24} sm={12} md={24} lg={12} xl={24}>
                  <Link to={"/dashboard/alljobs"}>
                    <Card hoverable={true} className="profile-card">
                      <Statistic
                        title="Total Active Jobs"
                        loading={userState?.isPending}
                        value={userState.stats?.jobs}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                        prefix={<BookOutlined />}
                      />
                    </Card>
                  </Link>
                </Col>
              </Row>
              <br></br>
              <Row gutter={[24, 16]}>
                <Col xs={24} sm={12} md={24} lg={12} xl={12}>
                  <Link to={"/dashboard/alljobs"}>
                    <Card hoverable={true} className="profile-card">
                      <Statistic
                        title="Total Hot Jobs"
                        value={userState?.stats?.hotJobs}
                        loading={userState.isPending}
                        valueStyle={{
                          color: "#de0a26",
                        }}
                        prefix={<FireOutlined />}
                      />
                    </Card>
                  </Link>
                </Col>
                <Col xs={24} sm={12} md={24} lg={12} xl={12}>
                  <Link to={"/dashboard/allApplicants"}>
                    <Card hoverable={true} className="profile-card">
                      <Statistic
                        title="Total Candidates"
                        loading={userState.isPending}
                        value={userState?.stats.AllApplicants}
                        valueStyle={{
                          color: "#0066ff",
                        }}
                        prefix={<TeamOutlined />}
                      />
                    </Card>
                  </Link>
                </Col>
              </Row>
              <br></br>
              <Row gutter={[24, 16]}>
                <Col xs={24} sm={12} md={24} lg={12} xl={12}>
                  <Link to={"/dashboard/closedjob"}>
                    <Card hoverable={true} className="profile-card">
                      <Statistic
                        title="Total Offers closed"
                        loading={userState.isPending}
                        value={userState.stats?.closedOffers}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                        prefix={<FileDoneOutlined />}
                      />
                    </Card>
                  </Link>
                </Col>
                <Col xs={24} sm={12} md={24} lg={12} xl={12}>
                  <Card hoverable={true} className="profile-card">
                    <p style={{ color: "gray" }}>Get Your Applied Candidates</p>
                    <CSVLink
                      data={userState.ApplicantsDataExcel}
                      Headers={HeadersApplicantDataExcel}
                      filename={"Applicant.csv"}
                      className="btn btn-primary"
                      target="_blank"
                      style={{ color: "green" }}
                    >
                      Download CSV
                    </CSVLink>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <br></br>

        <Modal
          dimmer="blurring"
          size="tiny"
          open={isModalOpen}
          onClose={() => {
            handleCancel();
          }}
        >
          <Modal.Header>Edit Profile</Modal.Header>

          <Modal.Content>
            <Form onSubmit={editProfile}>
              <Form.Input
                fluid
                label="First Name"
                name="firstName"
                required={true}
                placeholder="Enter First Name"
                value={userDetails?.firstName}
                onChange={changeHandler}
              ></Form.Input>
              <Form.Input
                fluid
                label="Last Name"
                name="lastName"
                required={true}
                placeholder="Enter Last Name"
                value={userDetails?.lastName}
                onChange={changeHandler}
              ></Form.Input>
              <Form.TextArea
                fluid
                label="Description"
                name="profile_info"
                placeholder="Enter Your Description"
                value={userDetails?.profile_info}
                onChange={changeHandler}
              ></Form.TextArea>
              <Form.Input
                fluid
                type="email"
                required={true}
                label="Email"
                name="email"
                placeholder="Enter your Email"
                value={userDetails?.email}
                onChange={changeHandler}
              ></Form.Input>
              <Form.Input
                fluid
                type="text"
                label="Linkedin"
                name="linkedIn_URL"
                placeholder="Enter your linkedin profile url"
                value={userDetails?.linkedIn_URL}
                onChange={changeHandler}
              ></Form.Input>
              <Form.Group widths={"18"}>
                <Form.Select
                  label="Country Code"
                  required={true}
                  name="country_code"
                  placeholder="country code"
                  options={countryCodeSemantic}
                  value={userDetails?.country_code}
                  onChange={(e, data) => {
                    setUserDetails({ ...userDetails, [data.name]: data.value });
                  }}
                ></Form.Select>
                <Form.Input
                  type="text"
                  fluid
                  required={true}
                  label="Mobile Number"
                  name="mobileNum"
                  placeholder="Mobile Number"
                  error={
                    userDetails?.mobileNum
                      ? userDetails?.mobileNum.length > 10 &&
                      "Mobile Number should be 10 digits only"
                      : false
                  }
                  width={12}
                  value={userDetails?.mobileNum}
                  onChange={changeHandler}
                />
              </Form.Group>
              <Form.Input
                fluid
                type="password"
                name="password"
                required={true}
                label="Current Password"
                placeholder="Enter current password to save changes"
                value={userDetails?.password}
                onChange={changeHandler}
              ></Form.Input>
              <p style={{ display: "flex" }}>
                Reset Password ?{" "}
                <p
                  style={{
                    color: "blue",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => resetMyPassword()}
                >
                  {" "}
                  Click here
                </p>
              </p>
              {userState?.isErrors && (
                <ErrorMessage
                  header="Error Message"
                  content={userState?.FormErrorMessage?.updateProfile}
                />
              )}

              <Form.Button
                style={{
                  width: "100%",
                  backgroundColor: "#6855f6",
                  color: "#ffffff",
                }}
                type="submit"
              >
                Save Edits
              </Form.Button>
              <Form.Button
                color="danger"
                style={{ width: "100%" }}
                onClick={handleCancel}
              >
                Cancel
              </Form.Button>
            </Form>
          </Modal.Content>
        </Modal>
      </div>
    </>
  );
};

export default Profile;
