import React, { useEffect, useState } from "react";
import { Button, Col, Space, Table, Tag, Typography, Spin, Input } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";
import { UserState } from "../features/userSlice";
import { useSelector } from "react-redux";
import { fetchQueries } from "../api/auth";
import { useDispatch } from "react-redux";
import axios from "axios";
import { getAskedQuery } from "../api/ZyvkaMember";
import { ZyvkaMemberState } from "../features/ZyvkaMemberSlice";

const { Column, ColumnGroup } = Table;

const { Title } = Typography;

export const Queries = () => {
  //   const [applicants, setApplicants] = useState([]);
  const userState = useSelector(UserState);
  const dispatch = useDispatch();
  const zyvkaState = useSelector(ZyvkaMemberState);
  const applicants = userState?.queries;
  const queriesAsked = zyvkaState?.queryOnMyJob;

  const fetchData = async () => {
    try {
      await fetchQueries(dispatch);
      await getAskedQuery({ id: userState?.userInfo.id }, dispatch);

      //   setApplicants();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const navigate = useNavigate();

  const clickHandler = (id1) => {
    const url = `/dashboard/applicant/job/${id1}`;
    navigate(url);
  };

  // this is for search
  const [searchValue1, setSearchValue1] = useState("");
  const [searchValue2, setSearchValue2] = useState("");

  return (
    <>
      <div style={{ overflow: "scroll" }} className="tableDiv">
        <Title
          style={{
            fontSize: "25px",
            marginBottom: "30px",
          }}
        >
          Queries on My Candidates :
        </Title>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flexWrap: "wrap",
            gap: "10px",
          }}
          // className="searchbox"
        >
          <Space direction="horizontal" wrap={true}>
            <Input
              placeholder="Search Here"
              style={{
                minWidth: 200,
                maxWidth: 400,
                paddingLeft: 10,
              }}
              onChange={(e) => {
                setSearchValue1(e.target.value);
              }}
            />
            <Button style={{ width: 80 }}>Search</Button>
          </Space>
        </div>

        <Table
          scroll={{ x: true }}
          dataSource={applicants}
          style={{ whiteSpace: "pre" }}
          pagination={{ pageSize: 5 }}
          loading={userState?.isPending}
          // title="My Candidates's Queries"
          bordered={true}
        >
          <Column
            title="Name"
            key="name"
            render={(_, record) => (
              <Space size="middle" key={record.id}>
                {/* {console.log(record)} */}
                {record.applicant.firstName + " " + record.applicant.lastName}
              </Space>
            )}
          />
          <Column
            title="email"
            key="email"
            filteredValue={[searchValue1]}
            onFilter={(value, record) => {
              return (
                String(record?.applicant?.firstName)
                  ?.toLowerCase()
                  .includes(value.toLocaleLowerCase()) ||
                String(record?.applicant?.lastName)
                  ?.toLowerCase()
                  .includes(value.toLocaleLowerCase()) ||
                String(record?.applicant?.email)
                  ?.toLowerCase()
                  .includes(value.toLocaleLowerCase()) ||
                String(record?.applicant?.mobileNum)
                  ?.toLowerCase()
                  .includes(value.toLocaleLowerCase()) ||
                String(record?.current_status)
                  ?.toLowerCase()
                  .includes(value.toLocaleLowerCase())
              );
            }}
            render={(_, record) => (
              <Space size="middle" key={record.id}>
                {/* {console.log(record)} */}
                {record.applicant.email}
              </Space>
            )}
          />
          <Column
            title="Mobile No"
            key="mobileNum"
            render={(_, record) => (
              <Space size="middle" key={record.id}>
                {/* {console.log(record)} */}
                {record.applicant.mobileNum}
              </Space>
            )}
          />

          <Column
            title="Status"
            key="job_status"
            render={(_, record) => (
              <Space size="middle" key={record.id}>
                {/* {console.log(record)} */}
                <Button style={{ color: "Blue", cursor: "pointer" }}>
                  {record.current_status}
                </Button>
              </Space>
            )}
          />

          <Column
            title="Action"
            key="action"
            render={(_, record) => (
              <Space
                size="middle"
                key={record.id}
                style={{ cursor: "pointer", fontSize: "17px" }}
                onClick={() => clickHandler(record.id)}
              >
                <EyeOutlined />
                {/* {console.log(record)} */}
                {/* <Button style={{ color: "red", cursor: "pointer" }}></Button> */}
              </Space>
            )}
          />
        </Table>

        {(userState.role === "zyvkaAdmin" ||
          userState.role === "zyvkaUser" ||
          userState?.role === "superAdmin") && (
          <>
            <Title
              style={{
                fontSize: "25px",
                // marginBottom: "20px",
                color: "black",
              }}
            >
              Queries Asked :
            </Title>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flexWrap: "wrap",
                gap: "10px",
              }}
              // className="searchbox"
            >
              <Space direction="horizontal" wrap={true}>
                <Input
                  placeholder="Search Here"
                  style={{
                    minWidth: 200,
                    maxWidth: 400,
                    paddingLeft: 10,
                  }}
                  onChange={(e) => {
                    setSearchValue2(e.target.value);
                  }}
                />
                <Button style={{ width: 80 }}>Search</Button>
              </Space>
            </div>

            <Table
              scroll={{ x: true }}
              dataSource={queriesAsked}
              style={{ whiteSpace: "pre" }}
              loading={zyvkaState?.isPending}
              pagination={{ pageSize: 5 }}
              bordered={true}
            >
              <Column
                title="Name"
                key="name"
                render={(_, record) => (
                  <Space size="middle" key={record.id}>
                    {/* {console.log(record)} */}
                    {record.applicant.firstName +
                      " " +
                      record.applicant.lastName}
                  </Space>
                )}
              />
              <Column
                title="email"
                key="email"
                filteredValue={[searchValue2]}
                onFilter={(value, record) => {
                  return (
                    String(record?.applicant?.firstName)
                      ?.toLowerCase()
                      .includes(value.toLocaleLowerCase()) ||
                    String(record?.applicant?.lastName)
                      ?.toLowerCase()
                      .includes(value.toLocaleLowerCase()) ||
                    String(record?.applicant?.email)
                      ?.toLowerCase()
                      .includes(value.toLocaleLowerCase()) ||
                    String(record?.applicant?.mobileNum)
                      ?.toLowerCase()
                      .includes(value.toLocaleLowerCase()) ||
                    String(record?.current_status)
                      ?.toLowerCase()
                      .includes(value.toLocaleLowerCase())
                  );
                }}
                render={(_, record) => (
                  <Space size="middle" key={record.id}>
                    {/* {console.log(record)} */}
                    {record.applicant.email}
                  </Space>
                )}
              />
              <Column
                title="Mobile No"
                key="mobileNum"
                render={(_, record) => (
                  <Space size="middle" key={record.id}>
                    {/* {console.log(record)} */}
                    {record.applicant.mobileNum}
                  </Space>
                )}
              />

              <Column
                title="Status"
                key="job_status"
                render={(_, record) => (
                  <Space size="middle" key={record.id}>
                    {/* {console.log(record)} */}
                    <Button style={{ color: "Blue", cursor: "pointer" }}>
                      {record.current_status}
                    </Button>
                  </Space>
                )}
              />

              <Column
                title="Action"
                key="action"
                render={(_, record) => (
                  <Space
                    size="middle"
                    key={record.id}
                    style={{ cursor: "pointer", fontSize: "17px" }}
                    onClick={() => clickHandler(record.id)}
                  >
                    <EyeOutlined />
                    {/* {console.log(record)} */}
                    {/* <Button style={{ color: "red", cursor: "pointer" }}></Button> */}
                  </Space>
                )}
              />
            </Table>
          </>
        )}
      </div>
    </>
  );
};
