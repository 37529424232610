/*


This is the util for using localstorage as the caching stograge 

*/

// This function will set the item into the lcoalstorage
export const setItemInLocalCache = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

// Reading the value from the localstorage
export const readItemInLocalCache = (key) => {
  return JSON.parse(localStorage.getItem(key)) || null;
};

// Deleting a specific value of the key from localStorage
export const deleteItemFromLocalCache = (key) => {
  localStorage.removeItem(key);
};
// set the metabase analytic url
export const applicantAnalyticLocalCache = (value) => {
  localStorage.setItem("applicantAnalytics", value);
};
// set the metabase analytic url
export const positionAnalyticLocalCache = (value) => {
  localStorage.setItem("positionAnalytics", value);
};

export const partnerAnalyticsLocalCache = (value) => {
  localStorage.setItem("partnerAnalytics", value);
};

export const zyvkaInternalLocalCache = (value) => {
  return localStorage.setItem("zyvkaInternal", value);
};

export const zyvkaAiLocalCache = (value) => {
  return localStorage.setItem("zyvkaAi", value);
};
export const userActivityLocalCache = (value) => {
  return localStorage.getItem("userActivity", value);
};



export const getPartnerAnalyticsLocalCache = () => {
  return localStorage.getItem("partnerAnalytics") || null;
};

// get the metabase analytic url from cache
export const getApplicantAnalyticLocalCache = () => {
  return localStorage.getItem("applicantAnalytics") || null;
};
// get the metabase analytic url from cache
export const getPositionAnalyticLocalCache = () => {
  return localStorage.getItem("positionAnalytics") || null;
};
export const getZyvkaInternalLocalCache = () => {
  return localStorage.getItem("zyvkaInternal") || null;
};
export const getUserActivityLocalCache = () => {
  return localStorage.getItem("userActivity") || null;
};
export const getZyvkaAiLocalCache = () => {
  return localStorage.getItem("zyvkaAi") || null;
};

// Clearing the localstorage i.e. all the elements would be deleted

export const clearLocalCache = () => {
  localStorage.clear();
};
