import React from "react";
import {
    Button,
    notification,
    message,
} from "antd";
import { useEffect, useState } from "react";
import { Icon, Menu, Table } from 'semantic-ui-react';
import { useDispatch } from "react-redux";
import { deallocateJob } from '../api/partner'

export const PartnerJobs = ({ id, partnerId, data, setCalls, name }) => {
    const jobDeAllocationHelper = async (id, jobTitle, cmpName) => {
        const result = window.confirm('Are you sure you want to remove this partner from this job?');
        if (result) {
            try {
                console.log(partnerId, id, cmpName, "print")
                const result = await deallocateJob({ partnerAdminID: partnerId, jobID: id })
                if (result?.response?.status !== 500) {
                    notification.open({
                        message: <span style={{ fontWeight: 'bold', fontSize: '17px' }}>De Allocation Successful✅</span>,
                        description:
                            ` ${jobTitle} Job for ${cmpName} has been successfully de allocated from ${name}.`
                    });
                }
                setCalls(prevCall => !prevCall)
                console.log(partnerId, id, cmpName, "print")
            } catch (e) {
                notification.open({
                    message: <span style={{ fontWeight: 'bold', fontSize: '17px' }}>Some Error Occurred ❌</span>,
                    description:
                        ` It seems theres an error from our side, please try again later.`
                });
                setCalls(prevCall => !prevCall)
            }
        }
    }
    return (
        <div>
            <Table celled  >
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Position</Table.HeaderCell>
                        <Table.HeaderCell>Company</Table.HeaderCell>
                        <Table.HeaderCell>Location</Table.HeaderCell>
                        <Table.HeaderCell>Job Status</Table.HeaderCell>
                        {/* <Table.HeaderCell>Candidate Score</Table.HeaderCell> */}
                        <Table.HeaderCell>Action</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        data?.length > 0 ?
                            data?.map((obj, idx) => {
                                return (
                                    <>
                                        <Table.Row key={obj.id} >
                                            <Table.Cell>
                                                {obj.job_title}
                                            </Table.Cell>
                                            <Table.Cell>{obj.company_name}</Table.Cell>
                                            <Table.Cell>{obj.city} ‎,‎ {obj.state} ( {obj.country} ) </Table.Cell>
                                            <Table.Cell>{obj.status}</Table.Cell>
                                            <Table.Cell>
                                                <Button type="danger" onClick={() => jobDeAllocationHelper(obj?.id, obj?.job_title, obj?.company_name)} >
                                                    De-allocate
                                                </Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    </>
                                )
                            }) :
                            <Table.Row>
                                <Table.Cell>
                                    ‎ ‎ ‎ ‎
                                </Table.Cell>
                                <Table.Cell>‎ ‎ ‎ </Table.Cell>
                                <Table.Cell>‎ ‎  -‎ ‎  / ‎ ‎ ‎  </Table.Cell>
                                <Table.Cell>‎ ‎ ‎ ‎ ‎ </Table.Cell>
                                <Table.Cell>‎ ‎ ‎ </Table.Cell>
                            </Table.Row>

                    }

                </Table.Body >
                <Table.Footer >
                    <Table.Row>
                        <Table.HeaderCell colSpan='5'>
                            <Menu floated='right' pagination>
                                <Menu.Item as='a' icon>
                                    <Icon name='chevron left' />
                                </Menu.Item>
                                <Menu.Item as='a'>1</Menu.Item>
                                <Menu.Item as='a'>2</Menu.Item>
                                <Menu.Item as='a'>3</Menu.Item>
                                <Menu.Item as='a'>4</Menu.Item>
                                <Menu.Item as='a' icon>
                                    <Icon name='chevron right' />
                                </Menu.Item>
                            </Menu>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </div>
    );
};