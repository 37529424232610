import { Space, Table, Button, Typography, Input } from "antd";
import React from "react";
import { useEffect, useState } from "react";
import {
  enablePartneradmin,
  fetchDisabledPartnerAdmin,
} from "../api/ZyvkaMember";
import { useDispatch } from "react-redux";
import { ZyvkaMemberState } from "../features/ZyvkaMemberSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../features/userSlice";
import { UserAddOutlined } from "@ant-design/icons";
const { Column } = Table;

const DisabledPartnerAdmins = () => {
  const [calls, setCalls] = useState(false);
  const dispatch = useDispatch();
  const zyvkaState = useSelector(ZyvkaMemberState);

  const fetchPartnerAdmins = async () => {
    try {
      await fetchDisabledPartnerAdmin(dispatch);
    } catch (error) {
      console.log(error);
    }
  };
  const enableHandler = async (id) => {
    if (
      window.confirm(
        `Are you sure you want Activate this account and all the partners accosiated with the partner admin ?`
      )
    ) {
      await enablePartneradmin(
        {
          id: id,
        },
        dispatch
      );
    }
    if (calls === false) setCalls(true);
    else setCalls(false);
  };
  useEffect(() => {
    fetchPartnerAdmins();
  }, [calls]);

  // this is for search
  const [searchValue, setSearchValue] = useState("");

  return (
    <>
      <div className="tableDiv">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          <Space direction="horizontal" wrap={true}>
            <Input
              placeholder="Search Here"
              style={{
                minWidth: 200,
                maxWidth: 400,
                paddingLeft: 10,
              }}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
            <Button style={{ width: 80 }}>Search</Button>
          </Space>
        </div>

        <Table
          scroll={{ x: true }}
          dataSource={zyvkaState?.disabledPartnerAdmins}
          loading={zyvkaState?.isPending}
          bordered={true}
        >
          <Column
            title="First Name"
            dataIndex="firstName"
            key="firstName"
            filteredValue={[searchValue]}
            onFilter={(value, record) => {
              return (
                String(record?.firstName)
                  ?.toLowerCase()
                  .includes(value.toLocaleLowerCase()) ||
                String(record?.lastName)
                  ?.toLowerCase()
                  .includes(value.toLocaleLowerCase()) ||
                String(record?.email)
                  ?.toLowerCase()
                  .includes(value.toLocaleLowerCase()) ||
                String(record?.mobileNum)
                  ?.toLowerCase()
                  .includes(value.toLocaleLowerCase())
              );
            }}
            render={(_, record) => {
              return (
                <>
                  <span>{record.firstName}</span>
                </>
              );
            }}
          />
          <Column
            title="Last Name"
            dataIndex="lastName"
            key="lastName"
            render={(_, record) => {
              return (
                <>
                  <span key={record.id}>{record.lastName}</span>
                </>
              );
            }}
          />
          <Column
            title="Email"
            render={(_, record) => {
              return (
                <>
                  <span key={record.id}>{record.email}</span>
                </>
              );
            }}
          />
          <Column
            title="Mobile Number"
            render={(_, record) => {
              return (
                <>
                  <span key={record.id}>
                    {record.country_code} {record.mobileNum}
                  </span>
                </>
              );
            }}
          />
          {/* company name added */}
          <Column
            title="Company"
            render={(_, record) => {
              return (
                <>
                  <span key={record.id}>{record.company_name || " ━ "}  </span>
                </>
              );
            }}
          />
          <Column
            title="Action"
            key="action"
            render={(_, record) => (
              <Space size="middle" key={record.id}>
                <Button onClick={() => enableHandler(record?.id)}>
                  <UserAddOutlined />
                </Button>
              </Space>
            )}
          />
        </Table>
      </div>
    </>
  );
};

export default DisabledPartnerAdmins;
