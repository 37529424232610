import React, { useEffect, useState } from "react";
import {
  UserOutlined,
  LogoutOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { Layout, Typography, Avatar, Popover, Button, List, notification, Switch, Modal, message, Spin } from "antd";
import { useSelector } from "react-redux";
import { LogOutUser } from "../api/auth";
import { selectUser, UserState } from "../features/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { profilePicURL } from "../utils/functions";
import { LeftOutlined } from '@ant-design/icons'
import axios from 'axios'
const API = axios.create({ baseURL: process.env.REACT_APP_SERVER_URL });
const { Header } = Layout;

const { Title } = Typography;

const Avatarcontent = () => {
  const userState = useSelector(UserState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notifVisible, setNotifVisible] = useState(false);
  const [notifData, setNotifData] = useState([]);

  const logout = async () => {
    try {
      await LogOutUser(dispatch, navigate);
      if (userState.isErrors) {
        alert("some error occured on logout");
      }
    } catch (error) {
      console.log(error);
    }
  };
  function convertTimestampToReadable(timestamp) {
    const date = new Date(timestamp);

    const options = {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short',
      timeZone: 'Asia/Kolkata', // Adjust to the desired time zone
    };

    const formattedDate = date.toLocaleString('en-US', options);
    return formattedDate;
  }
  function formatTimeDifference(timestamp) {
    const currentTime = new Date();
    const messageTime = new Date(timestamp);
    const timeDifference = currentTime - messageTime;
    console.log(timeDifference);
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    console.log(seconds, minutes, hours, days);

    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else {
      return 'Just now';
    }
  }
  const openNotification = (placement, data) => {
    notification.info({
      message: `${data?.title}`,
      description: `${data?.body}`,
      placement,
      duration: 0
    });
  };
  // useEffect(() => {
  //   const handleNewMessage = async () => {
  //     try {
  //       const data = await API.get(`/api/notification/getnotificationforUser/${userState?.userInfo?.id}`, {
  //         withCredentials: true
  //       });
  //       const res = data?.data[0]?.notification_data?.filter(element => new Date(element?.time) > new Date(data?.data[0]?.updatedAt));
  //       if (res?.length > 0) {
  //         res?.forEach(element => {
  //           openNotification('top', element);
  //         });
  //         console.log(res, "render");
  //         const updateTime = await API.post(`api/notification/updateNotificationTimeforUser/${userState?.userInfo?.id}`, { time: data?.data[0]?.notification_data[0]?.time }, { withCredentials: true })
  //       }
  //     } catch (e) {
  //       console.log(e, "Error occurred");
  //     }
  //   };
  //   handleNewMessage();
  // }, [notifVisible]);

  const getData = async () => {
    try {
      const data = await API.get(`/api/notification/getnotificationforUser/${userState?.userInfo?.id}`, {
        withCredentials: true
      });
      setNotifData(data?.data[0]?.notification_data);
    } catch (e) {
      setNotifData([]);
    }
  }
  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      style={{
        zIndex: "30",
        width: `${notifVisible ? '350px' : '250px'}`,
        maxHeight: `${notifVisible ? '500px' : '400px'}`,
        overflowY: `${notifVisible ? "auto" : ""}`,
        padding: "10px 20px 10px 10px",
      }}
    >
      {userState.isLoggedIn && (
        !notifVisible ? (
          <>
            <p>
              <MessageOutlined />
              <Button onClick={() => { setNotifVisible(true); getData() }} type="link">
                See Previous Notifications
              </Button>
            </p>
            <p>
              <LogoutOutlined />
              <Button type="link" onClick={logout}>
                Sign Out
              </Button>
            </p>
          </>
        ) : (
          <>
            <div onClick={() => setNotifVisible(false)} style={{ cursor: "pointer", display: "flex", justifyContent: "flex-end", justifyItems: "center", marginTop: "-10px", marginBottom: "-10px", color: "#ba3c3c", fontWeight: "bold" }} >
              <p>
                <LeftOutlined />
              </p>
              <p style={{ marginLeft: "10px" }} >Back to Profile</p>
            </div>
            <div>
              <List
                itemLayout="horizontal"
                dataSource={notifData}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src="https://login.zyvka.com/logo.svg" />}
                      title={<b>{item.title}</b>}
                      description={
                        <div style={{ display: "flex", justifyContent: "space-between", justifyItems: "center" }} >
                          <p>{item.body}</p>
                          <p>{formatTimeDifference(item.time)}</p>
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
            </div>
          </>
        )
      )}
    </div >
  );
};



const HeaderComponent = () => {
  const user = useSelector(selectUser);
  const userState = useSelector(UserState);
  const [isAvailable, setIsAvailable] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);

  const showAvailabilityToggle = userState.role !== 'partner' && userState.role !== 'partnerAdmin';

  const fetchUserList = async () => {
    setIsLoadingUsers(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/chat/getChatUser`, { withCredentials: true });
      setUserList(response.data?.users?.filter(u => u.type !== 'partner' && u.type !== 'partnerAdmin' && u.id!==user?.id && u.id!="zyvka_tech"));
    } catch (error) {
      console.error(error);
      console.error('Failed to fetch user list. Please try again.');
    } finally {
      setIsLoadingUsers(false);
    }
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  const handleAvailabilityToggle = (checked) => {
    if (checked) {
      setStatus(true, null);
    } else {
      setModalVisible(true);
    }
  };

  const handleUserSelection = (selectedUser) => {
    setSelectedUser(selectedUser);
    setStatus(false, selectedUser.id);
    setModalVisible(false);
  };

  const setStatus = async (status, alternatePsmId) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/chat/updateStatus`, {
        userId: user.id,
        Currentstatus: status,
        alternatePsmId: alternatePsmId
      }, { withCredentials: true });
      
      setIsAvailable(status);
      
      if (response.data && response.data.message) {
        message.success(response.data.message);
      } else {
        message.success(`Status set to ${status ? 'Available' : 'Unavailable'}`);
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.error) {
        message.error(error.response.data.error);
      } else {
        message.error('Failed to set status. Please try again.');
      }
      
      setIsAvailable(!status);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Header
        className="header-main"
        style={{
          cursor: "pointer",
          backgroundColor: "white",
          boxShadow: "0px -8px 49px -16px rgba(104,85,246,0.67)",
          zIndex: "50",
        }}
      >
        <div className="header-items" style={{ border: "", width: "96%" }}>
          {userState?.isLoggedIn && (
            <>
              <Popover
                placement="bottomLeft"
                content={<Avatarcontent />}
                title={`Hello ${user.firstName + " " + user.lastName}`}
                arrowPointAtCenter
                trigger="click"
              >
                <Avatar
                  className="header-avatar"
                  icon={
                    user.pfp_urn === null ? (
                      <UserOutlined />
                    ) : (
                      <img src={profilePicURL(user.pfp_urn)} alt="prof-pic" />
                    )
                  }
                />
              </Popover>
              {showAvailabilityToggle && (
                <div style={{ float: "right", marginRight: "24px", marginTop: "-10px" }}>
                  <Switch
                    checked={isAvailable}
                    onChange={handleAvailabilityToggle}
                    loading={isLoading}
                    checkedChildren="Available"
                    unCheckedChildren="Unavailable"
                  />
                </div>
              )}
            </>
          )}
          <h2 className="header-logo" style={{ color: "#6855f6" }}>
            ZYVKA
          </h2>
        </div>
      </Header>

      <Modal
        title="Select a User on your behalf"
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setIsAvailable(true); 
        }}
        footer={null}
      >
        {isLoadingUsers ? (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <Spin size="large" />
          </div>
        ) : (
          <List
            dataSource={userList}
            renderItem={(user) => (
              <List.Item onClick={() => handleUserSelection(user)} style={{ cursor: 'pointer' }}>
              <List.Item.Meta
                avatar={
                  <Avatar 
                    icon={<UserOutlined />} 
                    style={{ 
                      backgroundColor: '#6855f6', 
                      marginRight: '8px'
                    }} 
                  />
                }
                title={<span>{user.username || user.id} <span>({user.type})</span></span>}
                description={<span style={{ color: '#999', fontSize: '0.9em' }}>{user.email}</span>}
              />
            </List.Item>
            )}
          />
        )}
      </Modal>
    </div>
  );
};

export default HeaderComponent;