import React, { useState, useEffect } from "react";
import { Route, useNavigate, Routes, Router } from "react-router-dom";
import {
  TeamOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  ProfileOutlined,
  QuestionCircleOutlined,
  BankOutlined,
  SolutionOutlined,
  BarChartOutlined,
  AuditOutlined,
  BarsOutlined,
  StockOutlined,
  UsergroupAddOutlined,
  IdcardOutlined,
  UnorderedListOutlined,
  AlignLeftOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import DeletePartner from "../components/DeletePartner";
import AddPartner from "../components/AddPartner";
import ModifyPartners from "../components/ModifyPartners";
import AddPartnerAdmin from "../components/AddPartnerAdmin";
import { useDispatch, useSelector } from "react-redux";
import { UserState, selectUser } from "../features/userSlice";
import CreateJob from "../components/CreateJob";
import ReferNewApplicant from "../components/ReferNewApplicant";
import Profile from "./Profile";
import MyJobs from "../components/MyJobs";
import Form2 from "../components/Form2";
import OpenJobs from "../components/OpenJobs";
import CreateApplicant from "../components/CreateApplicant";
import HeaderComponent from "../components/HeaderComponent";

import { ReferMyApplicant } from "../components/ReferMyApplicant";
import ViewApplicant from "../components/ViewApplicant";
import { AppliedApplicant } from "../components/AppliedApplicant";
import data from "../Data/Data";
import AddProfileAndSkill from "../components/AddProfileAndSkill";
import AllApplicants from "../components/AllApplicants";
import CreateClient from "../components/CreateClient";
import { Queries } from "../components/Queries";
import AddZyvkaMember from "../components/AddZyvkaMember";
import ModifyZyvkaMembers from "../components/ModifyZyvkaMembers";
import AllJobs from "../components/AllJobs";
import CloneJob from "../components/CloneJob";
import DisabledPartnerAdmins from "../components/DisabledPartnerAdmins";
import AllClients from "../components/AllClients";
import ArchivesJobs from "../components/ArchivesJobs";
import ClosedJobs from "../components/ClosedJobs";
import InternalJobs from "../components/InternalJobs"
import ActionRequired from "../components/ActionRequired";
import ApplicantDetails from "../components/ApplicantDetails";
import MetabaseAnalytics from "../components/MetabaseAnalytics";
import ClientVerification from "../components/ClientVerification";
import AddNewNotifications from "../components/AddNewNotifications";
import ClientDetails from "../components/clientDetail";
import PartnerVerification from "../components/PartnerVerification";
import PartnerJobList from "../components/PartnerJobList";
import PartnerPendingJobs from "../components/PartnerPendingJobs";
import PartnerAssignedPendingJobs from "../components/PartnerAssignedJobs";
import VideoResumeUpload from "../components/VideoResumeUpload";
import PartnerDetails from "../components/PartnerDetails";
import MetabaseAnalyticsAi from "../components/MetabaseAnalyticsAi";
import MetabaseAnalyticsApplicant from "../components/MetabaseAnalyticsApplicants";
import MetabaseAnalyticsPosition from "../components/MetabaseAnalyticsPosition";
import MetabaseAnalyticsPartner from "../components/MetabaseAnalyticsPartner";
import MetabaseAnalyticsInternal from "../components/MetabaseAnalyticsInternal";
import MetabaseAnalyticsUser from "../components/MetabaseAnalyticsUser";
import MyJobList from "../components/MyJobList";
import GetJobDetails from "../components/GetJobDetails";
import SuperAdminPositionApproval from "../components/SuperAdminPositionApproval";
import ChatComponent from "../components/ChatApp/ChatComponent";
import ChatPages from "../components/ChatApp/ChatPages";
import GetAlluserChat from "../components/ChatApp/chatComponents/getAlluserChat";
import AdminChat from "../components/ChatApp/AdminChatComponent";

import { setUnreadCount,selectUnreadCount } from "../features/chatSlice";
import { useCreateChatClient } from "stream-chat-react";
import ChatButton from "../Small-Components/chatButton";

const { Header, Content, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}


const Dash3 = () => {
  const dashboardData = data.Dashboard;

  const user = useSelector(selectUser);
  const dispatch= useDispatch();

  const userState = useSelector(UserState);
  const navigate = useNavigate();

  const myid = user.id;
  

  const apiKey = `${process.env.REACT_APP_StreamChat_PROJECT_ID}`;
  const userId = user?.id;
  const token = JSON.parse(localStorage.getItem("chatToken"));
  
  const client = useCreateChatClient({
    apiKey,
    tokenOrProvider: token,
    userData: { id: userId },
});
  useEffect(()=>{
    if(client){
    const getUnreadCount=async ()=>{
      const response = await client.getUnreadCount(userId);
      // console.log("initial fetch unread messages",response.total_unread_count);
      dispatch(setUnreadCount(response.total_unread_count));
    }
  
    getUnreadCount();
  }
  },[client])

  useEffect(()=>{
    if(client){

      client.on((event) => {
      if (event.total_unread_count !== undefined) {
          // console.log("realtime update of unread messages",event.total_unread_count);
          dispatch(setUnreadCount(event.total_unread_count));
      }
      })
   }
  },[client])

  const unreadCount = useSelector(selectUnreadCount);

  //ZYVKA ADMIN SIDE PANEL
  const itemszyvkaAdmin = [
    getItem(`${dashboardData.profile.title}`, "profile", <ProfileOutlined />),
    getItem("Analytics", "analytics", <BarChartOutlined />, [
      getItem("Applicant Analytics", "analytics_applicant", <AuditOutlined />),
      getItem("Positions Analytics", "analytics_position", <BarsOutlined />),
      getItem("Partner Analytics", "analytics_partner", <StockOutlined />),
      getItem("Internal Zyvka", "zyvka_internal", <IdcardOutlined />),
      getItem("User Activity", "user_activity", <UsergroupAddOutlined />),
    ]),
    getItem(
      `${dashboardData.zyvka_partner_management.title}`,
      "partneradminmanagement",
      <UserOutlined />,
      [
        getItem(
          `${dashboardData.zyvka_partner_management.AddPartnerAdmin.title}`,
          "addpartneradmin"
        ),
        getItem(
          `${dashboardData.zyvka_partner_management.modifyPartner.title}`,
          "modifypartners"
        ),
        getItem(
          `${dashboardData.zyvka_partner_management.DisabledPartnerAdmins.title}`,
          "disabledpartneradmins"
        ),
        getItem(
          `${dashboardData.clientManageMent.partnerVerification.title}`,
          "partnerVerification"
        ),
      ]
    ),
    getItem(
      `${dashboardData.clientManageMent.title}`,
      "clientmanagement",
      <BankOutlined />,
      [
        getItem(
          `${dashboardData.clientManageMent.AddClient.title}`,
          "createclient"
        ),
        getItem(
          `${dashboardData.clientManageMent.AllClients.title}`,
          "clients"
        ),
        getItem(
          `${dashboardData.clientManageMent.clientVerification.title}`,
          "clientVerification"
        ),
      ]
    ),
    getItem(
      `${dashboardData.candidateManagement.title}`,
      "candidatemanagement",
      <TeamOutlined />,
      [
        getItem(
          `${dashboardData.P_Partner.createApplicant.title}`,
          "createapplicant"
        ),
        getItem(`${dashboardData.allCandidates.title}`, "allApplicants"),
      ]
    ),

    getItem("Zyvka User Management", "zyvkausermanagement", <TeamOutlined />, [
      getItem("Add Zyvka Member", "addzyvkamember"),
      getItem("All Zyvka Members", "allzyvkamembers"),
      getItem("Add New Notifications", "addnewnotifications"),
    ]),

    getItem(
      `${dashboardData.positions.title}`,
      "positions",
      <SolutionOutlined />,
      [
        getItem(
          `${dashboardData.positions.createPosition.title}`,
          "createnewjob"
        ),
        getItem(`${dashboardData.positions.allpositions.title}`, `alljobs`),
        getItem(
          `${dashboardData.positions.mypositions.title}`,
          `myjobs/${myid}`
        ),
        getItem(
          `Position Approval`,
          `approval`
        ),
        getItem(
          `${dashboardData?.positions?.archivepositions?.title}`,
          `archive`
        ),
        getItem(
          `${dashboardData?.positions?.internalpositions?.title}`,
          `internaljob`
        ),
        getItem(
          `${dashboardData?.positions?.closedpositions?.title}`,
          `closedjob`
        ),
      ]
    ),

    getItem(
      `${dashboardData.queries.title}`,
      "queries",
      <QuestionCircleOutlined />
    ),

    getItem(
      `${dashboardData.addprofile_skill.title}`,
      "addprofileandskill",
      <AppstoreAddOutlined />
    ),
    getItem(
      <span style={{ position: 'relative', width: '100%' }}>
        Start Chatting
        {unreadCount > 0 && (
          <span 
            style={{
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              backgroundColor: 'green',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '12px',
              fontWeight: 'bold',
              position: 'absolute',
              right: '-30px',
              top: '0px',
            }}
          >
            {unreadCount}
          </span>
        )}
      </span>,
      "start_chat",
      <ProfileOutlined />
    ),
  
  ];

  //SUPER ADMIN SIDE PANEL
  const itemsSuperAdmin = [
    getItem(`${dashboardData.profile.title}`, "profile", <ProfileOutlined />),
    getItem("Analytics", "analytics", <BarChartOutlined />, [
      getItem("Applicant Analytics", "analytics_applicant", <AuditOutlined />),
      getItem("Positions Analytics", "analytics_position", <BarsOutlined />),
      getItem("Partner Analytics", "analytics_partner", <StockOutlined />),
      getItem("Internal Zyvka", "zyvka_internal", <IdcardOutlined />),
      getItem("User Activity", "user_activity", <UsergroupAddOutlined />),
      getItem("AI Analytics", "zyvka_ai", <UsergroupAddOutlined />),
    ]),
    getItem(
      `${dashboardData.zyvka_partner_management.title}`,
      "partneradminmanagement",
      <UserOutlined />,
      [
        getItem(
          `${dashboardData.zyvka_partner_management.AddPartnerAdmin.title}`,
          "addpartneradmin"
        ),
        getItem(
          `${dashboardData.zyvka_partner_management.modifyPartner.title}`,
          "modifypartners"
        ),
        getItem(
          `${dashboardData.zyvka_partner_management.DisabledPartnerAdmins.title}`,
          "disabledpartneradmins"
        ),
        getItem(
          `${dashboardData.clientManageMent.partnerVerification.title}`,
          "partnerVerification"
        ),
      ]
    ),
    getItem(
      `${dashboardData.clientManageMent.title}`,
      "clientmanagement",
      <BankOutlined />,
      [
        getItem(
          `${dashboardData.clientManageMent.AddClient.title}`,
          "createclient"
        ),
        getItem(
          `${dashboardData.clientManageMent.AllClients.title}`,
          "clients"
        ),
        getItem(
          `${dashboardData.clientManageMent.clientVerification.title}`,
          "clientVerification"
        ),
      ]
    ),
    getItem(
      `${dashboardData.candidateManagement.title}`,
      "candidatemanagement",
      <TeamOutlined />,
      [
        getItem(
          `${dashboardData.P_Partner.createApplicant.title}`,
          "createapplicant"
        ),
        getItem(`${dashboardData.allCandidates.title}`, "allApplicants"),
      ]
    ),

    getItem("Zyvka User Management", "zyvkausermanagement", <TeamOutlined />, [
      getItem("Add Zyvka Member", "addzyvkamember"),
      getItem("All Zyvka Members", "allzyvkamembers"),
      getItem("Add New Notifications", "addnewnotifications"),
    ]),

    getItem(
      `${dashboardData.positions.title}`,
      "positions",
      <SolutionOutlined />,
      [
        getItem(
          `${dashboardData.positions.createPosition.title}`,
          "createnewjob"
        ),
        getItem(`${dashboardData.positions.allpositions.title}`, `alljobs`),
        getItem(
          `${dashboardData.positions.mypositions.title}`,
          `myjobs/${myid}`
        ),
        getItem(
          `Position Approval`,
          `approval`
        ),
        getItem(
          `${dashboardData?.positions?.archivepositions?.title}`,
          `archive`
        ),
        getItem(
          `${dashboardData?.positions?.internalpositions?.title}`,
          `internaljob`
        ),
        getItem(
          `${dashboardData?.positions?.closedpositions?.title}`,
          `closedjob`
        ),
      ]
    ),

    getItem(
      `${dashboardData.queries.title}`,
      "queries",
      <QuestionCircleOutlined />
    ),

    getItem(
      `${dashboardData.addprofile_skill.title}`,
      "addprofileandskill",
      <AppstoreAddOutlined />
    ),
    getItem(
      <span style={{ position: 'relative', width: '100%' }}>
        Start Chatting
        {unreadCount > 0 && (
          <span 
            style={{
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              backgroundColor: 'green',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '12px',
              fontWeight: 'bold',
              position: 'absolute',
              right: '-30px',
              top: '0px',
            }}
          >
            {unreadCount}
          </span>
        )}
      </span>,
      "start_chat",
      <ProfileOutlined />
    ),
    getItem(
      `Team Communication Hub`,
      "chatApp",
      <AppstoreAddOutlined />
    ),
  ];

  //ZYVKA USER SIDE PANEL
  const itemszyvkaUser = [
    getItem(`${dashboardData.profile.title}`, "profile", <ProfileOutlined />),
    getItem("Analytics", "analytics", <BarChartOutlined />, [
      getItem("Applicant Analytics", "analytics_applicant", <AuditOutlined />),
      getItem("Positions Analytics", "analytics_position", <BarsOutlined />),
      getItem("Partner Analytics", "analytics_partner", <StockOutlined />),
    ]),
    getItem(
      `${dashboardData.zyvka_partner_management.title}`,
      "partneradminmanagement",
      <UserOutlined />,
      [
        getItem(
          `${dashboardData.zyvka_partner_management.AddPartnerAdmin.title}`,
          "addpartneradmin"
        ),
        getItem(
          `${dashboardData.zyvka_partner_management.modifyPartner.title}`,
          "modifypartners"
        ),
        getItem(
          `${dashboardData.zyvka_partner_management.DisabledPartnerAdmins.title}`,
          "disabledpartneradmins"
        ),
        getItem(
          `${dashboardData.clientManageMent.partnerVerification.title}`,
          "partnerVerification"
        ),
      ]
    ),
    getItem(
      `${dashboardData.clientManageMent.title}`,
      "clientmanagement",
      <BankOutlined />,
      [
        getItem(
          `${dashboardData.clientManageMent.AddClient.title}`,
          "createclient"
        ),
        getItem(
          `${dashboardData.clientManageMent.AllClients.title}`,
          "clients"
        ),
        getItem(
          `${dashboardData.clientManageMent.clientVerification.title}`,
          "clientverfication"
        ),
      ]
    ),
    getItem(
      `${dashboardData.candidateManagement.title}`,
      "candidatemanagement",
      <TeamOutlined />,
      [
        getItem(
          `${dashboardData.P_Partner.createApplicant.title}`,
          "createapplicant"
        ),
        getItem(`${dashboardData.allCandidates.title}`, "allApplicants"),
      ]
    ),
    getItem(
      `${dashboardData.positions.title}`,
      "positions",
      <SolutionOutlined />,
      [
        getItem(
          `${dashboardData.positions.createPosition.title}`,
          "createnewjob"
        ),
        getItem(`${dashboardData.positions.allpositions.title}`, `alljobs`),
        getItem(
          `${dashboardData.positions.mypositions.title}`,
          `myjobs/${myid}`
        ),
        getItem(
          `${dashboardData?.positions?.archivepositions?.title}`,
          `archive`
        ),
        getItem(
          `${dashboardData?.positions?.internalpositions?.title}`,
          `internaljob`
        ),
        getItem(
          `${dashboardData?.positions?.closedpositions?.title}`,
          `closedjob`
        ),
       
      ]
    ),

    getItem(
      `${dashboardData.queries.title}`,
      "queries",
      <QuestionCircleOutlined />
    ),
    getItem(
      <span style={{ position: 'relative', width: '100%' }}>
        Start Chatting
        {unreadCount > 0 && (
          <span 
            style={{
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              backgroundColor: 'green',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '12px',
              fontWeight: 'bold',
              position: 'absolute',
              right: '-30px',
              top: '0px',
            }}
          >
            {unreadCount}
          </span>
        )}
      </span>,
      "start_chat",
      <ProfileOutlined />
    ),
  ];

  //FOR PARTNER ADMIN
  const PadminItems = [
    getItem(`${dashboardData.profile.title}`, "profile", <ProfileOutlined />),
    getItem("View Applicants", "allApplicants", <TeamOutlined />),
    //getItem(`${dashboardData.P_Partner.assigned_jobs.title}`, "openjobs", <BookOutlined />),
    getItem(
      `${dashboardData.queries.title}`,
      "queries",
      <QuestionCircleOutlined />
    ),
    // getItem(`${dashboardData.P_Partner.title}`, "partner", <TeamOutlined />, [
    //   // getItem(
    //   //   `${dashboardData.P_Partner.createApplicant.title}`,
    //   //   "createapplicant"
    //   // ),
    // ]),
    getItem(
      `${dashboardData.P_User_Management.title}`,
      "usermanagement",
      <UserOutlined />,
      [
        getItem(
          `${dashboardData.P_User_Management.addPartner.title}`,
          "addpartner"
        ),
        getItem(
          `${dashboardData.P_User_Management.deletePartner.title}`,
          "deletepartner"
        ),
      ]
    ),
    getItem(`My Positions`, "company-job-list", <UnorderedListOutlined />),
    getItem("Positions", "positions", <AlignLeftOutlined />, [
      getItem("Your Positions", "openjobs"),
      getItem("All Positions", "alljobs"),
      getItem("Pending Claimed Positions", "pendingpclaimedpositions"),
      getItem("Pending Assigned Positions", "pendingassignedpositions"),
    ]),
    getItem(
      <span style={{ position: 'relative', width: '100%' }}>
        Start Chatting
        {unreadCount > 0 && (
          <span 
            style={{
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              backgroundColor: 'green',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '12px',
              fontWeight: 'bold',
              position: 'absolute',
              right: '-30px',
              top: '0px',
            }}
          >
            {unreadCount}
          </span>
        )}
      </span>,
      "start_chat",
      <ProfileOutlined />
    ),
  ];
  //FOR PARTNERS
  const adminItems = [
    getItem(`${dashboardData.profile.title}`, "profile", <ProfileOutlined />),
    getItem("View Applicants", "allApplicants", <TeamOutlined />),
    getItem(`Company Job List`, "company-job-list", <UnorderedListOutlined />),
    getItem(
      `${dashboardData.queries.title}`,
      "queries",
      <QuestionCircleOutlined />
    ),
    getItem("Your Positions", "openjobs", <AlignLeftOutlined />),
    getItem(
      <span style={{ position: 'relative', width: '100%' }}>
        Start Chatting
        {unreadCount > 0 && (
          <span 
            style={{
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              backgroundColor: 'green',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '12px',
              fontWeight: 'bold',
              position: 'absolute',
              right: '-30px',
              top: '0px',
            }}
          >
            {unreadCount}
          </span>
        )}
      </span>,
      "start_chat",
      <ProfileOutlined />
    ),
  ];

  const onMenuClick = (item) => {
    const { key } = item;
    navigate(`/dashboard/${key}`);
  };
  const [collapsed, setCollapsed] = useState(true);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ height: "100%", overflow: "auto", width: "100%" }}>
      <Layout style={{ backgroundColor: "white" }}>
        <HeaderComponent />
        <Layout
          style={{
            minHeight: "100vh",
            maxWidth: "100vw",
          }}
        >
          <Sider
            breakpoint="md"
            collapsedWidth={"80px"}
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            style={{
              marginTop: "63px",
              zIndex: "2",
              position: "fixed",
              // position: `${windowSize.innerWidth < 768 && "fixed"}`,
              height: "100%",
              overflowY: "auto",
            }}
            theme="light"
            trigger={false}
            width="250px"
          >
            <div className="logo" />
            <Menu
              theme="light"
              mode="inline"
              items={
                userState.isLoggedIn === true &&
                  (userState.role === "zyvkaAdmin" ||
                    userState.role === "zyvkaUser" ||
                    userState?.role === "superAdmin")
                  ? userState.role === "superAdmin"
                    ? itemsSuperAdmin
                    : userState?.role === "zyvkaAdmin"
                      ? itemszyvkaAdmin
                      : itemszyvkaUser
                  : userState?.role === "partnerAdmin"
                    ? PadminItems
                    : adminItems
              }
              // items={userState.isLoggedIn === true && (
              //   userState.role === "zyvkaAdmin" ? (
              //     itemszyvkaAdmin
              //   ) : userState.role === "superAdmin" ? (
              //     itemsSuperAdmin
              //   ) : userState.role === "partnerAdmin" ? (
              //     PadminItems
              //   ) : (
              //     adminItems
              //   )
              // )}

              onClick={onMenuClick}
            />
          </Sider>
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{
                padding: 0,
              }}
            />
            <Content
              style={{
                // width: "100%",
                marginLeft: `${windowSize.innerWidth < 768
                  ? "20px"
                  : !collapsed
                    ? "236px"
                    : "60px"
                  }`,
                marginRight: `${windowSize.innerWidth < 768 ? "0px" : "20px"}`,
                paddingBottom: "20px",
                paddingLeft: `${windowSize.innerWidth < 768 ? "80px" : "40px"}`,
                paddingRight: "20px",
                maxWidth: "88vw",
                // minHeight: "80vh",
                height: "100%",
              }}
              theme="dark"f
            >
              <ChatButton/>
              <Routes>
                {/* Routes for Zyvka Users (all types) */}
                <Route path="/start_chat" element={<ChatPages/>}/>
                {userState.isLoggedIn === true &&
                  (userState.role === "zyvkaAdmin" ||
                    userState.role === "zyvkaUser" ||
                    userState?.role === "superAdmin") && (
                    <>
                      <Route
                        path="/analytics_applicant"
                        element={<MetabaseAnalyticsApplicant />}
                      />
                      <Route
                        path="/analytics_position"
                        element={<MetabaseAnalyticsPosition />}
                      />
                      <Route
                        path="/analytics_partner"
                        element={<MetabaseAnalyticsPartner />}
                      />
                      <Route
                        path="/zyvka_internal"
                        element={<MetabaseAnalyticsInternal />}
                      />
                      <Route
                        path="/user_activity"
                        element={<MetabaseAnalyticsUser />}
                      />
                      <Route
                        path="/zyvka_ai"
                        element={<MetabaseAnalyticsAi />}
                      />
                      <Route
                        path="/addpartneradmin"
                        element={<AddPartnerAdmin />}
                      />
                      <Route
                        path="/applicant/job/:applicationID"
                        element={<ViewApplicant />}
                      />
                      <Route
                        path="/myjobs1/:id"
                        element={<AppliedApplicant />}
                      />
                      <Route path="/createclient" element={<CreateClient />} />
                      <Route path="/clients" element={<AllClients />} />
                      <Route path="/client/:id" element={<ClientDetails />} />

                      <Route path="/createnewjob" element={<CreateJob />} />
                      <Route
                        path="/modifypartners"
                        element={<ModifyPartners />}
                      />
                      <Route
                        path="/disabledpartneradmins"
                        element={<DisabledPartnerAdmins />}
                      />
                      <Route path="/myjobs/:id/*" element={<MyJobs />} />
                      <Route path="/alljobs" element={<AllJobs />} />
                      <Route path="/internaljob" element={<InternalJobs />} />
                      {userState?.role === "superAdmin" && (
                        <>
                          <Route path="/archive" element={<ArchivesJobs />} />
                          <Route path="/closedjob" element={<ClosedJobs />} />
                          <Route path="/chatApp" element={<GetAlluserChat />} />
                          <Route path="/seeChat/:name" element={<AdminChat />} />
                        </>
                      )}
                      {(userState?.role === "superAdmin" || userState?.role === "zyvkaAdmin") && (
                        <>
                          <Route path="/approval" element={<SuperAdminPositionApproval />} />
                        </>
                      )}
                      <Route
                        path="/existingApplicant/:jobId"
                        element={<ReferMyApplicant />}
                      />
                      <Route
                        path="/allApplicants"
                        element={<AllApplicants />}
                      />

                      <Route path="/clone/:jobID" element={<CloneJob />} />
                      <Route
                        path="/actionrequired"
                        element={<ActionRequired />}
                      />

                      {(userState.role === "zyvkaAdmin" ||
                        userState?.role === "superAdmin") && (
                          <Route
                            path="/addzyvkamember"
                            element={<AddZyvkaMember />}
                          />
                        )}
                      {(userState.role === "zyvkaAdmin" ||
                        userState?.role === "superAdmin") && (
                          <Route
                            path="/allzyvkamembers"
                            element={<ModifyZyvkaMembers />}
                          />
                        )}
                      {(userState.role === "zyvkaAdmin" ||
                        userState?.role === "superAdmin") && (
                          <Route
                            path="/addprofileandskill"
                            element={<AddProfileAndSkill />}
                          />
                        )}
                      {(userState.role === "zyvkaAdmin" ||
                        userState?.role === "superAdmin") && (
                          <Route
                            path="/addnewnotifications"
                            element={<AddNewNotifications />}
                          />
                        )}
                    </>
                  )}
                {
                  (userState.role === "zyvkaAdmin" ||
                    userState?.role === "superAdmin") && (
                    <Route
                      path="/clientVerification"
                      element={<ClientVerification />}
                    />
                  )
                  // )
                }
                <Route
                  path="/uploadVideoResume/:id"
                  element={<VideoResumeUpload />}
                />
                {
                  (userState.role === "zyvkaAdmin" ||
                    userState?.role === "superAdmin" ||
                    userState?.role === "zyvkaUser") && (
                    <Route
                      path="/partnerverification"
                      element={<PartnerVerification />}
                    />
                  )
                  // )
                }
                <Route path="/partner/:id" element={<PartnerDetails />} />

                {/* Routes for Partner */}

                <Route path="/" element={<Profile />} />
                <Route path="/applicant/:id" element={<ApplicantDetails />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/openjobs" element={<OpenJobs />} />
                <Route path="/createapplicant" element={<CreateApplicant />} />
                {/* Routes for User Management (Partner) */}
                {userState.role !== "partner" && (
                  <>
                    <Route path="/addpartner" element={<AddPartner />} />
                    <Route path="/deletepartner" element={<DeletePartner />} />
                  </>
                )}
                {userState?.role === "partnerAdmin" && (
                  <>
                    <Route path="/alljobs" element={<PartnerJobList />} />
                    <Route
                      path="/pendingpclaimedpositions"
                      element={<PartnerPendingJobs />}
                    />
                    <Route
                      path="/pendingassignedpositions"
                      element={<PartnerAssignedPendingJobs />}
                    />
                  </>
                )}
                {(userState?.role === "partnerAdmin" || userState?.role === "partner") && (
                  <>
                    <Route path="/company-job-list" element={<MyJobList />} />
                  </>
                )}
                <Route
                  path="/referNewApplicant/:jobId"
                  element={<ReferNewApplicant />}
                />
                <Route
                  path="/job-details/:id"
                  element={<GetJobDetails />}
                />
                <Route
                  path="/existingApplicant/:jobId"
                  element={<ReferMyApplicant />}
                />
                <Route
                  path="/applicant/job/:applicationID"
                  element={<ViewApplicant />}
                />
                <Route path="/queries" element={<Queries />} />
                <Route path="/allApplicants" element={<AllApplicants />} />
                {/* Routes for testing pages and components */}
                <Route path="/form2" element={<Form2 />} />
              </Routes>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </div>
  );
};

export default Dash3;
