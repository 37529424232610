import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/userSlice";
import partnerReducer from "../features/partnersSlice";
import zyvkaMemberReducer from "../features/ZyvkaMemberSlice";
import metabaseReducer from "../features/metabaseSlice";
import clientReducer from "../features/clientSlice";
import chatReducer from "../features/chatSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    partners: partnerReducer,
    zyvkaMember: zyvkaMemberReducer,
    analytics: metabaseReducer,
    client: clientReducer,
    chat: chatReducer,
  },
});
