import React from "react";
import {
  Button,
  Space,
  Select,
  Modal,
  Form,
  Input,
  DatePicker,
  InputNumber,
  Tag,
  Radio,
} from "antd";
import { UserState } from "../features/userSlice";
import { useEffect, useState } from "react";
import axios from "axios";
import { Icon, Menu, Table } from 'semantic-ui-react';
import { EyeOutlined } from "@ant-design/icons";
import { ChangeApplicantStatus, ChangeJobAfterResign } from "../api/ZyvkaMember";
//import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const { Option } = Select;
const { TextArea } = Input;

export const CandidatesJobs = ({ id, data, setFlag, setFilterFlag, flag, filterFlag }) => {
  const [statusOption, setStatusOption] = useState();
  const [editable] = useState([]);
  const userState = useSelector(UserState);
  // copied
  const [currencyType, setCurrencyType] = useState("INR");
  const [join_date, setJoinDate] = useState("");
  const stat_array = ["Candidate Joined"];
  const [checkerModal, setCheckerModal] = useState(false);


  const dispatch = useDispatch();
  //const navigate = useNavigate();

  // updates whole status list in 1 state - contains {id:appId, status: appStatus}
  const [status, setStatus] = useState([]);
  const [value, setValue] = useState("");
  //const [tempID, setTempID] = useState("");

  //Joining modal states and functions
  const [isModalOpen, setIsModalOpen] = useState("");
  const [isRejectModalOpen, setIsRejectModalOpen] = useState("");
  const [isStatusModalOpen, setIsStatusModalOpen] = useState("");
  const resignHandler = async () => {
    await ChangeJobAfterResign(
      {
        jobID: data[0].jobID,
      },
      dispatch
    );

  }

  // job status setter and getter function 
  // const statusUpdate = (idx, newStatus) => {
  //   status[idx] = { value: newStatus }
  //   console.log(newStatus, "add", status);
  // }
  const statusUpdate = (idx, newStatus) => {
    const existingStateIndex = status.findIndex((item) => item.id === idx);

    if (existingStateIndex !== -1) {
      // If an object with the id exists, update its status
      const updatedStateArray = [...status];
      updatedStateArray[existingStateIndex] = { id: idx, status: newStatus };
      setStatus(updatedStateArray);
    } else {
      // If an object with the id doesn't exist, add a new object to the status
      const newStateItem = { id: idx, status: newStatus };
      setStatus([...status, newStateItem]);
    }
  };
  // const getStatus = (idx, nStatus) => {
  //   const existingObject = status[idx];
  //   if (existingObject) {
  //     return existingObject.value;
  //   } else {
  //     statusUpdate(idx, nStatus);
  //     return null;
  //   }
  // };
  const getStatus = (idx, nStatus) => {
    const existingObject = status?.find((item) => item.id === idx);
    if (existingObject) {
      return existingObject.status;
    } else {
      statusUpdate(idx, nStatus);
      return null;
    }
  };

  //modal handlers
  const onRejectModalFinish = async (data, status) => {
    try {
      if (window.confirm(`You Want to Reject the candidate? you wont be able ro revert back the changes`)) {
        const applicationID = isRejectModalOpen;
        const res = parseInt(value?.split(" ")[0].substring(1))
        console.log(value);
        rejectModalCancel();
        await ChangeApplicantStatus(
          {
            applicationID: applicationID,
            status: value,
            reject_reason: data.reject_reason,
            round_number: res,
            isLastRound:
              (parseInt(value?.split("-")[0]?.substring(1)) === statusOption.length)
                ? true
                : false,
          },
          dispatch
        );
        setFlag((prev) => !prev);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const rejectModalCancel = () => {
    setIsRejectModalOpen("");
  }
  const onRejectModalFail = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  console.log(data)

  const statusChangeHandler = async (status, applicationID, date) => {
    setValue(status);
    // setStatus(status);
    console.log(status, "here");
    let statusSplitArray = status.split(" ");
    console.log(status, "now updated this");
    if (status === "Offer Accepted") {
      setIsModalOpen(applicationID);
    } else if ((stat_array?.includes(status)) && date === null) {
      setCheckerModal({ id: applicationID, status: status });
    }
    else if (statusSplitArray[statusSplitArray.length - 1] === "Rejected") {
      // statusUpdate(applicationID, status);
      setIsRejectModalOpen(applicationID);
    }
    else {
      if (window.confirm(`You Want to change Status to ${status}`)) {
        if (statusSplitArray[statusSplitArray.length - 1] === "Selected") {
          const res = parseInt(status.split(" ")[1])
          // const new = status.split(" ")[3]
          await ChangeApplicantStatus(
            {
              applicationID: applicationID,
              status: status,
              round_number: res,
              isLastRound:
                (parseInt(status?.split(" ")[1]) === statusOption.length)
                  ? true
                  : false,
            },
            dispatch
          );
        } else if (statusSplitArray[statusSplitArray.length - 1] === "Resigned") {
          console.log("here is the trigger")
          statusUpdate(applicationID, status);
          await ChangeApplicantStatus(
            {
              applicationID: applicationID,
              status: status,
            },
            dispatch
          );
          resignHandler();
        }
        else {
          const res = status.split(" ")[0] === "Offer" ? 0 : 0;
          await ChangeApplicantStatus(
            {
              applicationID: applicationID,
              status: status,
              round_number: res,
            },
            dispatch
          );
        }
      }
      setFlag((prev) => !prev);
    }
  };
  const handleCancel = () => {
    setIsModalOpen("");
  };
  const showStatusModal = (id) => {
    setIsStatusModalOpen(id);
  };
  const handleOkStatus = (value, id, idx, date) => {
    setIsStatusModalOpen(false);
    // statusUpdate(tempID, value);
    if (getStatus(id) !== value) {
      statusChangeHandler(value, id, date);
    }
  };
  const handleCancelStatus = (id) => {
    setIsStatusModalOpen(false);
    const se = getStatus(id, "none")
    setValue(se);
  };

  const candidateJoinedStatusChangeHandler = async (values) => {
    try {
      if (window.confirm(`You Want to change Status to Offer Accepted`)) {
        const applicationID = isModalOpen;
        const currency_type = currencyType;
        const joining_date = join_date;
        handleCancel();
        setCurrencyType("INR");
        setJoinDate("");

        await ChangeApplicantStatus(
          {
            applicationID: applicationID,
            status: "Offer Accepted",
            final_salary: values.final_salary,
            currencyType: currency_type,
            joining_date: joining_date,
          },
          dispatch
        );

      }
    } catch (error) {
      console.log(error);
    }
    setFlag((prev) => !prev);
  };
  const candidateJoinedStatusChangeHandlerPreChecker = async (values) => {
    try {
      const applicationID = checkerModal?.id;
      const currency_type = currencyType;
      const joining_date = join_date;
      setCheckerModal(false);
      setCurrencyType("INR");
      setJoinDate("");
      await ChangeApplicantStatus(
        {
          applicationID: applicationID,
          status: checkerModal?.status,
          final_salary: values.final_salary,
          currencyType: currency_type,
          joining_date: joining_date,
        },
        dispatch
      );
    } catch (error) {
      console.log(error);
    }
    setFlag((prev) => !prev);
    setCheckerModal(false);
  };



  const candidateJoinedStatusFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };


  const onChangeRadio = (e) => {
    setValue(e.target.value);
  };
  const onChange = (date, dateString) => {
    setJoinDate(dateString);
  };
  // const clickHandlerJob = (id1) => {
  //   const url = `/dashboard/applicant/job/${id1}`;
  //   navigate(url);
  // };
  const currencySelector = (
    <Form.Item name="currency_type" noStyle>
      <Select
        defaultValue={currencyType}
        rules={[
          {
            required: true,
            message: "This is Required",
          },
        ]}
        onChange={(e) => setCurrencyType(e)}
      >
        <Option value="USD">$</Option>
        <Option value="INR">₹</Option>
      </Select>
    </Form.Item>
  );

  // copy ends

  const fetchJobData = async (id) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/jobs/getJobByID/${id}`,
        { withCredentials: true }
      );

      let arr = [];
      result?.data?.hiring_process?.forEach((element) => {
        arr.push(JSON.parse(element)?.name);
      });
      setStatusOption(arr);
    } catch (error) {
      console.log(error);
    }
  };



  const clickHandler = (value, defaultDrop, idx, edit) => {
    showStatusModal(value);
    setValue(defaultDrop);
    fetchJobData(value);
    // statusUpdate(idx, defaultDrop);
  }
  const editableCheck = (userId, idx) => {
    console.log(userState?.userInfo?.id);
    console.log(userId?.zyvka_userID === userState?.userInfo?.id);
    if ((userState?.role !== "partnerAdmin" && userState?.role !== "partner") && (userId?.zyvka_team_members?.includes(userState?.userInfo?.id) || userId?.zyvka_userID === userState?.userInfo?.id)) {
      editable[idx] = { value: true };
    } else {
      editable[idx] = { value: false };
    }
  }
  const disableButton = (first, second) => {
    window.alert(first !== true ? "This job is currently not assigned to you." : `This job is currently ${second === "no more submissions" ? ` not accepting any further submissions.` : `${second}`} `);
  }

  useEffect(() => {
    data?.map((el, idx) => {
      editableCheck(el.jobs, idx);
    });
  }, [flag, filterFlag]);

  return (
    <div>
      <Table celled  >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Position</Table.HeaderCell>
            <Table.HeaderCell>Company</Table.HeaderCell>
            {/* <Table.HeaderCell>Round Number</Table.HeaderCell> */}
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>lastStatusChangedBy</Table.HeaderCell>
            {/* <Table.HeaderCell>Candidate Score</Table.HeaderCell> */}
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            data?.map((obj, idx) => {
              return (
                <>
                  <Table.Row key={obj.id} >
                    <Table.Cell>
                      {obj.jobs.job_title}
                    </Table.Cell>
                    <Table.Cell>{obj.jobs.company_name}</Table.Cell>
                    {/* <Table.Cell>{obj.current_round_num}</Table.Cell> */}
                    <Table.Cell >
                      {
                        (editable[idx]?.value !== true || (obj?.jobs?.status === "on hold" || obj?.jobs?.status === "no more submissions" || obj?.jobs?.status === "closed")) ? (
                          <Tag
                            color={'orange'}
                            style={{
                              padding: "4px",
                              paddingLeft: "7px",
                              paddingRight: "7px",
                            }}
                            onClick={(e) => { disableButton(editable[idx]?.value, obj?.jobs?.status) }}
                          >
                            {obj.current_status}
                          </Tag>
                        ) : (
                          <Tag
                            color={'blue'}
                            style={{
                              padding: "4px",
                              paddingLeft: "7px",
                              paddingRight: "7px",
                              cursor: 'pointer'
                            }}
                            onClick={() => clickHandler(obj.jobID, obj.current_status, idx, obj.jobs)}
                          >
                            {
                              obj.current_status || getStatus(idx, obj.current_status)
                            }
                          </Tag>
                        )
                      }

                      {
                        editable[idx]?.value === true && (
                          <>
                            <Modal title="Change Status" visible={isStatusModalOpen === obj.jobID}
                              onOk={(e) => handleOkStatus(value, obj.id, idx, obj?.joiningdate)}
                              onCancel={(e) => handleCancelStatus(obj?.id)}>
                              <Radio.Group onChange={onChangeRadio} value={value || getStatus(obj?.id, obj?.current_status)}>
                                <Space direction="vertical">
                                  <Radio value="Under Review">Under Review</Radio>
                                  <Radio value="Zyvka Shortlisted">Zyvka Shortlisted</Radio>
                                  <Radio value="Zyvka Rejected">Zyvka Rejected</Radio>
                                  <Radio value="Client Shortlisted">Client Shortlisted</Radio>
                                  <Radio value="Client Rejected">Client Rejected</Radio>
                                  {statusOption?.map((data, idx) => {
                                    return (
                                      <>
                                        <Radio value={data + " Selected"}>
                                          {data + " Selected"}
                                        </Radio>
                                        <Radio value={data + " Rejected"}>
                                          {data + " Rejected"}
                                        </Radio>
                                      </>
                                    );
                                  })}
                                  <Radio value="Assignment Pending"> Assignment Pending </Radio>
                                  <Radio value="Assignment Rejected"> Assignment Rejected </Radio>
                                  <Radio value="Offer Discussion"> Offer Discussion </Radio>
                                  <Radio value="Offer rolled out"> Offer rolled out </Radio>
                                  <Radio value="Offer Accepted"> Offer Accepted </Radio>
                                  <Radio value="Offer Rejected"> Offer Rejected </Radio>
                                  <Radio value="Candidate Withdrawn"> Candidate Withdrawn </Radio>
                                  <Radio value="Didn't turn up for Drive"> Didn't turn up for Drive </Radio>
                                  <Radio value="Candidate Joined"> Candidate Joined </Radio>
                                  <Radio value="Candidate Resigned"> Candidate Resigned </Radio>
                                  <Radio value="Position Closed"> Position Closed </Radio>
                                </Space>
                              </Radio.Group>
                            </Modal>
                            <Modal
                              title="Joining Details"
                              visible={isModalOpen === obj.id}
                              // onOk={handleOk}
                              onCancel={handleCancel}
                              footer={null}
                            >
                              <Form
                                name="basic"
                                onFinish={candidateJoinedStatusChangeHandler}
                                onFinishFailed={candidateJoinedStatusFailed}
                                autoComplete="off"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",

                                  justifyContent: "center",
                                }}
                                labelCol={{
                                  span: 4,
                                }}
                                wrapperCol={{
                                  span: 20,
                                }}
                              >
                                <Form.Item
                                  label="Salary"
                                  name="final_salary"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Salary Information Is Required",
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    style={{ width: "100%" }}
                                    addonBefore={currencySelector}
                                  />
                                </Form.Item>

                                <Form.Item
                                  label="Joining Date"
                                  name="joining_date"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input joining date!",
                                    },
                                  ]}
                                >
                                  <DatePicker style={{ width: "100%" }} onChange={onChange} />
                                </Form.Item>

                                <Form.Item
                                  wrapperCol={{
                                    offset: 0,
                                    span: 24,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "100%",

                                      justifyContent: "space-between",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    <Button type="danger" onClick={handleCancel}>
                                      Cancel
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                      Update Status
                                    </Button>
                                  </div>
                                </Form.Item>
                              </Form>
                            </Modal>

                            <Modal
                              title="Requisite Joining Details"
                              visible={checkerModal?.id === obj?.id}
                              onCancel={() => setCheckerModal(null)}
                              footer={null}
                            >
                              <Form
                                name="basic"
                                onFinish={candidateJoinedStatusChangeHandlerPreChecker}
                                onFinishFailed={() => setCheckerModal(null)}
                                autoComplete="off"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",

                                  justifyContent: "center",
                                }}
                                labelCol={{
                                  span: 4,
                                }}
                                wrapperCol={{
                                  span: 20,
                                }}
                              >
                                <Form.Item
                                  label="Salary"
                                  name="final_salary"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Salary Information Is Required",
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    style={{ width: "100%" }}
                                    addonBefore={currencySelector}
                                  />
                                </Form.Item>

                                <Form.Item
                                  label="Joining Date"
                                  name="joining_date"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input joining date!",
                                    },
                                  ]}
                                >
                                  <DatePicker style={{ width: "100%" }} onChange={onChange} />
                                </Form.Item>

                                <Form.Item
                                  wrapperCol={{
                                    offset: 0,
                                    span: 24,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "100%",

                                      justifyContent: "space-between",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    <Button type="danger" onClick={() => setCheckerModal(null)}>
                                      Cancel
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                      Update Status
                                    </Button>
                                  </div>
                                </Form.Item>
                              </Form>
                            </Modal>

                            {/* Reject MODAL */}
                            <Modal
                              title="Reject Reason"
                              visible={isRejectModalOpen === obj.id}
                              // onOk={handleOk}
                              onCancel={rejectModalCancel}
                              footer={null}
                            >
                              <Form
                                name="basic"
                                onFinish={onRejectModalFinish}
                                onFinishFailed={onRejectModalFail}
                                autoComplete="off"
                              >
                                <Form.Item
                                  label="Reason"
                                  name="reject_reason"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input reason for Rejection',
                                    },
                                  ]}
                                >
                                  <TextArea rows={4} />
                                </Form.Item>
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",

                                    justifyContent: "space-between",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <Button type="danger" onClick={rejectModalCancel}>
                                    Cancel
                                  </Button>
                                  <Button type="primary" htmlType="submit">
                                    Submit Reason
                                  </Button>
                                </div>
                              </Form>
                            </Modal>
                          </>
                        )
                      }
                    </Table.Cell>
                    {/* <Table.Cell key={obj.id}
                      style={{
                        background: obj?.Score[0]?.TotalScore
                          ? (Math.floor(100 * obj?.Score[0]?.TotalScore) > 85
                            ? "#9EE381"
                            : Math.floor(100 * obj?.Score[0]?.TotalScore) > 50
                              ? "#F7E967"
                              : "#ff8989")
                          : "white" // Set to black when there is no score

                      }} >
                      <p style={{
                        fontSize: "16px",
                        fontWeight: "900",
                        color: obj?.Score[0]?.TotalScore
                          ? (Math.floor(100 * obj?.Score[0]?.TotalScore) > 85
                            ? "black"
                            : Math.floor(100 * obj?.Score[0]?.TotalScore) > 50
                              ? "black"
                              : "white")
                          : "black"
                      }} >{Math.floor(100 * obj?.Score[0]?.TotalScore) || "—"}</p>
                    </Table.Cell> */}
                    <Table.Cell key={obj.id}> 
                      {obj?.statusChangedBy && obj.statusChangedBy.length > 0
                      ? obj.statusChangedBy[obj.statusChangedBy.length - 1]: "-"
                      }
                    </Table.Cell>
                    <Table.Cell>
                      <a
                        href={`/dashboard/applicant/job/${obj.id}`}
                        rel="noreferrer"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <Space
                          size="middle"
                          key={obj.id}
                          style={{ cursor: "pointer", fontSize: "17px" }}
                        >
                          <EyeOutlined />
                        </Space>
                      </a>
                    </Table.Cell>

                  </Table.Row>
                </>
              )
            })
          }

        </Table.Body >
        <Table.Footer >
          <Table.Row>
            <Table.HeaderCell colSpan='5'>
              <Menu floated='right' pagination>
                <Menu.Item as='a' icon>
                  <Icon name='chevron left' />
                </Menu.Item>
                <Menu.Item as='a'>1</Menu.Item>
                <Menu.Item as='a'>2</Menu.Item>
                <Menu.Item as='a'>3</Menu.Item>
                <Menu.Item as='a'>4</Menu.Item>
                <Menu.Item as='a' icon>
                  <Icon name='chevron right' />
                </Menu.Item>
              </Menu>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
};