import { Space, Button, Table, Input, Select, Tooltip, Checkbox } from "antd";
import React from "react";
import { Form } from "semantic-ui-react";
import { useEffect, useState } from "react";
import {
  disablePartnerAdmin,
  fetchAllPartnerAdmins,
  fetchMyPartnerAdmins,
  getAllZyvkaUsers,
  UpdatePartnerAdminProfile,
} from "../api/ZyvkaMember";
import { useDispatch } from "react-redux";
import { ZyvkaMemberState } from "../features/ZyvkaMemberSlice";
import { useSelector } from "react-redux";
import { selectUser, UserState } from "../features/userSlice";
import { EditOutlined, StopOutlined } from "@ant-design/icons";
import { PartnerJobs } from "./PartnerJobs";
import { getPartnerJobList } from "../api/partner";
import { Link, useNavigate, Navigate } from "react-router-dom";

const { Column } = Table;

const ModifyPartners = () => {
  const [calls, setCalls] = useState(false);
  const dispatch = useDispatch();
  const zyvkaState = useSelector(ZyvkaMemberState);
  const userState = useSelector(UserState);
  const [data, setData] = useState([]);
  const user = useSelector(selectUser);
  const [currId, setCurrId] = useState();
  const [listData, setListData] = useState();
  const [displayTooltip, setDisplayTooltip] = useState(true)

  const updateHandler = async (id) => {
    if (window.confirm(`Are you sure you want to Update details`)) {
      console.log({
        id: id,
        newFirstName: newFirstName,
        newLastName: newLastName,
        newEmail: newEmail,
        newMobileNum: newMobileNo,
        newCompanyName: newCompanyName,
        PsmId: PsmId
      })
      await UpdatePartnerAdminProfile(
        {
          id: id,
          newFirstName: newFirstName,
          newLastName: newLastName,
          newEmail: newEmail,
          newMobileNum: newMobileNo,
          newCompanyName: newCompanyName,
          PsmId: PsmId
        },
        dispatch
      );
      setUpdateOpen("");
    }
    if (calls === false) setCalls(true);
    else setCalls(false);
  };
  const disableHandler = async (id) => {
    if (window.confirm(`Are you sure you want to Update details`)) {
      await disablePartnerAdmin(
        {
          id: id,
        },
        dispatch
      );
    }
    if (calls === false) setCalls(true);
    else setCalls(false);
  };

  const setDataFun = () => {
    if (zyvkaState.isPending === false) {
      setData(zyvkaState.myPartnerAdmins);
    }
  };

  const fetchPartnerAdmins = async () => {
    try {
      if (
        userState?.role === "zyvkaAdmin" ||
        userState?.role === "superAdmin"
      ) {
        await fetchAllPartnerAdmins(dispatch);
      } else if (userState?.role === "zyvkaUser") {
        await fetchMyPartnerAdmins({ id: user?.id }, dispatch);
      }

      setDataFun();
    } catch (error) {
      console.log(error);
    }
  };
  const getZyvkaUsers = async () => {
    try {
      await getAllZyvkaUsers(dispatch);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPartnerAdmins();
    getZyvkaUsers();
  }, [calls]);

  const [updateOpen, setUpdateOpen] = useState("");

  const [newFirstName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newMobileNo, setNewMobileNumber] = useState("");
  const [newCompanyName, setNewCompanyName] = useState("");
  const [PsmId, setPsmId] = useState();

  // this is for search
  const [searchValue, setSearchValue] = useState("");
  const dropdownOptions = zyvkaState?.zyvkaMembers.filter((data) => data?.isSuccessManager)
    .map((data, idx) => ({
      key: idx,
      label: `${data.firstName} ${data.lastName} (${data.email})`,
      value: data.id,
    }))
  console.log(zyvkaState?.AllPartnerAdmins)
  const foundElement = (id) => {
    const foundMember = zyvkaState?.zyvkaMembers.find((element) => element.id === id);

    if (foundMember) {
      const { firstName, lastName, email } = foundMember;
      return `${firstName} ${lastName} - (${email})`;
    }

    return " No SPM assigned ";
  };

  const inferActionRequired = (data) => {
    const inferArray = [];
    console.log(data)
    if (data?.firstName === "" || data?.firstName === null) {
      inferArray.push("firstName")
    } if (data?.lastName === "" || data?.lastName === null) {
      inferArray.push("lastName")
    } if (data?.email === "" || data?.email === null) {
      inferArray.push("email")
    } if (data?.mobileNum === "" || data?.mobileNum === null) {
      inferArray.push("mobileNum")
    }
    // if (data?.partnerContract === "" || data?.partnerContract === null) {
    //   inferArray.push("partnerContract")
    // }
    if (data?.typeOfHiring === "" || data?.typeOfHiring === null) {
      inferArray.push("typeOfHiring")
    } if (typeof data?.isFreelancer !== "boolean" || data?.isFreelancer === null) {
      inferArray.push("isFreelancer")
    } if (data?.company_name === "" || data?.company_name === null) {
      inferArray.push("company_name")
    } if (data?.rolesFulfilled?.length === 0 || data?.rolesFulfilled === null) {
      inferArray.push("rolesFulfilled")
    } if (data?.geography?.length === 0 || data?.geography === null) {
      inferArray.push("geography")
    } if (data?.sourcingVertical?.length === 0 || data?.sourcingVertical === null) {
      inferArray.push("sourcingVerticals")
    }
    let inferredProps
    if (inferArray?.length === 0) {
      return ""
    }
    inferredProps = inferArray?.join(", ")
    inferredProps += (inferArray?.length > 1 ? ' are ' : ' is ') + ` missing, please fill them at the earliest`
    return inferredProps
  }


  // console.log(zyvkaState?.AllPartnerAdmins)
  // addd current company

  const columns = [
    {
      title: "First Name",
      dataIndex: "id",
      key: "id",
      loading: zyvkaState?.isPending,
      filteredValue: [searchValue],
      onFilter: (value, record) => {
        return (
          String(record?.firstName)
            ?.toLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.lastName)
            ?.toLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.email)
            ?.toLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.mobileNum)
            ?.toLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.company_name)
            ?.toLowerCase()
            .includes(value.toLocaleLowerCase())
        );
      },
      render: (_, record) => {
        return (
          <>
            {updateOpen === record.id ? (
              <div class="input-wrapper" key={record.id}>
                <label for="first">First Name</label>
                <input
                  type="text"
                  value={newFirstName}
                  onChange={(e) => setNewFirstName(e.target.value)}
                ></input>
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }} >
                <Link to={`/partner/${record?.id}`} >
                  <span>{record.firstName}</span>
                </Link>
                {/* <Link to={{`/partner/${record?.id}`, state: {showModal: true }}} > */}
                {
                  displayTooltip ? <Link to={`/partner/${record?.id}`} state={{ showModal: true }} >
                    {
                      inferActionRequired(record) ?
                        <Tooltip
                          title={inferActionRequired(record)}
                        >
                          <span style={{
                            border: "0.15px solid #000",
                            background: "#000",
                            borderRadius: "4px",
                            marginLeft: "10px",
                            paddingLeft: "3px",
                            paddingRight: "3px",
                            fontSize: "13px",
                            fontWeight: "900",
                            color: "white"
                          }} >
                            action required
                          </span>
                        </Tooltip>
                        : null
                    }
                  </Link> : null
                }


              </div >
            )
            }
          </>
        );
      },
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      render: (_, record) => {
        return (
          <Link to={`/partner/${record?.id}`} >
            {updateOpen === record.id ? (
              <div class="input-wrapper" key={record.id}>
                <label for="first">Last Name</label>
                <input
                  value={newLastName}
                  onChange={(e) => setNewLastName(e.target.value)}
                ></input>
              </div>
            ) : (
              <span key={record.id}>{record.lastName}</span>
            )}
          </Link>
        );
      }
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "id",
      render: (_, record) => {
        return (
          <>
            {updateOpen === record.id ? (
              <div class="input-wrapper" key={record.id}>
                <label for="first">Email</label>
                <input
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                ></input>
              </div>
            ) : (
              <span key={record.id}>{record.email}</span>
            )}
          </>
        );
      }
    },
    {
      title: "Mobile",
      dataIndex: "mobileNum",
      key: "id",
      render: (_, record) => {
        return (
          <>
            {updateOpen === record.id ? (
              <div class="input-wrapper" key={record.id}>
                <label for="first">Mobile</label>
                <input
                  value={newMobileNo}
                  onChange={(e) => setNewMobileNumber(e.target.value)}
                ></input>
              </div>
            ) : (
              <span key={record.id}>
                {record.country_code} {record.mobileNum}
              </span>
            )}
          </>
        );
      },
    },
    {
      title: "Company",
      dataIndex: "id",
      key: "id",
      render: (_, record) => {
        return (
          <>
            {updateOpen === record.id ? (
              <div class="input-wrapper" key={record.id}>
                <label for="first">Company Name</label>
                <input
                  value={newCompanyName}
                  onChange={(e) => setNewCompanyName(e.target.value)}
                ></input>
              </div>
            ) : (
              <span key={record.id}>{record?.company_name || " ━ "}  </span>
            )}
          </>
        );
      },
    },
    {
      title: "Partner Success Manager",
      dataIndex: "id",
      key: "id",
      render: (_, record) => {
        return (
          <>
            {updateOpen === record.id ? (
              <div class="input-wrapper" key={record.id}>
                <Select
                  search
                  label="Success Manager"
                  dropdownMatchSelectWidth={false}
                  name="psmID"
                  style={{ width: "auto" }}
                  options={dropdownOptions}
                  value={dropdownOptions.find(
                    (option) => option.value === record?.SuccessManager[0]?.usersId
                  )}
                  onChange={(value) => {
                    setPsmId(value);
                    console.log(PsmId);
                  }}
                  key={record.id}
                ></Select>
              </div>
            ) : (
              <>
                <p> {foundElement(record?.SuccessManager[0]?.usersId)} </p>
              </>
            )}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (_, record) => (
        <Space size="middle" key={record.id}>
          {updateOpen === record.id ? (
            <>                  <Button
              type="primary"
              onClick={() => updateHandler(record.id)}
            >
              Save
            </Button>
              <Button
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => setUpdateOpen("")}
              >
                Cancel
              </Button>
            </>

          ) : (
            <Button
              onClick={() => {
                setUpdateOpen(record.id);
                setNewEmail(record.email);
                setNewFirstName(record.firstName);
                setNewLastName(record.lastName);
                setNewMobileNumber(record.mobileNum);
                setNewCompanyName(record.company_name);
              }}
            >
              <EditOutlined />
            </Button>
          )}

          <Button
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => disableHandler(record?.id)}
          >
            <StopOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  return (

    <>
      <div className="tableDiv">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "center"
            // gap: "10px",
          }}
        // className="searchbox"
        >
          <Space direction="horizontal" wrap={true}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
              <Form.Checkbox
                toggle
                name="displayTooltip"
                checked={displayTooltip}
                onChange={(e, data) => {
                  setDisplayTooltip((val) => !val);
                }}
              ></Form.Checkbox>
              <Button type={!displayTooltip ? "primary" : " secondary"} style={{ marginLeft: "50px" }} onClick={() => setDisplayTooltip((val) => !val)} >
                {
                  displayTooltip ? " Hide " : " Display "
                }
                Action Required
              </Button>
            </div>
          </Space>
          <Space direction="horizontal" wrap={true}>

            <div>
              <Input
                placeholder="Search Here"
                style={{
                  minWidth: 200,
                  maxWidth: 400,
                  paddingLeft: 10,
                }}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
              <Button style={{ width: 80 }}>Search</Button>
            </div>
          </Space>

        </div >

        <Table
          scroll={{ x: true }}
          columns={columns}
          dataSource={
            userState.role === "zyvkaUser"
              ? zyvkaState?.myPartnerAdmins
              : (userState?.role === "zyvkaAdmin" ||
                userState.role === "superAdmin") &&
              zyvkaState?.AllPartnerAdmins
          }
          expandable={{
            expandedRowRender: (record) => {
              return (
                <div key={record?.key} style={{ width: "100%" }}>
                  <PartnerJobs id={record?.id} key={record?.key} data={record?.allocatedJobs} partnerId={record?.id} name={record?.firstName.concat(" ", record?.lastName)} setCalls={setCalls} />
                </div>
              )
            },
          }}
        >
          {/* <Column
            title="First Name"
            dataIndex="firstName"
            loading={zyvkaState?.isPending}
            key="firstName"
            // showOn
            filteredValue={[searchValue]}
            onFilter={(value, record) => {
              return (
                String(record?.firstName)
                  ?.toLowerCase()
                  .includes(value.toLocaleLowerCase()) ||
                String(record?.lastName)
                  ?.toLowerCase()
                  .includes(value.toLocaleLowerCase()) ||
                String(record?.email)
                  ?.toLowerCase()
                  .includes(value.toLocaleLowerCase()) ||
                String(record?.mobileNum)
                  ?.toLowerCase()
                  .includes(value.toLocaleLowerCase()) ||
                String(record?.company_name)
                  ?.toLowerCase()
                  .includes(value.toLocaleLowerCase())
              );
            }}
            // onFilterDropdownOpenChange={getColumnSearchProps("firstName")}
            // onFilter={getColumnSearchProps("firstName")}
            // searchText={getColumnSearchProps("firstName")}
            render={(_, record) => {
              return (
                <>
                  {updateOpen === record.id ? (
                    <div class="input-wrapper" key={record.id}>
                      <label for="first">First Name</label>
                      <input
                        type="text"
                        value={newFirstName}
                        onChange={(e) => setNewFirstName(e.target.value)}
                      ></input>
                    </div>
                  ) : (
                    <span>{record.firstName}</span>
                  )}
                </>
              );
            }}
          />
          <Column
            title="Last Name"
            // dataIndex="name"
            key="lastName"
            // filteredValue={[searchValue]}
            // onFilter={(value, record) => {
            //   return record?.lastName?.includes(value);
            // }}
            render={(_, record) => {
              return (
                <>
                  {updateOpen === record.id ? (
                    <div class="input-wrapper" key={record.id}>
                      <label for="first">Last Name</label>
                      <input
                        value={newLastName}
                        onChange={(e) => setNewLastName(e.target.value)}
                      ></input>
                    </div>
                  ) : (
                    <span key={record.id}>{record.lastName}</span>
                  )}
                </>
              );
            }}
          />
          <Column
            title="Email"
            // filteredValue={[searchValue]}
            // onFilter={(value, record) => {
            //   return record?.email?.includes(value);
            // }}
            render={(_, record) => {
              return (
                <>
                  {updateOpen === record.id ? (
                    <div class="input-wrapper" key={record.id}>
                      <label for="first">Email</label>
                      <input
                        value={newEmail}
                        onChange={(e) => setNewEmail(e.target.value)}
                      ></input>
                    </div>
                  ) : (
                    <span key={record.id}>{record.email}</span>
                  )}
                </>
              );
            }}
          />
          <Column
            title="Mobile Number"
            render={(_, record) => {
              return (
                <>
                  {updateOpen === record.id ? (
                    <div class="input-wrapper" key={record.id}>
                      <label for="first">Mobile</label>
                      <input
                        value={newMobileNo}
                        onChange={(e) => setNewMobileNumber(e.target.value)}
                      ></input>
                    </div>
                  ) : (
                    <span key={record.id}>
                      {record.country_code} {record.mobileNum}
                    </span>
                  )}
                </>
              );
            }}
          />
          <Column
            title="Company"
            render={(_, record) => {
              return (
                <>
                  {updateOpen === record.id ? (
                    <div class="input-wrapper" key={record.id}>
                      <label for="first">Company Name</label>
                      <input
                        value={newCompanyName}
                        onChange={(e) => setNewCompanyName(e.target.value)}
                      ></input>
                    </div>
                  ) : (
                    <span key={record.id}>{record?.company_name || " ━ "}  </span>
                  )}
                </>
              );
            }}
          />
          <Column
            title="Partner Success Manager"
            render={(_, record) => {
              return (
                <>
                  {updateOpen === record.id ? (
                    <div class="input-wrapper" key={record.id}>
                      <Select
                        search
                        label="Success Manager"
                        dropdownMatchSelectWidth={false}
                        name="psmID"
                        style={{ width: "auto" }}
                        options={dropdownOptions}
                        value={dropdownOptions.find(
                          (option) => option.value === record?.SuccessManager[0]?.usersId
                        )}
                        onChange={(value) => {
                          setPsmId(value);
                          console.log(PsmId);
                        }}
                        key={record.id}
                      ></Select>
                    </div>
                  ) : (
                    <>
                      <p> {foundElement(record?.SuccessManager[0]?.usersId)} </p>
                    </>
                  )}
                </>
              );
            }}
          />
          <Column
            title="Action"
            key="action"
            render={(_, record) => (
              <Space size="middle" key={record.id}>
                {updateOpen === record.id ? (
                  <>                  <Button
                    type="primary"
                    onClick={() => updateHandler(record.id)}
                  >
                    Save
                  </Button>
                    <Button
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => setUpdateOpen("")}
                    >
                      Cancel
                    </Button>
                  </>

                ) : (
                  <Button
                    onClick={() => {
                      setUpdateOpen(record.id);
                      setNewEmail(record.email);
                      setNewFirstName(record.firstName);
                      setNewLastName(record.lastName);
                      setNewMobileNumber(record.mobileNum);
                      setNewCompanyName(record.company_name);
                    }}
                  >
                    <EditOutlined />
                  </Button>
                )}

                <Button
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={() => disableHandler(record?.id)}
                >
                  <StopOutlined />
                </Button>
              </Space>
            )}
          /> */}
        </Table>
      </div >
    </>
  );
};

export default ModifyPartners;
