import { Space, Table, Button, Card, Modal, Input, Form, Select } from "antd";
import { Avatar, Divider, List, Skeleton } from "antd";
import React, { useEffect, useState, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { useDispatch, useSelector } from "react-redux";
// import { ZyvkaMemberState } from "../features/ZyvkaMemberSlice";
// import { useSelector } from "react-redux";
import { AddClientContactPerson, approveClient, getAllClients, getAllUnApprovedClients } from "../api/ZyvkaMember";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import countryCode, { defaultcCode } from "../Data/CountryCode";
import Highlighter from "react-highlight-words";
import { ZyvkaMemberState } from "../features/ZyvkaMemberSlice";
import { Link } from "react-router-dom";
import { UserState } from "../features/userSlice";

const ClientVerification = () => {
    const dispatch = useDispatch();
    const zyvkaState = useSelector(ZyvkaMemberState);
    const [companyName, setCompanyName] = useState("");
    const [columndata, setcolumnData] = useState([]);
    const [ClientID, setClientID] = useState("");
    const [flag, setFlag] = useState(false);
    const [currentClient, setCurrentClient] = useState([]);
    const [form] = Form.useForm();
    const userState = useSelector(UserState);
    useEffect(() => {
        const fetchClients = async () => {
            const res = await getAllUnApprovedClients(setcolumnData);
            console.log(res);
        };
        fetchClients();
        setCurrentClient(columndata[0]);
    }, [dispatch, flag]);
    // this is for search
    const [searchValue, setSearchValue] = useState("");
    const info = (id) => {
        Modal.info({
            title: (<div style={{ fontSize: '20px', fontWeight: 'bold' }}>
                Candidate Score
            </div>),
            content: (
                <div>
                    <p style={{ fontSize: '16px', marginRight: '8px' }} >
                        Candidate Score is Zvyka&apos;s latest feature which simplifies the hiring process on Zyvka. It uses smart algorithms to analyze skills, experience, and location, all while giving special attention to your job description. This means you'll spend less time sifting through applicants and more time finding the perfect match for your job.
                    </p>
                    <br />
                    <p style={{ fontSize: '14px', fontWeight: "bold", marginRight: '8px' }} > Range:  0 - 100</p>
                </div>
            ),
            width: 600,
            onOk() { spAdminVerification(id) },
        });
    };
    const spAdminVerification = (id) => {
        try {
            // await dosomething(id);
        } catch (e) {
            console.log(e);
        }
    }


    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            filteredValue: [searchValue],
            onFilter: (value, record) => {
                return (
                    String(record?.name)
                        ?.toLowerCase()
                        .includes(value.toLocaleLowerCase()) ||
                    String(record?.email)
                        ?.toLowerCase()
                        .includes(value.toLocaleLowerCase()) ||
                    String(record?.mobileNum)
                        ?.toLowerCase()
                        .includes(value.toLocaleLowerCase()) ||
                    String(record?.country)
                        ?.toLowerCase()
                        .includes(value.toLocaleLowerCase()) ||
                    String(record?.url)?.toLowerCase().includes(value.toLocaleLowerCase())
                );
            },
            render: (value, key) => {
                return <>{value}</>;
            },
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Mobile",
            dataIndex: "mobileNum",
            key: "mobileNum",
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",

            sorter: (a, b) => a.address.length - b.address.length,
            sortDirections: ["descend", "ascend"],
        },
        {
            title: "Company URL",
            dataIndex: "url",
            key: "url",
        },
        {
            title: "Verified",
            dataIndex: "email",
            key: "superAdminVerified",
            render: (value, record, key) => {
                console.log(record); // this is the whole data 
                return (
                    <>
                        {
                            userState?.role === "zyvkaAdmin" ? (
                                false ? (<Button onClick={() => (alert("This Actions requires Super Admin Privilege"))} >Verified</Button>) : (<Button onClick={() => (alert("This Actions requires Super Admin Privilege"))} >Not Verified</Button>)
                            ) : (
                                false ? (<Button>Verified</Button>) : (<Button onClick={() => showAddNewModal(record?.id, record)} >Not Verified</Button>)
                            )
                        }
                    </>
                );
            },
        }
    ];

    const countryCodeSelectorContactPerson = (
        <Form.Item name="cCode_contact_person" noStyle>
            <Select options={countryCode} defaultValue={defaultcCode}></Select>
        </Form.Item>
    );
    //Add new contacts modal
    const [isAddNewModalOpen, setIsAddNewModalOpen] = useState(false);
    const showAddNewModal = (id, value) => {
        setIsAddNewModalOpen(id);
        setCurrentClient(value);
    };
    const handleOkAddNew = async (id) => {
        await approveClient(
            setFlag,
            id
        );
        handleCancelAddNew();

        // setIsAddNewModalOpen(false);
    };
    const handleCancelAddNew = () => {
        setIsAddNewModalOpen(false);
    };
    // const fetchClients = async () => {
    //     const res = await getAllUnApprovedClients(dispatch);
    //     console.log(res);
    // };
    // fetchClients();
    console.log(columndata);
    console.log(currentClient);
    return (
        <>
            <div style={{ marginTop: "30px" }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        flexWrap: "wrap",
                        gap: "10px",
                    }}
                // className="searchbox"
                >
                    <Space direction="horizontal" wrap={true}>
                        <Input
                            placeholder="Search Here"
                            style={{
                                border: "0.5px solid gray",
                                borderRadius: "5px",
                                outline: "none",
                                position: "relative",
                                width: 150,
                                padding: "3px",
                                paddingTop: "6px",

                                marginTop: "10px",
                                marginBottom: "10px",
                                lineHeight: "4ex",
                                minWidth: 200,
                                maxWidth: 400,
                                paddingLeft: 10,
                            }}
                            onChange={(e) => {
                                setSearchValue(e.target.value);
                            }}
                        />
                        <Button style={{ width: 80 }}>Search</Button>
                    </Space>
                </div>

                <Table
                    scroll={{ x: true }}
                    bordered={true}
                    columns={columns}
                    // expandable={{
                    //     expandedRowRender: (record) => (
                    //         <>
                    //             <div
                    //                 style={{
                    //                     display: "flex",
                    //                     paddingBottom: "10px",
                    //                     flexDirection: "column",
                    //                     justifyContent: "center",
                    //                     // alignItems: "center",
                    //                 }}
                    //             >
                    //                 <span
                    //                     style={{
                    //                         fontSize: "15px",
                    //                         fontWeight: "bolder",
                    //                     }}
                    //                 >
                    //                     Client Contact Persons
                    //                 </span>
                    //             </div>
                    //             <div
                    //                 id="scrollableDiv"
                    //                 style={{
                    //                     height: 300,
                    //                     overflow: "auto",
                    //                     padding: "0 16px",
                    //                     border: "1px solid rgba(140, 140, 140, 0.35)",
                    //                 }}
                    //             >
                    //                 <InfiniteScroll
                    //                     dataLength={100}
                    //                     height={300}
                    //                     loader={
                    //                         <Skeleton
                    //                             avatar
                    //                             paragraph={{
                    //                                 rows: 1,
                    //                             }}
                    //                             active
                    //                         />
                    //                     }
                    //                     // endMessage={<Divider plain></Divider>}
                    //                     scrollableTarget="scrollableDiv"
                    //                 >
                    //                     <List
                    //                         dataSource={record?.contact_person}
                    //                         renderItem={(item) => (
                    //                             <List.Item key={item.id}>
                    //                                 <List.Item.Meta
                    //                                     avatar={<Avatar icon={<UserOutlined />} />}
                    //                                     title={<a href="#">{item.name}</a>}
                    //                                     description={item.email}
                    //                                 />
                    //                                 <div
                    //                                     style={{ display: "flex", flexDirection: "column" }}
                    //                                 >
                    //                                     <span>
                    //                                         {String(item?.designation).toLocaleUpperCase()}
                    //                                     </span>
                    //                                     <span style={{ color: "#1890ff" }}>
                    //                                         {`${item.country_code} ${item?.mobileNum}`}
                    //                                     </span>
                    //                                 </div>
                    //                             </List.Item>
                    //                         )}
                    //                     />

                    //                     <List
                    //                         dataSource={[
                    //                             {
                    //                                 id: record?.id,
                    //                                 message: "Add More",
                    //                                 company: record?.name,
                    //                             },
                    //                         ]}
                    //                         renderItem={(item) => (
                    //                             <List.Item key={item.id}>
                    //                                 {/* <List.Item.Meta description={item?.message} /> */}
                    //                                 <div
                    //                                     style={{
                    //                                         width: "100%",
                    //                                         display: "flex",
                    //                                         justifyContent: "center",
                    //                                         alignItems: "center",
                    //                                     }}
                    //                                 >
                    //                                     <a
                    //                                         onClick={() => {
                    //                                             form.resetFields();
                    //                                             showAddNewModal();
                    //                                             setClientID(item?.id);
                    //                                             setCompanyName(item?.company);
                    //                                         }}
                    //                                     >
                    //                                         {item?.message}
                    //                                     </a>
                    //                                 </div>
                    //                             </List.Item>
                    //                         )}
                    //                     />
                    //                 </InfiniteScroll>
                    //             </div>
                    //         </>
                    //     ),
                    // }}
                    dataSource={columndata}
                />
            </div>

            {/* Modal for adding new contact */}
            {/* <Modal
                title={`Add new contact for ${companyName}`}
                visible={isAddNewModalOpen}
                footer={null}
                onCancel={handleCancelAddNew}
            >
                <Form form={form} onFinish={handleOkAddNew}>
                    <Form.Item
                        name="contact_person_name"
                        rules={[
                            {
                                required: true,
                                message: "Please Enter the Name",
                            },
                        ]}
                    >
                        <Input placeholder="Enter Contact Person's Full Name"></Input>
                    </Form.Item>
                    <Form.Item
                        name="contact_person_email"
                        rules={[
                            {
                                required: true,
                                message: "Please Enter the email",
                            },
                        ]}
                    >
                        <Input
                            type="email"
                            placeholder="Enter Contact Person Email"
                        ></Input>
                    </Form.Item>
                    <Form.Item
                        name="contact_person_designation"
                        // label="Designation"
                        rules={[
                            {
                                required: true,
                                message: "Please Enter the Designation",
                            },
                        ]}
                    >
                        <Input placeholder="Enter Designation" />
                    </Form.Item>
                    <Form.Item
                        name="contact_person_mobileNum"
                        // label="Mobile"
                        rules={[
                            {
                                required: true,
                                message: "Please input mobile number",
                            },
                            {
                                max: 10,
                                message: "Please input 10 digits only",
                            },
                        ]}
                    >
                        <Input
                            addonBefore={countryCodeSelectorContactPerson}
                            placeholder="Enter Client's contact"
                        />
                    </Form.Item>
                    <Button
                        htmlType="submit"
                        //disabled
                        disabled={zyvkaState.isPending === true}
                    >
                        {" "}
                        submit
                    </Button>
                </Form>
            </Modal> */}
            {
                isAddNewModalOpen && (
                    <Modal
                        title={`Add new contact for ${companyName}`}
                        visible={isAddNewModalOpen}
                        onCancel={handleCancelAddNew}
                        onOk={() => handleOkAddNew(currentClient?.revenue[0]?.id)}
                        okText="Verify"
                    >
                        <p>Revenue Model: {currentClient?.revenue[0]?.RevenueMode || "-"}</p>
                        <p>
                            Revenue Value:{" "}
                            {currentClient?.revenue[0]?.Value
                                ? currentClient?.revenue[0]?.RevenueMode === "Percentage"
                                    ? currentClient?.revenue[0]?.Value + "%"
                                    : currentClient?.revenue[0]?.Value + " " + currentClient?.revenue[0]?.Currency
                                : "-"}
                        </p>

                        <p>Payment Terms: {currentClient?.revenue[0]?.PaymentTerms || "-"}</p>
                        <p>Invoicing Terms: {currentClient?.revenue[0]?.InvoicingTerms || "-"}</p>
                        <p>Replacement Timeline: {currentClient?.revenue[0]?.ReplacementTimeline || "-"}</p>
                        <p>Replacement Model: {currentClient?.revenue[0]?.ReplacementModel || "-"}</p>
                        <p>Revenue Currecny: {currentClient?.revenue[0]?.Currency || "-"}</p>
                        {/* <Button
                                primary="true"
                                htmlType="submit"
                                //disabled
                                disabled={zyvkaState.isPending === true}
                            >
                                {" "}
                                submit
                            </Button>
                            <Button
                                danger
                                htmlType="cancel"
                                //disabled
                                onClick={handleCancelAddNew}
                                disabled={zyvkaState.isPending === true}
                            >
                                {" "}
                                cancel
                            </Button> */}
                    </Modal>
                )
            }

        </>
    );
};

export default ClientVerification;
