import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  Switch,
  Typography,
  Card,
  Alert,
  Select,
  message
} from "antd";
import { useSelector } from "react-redux";
import { createPartner } from "../api/AdminPartner";
import { useDispatch } from "react-redux";
import { selectUser } from "../features/userSlice";
import { UserState } from "../features/userSlice";
import { PartnerState } from "../features/partnersSlice";
import { AlertErrorMessage } from "../Error/AlertErrorMessage";
import { defaultcCode } from "../Data/CountryCode";
import countryCode from "../Data/CountryCode";

const { Title } = Typography;
const { Option } = Select;

const countryCodeSelector = (
  <Form.Item name="cCode" noStyle>
    <Select options={countryCode} defaultValue={defaultcCode}></Select>
  </Form.Item>
);
const AddPartner = () => {
  const [componentSize, setComponentSize] = useState("default");
  const [form] = Form.useForm();
  const partnerState = useSelector(PartnerState);
  const onFormLayoutChange = ({ size }) => {
    setComponentSize(size);
  };

  const formTailLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8, offset: 4 },
  };
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userState = useSelector(UserState);

  const submitForm = async () => {
    try {
      const values = await form.validateFields();
      // FORM VALUES GETTING STORED IN OBJECT values
      // Check console after pressing submit
      // console.log(user.id);

      await createPartner(
        {
          firstName: values.fname,
          lastName: values.lname,
          countryCode: values.cCode || defaultcCode,
          partnerAdminID: user.id,
          email: values.email,
          password: values.password,
          mobileNum: values.mobnum,
        },
        dispatch,
        form
      );
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  return (
    <div className="site-card-border-less-wrapper">
      <br></br>
      <Card
        title="Add a partner"
        headStyle={{ fontSize: "25px", fontWeight: "bold" }}
        bordered={false}
        style={{
          width: "auto",
          borderRadius: "10px",
          maxWidth: "900px",
        }}
      >
        <Form
          form={form}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 14,
          }}
          layout="horizontal"
          initialValues={{
            size: componentSize,
          }}
          onValuesChange={onFormLayoutChange}
          size={componentSize}
        >
          <br></br>
          <Form.Item
            name="fname"
            label="First Name"
            rules={[
              {
                required: true,
                message: "Please input the Name!",
              },
            ]}
          >
            <Input placeholder="Enter partner's first name" />
          </Form.Item>
          <Form.Item
            name="lname"
            label="Last Name"
            rules={[
              {
                required: true,
                message: "Please input the Last Name!",
              },
            ]}
          >
            <Input placeholder="Enter partner's last name" />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input placeholder="Enter partner's email" />
          </Form.Item>

          <Form.Item
            name="confirmEmail"
            label="Confirm Email"
            dependencies={["email"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm email!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("email") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two emails that you entered do not match!")
                  );
                },
              }),
            ]}
          >
            <Input placeholder="Confirm Email !" />
          </Form.Item>
          <Form.Item
            name="mobnum"
            label="Mobile"
            rules={[
              {
                required: true,
                message: "Please input mobile number",
              },
              {
                max: 10,
                message: "Please input 10 digits only",
              },
            ]}
          >
            <Input
              addonBefore={countryCodeSelector}
              placeholder="Enter partner's mobile number"
            />
          </Form.Item>
          {partnerState?.isErrors && (
            <AlertErrorMessage
              message={partnerState?.FormErrorMessage?.createPartner}
            />
          )}

          <Form.Item {...formTailLayout}>
            <Button type="primary" onClick={submitForm}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default AddPartner;
