import React, { useState, useEffect } from "react";
import "./styles/login.css";
import Dashboard from "./pages/Dashboard";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./styles/App.less";
import "./styles/responsive.css";
import { notification } from "antd";
// import "antd/dist/antd.less"
import { LogIn } from "./pages/LogIn";
import { UserState } from "./features/userSlice";
import { useSelector } from "react-redux";
import ResetPass from "./pages/ResetPass";
import "./styles/Global.css";
import ClientLogin from "./pages/ClientLogin";
import { CandSheet } from "./pages/CandSheet";
import AppDetail from "./pages/AppDetail";
import { regSw, subscribe } from './serviceWorkerRegistration.js';
import axios from "axios";
import YourHr from "./pages/YourHr.js";
const API = axios.create({ baseURL: process.env.REACT_APP_SERVER_URL });
export const openNotificationWithIcon = (type, message, placement) => {
  notification[type]({
    message: type,
    description: message,
    placement: placement,
  });
};
const App = () => {

  async function registerAndSubscribe() {
    try {
      const serviceWorkerReg = await regSw();
      await subscribe(serviceWorkerReg);
    } catch (error) {
      console.log(error);
    }
  }
  const userState = useSelector(UserState);
  const [loading, setLoading] = useState(true);
  const [notificationData, setNotificationData] = useState();

  // useEffect(async () => {
  //   console.log("app loading");
  //   // await registerAndSubscribe();
  //   const loader = document.getElementById("loading");
  //   if (loader) {
  //     loader.style.display = "none";
  //     setLoading(false);
  //   }
  // }, []);
  useEffect(() => {
    const fetchData = async () => {
      console.log("app loading");
      const loader = document.getElementById("loading");
      if (loader) {
        loader.style.display = "none";
        setLoading(false);
      }
    };
    fetchData();
    console.log("works on render")
  }, []);
  const openNotification = (placement, data) => {
    notification.info({
      message: <span style={{ fontWeight: 'bold', fontSize: '16px' }}>{data?.title}</span>,
      description: `${data?.body}`,
      placement,
      duration: 0
    });
  };

  useEffect(() => {
    const handleNewMessage = async () => {
      try {
        const data = await API.get(`/api/notification/getnotificationforUser/${userState?.userInfo?.id}`, {
          withCredentials: true
        });
        const res = data?.data[0]?.notification_data?.filter(element => new Date(element?.time) > new Date(data?.data[0]?.updatedAt));
        if (res?.length > 0) {
          res?.forEach(element => {
            openNotification('top', element);
          });
          console.log(res, "render");
          const updateTime = await API.post(`api/notification/updateNotificationTimeforUser/${userState?.userInfo?.id}`, { time: data?.data[0]?.notification_data[0]?.time }, { withCredentials: true })
        }
      } catch (e) {
        console.log(e, "Error occurred");
      }

    };
    handleNewMessage();
    const intervalId = setInterval(handleNewMessage, 3 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [userState?.userInfo?.id]);

  return (
    <>
      {!loading && (
        <div style={{ width: "100%", height: "100%", minHeight: "100vh" }}  >
          {/* <ViewApplicant /> */}
          <Router>
            <Routes>
              {/* <Route path="*" element={<Dashboard />} /> */}
              <Route
                path="/yourhr"
                element={
                  <YourHr />
                }
              />
              <Route
                path="/*"
                element={
                  userState.isLoggedIn === true ? <Dashboard /> : <LogIn />
                }
              />

              <Route
                path="/login/*"
                element={
                  userState.isLoggedIn === true ? <Dashboard /> : <LogIn />
                }
              />

              {/* <Route path="/login" element={<Login />} /> */}
              <Route
                path="/dashboard/*"
                element={
                  userState.isLoggedIn === true ? <Dashboard /> : <LogIn />
                }
              />
              {/* <Route path="/dashboard/*" element={<Dashboard />} /> */}
              <Route
                path="auth/resetPassword/:id/:token"
                element={<ResetPass />}
              />
              {/* Route for client Login page, used to see candidates on a position */}
              <Route
                path="auth/clientLogin/:token"
                element={<ClientLogin />}
              />
              <Route
                path="/applicantdetail/:id"
                element={<CandSheet />}
              />
              <Route
                path="/applicant/:id"
                element={<AppDetail />}
              />
              {/* <Route path="/login" element={<LogIn />} /> */}
            </Routes>
          </Router>
        </div>
      )}
    </>
  );
};

export default App;