import { Space, Table, Button, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserState } from "../features/userSlice";
import { getPartnerPendingJobList } from "../api/partner";
import { selectPartnerPendingJobs } from "../features/partnersSlice";

const PartnerPendingJobs = () => {
    const dispatch = useDispatch();
    const [columndata, setcolumnData] = useState([]);
    const [flag, setFlag] = useState(false);
    const [currentJob, setCurrentJob] = useState([]);
    const userState = useSelector(UserState);
    console.log("id here", userState);
    const pendingJobs = useSelector(selectPartnerPendingJobs);
    useEffect(() => {
        const fetchPendingJobs = async () => {
            const res = await getPartnerPendingJobList({ partnerAdminID: userState?.userInfo?.id }, dispatch);
            setcolumnData(res);
        };
        fetchPendingJobs();
        setCurrentJob(columndata?.[0]);
    }, [dispatch, flag]);
    const [searchValue, setSearchValue] = useState("");
    console.log(columndata[0])

    const columns = [
        {
            title: "Job Title",
            key: "jobtitle",
            filteredValue: [searchValue],
            onFilter: (value, record) => {
                return (
                    String(record?.Jobs?.company_name)
                        ?.toLowerCase()
                        .includes(value.toLocaleLowerCase()) ||
                    String(record?.Jobs?.job_title)
                        ?.toLowerCase()
                        .includes(value.toLocaleLowerCase()) ||
                    String(record?.Jobs?.city)
                        ?.toLowerCase()
                        .includes(value.toLocaleLowerCase()) ||
                    String(record?.Jobs?.state)
                        ?.toLowerCase()
                        .includes(value.toLocaleLowerCase()) ||
                    String(record?.Jobs?.jobType)
                        ?.toLowerCase()
                        .includes(value.toLocaleLowerCase()) ||
                    String(record?.Jobs?.country)
                        ?.toLowerCase()
                        .includes(value.toLocaleLowerCase())
                )

            },
            render: (record, key) => {
                return <>{record?.Jobs?.job_title}</>;
            },
        },
        {
            title: "Client",
            key: "client",
            render: (record, key) => {
                return <>{record?.Jobs?.company_name}</>;
            },
        },
        {
            title: "CTC",
            key: "ctc",
            render: (record, key) => {
                return <>{record?.Jobs?.min_CTC}-{record?.Jobs?.max_CTC} {record?.Jobs?.currencyType ==="INR"?"LPA":"K"}</>;
            },
        },
        {
            title: "Job Type",
            key: "jobtype",
            render: (record, key) => {
                return <>{record?.Jobs?.jobType}</>;
            },
        },
        {
            title: "Approval",
            key: "approval",
            render: (value, record) => {
                return (
                    <>
                        <Button >Pending</Button>
                    </>
                )
            }
        }
    ];
    return (
        <>
            <div style={{ marginTop: "30px" }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        flexWrap: "wrap",
                        gap: "10px",
                    }}
                >
                    <Space direction="horizontal" wrap={true}>
                        <Input
                            placeholder="Search Here"
                            style={{
                                border: "0.5px solid gray",
                                borderRadius: "5px",
                                outline: "none",
                                position: "relative",
                                width: 150,
                                padding: "3px",
                                paddingTop: "6px",

                                marginTop: "10px",
                                marginBottom: "10px",
                                lineHeight: "4ex",
                                minWidth: 200,
                                maxWidth: 400,
                                paddingLeft: 10,
                            }}
                            onChange={(e) => {
                                setSearchValue(e.target.value);
                            }}
                        />
                        <Button style={{ width: 80 }}>Search</Button>
                    </Space>
                </div>

                <Table
                    scroll={{ x: true }}
                    bordered={true}
                    columns={columns}
                    dataSource={columndata}
                />
            </div>
        </>
    );
};

export default PartnerPendingJobs;
