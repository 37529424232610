import { useDispatch, useSelector } from 'react-redux';
import { Chat, useCreateChatClient } from 'stream-chat-react';
import "./ChatApp.css"
import { UserState } from "../../features/userSlice"
import ChannelListContainer from './ChannelListContainer';
import ChannelContainer from './ChannelContainer';
import { useState, useEffect } from 'react';
import 'stream-chat-react/dist/css/v2/index.css';
import { Spin } from 'antd';
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { setUnreadCount } from '../../features/chatSlice';
import axios from 'axios';const ChatComponent = () => {
    const user = useSelector(UserState);
    const apiKey = `${process.env.REACT_APP_StreamChat_PROJECT_ID}`;
    const userId = user?.userInfo?.id;
    const token = JSON.parse(localStorage.getItem("chatToken"));
    const [createType, setCreateType] = useState('');
    const [isCreating, setIsCreating] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeactivated, setIsDeactivated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const client = useCreateChatClient({
        apiKey,
        tokenOrProvider: token,
        userData: { id: userId },
    });

    useEffect(()=>{
        if(client){
            client.on((event) => {
                if (event.total_unread_count !== undefined) {
                    dispatch(setUnreadCount(event.total_unread_count));
                }
            })
        }
    },[client])

    useEffect(() => {
        const checkDeactivationStatus = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/chat/checkStatus`, {
                    userID: userId,
                }, { withCredentials: true });

                if (response.status === 200 && response.data.isDeactivate) {
                    setIsDeactivated(true);
                }
            } catch (error) {
                console.log(error);
            } finally {
                if(client){
                setIsLoading(false);
                }else{
                    setTimeout(()=>{
                              if(!client){
                           setIsLoading(false);
                           }
                    },2000)
                }
            }
        };
        checkDeactivationStatus();
    }, [userId])

    useEffect(() => {
        if (client) {
            setIsLoading(false);
        }
    }, [client]);

    const antIcon = <LoadingOutlined style={{ fontSize: 64 }} spin />;

    if (isLoading) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: '#f0f2f5'
            }}>
                <Spin indicator={antIcon} />
                <p style={{ marginTop: 16, fontSize: 24, color: '#1890ff' }}>
                    Loading ...
                </p>
            </div>
        );
    }

    if (isDeactivated) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: '#f0f2f5'
            }}>
                <ExclamationCircleOutlined style={{ fontSize: 48, color: '#ff4d4f' }} />
                <p style={{ marginTop: 16, fontSize: 24, color: '#ff4d4f' }}>
                    {"Your ID has been deactivated"}
                </p>
            </div>
        );
    }

    if (!client) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: '#f0f2f5'
            }}>
                <ExclamationCircleOutlined style={{ fontSize: 48, color: '#ff4d4f' }} />
                <p style={{ marginTop: 16, fontSize: 24, color: '#ff4d4f' }}>
                    {"Unable to initialize chat. Please try again later."}
                </p>
            </div>
        );
    }

    return (
        <div className="app__wrapper">
            <Chat client={client} theme="team light">
                <ChannelListContainer
                    isCreating={isCreating}
                    setIsCreating={setIsCreating}
                    setCreateType={setCreateType}
                    setIsEditing={setIsEditing}
                />
                <ChannelContainer
                    isCreating={isCreating}
                    setIsCreating={setIsCreating}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    createType={createType}    
                    setCreateType={setCreateType}
                />
            </Chat>
        </div>
    );
};

export default ChatComponent;