import axios from "axios";
const API = axios.create({ baseURL: process.env.REACT_APP_SERVER_URL });

export const getDashboardApplicant = async () => {
  try {
    const result = await API.get("api/stats/dashboardApplicant", {
      withCredentials: true,
    });
    //console.log("applicants")
    return result.data.ifurl;
  } catch (error) {
    console.log(error);
  }
};

export const getDashboardJobs = async () => {
  try {
    const result = await API.get("api/stats/dashboardJobs", {
      withCredentials: true,
    });
    //console.log("position");
    return result.data.ifurl;
  } catch (error) {
    console.log(error);
  }
};


export const getPartnerData = async () => {
  try {
    const result = await API.get("api/stats/dashboardPartnersOverview", {
      withCredentials: true,
    });
    return result.data.ifurl;
  } catch (error) {
    console.log(error);
  }
};


export const getZyvkaInternalData = async () => {
  try {
    const result = await API.get("api/stats/dashboardinternal", {
      withCredentials: true,
    });
    return result.data.ifurl;
  } catch (error) {
    console.log(error);
  }
};


export const getZyvkaAiData = async () => {
  try {
    const result = await API.get("api/stats/dashboardZyvkaAI", {
      withCredentials: true,
    });
    return result.data.ifurl;
  } catch (error) {
    console.log(error);
  }
};


export const getUserMonitorData = async () => {
  try {
    const result = await API.get("api/stats/dashboardActivity", {
      withCredentials: true,
    });
    return result.data.ifurl;
  } catch (error) {
    console.log(error);
  }
};