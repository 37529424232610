import { Space, Table, Button, Card, Modal, Input, Form } from "antd";
import { Avatar, Divider, List, Skeleton, Col, DatePicker, Row, Select as Select3, Tooltip } from "antd";
import React, { useEffect, useState, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Select, Checkbox } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
// import { ZyvkaMemberState } from "../features/ZyvkaMemberSlice";
// import { useSelector } from "react-redux";
import { AddClientContactPerson, getAllClients, updateClient } from "../api/ZyvkaMember";
import { countryCodeSemantic } from "../Data/CountryCode";
import { SearchOutlined, UserOutlined, EditOutlined } from "@ant-design/icons";
import countryCode, { defaultcCode } from "../Data/CountryCode";
import Highlighter from "react-highlight-words";
import { ZyvkaMemberState } from "../features/ZyvkaMemberSlice";
import { Link } from "react-router-dom";
import axios from 'axios';
import { UserState } from "../features/userSlice";
import moment from 'moment';

const { TextArea } = Input;
const AllClients = () => {
  const dispatch = useDispatch();
  const zyvkaState = useSelector(ZyvkaMemberState);
  const [companyName, setCompanyName] = useState("");
  const [columndata, setcolumnData] = useState([]);
  const [ClientID, setClientID] = useState("");
  const [lcDate, setLcDate] = useState();
  const [remark, setRemark] = useState();
  const [flag, setFlag] = useState(false);
  const [form] = Form.useForm();
  const [isAddNewModalOpen, setIsAddNewModalOpen] = useState(false);
  const [isUpdateDateModalOpen, setIsUpdateDateModalOpen] = useState(false);
  const [displayTooltip, setDisplayTooltip] = useState(true)
  function formatDateToYYYYMMDD(originalDateString) {
    const originalDate = new Date(originalDateString);

    // Get the individual components
    const year = originalDate.getFullYear();
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
    const day = originalDate.getDate().toString().padStart(2, '0');

    // Format the date as "yyyy-mm-dd"
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  const inferActionRequired = (data, res = 'yes') => {
    const inferArray = [];
    if (data?.name === "" || data?.name === null) {
      inferArray.push("name")
    } if (data?.clientType === "" || data?.clientType === null) {
      inferArray.push("clientType")
    } if (data?.Positions_available === "" || data?.Positions_available === null) {
      inferArray.push("Positions_available")
    } if (data?.mobileNum === "" || data?.mobileNum === null) {
      inferArray.push("mobileNum")
    }
    // if (data?.clientContract === "" || data?.clientContract === null) {
    //   inferArray.push("clientContract")
    // }
    if (data?.email === "" || data?.email === null) {
      inferArray.push("email")
    } if (data?.linkedin_URL === "" || data?.linkedin_URL === null) {
      inferArray.push("linkedin URL")
    } if (data?.company_news === "" || data?.company_news === null) {
      inferArray.push("company_news")
    } if (data?.country === "" || data?.country === null || data?.city === "" || data?.city === null || data?.state === "" || data?.state === null) {
      inferArray.push("companys Location")
    } if (data?.contact_person?.length <= 0 || data?.contact_person?.[0]?.mobileNum === null || data?.contact_person?.[0]?.mobileNum === " ") {
      inferArray.push("contact person mobile")
    } if (data?.contact_person?.length <= 0 || data?.contact_person?.[0]?.designation === null || data?.contact_person?.[0]?.designation === " ") {
      inferArray.push("contact person designation")
    } if (data?.contact_person?.length <= 0 || data?.contact_person?.[0]?.email === null || data?.contact_person?.[0]?.email === " ") {
      inferArray.push("contact person email")
    } if (data?.contact_person?.length <= 0 || data?.contact_person?.[0]?.name === null || data?.contact_person?.[0]?.name === " ") {
      inferArray.push("contact person name")
    } if (data?.url === "" || data?.url === null) {
      inferArray.push("companys URL")
    } if (data?.sourcingVertical?.length <= 0 || data?.sourcingVertical === null) {
      inferArray.push("sourcingVerticals")
    }
    let inferredProps
    if (res === 'no') {
      return inferArray
    }
    if (inferArray?.length === 0) {
      return ""
    }
    inferredProps = inferArray?.join(" , ")
    inferredProps += (inferArray?.length > 1 ? ' are ' : ' is ') + ` missing, please fill them at the earliest`
    return inferredProps
  }
  const isClienContactMissing = (data) => {
    return Array.isArray(data) && data?.every(element => element.includes("client person"));
  };

  useEffect(() => {
    const fetchClients = async () => {
      await getAllClients(dispatch, setcolumnData);
    };
    fetchClients();
  }, [dispatch, flag]);

  const setState = (value, data) => {
    const ret = isClienContactMissing(value) ? { showClientModal: true, clientId: data?.contact_person?.[0]?.id, clientData: data?.contact_person?.[0] } : { showModal: true }
    return ret
  }

  // this is for search
  const [searchValue, setSearchValue] = useState("");
  const columns = [
    {
      title: "Name",
      key: "name",
      filteredValue: [searchValue],
      onFilter: (value, record) => {
        return (
          String(record?.name)
            ?.toLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.email)
            ?.toLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.mobileNum)
            ?.toLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.country)
            ?.toLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.url)?.toLowerCase().includes(value.toLocaleLowerCase())
        );
      },
      render: (value, key, record) => {
        console.log(value?.sourcingVertical?.length <= 0)
        return (
          <Link to={`/dashboard/client/${value?.id}`} style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}>
            {value?.name}
            {
              value?.type && <span
                style={{
                  border: value?.type === "Hot" ? "0.15px solid #BC3F33" : (value?.type === "Warm" ? "0.15px solid orange" : "0.15px solid #6855f5"),
                  background: value?.type === "Hot" ? "#BC3F33" : (value?.type === "Warm" ? "orange" : "#6855f5"),
                  color: value?.type === "Warm" ? "black" : "white",
                  borderRadius: "4px",
                  marginLeft: "10px",
                  paddingLeft: "3px",
                  paddingRight: "3px",
                  fontSize: "11px",
                  fontWeight: "800"
                }}
              >
                {value?.type}
              </span>
            }
            {
              displayTooltip ? <Link to={`/client/${value?.id}`} state={setState(inferActionRequired(value, "no"), value)} >
                {
                  inferActionRequired(value) ?
                    <Tooltip
                      title={inferActionRequired(value)}
                    >
                      <span style={{
                        border: "0.15px solid #000",
                        background: "#000",
                        borderRadius: "4px",
                        marginLeft: "10px",
                        paddingLeft: "3px",
                        paddingRight: "3px",
                        fontSize: "13px",
                        fontWeight: "900",
                        color: "white"
                      }} >
                        action required
                      </span>
                    </Tooltip>
                    : null
                }
              </Link> : null
            }

          </Link >
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobileNum",
      key: "mobileNum",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",

      sorter: (a, b) => a.address.length - b.address.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Positions Available",
      key: "posn_avl",
      render: (value) => {
        if (value && value.Positions_available === true) {
          return "Yes";
        } else {
          return "No";
        }
      },
    },

    {
      title: "Last Connected Date",
      // dataIndex: "url",
      render: (value) => {
        return (
          <>
            <p style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}>
              {formatDateToYYYYMMDD(value?.lastConnectDate)}
              <Button
                onClick={() => {
                  form.resetFields();
                  setLcDate(value?.lastConnectDate);
                  setRemark(value?.remarks);
                  showUpdateDateModal();
                  setClientID(value?.id);
                  setCompanyName(value?.name);

                }}
                style={{ border: "0.15px solid #5346b5", color: "#6855f5", borderRadius: "4px", marginLeft: "10px", paddingLeft: "10px", paddingRight: "10px", fontSize: "15px" }}>
                <EditOutlined />
              </Button>
            </p>


          </>
        );
      },
    },
  ];

  const countryCodeSelectorContactPerson = (
    <Form.Item name="cCode_contact_person" noStyle>
      <Select options={countryCode} defaultValue={defaultcCode}></Select>
    </Form.Item>
  );
  //Add new contacts modal

  const showAddNewModal = () => {
    setIsAddNewModalOpen(true);
  };
  const showUpdateDateModal = () => {
    setIsUpdateDateModalOpen(true);
  };
  const handleOkAddNew = async (values) => {
    await AddClientContactPerson(
      {
        clientID: ClientID,
        contact_person_name: values?.contact_person_name,
        contact_person_email: values?.contact_person_email,
        contact_person_designation: values?.contact_person_designation,
        contact_person_mobileNum: values?.contact_person_mobileNum,
        cCode_contact_person: values?.cCode_contact_person || defaultcCode,
      },
      dispatch,
      setFlag
    );
    handleCancelAddNew();

    // setIsAddNewModalOpen(false);
  };
  const handleOkUpdateDate = async (values) => {
    const parsedDate = new Date(lcDate);
    const formattedDate = parsedDate.toISOString();
    await updateClient(
      dispatch,
      {
        clientID: ClientID,
        lastConnectedDate: values?.lastConnectDate?._d?.toISOString(),
        remarks: values?.remarks
      },
      setcolumnData
    );
    setFlag(!flag);
    handleCancelUpdateDate();
    setIsAddNewModalOpen(false);
  };
  const handleOkClientUpdate = async (values) => {
    await updateClient(
      {
        clientID: ClientID,
        last_connected_date: values?.last_connected_date,
        remark: values?.remark
      },
      dispatch,
      setcolumnData
    );
    handleCancelUpdateDate();

    // setIsAddNewModalOpen(false);
  };
  const handleCancelAddNew = () => {
    form.resetFields();
    setIsAddNewModalOpen(false);
  };
  const handleCancelUpdateDate = () => {
    form.resetFields();
    setIsUpdateDateModalOpen(false);
  };
  return (
    <>
      <div style={{ marginTop: "30px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "center"
            // gap: "10px",
          }}
        // className="searchbox"
        >
          <Space direction="horizontal" wrap={true}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
              <Checkbox
                toggle
                name="displayTooltip"
                checked={displayTooltip}
                onChange={(e, data) => {
                  setDisplayTooltip((val) => !val);
                }}
              ></Checkbox>
              <Button type={!displayTooltip ? "primary" : " secondary"} style={{ marginLeft: "50px" }} onClick={() => setDisplayTooltip((val) => !val)} >
                {
                  displayTooltip ? " Hide " : " Display "
                }
                Action Required
              </Button>
            </div>
          </Space>
          <Space direction="horizontal" wrap={true}>
            <Input
              placeholder="Search Here"
              style={{
                border: "0.5px solid gray",
                borderRadius: "5px",
                outline: "none",
                position: "relative",
                width: 150,
                padding: "3px",
                paddingTop: "6px",

                marginTop: "10px",
                marginBottom: "10px",
                lineHeight: "4ex",
                minWidth: 200,
                maxWidth: 400,
                paddingLeft: 10,
              }}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
            <Button style={{ width: 80 }}>Search</Button>
          </Space>
        </div>

        <Table
          scroll={{ x: true }}
          bordered={true}
          key="table"
          columns={columns}
          expandable={{
            expandedRowRender: (record) => (
              <>
                <div
                  style={{
                    display: "flex",
                    paddingBottom: "10px",
                    flexDirection: "column",
                    justifyContent: "center",
                    // alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: "15px",
                      fontWeight: "bolder",
                    }}
                  >
                    Client Contact Persons
                  </span>
                </div>
                <div
                  id="scrollableDiv"
                  style={{
                    height: 300,
                    overflow: "auto",
                    padding: "0 16px",
                    border: "1px solid rgba(140, 140, 140, 0.35)",
                  }}
                >
                  <InfiniteScroll
                    dataLength={100}
                    height={300}
                    loader={
                      <Skeleton
                        avatar
                        paragraph={{
                          rows: 1,
                        }}
                        active
                      />
                    }
                    // endMessage={<Divider plain></Divider>}
                    scrollableTarget="scrollableDiv"
                  >
                    <List
                      dataSource={record?.contact_person}
                      renderItem={(item) => (
                        <List.Item key={item.id}>
                          <List.Item.Meta
                            avatar={<Avatar icon={<UserOutlined />} />}
                            title={<a href="#">{item.name}</a>}
                            description={item.email}
                          />
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span>
                              {String(item?.designation).toLocaleUpperCase()}
                            </span>
                            <span style={{ color: "#1890ff" }}>
                              {`${item.country_code} ${item?.mobileNum}`}
                            </span>
                          </div>
                        </List.Item>
                      )}
                    />

                    <List
                      dataSource={[
                        {
                          id: record?.id,
                          message: "Add More",
                          company: record?.name,
                        },
                      ]}
                      renderItem={(item) => (
                        <List.Item key={item.id}>
                          {/* <List.Item.Meta description={item?.message} /> */}
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <a
                              onClick={() => {
                                form.resetFields();
                                showAddNewModal();
                                setClientID(item?.id);
                                setCompanyName(item?.company);
                              }}
                            >
                              {item?.message}
                            </a>
                          </div>
                        </List.Item>
                      )}
                    />
                  </InfiniteScroll>
                </div>
              </>
            ),
          }}
          dataSource={columndata}
        />
      </div>

      {/* Modal for adding new contact */}
      <Modal
        title={`Add new contact for ${companyName}`}
        visible={isAddNewModalOpen}
        footer={null}
        onCancel={handleCancelAddNew}
      >
        <Form form={form} onFinish={handleOkAddNew}>
          <Form.Item
            name="contact_person_name"
            rules={[
              {
                required: true,
                message: "Please Enter the Name",
              },
            ]}
          >
            <Input placeholder="Enter Contact Person's Full Name"></Input>
          </Form.Item>
          <Form.Item
            name="contact_person_email"
            rules={[
              {
                required: true,
                message: "Please Enter the email",
              },
            ]}
          >
            <Input
              type="email"
              placeholder="Enter Contact Person Email"
            ></Input>
          </Form.Item>
          <Form.Item
            name="contact_person_designation"
            // label="Designation"
            rules={[
              {
                required: true,
                message: "Please Enter the Designation",
              },
            ]}
          >
            <Input placeholder="Enter Designation" />
          </Form.Item>
          <Form.Item
            name="contact_person_mobileNum"
            // label="Mobile"
            rules={[
              {
                required: true,
                message: "Please input mobile number",
              },
              {
                max: 10,
                message: "Please input 10 digits only",
              },
            ]}
          >
            <Input
              addonBefore={countryCodeSelectorContactPerson}
              placeholder="Enter Client's contact"
            />
          </Form.Item>
          <Button
            htmlType="submit"
            //disabled
            disabled={zyvkaState.isPending === true}
          >
            {" "}
            submit
          </Button>
        </Form>
      </Modal>
      {/*  Modal for updating last Connected Date */}

      <Modal
        centered
        title={`Update Last Connected Date for ${companyName}`}
        visible={isUpdateDateModalOpen}
        footer={null}
        onCancel={handleCancelUpdateDate}
        width={600}
      >
        <Form form={form} onFinish={handleOkUpdateDate}>
          <Form.Item
            name="lastConnectDate"
            label="Last Connect Date"
            rules={[
              {
                required: false,
                message: "Please enter the date",
              },
            ]}
          >
            <DatePicker defaultValue={moment(lcDate)} max={new Date().toISOString().split('T')[0]} placeholder="dd-mm-yyyy" />
          </Form.Item>
          <Form.Item
            name="remarks"
            label="Client Remark"
            rules={[
              {
                required: false,
                message: "Please Enter the Remark",
              },
            ]}
          >
            <TextArea
              defaultValue={remark} rows={4} placeholder="Company's Remark"
            ></TextArea>
          </Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            disabled={UserState?.isPending === true}
          // disabled={!form.getFieldValue('lastConnectDate') && !form.getFieldValue('remarks')}
          >
            {" "}
            submit
          </Button>
        </Form>
      </Modal >
    </>
  );
};

export default AllClients;