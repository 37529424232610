import { InboxOutlined } from '@ant-design/icons';
import { message, Upload, Button } from 'antd';
import { useParams, useNavigate, Link } from 'react-router-dom'
import { getApplicantDetailsByID } from "../api/auth";
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux'
const { Dragger } = Upload;



const VideoResumeUpload = () => {
    const { id } = useParams();
    const queryParams = new URLSearchParams(window.location.search)
    // Get values from the URL parameters
    const fromCreation = queryParams.get('fromCreation')
    const jobid = queryParams.get('jobid')
    const jobTitle = queryParams.get('jobTitle')
    const navigate = useNavigate();
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'video/mp4' || file.type === 'video/ogg' || file.type === 'video/x-msvideo' || file.type === 'video/mpeg' || file.type === 'video/quicktime' || file.type === 'video/x-ms-wmv' || file.type === 'video/x-flv' || file.type === 'video/x-matroska';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 15MB!');
        }
        return isJpgOrPng && isLt2M;
    };
    const props = {
        name: 'file',
        action: `${process.env.REACT_APP_SERVER_URL}/api/video/uploadVideoResume/${id}`,
        beforeUpload: beforeUpload,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
                if (jobid === 'false') {
                    navigate('/dashboard/allApplicants')
                } else {
                    navigate(`/dashboard/myjobs1/${jobid}`)
                }
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const [flag, setFlag] = useState(false)
    const [applicantDetails, setApplicantDetails] = useState(null);
    const [candForm, setCandForm] = useState(null)
    const dispatch = useDispatch();
    console.log("working here");
    const getApplicant = async () => {
        const res = await getApplicantDetailsByID(
            { id: id },
            dispatch,
            setApplicantDetails,
            setCandForm
        );
        if (res?.videoResume === '') {
            message.info(`video resume already uploaded for ${applicantDetails?.firstName} ${applicantDetails?.lastName}`);
            if (jobid === 'false') {
                navigate('/dashboard/allApplicants')
            } else {
                navigate(`/dashboard/myjobs1/${jobid}`)
            }
        }
    };
    const skipAndRedirect = async () => {
        if (jobid === 'false') {
            navigate('/dashboard/allApplicants')
        } else {
            navigate(`/dashboard/myjobs1/${jobid}`)
        }
    }
    useEffect(() => {
        getApplicant();
    }, [flag]);
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            marginX: "auto",
            width: "100%",
            height: "100%",
        }} >
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "center",
                marginX: "auto",
                width: "100%",
                height: "100%",
                marginTop: "5rem"
            }} >
                <h3 style={{ fontSize: "2rem" }} >Upload Video Resume of {applicantDetails?.firstName + " " + applicantDetails?.lastName} for <span style={{ fontWeight: 900, color: "#6E5CF6" }} >{jobTitle}</span>  </h3>
                <p style={{ fontSize: "1.3rem" }} >Please Compress your video at <a href="https://www.freeconvert.com/video-compressor" style={{ color: "#6E5CF6", textDecoration: "none", fontWeight: "bolder" }} target="_blank" rel="noreferrer" >videoconvertor.com</a> first and then upload the compressed video below </p>
                <Dragger {...props} style={{ marginTop: "3rem", paddingLeft: "3rem", paddingRight: "3rem" }} >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint" style={{ color: "#aeaaae" }} >
                        Upload video under 15Mb
                    </p>
                </Dragger>

            </div>
            <div
                style={{
                    display: "flex", margin: "auto", paddingBottom: "5rem", width: "60%", justifyContent: "center", alignContent: "center"
                }} >
                <Button onClick={skipAndRedirect} type="primary" style={{ paddingLeft: "3rem", paddingRight: "3rem", maxWidth: "40rem", width: "100%", fontSize: "1.3rem", fontWeight: 500 }} >
                    Skip Video Upload
                </Button>
            </div >
        </div >
    )
}

export default VideoResumeUpload;