import React, { useState } from 'react'
import { Button, Header, Form, Modal } from 'semantic-ui-react'

const QuestionsForJob = ({ Open, setFormData, formData }) => {
  const [open, setOpen] = React.useState(Open);
  const options = [
    { key: 'num', text: 'Numeric', value: '0' },
    { key: 'yn', text: 'Yes/No', value: '1' },
    { key: 'ot', text: 'Open Text', value: '2' },
  ];
  const [quest, setQuest] = useState();
  const [quesType, setQuestType] = useState();
  const submitHandler = () => {
    setFormData((prevFormData) => {
      let newQuestions = [...prevFormData.questions]
      newQuestions.push(quest);
      let newQuestionType = [...prevFormData.questionType];
      newQuestionType.push(quesType)
      return {
        ...prevFormData,
        questions: newQuestions,
        questionType: newQuestionType
      };
    });
    setOpen(false);
  }
  return (
    <>

      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size="small"
      >
        <Modal.Header>Add Mandatory questions for this position</Modal.Header>

        <Modal.Content>
          <Form onSubmit={submitHandler} >
            <Form.Select
              fluid
              label='Answer Type'
              options={options}
              required={true}
              placeholder='Type of question'
              value={quesType}
              onChange={(e, { value }) => setQuestType(value)} />
            <Form.TextArea required={true} value={quest} onChange={(e, { value }) => setQuest(value)} label='Question' placeholder='Add your question here....' />
          </Form>
          <Form.Button
            style={{
              backgroundColor: "#6855f6",
              color: "#ffffff",
              marginTop: "10px",
            }}
            type="submit"
            onClick={submitHandler}
          >
            Add Question
          </Form.Button>
        </Modal.Content>

        <Modal.Actions>

          <Button color='red' onClick={() => setOpen(false)}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default QuestionsForJob