//THIS IS THE PARTNER APPROVAL OR REJECT SIDE
import { Space, Table, Button, Card, Modal, Input, Form, Select } from "antd";
import {
  Divider,
  Descriptions,
  Col,
  Row,
  Drawer,
  Tag,
  Typography,
  Popconfirm,
  notification,
  message,
} from "antd";
import Currency, { ctcMap, CurrencySymbols } from "../Data/Currency";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddClientContactPerson,
  approveClient,
  getAllClients,
  getAllUnApprovedClients,
} from "../api/ZyvkaMember";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import countryCode, { defaultcCode } from "../Data/CountryCode";
import Highlighter from "react-highlight-words";
import { ZyvkaMemberState } from "../features/ZyvkaMemberSlice";
import { Link } from "react-router-dom";
import { UserState, selectUser } from "../features/userSlice";
import {
  AcceptPartnerJobProposal,
  getPartnerAssignedPendingJobList,
  getPartnerPendingJobList,
} from "../api/partner";
import { selectPartnerPendingJobs } from "../features/partnersSlice";
import parse from "html-react-parser";
import { DownloadOutlined } from "@ant-design/icons";
import axios from "axios";
const { Title } = Typography;

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

const PartnerAssignedPendingJobs = () => {
  const dispatch = useDispatch();
  const zyvkaState = useSelector(ZyvkaMemberState);
  const [companyName, setCompanyName] = useState("");
  const [columndata, setcolumnData] = useState([]);
  const [ClientID, setClientID] = useState("");
  const [flag, setFlag] = useState(false);
  const [currentJob, setCurrentJob] = useState([]);
  const [form] = Form.useForm();
  const userState = useSelector(UserState);
  const pendingJobs = useSelector(selectPartnerPendingJobs);
  console.log(columndata);
  const user = useSelector(selectUser);
  useEffect(() => {
    const fetchPendingJobs = async () => {
      const res = await getPartnerAssignedPendingJobList(
        { partnerAdminID: userState?.userInfo?.id },
        dispatch
      );
      setcolumnData(res);
    };
    fetchPendingJobs();
    setCurrentJob(columndata?.[0]);
  }, [dispatch, flag]);
  const [searchValue, setSearchValue] = useState("");

  const columns = [
    {
      title: "Job Title",
      key: "id",
      filteredValue: [searchValue],
      onFilter: (value, record) => {
        return (
          String(record?.Jobs?.company_name)
            ?.toLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.Jobs?.job_title)
            ?.toLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.Jobs?.city)
            ?.toLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.Jobs?.state)
            ?.toLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.Jobs?.jobType)
            ?.toLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.Jobs?.country)
            ?.toLowerCase()
            .includes(value.toLocaleLowerCase())
        );
      },
      render: (record, key) => {
        console.log("record here", record);
        return <>{record?.Jobs?.job_title}</>;
      },
    },
    {
      title: "Client",
      key: "id",
      render: (record, key) => {
        return <>{record?.Jobs?.company_name}</>;
      },
    },
    {
      title: "CTC",
      key: "ctc",
      render: (record, key) => {
        return (
          <>
            {record?.Jobs?.min_CTC}-{record?.Jobs?.max_CTC}{" "}
            {record?.Jobs?.currencyType === "INR" ? "LPA" : "K"}
          </>
        );
      },
    },
    {
      title: "Job Type",
      key: "id",
      render: (record, key) => {
        return <>{record?.Jobs?.jobType}</>;
      },
    },
    {
      title: "Approval",
      key: "id",
      render: (value, record) => {
        return (
          <>
            {record?.isPertnerAccepted === false ? (
              <Button
                type="primary"
                onClick={() => showAddNewModal(record?.id, record)}
              >
                Detail
              </Button>
            ) : (
              <Button>Pending</Button>
            )}
          </>
        );
      },
    },
  ];
  const [isAddNewModalOpen, setIsAddNewModalOpen] = useState(false);
  const showAddNewModal = (id, value) => {
    setIsAddNewModalOpen(id);
    setCurrentJob(value);
  };
  const handleOkAddNew = async (id, approval) => {
    try {
      await AcceptPartnerJobProposal(
        { assignedJobID: id, approvedStatus: approval },
        dispatch
      );
      notification.open({
        message: (
          <span style={{ fontWeight: "bold", fontSize: "17px" }}>
            {approval === "Accept"
              ? "✅Job Accepted Request Sent"
              : " ❌ Job Declined"}
          </span>
        ),
      });
    } catch (e) {
      handleCancelAddNew();
      message?.error("Some Error Occurred");
    }
    handleCancelAddNew();
    setFlag(!flag);
  };
  const handleCancelAddNew = () => {
    setIsAddNewModalOpen(false);
  };

  //For dynammically Jd generate
  const handleshowPdf = async () => {
    try {
      if (currentJob.Jobs?.isZyvkaJdAiEnable) {
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/files/jd/generate/${currentJob.Jobs.job_title.split(" ").join("-")}`, {
          jobId: currentJob.Jobs?.id,
          name: user?.firstName + " " + user?.lastName,
          mobile: user?.mobileNum,
          email: user?.email
        }, {
          responseType: 'blob',
          withCredentials: true
        });
        const url = URL.createObjectURL(response.data);
        window.open(url, '_blank');
      } else {
        const url = `${process.env.REACT_APP_SERVER_URL}/api/files/jd/${currentJob.Jobs?.jd_urn}`
        window.open(url, '_blank')
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <div style={{ marginTop: "30px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          <Space direction="horizontal" wrap={true}>
            <Input
              placeholder="Search Here"
              style={{
                border: "0.5px solid gray",
                borderRadius: "5px",
                outline: "none",
                position: "relative",
                width: 150,
                padding: "3px",
                paddingTop: "6px",

                marginTop: "10px",
                marginBottom: "10px",
                lineHeight: "4ex",
                minWidth: 200,
                maxWidth: 400,
                paddingLeft: 10,
              }}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
            <Button style={{ width: 80 }}>Search</Button>
          </Space>
        </div>

        <Table
          scroll={{ x: true }}
          bordered={true}
          columns={columns}
          dataSource={columndata}
        />
      </div>
      {isAddNewModalOpen && (
        <Modal
          width={700}
          centered={true}
          style={{
            top: 40,
          }}
          visible={isAddNewModalOpen}
          onCancel={handleCancelAddNew}
          footer={[
            <Button type="ghost" key="cancel" onClick={handleCancelAddNew}>
              Cancel
            </Button>,
            <Popconfirm
              title="Sure you want to Decline this assigned Job?"
              onConfirm={() => handleOkAddNew(currentJob?.id, "Decline")}
              onCancel={() => {
                handleCancelAddNew();
                message?.error("Cancelled");
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger" key="decline">
                Decline
              </Button>
            </Popconfirm>,
            <Popconfirm
              title="Sure you want to Accept this assigned Job?"
              onConfirm={() => {
                handleOkAddNew(currentJob?.id, "Accept");
              }}
              onCancel={() => {
                handleCancelAddNew();
                message?.error("Cancelled");
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" key="accept">
                Accept
              </Button>
            </Popconfirm>,
          ]}
        >
          <Card
            title={currentJob?.Jobs?.job_title}
            headStyle={{ fontSize: "18px", fontWeight: "bold" }}
            extra={
              <div>
                <Button
                  style={{ marginRight: "15px" }}
                  type="ghost"
                  key="cancel"
                  onClick={handleCancelAddNew}
                >
                  Cancel
                </Button>
                <Popconfirm
                  title="Sure you want to Decline this assigned Job?"
                  onConfirm={() => handleOkAddNew(currentJob?.id, "Decline")}
                  onCancel={() => {
                    handleCancelAddNew();
                    message?.error("Cancelled");
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    style={{ marginRight: "15px" }}
                    type="danger"
                    key="decline"
                  >
                    Decline
                  </Button>
                </Popconfirm>
                <Popconfirm
                  title="Sure you want to Accept this assigned Job?"
                  onConfirm={() => {
                    handleOkAddNew(currentJob?.id, "Accept");
                  }}
                  onCancel={() => {
                    handleCancelAddNew();
                    message?.error("Cancelled");
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="primary" key="accept">
                    Accept
                  </Button>
                </Popconfirm>
              </div>
            }
          >
            <Row>
              <Col span={12}>
                <DescriptionItem
                  title="Company Name"
                  Link={"https://www.google.com"}
                  content={
                    <a
                      href={currentJob?.Jobs?.client?.url}
                      style={{ color: "inherit" }}
                    >
                      {currentJob?.Jobs?.company_name}
                    </a>
                  }
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Total Rounds"
                  content={currentJob?.Jobs?.number_of_rounds}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <DescriptionItem
                  title="Experience"
                  content={
                    currentJob?.Jobs?.min_experience_req +
                    " - " +
                    currentJob?.Jobs?.max_experience_req +
                    " Years"
                  }
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="CTC"
                  content={`${CurrencySymbols[currentJob?.Jobs?.currencyType]}
            ${currentJob?.Jobs?.min_CTC}${ctcMap[
                    currentJob?.Jobs?.currencyType
                      ? currentJob?.Jobs?.currencyType
                      : "INR"
                    ]
                    } - ${currentJob?.Jobs?.max_CTC}${ctcMap[
                    currentJob?.Jobs?.currencyType
                      ? currentJob?.Jobs?.currencyType
                      : "INR"
                    ]
                    } P.A.`}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "13px" }}>
              <Col span={12}>
                <DescriptionItem
                  title="Job Type"
                  content={currentJob?.Jobs?.jobType}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="No. of Positions"
                  content={currentJob?.Jobs?.number_of_positions}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ paddingTop: "13px" }}>
                <DescriptionItem
                  title="Job Place"
                  labelStyle
                  content={
                    currentJob?.Jobs?.country +
                    "  - " +
                    currentJob?.Jobs?.state +
                    "  , " +
                    currentJob?.Jobs?.city
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ paddingTop: "13px" }}>
                <DescriptionItem
                  title="Skills Required"
                  labelStyle
                  content={
                    <div
                      style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
                    >
                      {currentJob?.Jobs?.skills_req?.map((data1) => {
                        return (
                          <>
                            <Tag color="blue">{data1.toUpperCase() + "  "}</Tag>
                          </>
                        );
                      })}
                    </div>
                  }
                />
              </Col>
            </Row>
          </Card>
          <Card style={{ marginTop: "30px" }}>
            <Row>
              <Col span={24}>
                <DescriptionItem
                  title={<b> Job Description</b>}
                  content={
                    <>
                      {currentJob?.Jobs?.job_description[0] === "<" ? (
                        <div>{parse(currentJob?.Jobs?.job_description)}</div>
                      ) : (
                        <pre
                          style={{
                            color: "black",
                            whiteSpace: "pre-wrap",
                            fontFamily: "monospace",
                          }}
                        >
                          {currentJob?.Jobs?.job_description}
                        </pre>
                      )}
                    </>
                  }
                />
              </Col>
              <Divider />
              <Col span={24}>
                <a
                  target="_blank"
                  rel="noreferrer"
                // href={`${process.env.REACT_APP_SERVER_URL}/api/files/jd/${currentJob?.Jobs?.jd_urn}`}
                >
                  <Button type="primary" icon={<DownloadOutlined />} onClick={handleshowPdf}>
                    Download JD
                  </Button>
                </a>
              </Col>
            </Row>
          </Card>
          <Card style={{ marginTop: "30px" }}>
            <Row>
              <Col span={24}>
                <DescriptionItem
                  title={<b>Hiring Process / Round Details</b>}
                  content={currentJob?.Jobs?.hiring_process?.map(
                    (val, _idx) => {
                      //console.log(val);
                      return (
                        <div>
                          <Title level={5}>{JSON.parse(val).name} :</Title>
                          <pre
                            style={{
                              whiteSpace: "pre-wrap",
                              fontFamily: "inherit",
                            }}
                          >
                            {JSON.parse(val).description}
                          </pre>
                        </div>
                      );
                    }
                  )}
                />
              </Col>
            </Row>
          </Card>
        </Modal>
      )}
    </>
  );
};

export default PartnerAssignedPendingJobs;
