import { createSlice } from "@reduxjs/toolkit";
import { openNotificationWithIcon } from "../App";

export const partnersSlice = createSlice({
  name: "partners",
  initialState: {
    partnersData: [],
    myApplicants: [],
    assignedJobs: [],
    isPending: false,
    isErrors: false,
    HotJobs: [],
    TechJobs: [],
    PartnerHot: [],
    PartnerNonTech: [],
    PartnerTech: [],
    NonTechJobs: [],
    pendingJobList: [],
    FormErrorMessage: {
      createPartner: null,
    },
  },
  reducers: {
    start: (state) => {
      state.isPending = true;
      state.isErrors = false;
    },
    // registersuccess: (state, action) => {
    //   state.isPending = false;
    // },
    createPartnerStart: (state) => {
      state.isErrors = false;
      state.FormErrorMessage.createPartner = null;
      state.isPending = true;
    },
    createPartnerSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;

      openNotificationWithIcon(
        "success",
        "Partner Created Successfully !!",
        "bottomRight"
      );
    },
    createPartnerError: (state, action) => {
      state.isErrors = true;
      state.isPending = false;

      if (action.payload.status === 401) {
        if (state.isLoggedIn === true) {
          openNotificationWithIcon("info", "Please Login Again", "bottomRight");
          state.isLoggedIn = false;
          state.userInfo = null;
          localStorage.clear();
        } else {
          state.isErrors = false;
        }
      } else {
        openNotificationWithIcon(
          "error",
          action.payload.data.message || "Some Error Occured",
          "bottomRight"
        );
        state.FormErrorMessage.createPartner = action.payload.data.message;
      }
    },

    success: (state) => {
      state.isPending = false;
    },
    ReferApplicantSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      openNotificationWithIcon(
        "success",
        "Candidate Applied Successfully !!",
        "bottomRight"
      );
    },
    ReferApplicantError: (state) => {
      state.isErrors = true;
      state.isPending = false;
      openNotificationWithIcon("error", "Some Error Occured!!", "bottomRight");
    },
    successFetch: (state, action) => {
      state.partnersData = action.payload;
      state.isPending = false;
    },
    successFetchJob: (state, action) => {
      state.assignedJobs = action.payload;
      state.isPending = false;
    },
    successFetchMyApplicant: (state, action) => {
      state.myApplicants = action.payload;
      state.isPending = false;
    },
    deleteSuccess: (state, action) => {
      state.isPending = false;
    },
    errors: (state) => {
      state.isPending = false;
      state.isErrors = true;
    },
    hotJobsFetchSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      state.HotJobs = action.payload;
    },
    TechJobsFetchSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      state.TechJobs = action.payload;
    },
    NonTechJobsFetchSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      state.NonTechJobs = action.payload;
    },
    partnerHotJobsFetchSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      state.PartnerHot = action.payload;
    },
    partnerTechJobsFetchSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      state.PartnerTech = action.payload;
    },
    partnerNonTechJobsFetchSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      state.PartnerNonTech = action.payload;
    },
    pendingJobListFetchSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      state.pendingJobList = action.payload;
    },
    startUpdatePartner: (state) => {
      state.isErrors = false;
      state.isPending = true;
    },
    succesUpdatePartner: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      openNotificationWithIcon(
        "success",
        "Updated Successfully !!!",
        "bottomRight"
      );
    },
    errorsUpdatePartner: (state) => {
      state.isErrors = true;
      state.isPending = false;
      openNotificationWithIcon(
        "error",
        "Some Error Occured ! Try Again",
        "bottomRight"
      );
    },
  },
});
export const {
  start,
  successFetch,
  errors,
  deleteSuccess,
  successFetchMyApplicant,
  success,
  successFetchJob,
  hotJobsFetchSuccess,
  TechJobsFetchSuccess,
  NonTechJobsFetchSuccess,
  ReferApplicantSuccess,
  ReferApplicantError,
  createPartnerError,
  createPartnerStart,
  createPartnerSuccess,
  startUpdatePartner,
  errorsUpdatePartner,
  succesUpdatePartner,
  partnerNonTechJobsFetchSuccess,
  partnerTechJobsFetchSuccess,
  partnerHotJobsFetchSuccess,
  pendingJobListFetchSuccess
} = partnersSlice.actions;
export const PartnerState = (state) => state.partners;
export const selectTechJobs = (state) => state.partners.TechJobs;
export const selectHotJobs = (state) => state.partners.HotJobs;
export const selectNonTechJobs = (state) => state.partners.NonTechJobs;

export const selectPartnerTechJobs = (state) => state.partners.PartnerTech;
export const selectPartnerHotJobs = (state) => state.partners.PartnerHot;
export const selectPartnerNonTechJobs = (state) => state.partners.PartnerNonTech;
export const selectPartnerPendingJobs = (state) => state.partners.pendingJobList;
export const selectPartners = (state) => state.partners.partnersData;
export const selectMyApplicants = (state) => state.partners.myApplicants;
export default partnersSlice.reducer;
