import React, { useState, useEffect } from "react";
import { Card, Form, Input } from "semantic-ui-react";
import { UserState, selectUser } from "../features/userSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { createApplicant, verifyApplicant } from "../api/auth";
import { fetchAllSkills } from "../api/auth";
import countryCode, {
  countryCodeSemantic,
  defaultcCode,
} from "../Data/CountryCode";
import Currency, { defaultCurrency } from "../Data/Currency";
import ErrorMessage from "../Error/ErrorMessage";
import SuccessMessage from "../Small-Components/SuccessMessage";
import { useNavigate } from 'react-router-dom'
import { message } from 'antd'

// const { Title } = Typography;

const CreateApplicant = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [sourceValue1, setSourceValue1] = useState("");
  const userState = useSelector(UserState);
  const [skillOption, setSkillOption] = useState([]);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    countryCode: defaultcCode,
    mobileNum: "",
    key_skills: [],
    file: null,
    current_company: "",
    current_designation: "",
    salary_currency: defaultCurrency,
    current_ctc: 0,
    expected_ctc: 0,
    notice_period: 0,
    total_experience: 0,
    location: "",
    applicant_story: "",
    remarks: "",
    candidateSource: `${userState.role === "partnerAdmin" || userState.role === "partner" ? `${userState.role === "partnerAdmin" ? "Partner Admin" : "Partner"} ${userState.userInfo.firstName} ${userState.userInfo.lastName}` : ""}`,
    candidateSourceOther: "",
    linkedIn_URL: "",
    ctc_negotiable: false,

  });
  const fetchSkills = async () => {
    try {
      await fetchAllSkills(dispatch, setSkillOption);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchSkills();
  }, []);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneRegex = /^\d{10}$/;
  const verifyCandidate = async () => {
    if (emailRegex.test(formData?.email) && phoneRegex.test(formData?.mobileNum)) {
      try {
        const res = await verifyApplicant({ email: formData?.email, mobileNum: formData?.mobileNum })
        console.log(res)
        if (res?.duplicate === 'yes') {
          message.error("A candidate already exists with same email or mobile number")
        } else if (res?.duplicate === 'no') {
          message.success("No duplicate candidate found")
        }
      } catch (e) {
        console.log(e)
      }
    } else if (!emailRegex.test(formData?.email) && !phoneRegex.test(formData?.mobileNum)) {
      message.error("Please enter Valid Email and Mobile Number")
    } else if (!emailRegex.test(formData?.email)) {
      message.error("Please enter Valid Email")
    } else {
      message.error("Please enter Valid Mobile Number")
    }
  }

  const submitForm = async (e) => {
    try {
      e.preventDefault();
      console.log(formData);
      const formdata = new FormData();
      formdata.append("countryCode", formData?.countryCode);
      formdata.append("firstName", formData?.firstName);
      formdata.append("lastName", formData?.lastName);
      formdata.append("creatorId", user.id);
      formdata.append("mobileNum", formData?.mobileNum);
      formdata.append("email", formData?.email);
      formdata.append("current_company", formData?.current_company);
      formdata.append("current_designation", formData?.current_designation);
      formdata.append("current_salary", formData?.current_ctc);
      formdata.append("expected_salary", formData?.expected_ctc);
      formdata.append("salary_currency", formData?.salary_currency);
      formdata.append("notice_period", formData?.notice_period);
      formdata.append("key_skills", formData?.key_skills);
      formdata.append("remarks", formData?.remarks);
      formdata.append("location", formData?.location);
      formdata.append("linkedIn_URL", formData?.linkedIn_URL);
      formdata.append("total_experience", formData?.total_experience);
      formdata.append("ctc_negotiable", formData?.ctc_negotiable);
      formdata.append("file", formData?.file);
      if (formData?.candidateSource !== "Other") {
        formdata.append("applicantSource", formData?.candidateSource);
      } else {
        formdata.append("applicantSource", formData?.candidateSourceOther);
      }
      if (formData?.applicant_story?.trim() === "" || formData?.applicant_story?.length === 0) {
        let applicantStory = "";
        let name = `${formData?.firstName} ${formData?.lastName}`;
        let experience = formData?.total_experience ? `an experience of ${formData.total_experience} years` : "a fresher";
        let company = formData?.current_company;
        let locationStr = formData?.location ? `based out of ${formData.location}` : "";

        if (company?.trim().length >= 3) {
          if (experience > 0) {
            applicantStory += `${name} has ${experience} and is currently employed with ${company}. `;
          } else {
            applicantStory += `${name} has ${experience} and is currently employed with ${company}. `;
          }
        } else {
          if (experience > 0) {
            applicantStory += `${name} has ${experience}. `;
          } else {
            applicantStory += `${name} has ${experience}. `;
          }
        }
        if (locationStr && formData?.location?.trim().length >= 3) {
          applicantStory += `${name} is ${locationStr}. `;
        }
        if (formData?.key_skills && formData?.key_skills.length > 0) {
          let skills = formData?.key_skills.join(", ");
          applicantStory += `${name} has capabilities on key skills sets like ${skills}.`;
        }
        formdata.append("applicant_story", applicantStory);
      }
      else {
        formdata.append("applicant_story", formData?.applicant_story);
      }
      const resp = await createApplicant(formdata, dispatch, setFormData);
      if (resp && resp?.status === 200) {
        navigate(`/uploadVideoResume/${resp?.data?.id}?fromCreation=true&jobid=false`)
      }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    console.log(formData)
  };


  return (
    <div className="site-card-border-less-wrapper">
      {/* <br></br> */}
      <Card
        headStyle={{ fontSize: "25px", fontWeight: "bold" }}
        bordered={false}
        style={{
          width: "auto",
          padding: "15px",
          maxWidth: "900px",
          marginBottom: "100px",
          marginTop: "10px",
        }}
      >
        <Card.Content header="Create new applicant"></Card.Content>
        <Card.Content>
          <Form onSubmit={submitForm} loading={userState?.isPending}>
            <Form.Group fluid widths="equal">
              <Form.Input
                fluid
                name="firstName"
                required={true}
                placeholder="Enter First Name"
                label="First Name"
                value={formData?.firstName}
                onChange={changeHandler}
              ></Form.Input>
              <Form.Input
                fluid
                name="lastName"
                required={true}
                label="Last Name"
                placeholder="Enter Last Name"
                value={formData?.lastName}
                onChange={changeHandler}
              ></Form.Input>
            </Form.Group>
            <Form.Input
              type="email"
              required={true}
              placeholder="Enter email"
              name="email"
              label="Email"
              value={formData?.email}
              onChange={changeHandler}
            ></Form.Input>
            <Form.Group widths={"18"}>
              <Form.Select
                defaultValue={defaultcCode}
                label="Country Code"
                name="countryCode"
                placeholder="country code"
                options={countryCodeSemantic}
                required={true}
                value={formData?.countryCode}
                onChange={(e, data) => {
                  setFormData({ ...formData, [data.name]: data.value });
                }}
              ></Form.Select>
              <Form.Input
                fluid
                label="Mobile Number"
                name="mobileNum"
                placeholder="Enter mobile number"
                required={true}
                value={formData?.mobileNum}
                onChange={changeHandler}
                error={
                  formData?.mobileNum
                    ? formData?.mobileNum.length > 10 &&
                    "Mobile Number should be 10 digits only"
                    : false
                }
                width={9}
              />
              <Form.Button label='Verify' color='violet' onClick={verifyCandidate} >
                Check for duplicacy
              </Form.Button>
            </Form.Group>

            <Form.Select
              options={skillOption}
              search
              required={true}
              name="key_skills"
              multiple
              label="Key skills"
              placeholder="Enter candidate's Key Skills"
              value={formData?.key_skills}
              onChange={(e, data) => {
                setFormData({ ...formData, [data.name]: data.value });
              }}
            ></Form.Select>
            <Form.Input
              type="file"
              required={true}
              label="Upload Resume"
              accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              name="file"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  [e.target.name]: e.target.files[0],
                });
              }}
            ></Form.Input>
            <Form.Group widths="equal">
              <Form.Input
                label={`Current Company `}
                required={false}
                name="current_company"
                placeholder={` Current Company`}
                value={formData?.current_company}
                onChange={changeHandler}
              ></Form.Input>
              <Form.Input
                label={` Current Designation `}
                required={false}
                placeholder={` CEO   |   Manager   |   .... `}
                name="current_designation"
                value={formData?.current_designation}
                onChange={changeHandler}
              ></Form.Input>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Select
                defaultValue={defaultCurrency}
                options={Currency}
                label="Salary currency"
                required={true}
                name="salary_currency"
                value={formData?.salary_currency}
                onChange={(e, data) => {
                  setFormData({ ...formData, [data.name]: data.value });
                }}
              ></Form.Select>
              <Form.Input
                type="number"
                label={`Current CTC ( In ${formData?.salary_currency === "INR" ? "LPA" : "KPA"
                  })`}
                required={true}
                name="current_ctc"
                placeholder={`Enter Current ctc (in ${formData?.salary_currency === "INR" ? "LPA" : "KPA"
                  })`}
                value={formData?.current_ctc}
                onChange={changeHandler}
                error={
                  formData?.current_ctc
                    ? formData?.current_ctc.length > 5 &&
                    "Please enter CTC in Decimal format"
                    : false
                }
              ></Form.Input>
              <Form.Input
                type="number"
                label={`Expected CTC ( In ${formData?.salary_currency === "INR" ? "LPA" : "KPA"
                  })`}
                required={true}
                placeholder={`Enter expected CTC (in ${formData?.salary_currency === "INR" ? "LPA" : "KPA"
                  })`}
                name="expected_ctc"
                value={formData?.expected_ctc}
                onChange={changeHandler}
                error={
                  formData?.expected_ctc
                    ? formData?.expected_ctc.length > 5 &&
                    "Please enter CTC in Decimal format"
                    : false
                }
              ></Form.Input>
            </Form.Group>

            <Form.Group widths={"equal"}>
              <Form.Input
                name="notice_period"
                label="Notice Period (in days)"
                defaultValue={0}
                placeholder="Enter Candidate's notice period."
                value={formData?.notice_period}
                onChange={changeHandler}
                type="number"
              ></Form.Input>
              <Form.Input
                name="total_experience"
                label="Experience (in years)"
                defaultValue={0}
                placeholder="Enter candidate's total experience."
                value={formData?.total_experience}
                onChange={changeHandler}
                type="number"
              ></Form.Input>
            </Form.Group>
            <Form.Input
              name="location"
              label="Location"
              required={true}
              placeholder="Enter candidate's Location"
              value={formData?.location}
              onChange={changeHandler}
            ></Form.Input>
            {/* <Form.TextArea
              name="applicant_story"
              label="Candidate Hightlight"
              value={formData?.applicant_story}
              onChange={changeHandler}
              maxLength={500}
              placeholder="Enter candidate's highlight, if required ( 500 words max.)"
            ></Form.TextArea> */}
            <Form.TextArea
              name="remarks"
              label="Remarks"
              value={formData?.remarks}
              onChange={changeHandler}
              maxLength={200}
              placeholder="Enter any remarks, if required (200 words max.)"
            ></Form.TextArea>

            {userState.role === "partnerAdmin" || userState.role === "partner" ?
              <>
                <Form.Input
                  //style={{color:"red"}}
                  name="candidateSource"
                  required={true}
                  label="Candidate's source"
                  value={formData?.candidateSource}
                  defaultValue={`${userState.role === "partnerAdmin" ? "Partner Admin" : "Partner"} - ${userState.userInfo.firstName} ${userState.userInfo.lastName}`}

                  placeholder={`${userState.role === "partnerAdmin" ? "Partner Admin" : "Partner"} - ${userState.userInfo.firstName} ${userState.userInfo.lastName}`}
                  disabled={true}
                ></Form.Input>
                {/* {setFormData({ ...formData, candidateSource: `${userState.role === "partnerAdmin" ? "Partner Admin" : "Partner"} - ${userState.userInfo.firstName} ${userState.userInfo.lastName}` })} */}
              </>
              :
              <Form.Group widths={"equal"}>
                <Form.Select
                  options={[
                    { key: 1, text: "LinkedIn", value: "LinkedIn" },
                    { key: 2, text: "Naukri", value: "Naukri" },
                    { key: 3, text: "Shine", value: "Shine" },
                    { key: 4, value: "Other", text: "Other (Please Mention)" },
                  ]}
                  name="candidateSource"
                  required={true}
                  label="Candidate's source"
                  placeholder="Select Candidate's Source"
                  value={formData?.candidateSource}
                  onChange={(e, data) => {
                    setFormData({ ...formData, [data.name]: data.value });

                  }}
                ></Form.Select>
                <Form.Input
                  type="text"
                  name="candidateSourceOther"
                  disabled={formData?.candidateSource !== "Other"}
                  label="Other"
                  placeholder="Please mention other source information"
                  value={formData?.candidateSourceOther}
                  onChange={changeHandler}
                ></Form.Input>
              </Form.Group>}

            <Form.Input
              name="linkedIn_URL"
              label="LinkedIn url"
              placeholder="Enter Candidate's Linkedin Profile url (if any)"
              value={formData?.linkedIn_URL}
              onChange={changeHandler}
            ></Form.Input>
            <Form.Checkbox
              toggle
              name="ctc_negotiable"
              label="CTC negotiable ?"
              checked={formData?.ctc_negotiable}
              onChange={(e, data) => {
                setFormData({ ...formData, [data.name]: data.checked });
              }}
            ></Form.Checkbox>

            {userState?.isErrors && (
              <ErrorMessage
                header={"Error Message"}
                content={userState.FormErrorMessage?.createApplicant}
              />
            )}
            {userState?.isErrors === false &&
              userState?.FormSuccess.createApplicant === true && (
                <SuccessMessage
                  header="Success"
                  content="Candidate Created Successfully !"
                />
              )}

            <Form.Button
              type="submit"
              floated="right"
              style={{
                backgroundColor: "#6855f6",
                color: "white",
                marginTop: "10px",
                right: 0,
              }}
            >
              Submit
            </Form.Button>
          </Form>
        </Card.Content>
      </Card>
    </div>
  );
};

export default CreateApplicant;