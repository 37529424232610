import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    aiAnalytics,
    fetchZyvkaAi,
} from "../features/metabaseSlice";
import {
    getZyvkaAiLocalCache,
} from "../cache/localStorage";

const MetabaseAnalyticsAi = () => {
    const [url, setUrl] = useState("")
    const dispatch = useDispatch()
    const zyvkaAiAnalytic = useSelector(aiAnalytics)
    console.log("new page")
    useEffect(() => {
        const getData = async () => {
            const posUrl = getZyvkaAiLocalCache();
            if (!posUrl) {
                dispatch(fetchZyvkaAi());
                setUrl(zyvkaAiAnalytic);
            } else {
                setUrl(posUrl);
            }
        }
        getData()
    }, [dispatch,
        zyvkaAiAnalytic,
        url, setUrl])

    return <div style={{ width: "98%", maxWidth: "80vw" }} >
        <iframe
            loading="eager"
            src={url}
            style={{
                width: "100%",
                height: "100%",
                minHeight: "80vh",
                border: "2px solid #4C577370"
            }}
            title="Ai Analytics"
        />
    </div>;
};

export default MetabaseAnalyticsAi;
