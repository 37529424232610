import React, { useState, useEffect } from "react";
//import { Button, Input, Select, Divider, Typography } from "antd";
import { DatePicker } from "antd";
import axios from "axios";
import { CreateNewClient, getIndustryData, getVerticalSourcingData } from "../api/ZyvkaMember";
import { UserState, sourcingVerticalOptions } from "../features/userSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ZyvkaMemberState } from "../features/ZyvkaMemberSlice";
import { AlertErrorMessage } from "../Error/AlertErrorMessage";
import countryCode, { defaultcCode } from "../Data/CountryCode";
import { Form, Card, TextArea, Select, Divider } from "semantic-ui-react";
import { countryCodeSemantic } from "../Data/CountryCode";
import ErrorMessage from "../Error/ErrorMessage";
import moment from 'moment';
import data from "../Data/Data";

// const { TextArea } = Input;
// const { Option } = Select;
// const { Title } = Typography;

// const countryCodeSelector = (
//   <Form.Item name="cCode" noStyle>
//     <Select options={countryCode} defaultValue={defaultcCode}></Select>
//   </Form.Item>
// );
// const countryCodeSelectorContactPerson = (
//   <Form.Item name="cCode_contact_person" noStyle>
//     <Select options={countryCode} defaultValue={defaultcCode}></Select>
//   </Form.Item>
// );

const CreateClient = () => {
  const [componentSize, setComponentSize] = useState("default");
  //const [form] = Form.useForm();
  const userState = useSelector(UserState);
  const zyvkaState = useSelector(ZyvkaMemberState);

  const [country, setCountry] = useState("");
  const [countryOption, setCountryOption] = useState([]);
  const [stateOption, setStateOption] = useState([]);
  const [cityOption, setCityOption] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const fetchIndustryData = async () => {
    try {
      const res = await getIndustryData();
      setIndustryOptions(res?.industry);
    } catch (e) {
      console.error(e)
    }
  }
  const [state, setState] = useState(null);
  const dispatch = useDispatch();
  const [fileObj, setFileObj] = useState()

  const onFormLayoutChange = ({ size }) => {
    setComponentSize(size);
  };

  const formTailLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8, offset: 4 },
  };

  const currencyOptions = [
    { id: 1, text: "INR", value: "INR" },
    { id: 2, text: "USD", value: "USD" },
  ];
  const modelOptions = [
    { id: 1, text: "Percentage", value: "Percentage" },
    { id: 2, text: "Fixed Price", value: "Fixed Price" },
  ];
  const repModel = [
    { id: 1, text: "Replacement", value: "Replacement" },
    { id: 2, text: "Refund", value: "Refund" },
  ];
  const clientType = [
    { id: 1, text: "Hot", value: "Hot" },
    { id: 2, text: "Warm", value: "Warm" },
    { id: 3, text: "Cold", value: "Cold" },
  ];
  const positions_available = [
    { id: 1, text: "Yes", value: true },
    { id: 2, text: "No", value: false }
  ];
  const verticlalOptions = useSelector(sourcingVerticalOptions);
  useEffect(() => {
    const getVerticalData = async () => {
      await getVerticalSourcingData(dispatch)
    }
    getVerticalData()
    fetchIndustryData()
  }, [])
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  };
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    confirmEmail: "",
    mobileNum: "",
    countryCode: defaultcCode,
    url: "",
    linkedIn_URL: "",
    company_news: "",
    country: "",
    state: "",
    city: "",
    address: "",
    contact_person_name: "",
    contact_person_email: "",
    contact_person_mobileNum: "",
    contact_person_designation: "",
    contact_person_countryCode: defaultcCode,
    revenue_model: "Percentage",
    charge_value: "",
    payment_terms: 30,
    invoicing_terms: 10,
    replacement_timeline: 60,
    replacement_model: "Replacement",
    currency: "INR",
    type: "",
    positions_available: "",
    remarks: "",
    lastConnectDate: "",
    sourcingVertical: "",
    industry: ''
  });

  const changeHandler = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
    console.log(formValues);
  };
  const onFinish = async (e) => {
    try {
      e.preventDefault();
      const formdata = new FormData()
      const dataValue = {
        name: formValues?.name,
        email: formValues?.email,
        mobileNum: formValues?.mobileNum,
        countryCode: formValues?.countryCode,
        url: formValues?.url,
        company_news: formValues?.company_news,
        country: countryOption?.find((e) => e.iso2 === formValues?.country)
          .name,
        state: stateOption?.find((e) => e.iso2 === formValues?.state).name,
        city: formValues?.city,
        linkedIn_URL: formValues?.linkedIn_URL,
        address: formValues?.address,
        contact_person_name: formValues?.contact_person_name,
        contact_person_email: formValues?.contact_person_email,
        contact_person_mobileNum: formValues?.contact_person_mobileNum,
        contact_person_countryCode: formValues?.contact_person_countryCode,
        contact_person_designation: formValues?.contact_person_designation,
        revenue_model: formValues?.revenue_model,
        charge_value: parseFloat(formValues?.charge_value),
        payment_terms: parseFloat(formValues?.payment_terms),
        invoicing_terms: parseFloat(formValues?.invoicing_terms),
        replacement_model: formValues?.replacement_model,
        replacement_timeline: parseFloat(formValues?.replacement_timeline),
        currency: formValues?.currency,
        type: formValues?.type,
        Positions_available: formValues?.Positions_available,
        remarks: formValues?.remarks,
        lastConnectDate: formValues?.lastConnectDate?._d?.toISOString(),
        sourcingVertical: formValues?.sourcingVertical,
        industry: formValues?.industry
      }
      formdata.append("file", fileObj)
      formdata.append("formvalue", JSON.stringify(dataValue))
      await CreateNewClient(
        formdata,
        dispatch,
        setFormValues
      );

      setFormValues();
      // form.resetFields();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCountry = async () => {
    try {
      const result = await axios.get(
        "https://api.countrystatecity.in/v1/countries",
        {
          headers: {
            "X-CSCAPI-KEY":
              "eVNMbzF6NEVPNFFld1IyMXlvVlN5OVpMaExVWFN1RG82elc2SFBscg==",
          },
        }
      );
      setCountryOption(result.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchCountry();
  }, []);

  useEffect(() => {
    const fetchState = async () => {
      try {
        if (country) {
          const result = await axios.get(
            `https://api.countrystatecity.in/v1/countries/${country}/states`,
            {
              headers: {
                "X-CSCAPI-KEY":
                  "eVNMbzF6NEVPNFFld1IyMXlvVlN5OVpMaExVWFN1RG82elc2SFBscg==",
              },
            }
          );
          setStateOption(result.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchState();
  }, [country]);
  useEffect(() => {
    const fetchCity = async () => {
      try {
        if (state) {
          const result = await axios.get(
            `https://api.countrystatecity.in/v1/countries/${country}/states/${state}/cities`,
            {
              headers: {
                "X-CSCAPI-KEY":
                  "eVNMbzF6NEVPNFFld1IyMXlvVlN5OVpMaExVWFN1RG82elc2SFBscg==",
              },
            }
          );
          setCityOption(result.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCity();
  }, [state]);

  const changeHandlerCountry = (e) => {
    setCountry(e);
    console.log(e);
  };

  const changeHandlerState = (e) => {
    // console.log(e);
    setState(e);
  };
  function formatDateToYYYYMMDD(originalDateString) {
    const originalDate = new Date(originalDateString);

    // Get the individual components
    const year = originalDate.getFullYear();
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
    const day = originalDate.getDate().toString().padStart(2, '0');

    // Format the date as "yyyy-mm-dd"
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  return (
    <div
      className="site-card-border-less-wrapper"
      style={{ paddingBottom: "100px" }}
    >
      <br></br>
      <Card
        headStyle={{ fontSize: "25px", fontWeight: "bold" }}
        bordered={false}
        style={{
          width: "auto",
          padding: "15px",
          maxWidth: "900px",
        }}
      >
        <Card.Content header="Add Client"></Card.Content>
        <Card.Content>
          <Form onSubmit={onFinish}>
            <Form.Group>
              <Form.Input
                width={8}
                fluid
                name="name"
                type="text"
                value={formValues?.name}
                onChange={changeHandler}
                label="Name"
                placeholder="Please input the Client/Company name"
                required={true}
              />
              <Form.Select
                width={4}
                fluid
                label="Client Type "
                name="type"
                placeholder="Client Type"
                value={formValues?.type}
                onChange={(e, data) => {
                  setFormValues({ ...formValues, [data.name]: data.value });
                }}
                options={clientType}
                required={true}
              ></Form.Select>
              <Form.Select
                width={4}
                fluid
                label="Positions Available"
                name="Positions_available"
                placeholder="Positions Available"
                value={formValues?.Positions_available}
                onChange={(e, data) => {
                  setFormValues({ ...formValues, [data.name]: data.value });
                }}
                options={positions_available}
                required={true}
              ></Form.Select>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                name="email"
                type="email"
                value={formValues?.email}
                onChange={changeHandler}
                label="Enter client email"
                placeholder="Email"
                required={true}
              />
              <Form.Input
                fluid
                name="confirmEmail"
                type="email"
                label="Confirm email"
                value={formValues?.confirmEmail}
                onChange={changeHandler}
                error={
                  formValues?.confirmEmail
                    ? formValues?.confirmEmail !== formValues?.email &&
                    "Both Email ids must be same"
                    : false
                }
                placeholder="Confirm email"
                required={true}
              />
            </Form.Group>

            <Form.Group widths={"18"}>
              <Form.Select
                label="Mobile Number"
                name="countryCode"
                value={formValues?.countryCode}
                onChange={(e, data) => {
                  setFormValues({ ...formValues, [data.name]: data.value });
                }}
                placeholder="Country code"
                options={countryCodeSemantic}
                required={true}
              ></Form.Select>
              <Form.Input
                type="number"
                fluid
                label="‎ "
                name="mobileNum"
                required={true}
                value={formValues?.mobileNum}
                error={
                  formValues?.mobileNum
                    ? formValues?.mobileNum.length > 10 &&
                    "Mobile Number should be 10 digits only"
                    : false
                }
                onChange={changeHandler}
                placeholder="Enter client's mobile number"
                width={12}
              />
              <Form.Input
                type="file"
                label="Client Contract (optional)"
                accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                name="file"
                onChange={(e) => {
                  setFileObj(
                    e.target.files[0],
                  )
                }}
              ></Form.Input>
            </Form.Group>

            <Form.Input
              fluid
              name="url"
              type="url"
              value={formValues?.url}
              onChange={changeHandler}
              label="Company URL"
              placeholder="Enter company's website URL"
              required={true}
            />

            <Form.Input
              fluid
              name="linkedIn_URL"
              type="url"
              value={formValues?.linkedIn_URL}
              onChange={changeHandler}
              label="LinkedIn URL"
              placeholder="Enter Company's LinkedIn URL"
              required={true}
            />

            <Form.TextArea
              fluid
              name="company_news"
              type="text"
              value={formValues?.company_news}
              onChange={changeHandler}
              label="Company News"
              placeholder="Enter company news (max limit : 1000 words)"
              required={true}
            />
            <Form.Select
              fluid
              search
              label="Sourcing Verticals"
              multiple
              name="sourcingVertical"
              value={formValues?.sourcingVertical}
              onChange={(e, data) => {
                setFormValues({ ...formValues, [data.name]: data.value });
              }}
              options={verticlalOptions?.map((id, el) => {
                return {
                  text: id?.sourcingVertical,
                  value: id?.sourcingVertical,
                  id: id?.id
                }
              })}
              placeholder="Select sourcing vertical"
              required={true}
            />
            <Form.Select
              search
              options={industryOptions?.map((data, idx) => {
                return { key: idx, value: data.name, text: data.name };
              })}
              placeholder="Select Client Industry"
              name="industry"
              value={formValues?.industry}
              onChange={(e, data) => {
                setFormValues({ ...formValues, [data.name]: data.value });
              }}
              label="Industry"
            ></Form.Select>
            <Form.Group widths={"equal"}>
              {/* U+200E is a blank character inserted for proper spacing, nothing else */}
              <Form.Select
                search
                fluid
                name="country"
                label="Location"
                required={true}
                placeholder="Select country"
                value={formValues?.country}
                onChange={(e, data) => {
                  changeHandlerCountry(data.value);
                  setFormValues({
                    ...formValues,
                    [data.name]: data.value,
                  });
                }}
                options={countryOption.map((data, idx) => {
                  return {
                    text: `${data.name + "  (" + data.iso2 + ")"}`,
                    value: `${data.iso2}`,
                    key: `${idx}`,
                  };
                })}
              />
              <Form.Select
                search
                fluid
                label="‎ "
                placeholder="Select State"
                name="state"
                value={formValues?.state}
                onChange={(e, data) => {
                  changeHandlerState(data.value);
                  setFormValues({
                    ...formValues,
                    [data.name]: data.value,
                  });
                }}
                options={stateOption.map((data, idx) => {
                  return {
                    text: `${data.name + "  (" + data.iso2 + ")"}`,
                    value: `${data.iso2}`,
                    key: `${idx}`,
                  };
                })}
              />
              <Form.Select
                fluid
                search
                label="‎ "
                placeholder="Select City"
                name="city"
                value={formValues?.city}
                onChange={(e, data) => {
                  setFormValues({ ...formValues, [data.name]: data.value });
                }}
                options={cityOption.map((data, idx) => {
                  return {
                    text: `${data.name}`,
                    value: `${data.name}`,
                    key: `${idx}`,
                  };
                })}
              />
            </Form.Group>
            <Form.Input
              fluid
              name="address"
              type="text"
              value={formValues?.address}
              onChange={changeHandler}
              placeholder="Enter Company's Street Address (max limit : 120 words)"
              required={false}
            />
            <Form.Group>
              <Form.TextArea
                width={10}
                fluid
                name="remarks"
                type="text"
                value={formValues?.remarks}
                onChange={(e, data) => {
                  setFormValues({ ...formValues, [data.name]: data.value });
                }}
                label="Remarks"
                placeholder="Enter remarks (max limit : 500 words)"
              />
              <Form.Field >
                <label style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  // Additional styles for small screens
                  "@media (max-width: 767px)": {
                    marginLeft: "-15px",
                    marginRight: "10px",
                    marginTop: "20px",
                  },
                }} >Last Connected Date</label>
                <DatePicker
                  fluid
                  width={10}
                  placeholder=" Date"
                  name="lastConnectedDate"
                  value={formValues?.lastConnectDate}
                  onChange={((e) => { console.log(e); setFormValues({ ...formValues, lastConnectDate: e }) })}
                  label="Last Connected Data"
                  max={new Date().toISOString().split('T')[0]}
                  format="YYYY-MM-DD"
                  disabledDate={disabledDate}
                  style={{
                    width: "100%",
                    border: "none",
                    background: "transparent",
                    minHeight: "30px",
                    minWidth: "280px",
                    height: "40px",
                  }}

                />
              </Form.Field>
            </Form.Group>

            <br></br>

            <Divider horizontal>Contact Person Details</Divider>

            <Form.Input
              fluid
              name="contact_person_name"
              type="text"
              value={formValues?.contact_person_name}
              onChange={changeHandler}
              label="Name"
              placeholder="Enter Contact Person's Full Name"
              required={true}
            />

            <Form.Input
              fluid
              name="contact_person_email"
              type="email"
              value={formValues?.contact_person_email}
              onChange={changeHandler}
              label="Email"
              placeholder="Enter Contact Person's Email"
              required={true}
            />

            <Form.Group widths={"equal"}>
              <Form.Select
                label="Mobile Number"
                name="contact_person_countryCode"
                value={formValues?.contact_person_countryCode}
                onChange={(e, data) => {
                  setFormValues({ ...formValues, [data.name]: data.value });
                }}
                placeholder="Country code"
                options={countryCodeSemantic}
                required={true}
              ></Form.Select>
              <Form.Input
                type="number"
                fluid
                label="‎ "
                required={true}
                error={
                  formValues?.contact_person_mobileNum
                    ? formValues?.contact_person_mobileNum.length > 10 &&
                    "Mobile Number should be 10 digits only"
                    : false
                }
                name="contact_person_mobileNum"
                value={formValues?.contact_person_mobileNum}
                onChange={changeHandler}
                placeholder="Enter Contact Person's mobile number"
                width={12}
              />
            </Form.Group>

            <Form.Input
              fluid
              name="contact_person_designation"
              type="text"
              value={formValues?.contact_person_designation}
              onChange={changeHandler}
              label="Designation"
              placeholder="Enter Contact Person's Designation"
              required={true}
            />

            <Divider horizontal>Revenue Parameters</Divider>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "20px",
                backgroundColor: "#f0f0f0",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  padding: "20px",
                  border: "2px solid #007bff50",
                  borderRadius: "10px",
                }}
              >
                These parameters are already filled with default values. In case
                you want to edit these, please note that a small duration will
                be required for the super admin to fulfill your edit request in
                the revenue model.
              </p>
            </div>
            <Form.Group widths={"equal"}>
              <Form.Select
                label="Revenue Model "
                name="revenue_model"
                placeholder="Percentage"
                value={formValues?.revenue_model}
                onChange={(e, data) => {
                  setFormValues({ ...formValues, [data.name]: data.value });
                }}
                options={modelOptions}
              ></Form.Select>

              <Form.Input
                fluid
                name="charge_value"
                type="number"
                placeholder="10%"
                value={formValues?.charge_value}
                onChange={changeHandler}
                label="Charge Value"
              />
            </Form.Group>
            <Form.Group widths={"equal"}>
              <Form.Input
                label="Payment Terms (in days)"
                name="payment_terms"
                placeholder="30 days"
                value={formValues?.payment_terms}
                onChange={(e, data) => {
                  setFormValues({ ...formValues, [data.name]: data.value });
                }}
                type="number"
              ></Form.Input>
              <Form.Input
                label="Invoicing Terms (in days)"
                name="invoicing_terms"
                placeholder="10 days"
                value={formValues?.invoicing_terms}
                onChange={(e, data) => {
                  setFormValues({ ...formValues, [data.name]: data.value });
                }}
                type="number"
              ></Form.Input>
              <Form.Input
                label="Replacement Timeline (in days)"
                name="replacement_timeline"
                placeholder="60 days"
                value={formValues?.replacement_timeline}
                onChange={(e, data) => {
                  setFormValues({ ...formValues, [data.name]: data.value });
                }}
                type="number"
              ></Form.Input>
            </Form.Group>
            <Form.Group widths={"equal"}>
              <Form.Select
                label="Replacement Model "
                name="replacement_model"
                placeholder="Replacement"
                value={formValues?.replacement_model}
                onChange={(e, data) => {
                  setFormValues({ ...formValues, [data.name]: data.value });
                }}
                options={repModel}
              ></Form.Select>
              <Form.Select
                label="Currency"
                name="currency"
                placeholder="INR"
                value={formValues?.currency}
                onChange={(e, data) => {
                  setFormValues({ ...formValues, [data.name]: data.value });
                }}
                options={currencyOptions}
              ></Form.Select>
            </Form.Group>

            {zyvkaState?.isErrors && (
              <ErrorMessage
                header={"Error ! "}
                content={zyvkaState?.FormErrorMessage?.clientCreate}
              />
            )}

            <Form.Button
              type="submit"
              style={{
                backgroundColor: "#6855f6",
                color: "white",
                marginTop: "10px",
              }}
            >
              Submit
            </Form.Button>
          </Form>
        </Card.Content>
      </Card>
    </div>
  );
};

export default CreateClient;
