const data = {
  /* For Changes in Login Page static Data */
  Login: {
    title: "Sign In",
    instruction: "Enter your email and password to sign in",
    em: "Email",
    pass: "Password",
    emError: "Please input your email!",
    passError: "Please input your password!",
    year: "2022",
    company_name: "Zyvka",
  },
  Dashboard: {
    profile: {
      title: "Profile",
    },
    zyvka_partner_management: {
      title: "Partner Management",
      AddPartnerAdmin: {
        title: "Add Partner Admin",
        form: {
          title: "Add a partner admin",
          inputs: {
            fname: {
              label: "First Name",
              required: true,
              instruction: "Please input the Name!",
            },
            lname: {
              label: "Last Name",
              required: true,
              instruction: "Please input the Last Name!",
            },
            email: {
              label: "Email",
              required: true,
              instruction: "Please input the Email!",
              validemailInstruction: "The input is not valid E-mail!",
            },
            mobile: {
              label: "Mobile",
              required: true,
              instruction: "Please input the Mobile Number!",
            },
            password: {
              label: "Password",
              required: true,
              instruction: "Please input the Password",
            },
            cpassword: {
              label: "Confirm Password",
              required: true,
              instruction: "Please Confirm Your Password",
              unmatchError: "The two passwords that you entered do not match!",
            },
            submit: {
              label: "Submit",
            },
            company_name: {
              label: "Company Name",
            },
          },
        },
      },
      modifyPartner: {
        title: "Modify Partners",
      },
      DisabledPartnerAdmins: {
        title: "Disabled Partner Admins",
      },
      queries: {},
    },
    allCandidates: {
      title: "Candidates",
    },
    candidateManagement: {
      title: "Candidate Management",
    },
    clientManageMent: {
      title: "Client Management",
      AddClient: {
        title: "Add Client",
      },
      AllClients: {
        title: "View Clients",
      },
      clientVerification: {
        title: "Client Verification",
      },
      partnerVerification: {
        title: "Position Allocation",
      },
    },
    positions: {
      title: "Positions",
      createPosition: {
        title: "Open New Position",
      },
      mypositions: {
        title: "My Positions",
      },
      allpositions: {
        title: "All Positions",
      },
      archivepositions: {
        title: "Archived",
      },
      internalpositions: {
        title: "Internal Positions",
      },
      closedpositions: {
        title: "Closed Positions",
      },
    },
    AddClient: {
      title: "Add client",
    },
    queries: {
      title: "Queries",
    },
    addprofile_skill: {
      title: "Add Profile/Skill",
    },

    P_Partner: {
      title: "Partner",
      createApplicant: {
        title: "Add Candidate",
      },
      assigned_jobs: {
        title: "Your Positions",
      },
    },
    P_User_Management: {
      title: "User Management",
      addPartner: {
        title: "Add Partner",
      },
      deletePartner: {
        title: "Delete Partner",
      },
    },
  },
};

export default data;
