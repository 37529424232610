import {
  Space,
  Table,
  Button,
  Modal,
  Input,
  Card,
  Row,
  Col,
  Typography,
  Tag,
  Divider,
  Popconfirm,
  message,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  approvePartnerJobProposal,
  getAllUnapprovedPartnerForJob,
} from "../api/ZyvkaMember";
import { ZyvkaMemberState } from "../features/ZyvkaMemberSlice";
import { UserState, selectUser } from "../features/userSlice";
import parse from "html-react-parser";
import { DownloadOutlined } from "@ant-design/icons";
import Currency, { ctcMap, CurrencySymbols } from "../Data/Currency";
import axios from "axios";
const { Title } = Typography;

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

const PartnerVerification = () => {
  const dispatch = useDispatch();
  const zyvkaState = useSelector(ZyvkaMemberState);
  const [columndata, setcolumnData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [currentPartner, setCurrentPartner] = useState([]);
  const userState = useSelector(UserState);
  useEffect(() => {
    const fetchClients = async () => {
      const res = await getAllUnapprovedPartnerForJob(setcolumnData, dispatch);
    };
    fetchClients();
    setCurrentPartner(columndata?.[0]);
  }, [dispatch, flag]);
  // this is for search
  const [searchValue, setSearchValue] = useState("");

  const columns = [
    {
      title: "Job Title",
      key: "jobtitle",
      render: (record) => {
        return <>{record?.Jobs?.job_title}</>;
      },
    },
    {
      title: "Client",
      key: "client",
      render: (record) => {
        return <>{record?.Jobs?.company_name}</>;
      },
    },
    {
      title: "Partner",
      key: "partner",
      filteredValue: [searchValue],
      onFilter: (value, record) => {
        return (
          String(
            record?.partnerAdmins?.firstName +
            " " +
            record?.partnerAdmins?.lastName
          )
            ?.toLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.partnerAdmins?.email)
            ?.toLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.partnerAdmins?.mobileNum)
            ?.toLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.partnerAdmins?.company_name)
            ?.toLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.Jobs[0]?.job_title)
            ?.toLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          String(record?.partnerAdmins?.company_name)
            ?.toLowerCase()
            .includes(value.toLocaleLowerCase())
        );
      },
      render: (record) => {
        return (
          <>
            {record?.partnerAdmins?.firstName +
              " " +
              record?.partnerAdmins?.lastName}{" "}
          </>
        );
      },
    },
    {
      title: "Email",
      key: "email",
      render: (record, key) => {
        return <>{record?.partnerAdmins?.email}</>;
      },
    },
    {
      title: "Company",
      key: "company",
      render: (record, key) => {
        return <>{record?.partnerAdmins?.company_name}</>;
      },
    },
    {
      title: "Verification",
      key: "isSuperAdminApprove",
      dataIndex: "isSuperAdminApprove",
      render: (value, record, key) => {
        return (
          <>
            {userState?.role === "superAdmin" ||
              userState?.userInfo?.isSuccessManager ? (
              value ? (
                <Button>Verified</Button>
              ) : (
                <Button
                  type="primary"
                  onClick={() => showAddNewModal(record?.id, record)}
                >
                  View
                </Button>
              )
            ) : value ? (
              <Button
                onClick={() =>
                  alert(
                    "This action requires Super Admin & Success Manager Privilege"
                  )
                }
              >
                Verified
              </Button>
            ) : (
              <Button
                onClick={() =>
                  alert(
                    "This action requires Super Admin & Success Manager Privilege"
                  )
                }
              >
                Not Verified
              </Button>
            )}
          </>
        );
      },
    },
  ];
  const user = useSelector(selectUser);
  //Add new contacts modal
  const [isAddNewModalOpen, setIsAddNewModalOpen] = useState(false);
  const showAddNewModal = (id, value) => {
    setIsAddNewModalOpen(id);
    setCurrentPartner(value);
  };
  //Jd Pdf Show
  const handleshowPdf = async () => {
    try {
      if (currentPartner.Jobs?.isZyvkaJdAiEnable) {
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/files/jd/generate/${currentPartner?.Jobs?.job_title.split(" ").join("-")}`, {

          jobId: currentPartner?.Jobs?.id,
          name: user?.firstName + " " + user?.lastName,
          mobile: user?.mobileNum,
          email: user?.email
        },
          {
            responseType: 'blob',
            withCredentials: true
          });
        const url = URL.createObjectURL(response.data);
        window.open(url, '_blank');
      } else {
        const url = `${process.env.REACT_APP_SERVER_URL}/api/files/jd/${currentPartner.Jobs?.jd_urn}`
        window.open(url, '_blank')
      }

    } catch (error) {
      console.log(error);
    }
  }
  const handleOkAddNew = async (id, approval) => {
    try {
      await approvePartnerJobProposal(
        { claimID: id, approvedStatus: approval },
        setFlag
      );
      notification.open({
        message: (
          <span style={{ fontWeight: "bold", fontSize: "17px" }}>
            {approval === "Accept"
              ? "✅Position Allocation Request Accepted"
              : " ❌ Position ALlocation Request Denied"}
          </span>
        ),
      });
    } catch (e) {
      message.error("Some Error Occurred, Please try again after some time");
      setFlag(!flag);
    }
    handleCancelAddNew();
  };
  const handleCancelAddNew = () => {
    setIsAddNewModalOpen(false);
  };
  console.log(currentPartner);
  // console.log(columndata);
  return (
    <>
      <div style={{ marginTop: "30px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flexWrap: "wrap",
            gap: "10px",
          }}
        // className="searchbox"
        >
          <Space direction="horizontal" wrap={true}>
            <Input
              placeholder="Search Here"
              style={{
                border: "0.5px solid gray",
                borderRadius: "5px",
                outline: "none",
                position: "relative",
                width: 150,
                padding: "3px",
                paddingTop: "6px",

                marginTop: "10px",
                marginBottom: "10px",
                lineHeight: "4ex",
                minWidth: 200,
                maxWidth: 400,
                paddingLeft: 10,
              }}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
            <Button style={{ width: 80 }}>Search</Button>
          </Space>
        </div>

        <Table
          scroll={{ x: true }}
          bordered={true}
          columns={columns}
          dataSource={columndata}
        />
      </div>

      {isAddNewModalOpen && (
        <Modal
          width={700}
          centered
          style={{
            top: 50,
          }}
          visible={isAddNewModalOpen}
          onCancel={handleCancelAddNew}
          footer={[
            <Button type="ghost" key="cancel" onClick={handleCancelAddNew}>
              Cancel
            </Button>,
            <Popconfirm
              title="Sure you want to Decline this Job Claim?"
              onConfirm={() => handleOkAddNew(currentPartner?.id, "Decline")}
              onCancel={() => message.error("Task Cancelled")}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger" key="decline">
                Decline
              </Button>
            </Popconfirm>,
            <Popconfirm
              title="Sure you want to Accept this Job Claim?"
              onConfirm={() => handleOkAddNew(currentPartner?.id, "Accept")}
              onCancel={() => message.error("Task Cancelled")}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" key="accept">
                Accept
              </Button>
            </Popconfirm>,
          ]}
        >
          <Card
            title={currentPartner?.Jobs?.job_title}
            headStyle={{ fontSize: "18px", fontWeight: "bold" }}
            extra={
              <div>
                <Button
                  style={{ marginRight: "15px" }}
                  type="ghost"
                  key="cancel"
                  onClick={handleCancelAddNew}
                >
                  Cancel
                </Button>

                <Popconfirm
                  title="Sure you want to Decline this Job Claim?"
                  onConfirm={() =>
                    handleOkAddNew(currentPartner?.id, "Decline")
                  }
                  onCancel={() => message.error("Task Cancelled")}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    style={{ marginRight: "15px" }}
                    type="danger"
                    key="decline"
                  >
                    Decline
                  </Button>
                </Popconfirm>

                <Popconfirm
                  title="Sure you want to Accept this Job claim?"
                  onConfirm={() => handleOkAddNew(currentPartner?.id, "Accept")}
                  onCancel={() => message.error("Task Cancelled")}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="primary" key="accept">
                    Accept
                  </Button>
                </Popconfirm>
              </div>
            }
          >
            <Row>
              <Col span={12}>
                <DescriptionItem
                  title="Company Name"
                  Link={"https://www.google.com"}
                  content={
                    <a
                      href={currentPartner?.Jobs?.client?.url}
                      style={{ color: "inherit" }}
                    >
                      {currentPartner?.Jobs?.company_name}
                    </a>
                  }
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Total Rounds"
                  content={currentPartner?.Jobs?.number_of_rounds}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <DescriptionItem
                  title="Experience"
                  content={
                    currentPartner?.Jobs?.min_experience_req +
                    " - " +
                    currentPartner?.Jobs?.max_experience_req +
                    " Years"
                  }
                />
                <DescriptionItem
                  title="Location"
                  content={
                    currentPartner?.Jobs?.country +
                    ", " +
                    currentPartner?.Jobs?.state
                  }
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="CTC"
                  content={`${CurrencySymbols[currentPartner?.Jobs?.currencyType]
                    }
            ${currentPartner?.Jobs?.min_CTC}${ctcMap[
                    currentPartner?.Jobs?.currencyType
                      ? currentPartner?.Jobs?.currencyType
                      : "INR"
                    ]
                    } - ${currentPartner?.Jobs?.max_CTC}${ctcMap[
                    currentPartner?.Jobs?.currencyType
                      ? currentPartner?.Jobs?.currencyType
                      : "INR"
                    ]
                    } P.A.`}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <DescriptionItem
                  title="Location"
                  content={
                    currentPartner?.Jobs?.country +
                    ", " +
                    currentPartner?.Jobs?.state
                  }
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Remote"
                  content={
                    "" + currentPartner?.Jobs?.isRemote ? "Remote" : "On-Site"
                  }
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "13px" }}>
              <Col span={12}>
                <DescriptionItem
                  title="Job Type"
                  content={currentPartner?.Jobs?.jobType}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="No. of Positions"
                  content={currentPartner?.Jobs?.number_of_positions}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ paddingTop: "13px" }}>
                <DescriptionItem
                  title="Skills Required"
                  labelStyle
                  content={
                    <div
                      style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
                    >
                      {currentPartner?.Jobs?.skills_req?.map((data1, i) => {
                        return (
                          <>
                            <Tag key={i} color="blue">
                              {data1.toUpperCase() + "  "}
                            </Tag>
                          </>
                        );
                      })}
                    </div>
                  }
                />
              </Col>
            </Row>
          </Card>
          <Card style={{ marginTop: "30px" }}>
            <Row>
              <Col span={24}>
                <DescriptionItem
                  title={<b> Job Description</b>}
                  content={
                    <>
                      {currentPartner?.Jobs?.job_description[0] === "<" ? (
                        <div>
                          {parse(currentPartner?.Jobs?.job_description)}
                        </div>
                      ) : (
                        <pre
                          style={{
                            color: "black",
                            whiteSpace: "pre-wrap",
                            fontFamily: "monospace",
                          }}
                        >
                          {currentPartner?.Jobs?.job_description}
                        </pre>
                      )}
                    </>
                  }
                />
              </Col>
              <Divider />
              <Col span={24}>
                <a
                  target="_blank"
                  rel="noreferrer"
                // href={`${process.env.REACT_APP_SERVER_URL}/api/files/jd/${currentPartner?.Jobs?.jd_urn}`}
                >
                  {/* <Button type="primary" icon={<DownloadOutlined />} > */}
                  <Button type="primary" icon={<DownloadOutlined />} onClick={handleshowPdf}>
                    Download JD
                  </Button>
                </a>
              </Col>
            </Row>
          </Card>
          <Card style={{ marginTop: "30px" }}>
            <Row>
              <Col span={24}>
                <DescriptionItem
                  title={<b>Hiring Process / Round Details</b>}
                  content={currentPartner?.Jobs?.hiring_process?.map(
                    (val, _idx) => {
                      //console.log(val);
                      return (
                        <div>
                          <Title level={5}>{JSON.parse(val).name} :</Title>
                          <pre
                            style={{
                              whiteSpace: "pre-wrap",
                              fontFamily: "inherit",
                            }}
                          >
                            {JSON.parse(val).description}
                          </pre>
                        </div>
                      );
                    }
                  )}
                />
              </Col>
            </Row>
          </Card>
        </Modal>
      )}
    </>
  );
};

export default PartnerVerification;
