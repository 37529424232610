import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card, Form, Modal, ModalHeader,
  ModalDescription,
  ModalContent,
  ModalActions,
  Button,
  Header,
  TextArea
} from "semantic-ui-react";
import {
  InboxOutlined
} from '@ant-design/icons';
import { UserState, selectUser } from "../features/userSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { referNewApplicant, verifyApplicantForJob } from "../api/auth";
import { Link, useParams, useSearchParams, useNavigate } from "react-router-dom";
import { fetchAllSkills } from "../api/auth";
import { referApplicant } from "../api/partner";
import countryCode, {
  countryCodeSemantic,
  defaultcCode,
} from "../Data/CountryCode";
import ErrorMessage from "../Error/ErrorMessage";
import Currency, { defaultCurrency } from "../Data/Currency";
import SuccessMessage from "../Small-Components/SuccessMessage";
import { getJobQuestions } from "../api/partner";
import {
  message, List, Popconfirm, Checkbox, Upload
} from 'antd';
import { fetchJobData, getPreviousVideoResume } from "../api/ZyvkaMember";
import { PlusCircleOutlined, MenuFoldOutlined } from "@ant-design/icons";
const API = axios.create({ baseURL: process.env.REACT_APP_SERVER_URL });
const { Dragger } = Upload;

const ReferNewApplicant = () => {
  const user = useSelector(selectUser);
  const userState = useSelector(UserState);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [skillOption, setSkillOption] = useState([]);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    countryCode: defaultcCode,
    mobileNum: "",
    key_skills: [],
    file: null,
    salary_currency: defaultCurrency,
    current_ctc: 0,
    expected_ctc: 0,
    current_company: "",
    current_designation: "",
    notice_period: 0,
    total_experience: 0,
    location: "",
    applicant_story: "",
    remarks: "",
    candidateSource: "",
    candidateSourceOther: "",
    answerArray: [],
    linkedIn_URL: "",
    ctc_negotiable: false,
  });
  const [applicantData, setApplicantData] = useState({});
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false)
  const [newStory, setNewStory] = useState(false)
  const [candidateStory, setCandidateStory] = useState()
  const [questionArray, setQuestionArray] = useState([]);
  const [answerArray, setAnswerArray] = useState([]);
  const [newVideo, setNewVideo] = useState(false)
  const [flag3, setFlag3] = useState(false)
  const [prevVResume, setPrevVResume] = useState([])
  const [videoResume, setVideoResume] = useState(null)
  const [videoRequired, setVideoRequired] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)


  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'video/mp4' || file.type === 'video/ogg' || file.type === 'video/x-msvideo' || file.type === 'video/mpeg' || file.type === 'video/quicktime' || file.type === 'video/x-ms-wmv' || file.type === 'video/x-flv' || file.type === 'video/x-matroska';
    if (!isJpgOrPng) {
      message.error('You can only upload video file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 15;
    if (!isLt2M) {
      message.error('Image must be smaller than 15MB!');
    }
    const isGt400KB = file.size / 1024 > 400;
    if (!isGt400KB) {
      message.error('Image must be greater than 400KB!');
    }
    return isJpgOrPng && isLt2M && isGt400KB;
  };


  const dispatch = useDispatch();
  const { jobId } = useParams();

  const props = {
    name: 'file',
    action: `${process.env.REACT_APP_SERVER_URL}/api/video/uploadVideoResume/${jobId}`,
    beforeUpload: beforeUpload,
    onChange(info) {
      const { status } = info.file;
      setLoading(true)
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        setVideoResume(info?.file?.response?.urn)
        setNewVideo(true)
        console.log(info?.file?.response?.urn)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
      setLoading(false)
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneRegex = /^\d{10}$/;

  const verifyCandidate = async () => {
    if (emailRegex.test(formData?.email) && phoneRegex.test(formData?.mobileNum)) {
      try {
        const res = await verifyApplicantForJob({ email: formData?.email, mobileNum: formData?.mobileNum, jobId: jobId })
        console.log(res, "dd")
        if (res?.duplicate === 'yes') {
          setApplicantData(res?.data)
          message.error("A candidate already exists with same email or mobile number")
          setOpenDuplicateModal(true)
        } else if (res?.duplicate === 'no') {
          message.success("No duplicate candidate found")
        } else if (res?.duplicate === 'stop') {
          message.success("Applicant already applied for the job")
          navigate(`/dashboard/myjobs1/${jobId}`)
        }
      } catch (e) {
        console.log(e)
      }
    } else if (!emailRegex.test(formData?.email) && !phoneRegex.test(formData?.mobileNum)) {
      message.error("Please enter Valid Email and Mobile Number")
    } else if (!emailRegex.test(formData?.email)) {
      message.error("Please enter Valid Email")
    } else {
      message.error("Please enter Valid Mobile Number")
    }
  }
  const getJobDetail = async (id) => {
    try {
      const res = await fetchJobData(id)
      console.log(res, "data job")
      setVideoRequired(res?.isVideoResumeRequired)
    } catch (e) {
      console.log(e)
    }
  }
  const getPreviousResumevideo = async (id) => {
    setFlag3(true)
    try {
      const res = await getPreviousVideoResume(
        { id: id },
        dispatch
      );
      const vidMap = res?.data?.map((el) => el?.videoResume)
      console.log(vidMap, "here it is")
      setPrevVResume(vidMap)
    } catch (e) {
      console.log(e)
    }
    setFlag3(false)
  };

  const handleCheckboxChange = (checked, item) => {
    if (checked) {
      setVideoResume(item);
      setNewVideo(false);
    } else {
      setVideoResume(null);
      setNewVideo(true);
    }
  };

  const handleListItemClick = (item) => {
    setVideoResume(item);
    setModalVisible(true);
    setNewVideo(false);
  };


  const referDuplicateCandidate = async () => {
    try {
      const res = await referApplicant(
        {
          applicantID: applicantData?.id,
          jobID: jobId,
          applicant_story: candidateStory,
          isNewApplicantStory: newStory,
          isNewVideo: newVideo,
          videoResume
        },
        dispatch
      );
      console.log(res)
      // setOpenDuplicateModal((flag) => !flag);
      navigate(`/dashboard/existingApplicant/${jobId}/?company_name=${searchParams.get("company_name")}&job_title=${searchParams.get("job_title")}`)
      // http://localhost:3000/dashboard/existingApplicant/clvcpacjl0001u1y4qgxtqld2/?company_name=dummyClient&job_title=Sales%20Executive

    } catch (error) {
      console.log(error);
    }
  }

  const changeAnswer = (answer, idx, id) => {
    // [{jobId:,answer:},{}]
    console.log(answer);
    let ansSend = answer;
    const answerArrayNew = [...answerArray];
    if (typeof answer === "boolean") {
      let ans = answer.toString();
      let ansUp;
      if (ans === "true") {
        ansUp = "Yes"
      } else {
        ansUp = "No"
      }
      ansSend = ansUp;
    }
    answerArrayNew[idx] = { answer: ansSend, questionId: id };
    setAnswerArray(answerArrayNew);
    console.log(answerArray);
  }

  const fetchSkills = async () => {
    try {
      await fetchAllSkills(dispatch, setSkillOption);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchSkills();
    getQuestions();
    getJobDetail(jobId)
  }, []);
  
  const [isloading, setisloading] = useState(false)
  const submitForm = async () => {
    setisloading(true)
    try {
      // e.preventDefault();
      console.log(formData);

      const formdata = new FormData();
      formdata.append("countryCode", formData?.countryCode);
      formdata.append("firstName", formData?.firstName);
      formdata.append("lastName", formData?.lastName);
      formdata.append("jobID", jobId);
      formdata.append("creatorId", user.id);
      formdata.append("mobileNum", formData?.mobileNum);
      formdata.append("email", formData?.email);
      formdata.append("current_salary", formData?.current_ctc);
      formdata.append("expected_salary", formData?.expected_ctc);
      formdata.append("salary_currency", formData?.salary_currency);
      formdata.append("notice_period", formData?.notice_period);
      formdata.append("key_skills", formData?.key_skills);
      formdata.append("remarks", formData?.remarks);
      formdata.append("location", formData?.location);
      formdata.append("linkedIn_URL", formData?.linkedIn_URL);
      formdata.append("current_company", formData?.current_company);
      formdata.append("current_designation", formData?.current_designation);
      formdata.append("total_experience", formData?.total_experience);
      formdata.append("ctc_negotiable", formData?.ctc_negotiable);
      formdata.append("file", formData?.file);
      formdata?.append("answere", JSON.stringify(answerArray));
      if (formData?.candidateSource !== "Other") {
        formdata.append("candidateSource", formData?.candidateSource);
      } else {
        formdata.append("candidateSource", formData?.candidateSourceOther);
      }
      if (formData?.applicant_story?.trim() === "" || formData?.applicant_story?.length === 0) {
        let applicantStory = "";
        let name = `${formData?.firstName} ${formData?.lastName} `;
        let experience = formData?.total_experience ? `an experience of ${formData.total_experience} years` : "a fresher";
        let company = formData?.current_company;
        let locationStr = formData?.location ? `based out of ${formData.location} ` : "";

        if (company?.trim().length >= 3) {
          if (experience > 0) {
            applicantStory += `${name} has ${experience} and is currently employed with ${company}.`;
          } else {
            applicantStory += `${name} is ${experience} and is currently employed with ${company}.`;
          }
        } else {
          if (experience > 0) {
            applicantStory += `${name} has ${experience}.`;
          } else {
            applicantStory += `${name} is ${experience}.`;
          }
        }
        if (locationStr && formData?.location?.trim().length >= 3) {
          applicantStory += `${name} is ${locationStr}.`;
        }
        if (formData?.key_skills && formData?.key_skills.length > 0) {
          let skills = formData?.key_skills.join(", ");
          applicantStory += `${name} has capabilities on key skills sets like ${skills}.`;
        }
        formdata.append("applicant_story", applicantStory);
      }
      else {
        formdata.append("applicant_story", formData?.applicant_story);
      }
      await referNewApplicant(formdata, dispatch, setFormData);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
    setisloading(false)
  };
  const [isReferable, setIsReferable] = useState(null)
  const [isReferableOpen, setIsReferableOpen] = useState(false)
  const isConfirmReferable = async (e) => {
    e.preventDefault();
    const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/jobs/isApplicantReferable`, {
      jobID: jobId,
      expectedSalary: formData?.expected_ctc,
      totalExperience: formData?.total_experience,
      noticePeriod: formData?.notice_period,
      salaryCurrency:formData?.salary_currency
    })
    if (!res.data?.isReferable) {
      setIsReferableOpen(true)
      setIsReferable(res?.data?.data)
    } else {
      submitForm();
    }
  }
  const getQuestions = async () => {
    try {
      const result = await API.post(`/api/jobs/getQuestionsForJob/`, { jobId: jobId }, {
        withCredentials: true,
      });
      setQuestionArray(result.data);
      console.log(result.data);
    } catch (error) {
      console.log(error);
    }
  }
  // const getJobDetail = async (id) => {
  //   try {
  //     const res = await fetchJobData(id)
  //     setVideoRequired(res?.isVideoResumeRequired)
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  return (
    <div className="site-card-border-less-wrapper">
      <br></br>
      <Card
        headStyle={{ fontSize: "25px", fontWeight: "bold" }}
        bordered={false}
        style={{
          width: "auto",
          padding: "15px",
          maxWidth: "900px",
          marginBottom: "100px",
          marginTop: "10px",
        }}
      >
        <Card.Content
          header={`Add new candidate(${searchParams.get("job_title")})`}
        ></Card.Content>
        <Card.Content>
          <Form onSubmit={isConfirmReferable} loading={userState?.isPending}>
            <Form.Group fluid widths="equal">
              <Form.Input
                fluid
                name="firstName"
                required={true}
                placeholder="Enter First Name"
                label="First Name"
                value={formData?.firstName}
                onChange={changeHandler}
              ></Form.Input>
              <Form.Input
                fluid
                name="lastName"
                required={true}
                label="Last Name"
                placeholder="Enter Last Name"
                value={formData?.lastName}
                onChange={changeHandler}
              ></Form.Input>
            </Form.Group>
            <Form.Input
              type="email"
              required={true}
              placeholder="Enter email"
              name="email"
              label="Email"
              value={formData?.email}
              onChange={changeHandler}
            ></Form.Input>
            <Form.Group widths={"18"}>
              <Form.Select
                defaultValue={defaultcCode}
                label="Country Code"
                name="countryCode"
                placeholder="country code"
                options={countryCodeSemantic}
                required={true}
                value={formData?.countryCode}
                onChange={(e, data) => {
                  setFormData({ ...formData, [data.name]: data.value });
                }}
              ></Form.Select>
              <Form.Input
                fluid
                label="Mobile Number"
                name="mobileNum"
                placeholder="Enter mobile number"
                required={true}
                value={formData?.mobileNum}
                onChange={changeHandler}
                error={
                  formData?.mobileNum
                    ? formData?.mobileNum.length > 10 &&
                    "Mobile Number should be 10 digits only"
                    : false
                }
                width={9}
              />
              <Form.Button label='Verify' color='violet' onClick={verifyCandidate} >
                Check for duplicacy
              </Form.Button>
            </Form.Group>

            <Form.Select
              options={skillOption}
              search
              required={true}
              name="key_skills"
              multiple
              label="Key skills"
              placeholder="Enter candidate's Key Skills"
              value={formData?.key_skills}
              onChange={(e, data) => {
                setFormData({ ...formData, [data.name]: data.value });
              }}
            ></Form.Select>
            <Form.Input
              type="file"
              required={true}
              label="Upload Resume"
              accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              name="file"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  [e.target.name]: e.target.files[0],
                });
              }}
            ></Form.Input>
            <Form.Group widths="equal">
              <Form.Input
                label={`Current Company `}
                required={false}
                name="current_company"
                placeholder={` Current Company`}
                value={formData?.current_company}
                onChange={changeHandler}
              ></Form.Input>
              <Form.Input
                label={` Current Designation `}
                required={false}
                placeholder={` CEO | Manager |   ....`}
                name="current_designation"
                value={formData?.current_designation}
                onChange={changeHandler}
              ></Form.Input>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Select
                defaultValue={defaultCurrency}
                options={Currency}
                label="Salary currency"
                required={true}
                name="salary_currency"
                value={formData?.salary_currency}
                onChange={(e, data) => {
                  setFormData({ ...formData, [data.name]: data.value });
                }}
              ></Form.Select>
              <Form.Input
                type="number"
                label={`Current CTC(In ${formData?.salary_currency === "INR" ? "LPA" : "KPA"
                  })`}
                required={true}
                placeholder={`Enter Current CTC(in ${formData?.salary_currency === "INR" ? "LPA" : "KPA"
                  })`}
                name="current_ctc"
                value={formData?.current_ctc}
                onChange={changeHandler}
                error={
                  formData?.current_ctc
                    ? formData?.current_ctc.length > 5 &&
                    "Please enter CTC in Decimal format"
                    : false
                }
              ></Form.Input>
              <Form.Input
                type="number"
                label={`Expected CTC(In ${formData?.salary_currency === "INR" ? "LPA" : "KPA"
                  })`}
                required={true}
                placeholder={`Enter expected CTC(in ${formData?.salary_currency === "INR" ? "LPA" : "KPA"
                  })`}
                name="expected_ctc"
                value={formData?.expected_ctc}
                onChange={changeHandler}
                error={
                  formData?.expected_ctc
                    ? formData?.expected_ctc.length > 5 &&
                    "Please enter CTC in Decimal format"
                    : false
                }
              ></Form.Input>
            </Form.Group>

            <Form.Group widths={"equal"}>
              <Form.Input
                name="notice_period"
                label="Notice Period (in days)"
                defaultValue={0}
                placeholder="Enter Candidate's notice period."
                value={formData?.notice_period}
                onChange={changeHandler}
                type="number"
              ></Form.Input>
              <Form.Input
                name="total_experience"
                label="Experience (in years)"
                defaultValue={0}
                placeholder="Enter candidate's total experience."
                value={formData?.total_experience}
                onChange={changeHandler}
                type="number"
              ></Form.Input>
            </Form.Group>
            <Form.Input
              name="location"
              label="Location"
              required={true}
              placeholder="Enter candidate's Location"
              value={formData?.location}
              onChange={changeHandler}
            ></Form.Input>
            {/* <Form.TextArea
              name="applicant_story"
              label="Candidate Hightlight"
              value={formData?.applicant_story}
              onChange={changeHandler}
              maxLength={500}
              placeholder="Enter candidate's highlight, if required ( 500 words max.)"
            ></Form.TextArea> */}
            <Form.TextArea
              name="remarks"
              label="Remarks"
              value={formData?.remarks}
              onChange={changeHandler}
              maxLength={200}
              placeholder="Enter any remarks, if required (200 words max.)"
            ></Form.TextArea>
            <Form.Group widths={"equal"}>
              <Form.Select
                options={[
                  { key: 1, text: "LinkedIn", value: "LinkedIn" },
                  { key: 2, text: "Naukri", value: "Naukri" },
                  { key: 3, text: "Shine", value: "Shine" },
                  { key: 4, value: "Other", text: "Other (Please Mention)" },
                ]}
                name="candidateSource"
                required={true}
                label="Candidate's source"
                placeholder="Select Candidate's Source"
                value={formData?.candidateSource}
                onChange={(e, data) => {
                  setFormData({ ...formData, [data.name]: data.value });
                }}
              ></Form.Select>
              <Form.Input
                type="text"
                name="candidateSourceOther"
                disabled={formData?.candidateSource !== "Other"}
                label="Other"
                placeholder="Please mention other source information"
                value={formData?.candidateSourceOther}
                onChange={changeHandler}
              ></Form.Input>
            </Form.Group>
            <Form.Input
              name="linkedIn_URL"
              label="LinkedIn url"
              placeholder="Enter Candidate's Linkedin Profile url (if any)"
              value={formData?.linkedIn_URL}
              onChange={changeHandler}
            ></Form.Input>
            <Form.Checkbox
              toggle
              name="ctc_negotiable"
              label="CTC negotiable ?"
              checked={formData?.ctc_negotiable}
              onChange={(e, data) => {
                setFormData({ ...formData, [data.name]: data.checked });
              }}
            ></Form.Checkbox>
            {questionArray?.length > 0 && (
              <>
                <h3>Mandatory Questions</h3>
                {questionArray?.map((el, idx) => {
                  return (
                    el.questionType === 1 ? (
                      <Form.Checkbox
                        toggle
                        name={el.question}
                        required={true}
                        label={el.question}
                        checked={answerArray[idx]?.answer === 'Yes' ? true : false}
                        onChange={(e, data) => {
                          // let answer = String(data.checked);
                          changeAnswer(data.checked, idx, el.id);
                        }}
                      ></Form.Checkbox>
                    ) : (
                      <Form.Input
                        name={el.question}
                        required={true}
                        label={el.question}
                        // placeholder="Enter Candidate's Linkedin Profile url (if any)"
                        type={el.questionType === 2 ? "" : "number"}
                        value={answerArray[idx]?.answer}
                        onChange={(e, data) => {
                          changeAnswer(data.value, idx, el.id);
                          console.log(answerArray);
                        }}
                      ></Form.Input>
                    )
                  )
                })}
              </>
            )}
            {userState?.isErrors && (
              <ErrorMessage
                header={"Error Message"}
                content={userState.FormErrorMessage?.createApplicant}
              />
            )}
            {userState?.isErrors === false &&
              userState?.FormSuccess.createApplicant === true && (
                <SuccessMessage
                  header="Success"
                  content="Candidate Created Successfully !"
                />
              )}

            <Form.Button
              type="submit"
              floated="right"
              style={{
                backgroundColor: "#6855f6",
                color: "white",
                marginTop: "10px",
                right: 0,
              }}
            >
              Submit
            </Form.Button>
          </Form>
          <Modal
            size="350"
            onClose={() => setOpenDuplicateModal(false)}
            onOpen={() => setOpenDuplicateModal(true)}
            open={openDuplicateModal}
          >
            <ModalHeader>Duplicate Candidate Found !!!</ModalHeader>
            <ModalContent image>
              <ModalDescription>
                <Header >
                  <Link to={`/ dashboard / applicant / ${applicantData?.id} `}>
                    {applicantData?.firstName + " " + applicantData?.lastName}
                  </Link>
                </Header>
                <p>
                  Mobile Number: {applicantData?.mobileNum}
                </p>
                <p>Email: {applicantData?.email}</p>
                <br />
                {newStory === false ? (
                  applicantData?.applicant_story?.length > 0 && (
                    <>
                      <h2 >
                        Previous Candidate's Highlights
                      </h2>
                      <List
                        size="small"
                        bordered
                        style={{
                          maxHeight: 300,
                          overflow: "scroll",
                          display: "flex",
                          flexDirection: "column",
                          width: "full",
                          maxWidth: "600px"
                        }}
                        // style={{ fontFamily: "sans-serif" }}
                        dataSource={applicantData?.applicant_story}
                        renderItem={(item) => (
                          <div
                            onClick={() => setCandidateStory(item)}
                            className="lstitem"
                            style={{ padding: "10px", width: "100%" }}
                          >
                            {candidateStory === item ? (
                              <div style={{ color: "red" }}>{item}</div>
                            ) : (
                              item
                            )}
                          </div>
                        )}
                      />
                    </>
                  )
                ) : (
                  <>
                    <Form style={{
                      paddingLeft: "10px",
                      paddingRight: "10px"
                    }} >
                      <TextArea
                        style={{
                          padding: "10px",
                          border: "black",
                          // outlineColor: "#475569",
                          // outlineStyle: "solid",
                          outline: "2px solid #475569",
                          // outlineWidth: "2px",
                          color: "black",
                          fontSize: "16px",
                        }}
                        value={candidateStory}
                        onChange={(e) => setCandidateStory(e.target.value)}
                        placeholder="Enter New Candidate's Highlight" />
                    </Form>
                    {/* <textarea
                      type="text"
                      style={{
                        minWidth: "400px",
                        height: "250px",
                        padding: "10px",
                        outline: "none",
                        width: "auto",
                        border: "black",
                        outlineColor: "black",
                        outlineWidth: "2px",
                        color: "black",
                        fontSize: "16px",
                      }}
                      value={candidateStory}
                      onChange={(e) => setCandidateStory(e.target.value)}
                      placeholder="Enter New Candidate's Highlight"
                    ></textarea> */}
                  </>
                )}

                <br />
                {newStory === true ? (
                  <div
                    style={{ fontSize: "20px", cursor: "pointer" }}
                    onClick={() => {
                      setNewStory((newStory) => !newStory);
                      setCandidateStory("");
                    }}
                  >
                    {" "}
                    <MenuFoldOutlined /> Use Previous Used Highlights
                  </div>
                ) : (
                  <div
                    style={{ fontSize: "20px", cursor: "pointer" }}
                    onClick={() => {
                      setNewStory((newStory) => !newStory);
                      setCandidateStory("");
                    }}
                  >
                    {" "}
                    <PlusCircleOutlined /> Add new highlight
                  </div>
                )}
                {
                  videoRequired && (
                    newVideo === false ? (
                      <>
                        <h3 >Previous Candidate's Video Resume</h3>
                        <List
                          size="small"
                          bordered
                          style={{
                            maxHeight: 300,
                            overflow: "scroll",
                            display: "flex",
                            flexDirection: "column",
                          }}
                          dataSource={prevVResume?.slice(0, 3) || []}
                          renderItem={(item) => (
                            // <div
                            //   onClick={() => setVideoResume(item)}
                            //   className="lstitem"
                            //   style={{ padding: "10px", width: "100%" }}
                            // >
                            //   {videoResume === item ? (
                            //     <div style={{ color: "red" }}>{item}</div>
                            //   ) : (
                            //     <div >{item}</div>
                            //   )}
                            // </div>
                            <div
                              className="lstitem"
                              style={{ padding: "10px", width: "100%" }}
                            >
                              <Checkbox
                                checked={videoResume === item}
                                onChange={(e) => handleCheckboxChange(e.target.checked, item)}
                              />
                              <span
                                onClick={() => handleListItemClick(item)}
                                style={{ color: videoResume === item ? "red" : "inherit", marginLeft: 10, cursor: 'pointer' }}
                              >
                                {item}
                              </span>
                            </div>
                          )}
                        />
                      </>
                    ) : (
                      <div style={{
                        width: "100%",
                        maxHeight: 300,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        overflow: "clip",
                        flexWrap: "wrap",
                      }}>
                        <p>Please Upload compressed video below.
                          <br />
                          know more: <a href="https://www.freeconvert.com/video-compressor" style={{ color: "#6E5CF6", textDecoration: "none", fontWeight: "bolder" }} target="_blank" rel="noreferrer">videoconvertor.com</a> </p>
                        <Dragger {...props} style={{ marginTop: "3rem", paddingLeft: "3rem", paddingRight: "3rem", width: "100%" }}>
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">Click or drag file to this area to upload</p>
                          <p className="ant-upload-hint" style={{ color: "#aeaaae" }}>
                            Upload video under 15Mb
                          </p>
                        </Dragger>
                      </div>
                    )
                  )
                }
                <Modal
                  open={modalVisible}
                  onClose={() => setModalVisible(false)}
                  dimmer="blurring"
                  size="small"
                >
                  <ModalContent>
                    <video controls width="100%">
                      <source src={`${process.env.REACT_APP_SERVER_URL}/api/files/resume/${videoResume}`} type="" />
                      Your browser does not support the video tag.
                    </video>
                  </ModalContent>
                </Modal>
                {
                  videoRequired && (
                    newVideo === true ? (
                      <div
                        style={{ fontSize: "20px", cursor: "pointer" }}
                        onClick={() => {
                          setNewVideo((newVideo) => !newVideo);
                          setVideoResume(null);
                        }}
                      >
                        <MenuFoldOutlined /> Use Previous Used Video Resume
                      </div>
                    ) : (
                      <div
                        style={{ fontSize: "20px", cursor: "pointer" }}
                        onClick={() => {
                          setNewVideo((newVideo) => !newVideo);
                          setVideoResume(null);
                        }}
                      >
                        <PlusCircleOutlined /> Add new Video Resume
                      </div>
                    )
                  )
                }

              </ModalDescription>
            </ModalContent>
            <ModalActions>
              <Button color='gray' onClick={() => setOpenDuplicateModal(false)}>
                Nope
              </Button>
              <Popconfirm
                title="do you want to refer this candidate ?"
                onConfirm={referDuplicateCandidate}
                onCancel={() => setOpenDuplicateModal(false)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  content="Refer candidate"
                  labelPosition='right'
                  icon='checkmark'
                  color='violet'
                  disabled={(candidateStory === "" || (videoRequired && !videoResume) || loading)}
                // onClick={() => setOpenDuplicateModal(false)}
                />
              </Popconfirm>

            </ModalActions>
          </Modal>

        </Card.Content>
      </Card>
      <Modal
                    visible={isReferableOpen}
                    onCancel={() => setIsReferableOpen(false)}
                    footer={null}
                    closeIcon
                    dimmer="blurring"
                    size="small"
                    onClose={() => setIsReferableOpen(false)}
                    closeOnDimmerClick={false}
                    open={isReferableOpen}
                  >
                    <Modal.Header>Review Applicant Referral</Modal.Header>
                    <Modal.Content>
                      <p>Please review the following issues before proceeding with the referral:</p>
                      <ul style={{ color: "red" }}>
                        {isReferable?.ctcIssue && <li>{isReferable.ctcIssue}</li>}
                        {isReferable?.experienceIssue && <li>{isReferable.experienceIssue}</li>}
                        {isReferable?.maxCtcIssue && <li>{isReferable.maxCtcIssue}</li>}
                        {isReferable?.noticePeriodIssue && <li>{isReferable.noticePeriodIssue}</li>}
                      </ul>
                      <p>Are you sure you want to refer this applicant despite the above issues?</p>
                    </Modal.Content>
                    <Modal.Actions >
                      <Button danger style={{ backgroundColor: "red", color: "white", marginRight: "10px" }} onClick={() => setIsReferableOpen(false)}>
                        Disagree
                      </Button>
                      <Button loading={isloading} style={{ backgroundColor: "green", color: "white" }}
                      onClick={()=>{
                        submitForm();
                        setIsReferableOpen(false)
                      }}
                      >
                        Agree
                      </Button>
                    </Modal.Actions>
                  </Modal>
    </div>
  );
};

export default ReferNewApplicant;
