import React from 'react';
import { Button, Tooltip } from 'antd';
import { WechatFilled} from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserState } from '../features/userSlice';

const ChatButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userState= useSelector(UserState);

  const handleClick = () => {
    navigate('/dashboard/start_chat');
  };
  const userRole=userState.role;

// checking roles
const isAllowedRole = userRole === 'partnerAdmin' || userRole === 'partner';

// Don't render the button if we're already on the start_chat page or if the user role isn't allowed
if (location.pathname === '/dashboard/start_chat' || !isAllowedRole) {
  return null;
}

  return (
    <div style={{
      position: 'fixed',
      right: '36px',
      bottom: '36px',
      zIndex: 1000,
    }}>
      <Tooltip title="Start Chatting" placement="left">
        <Button
          type="primary"
          shape="circle"
          icon={<WechatFilled />}
          onClick={handleClick}
          size="large"
        />
      </Tooltip>
    </div>
  );
};

export default ChatButton;