import React from 'react';
import { Channel, Avatar, useChannelStateContext, useChatContext } from 'stream-chat-react';
import CreateChannel from './chatComponents/CreateChannel';
import EditChannel from './chatComponents/EditChannel';
import ChannelInner from './chatComponents/ChannelInner';
import { Empty, Button, Space, Typography } from 'antd';
import { MessageOutlined, UsergroupAddOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const CustomAvatar = (props) => {
  const { email, username } = props.user;
  return (
    <Avatar
      name={username || 'Unknown'}
      size={props.size || 40}
      getInitials={(fullName) => fullName.slice(0, 2).toUpperCase()}
    />
  );
};

const EmptyState = () => (
  <div className="channel-empty__container">
    <p className="channel-empty__first">This is the beginning of your chat history.</p>
    <p className="channel-empty__second">Send messages, attachments, links, emojis, and more!</p>
  </div>
);

const NoChannelsOrChats = ({ setIsCreating, setCreateType }) =>{
  const {client}= useChatContext();
  const isGuest = client.user.role === "guest";
   
  return (
  
  <div className="no-channels-chats__container" style={{ 
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center', 
    justifyContent: 'center', 
    width: "100%",
    height: '100%', 
    padding: '2rem',
    background: 'linear-gradient(135deg, #ffffff 0%, #f5f5f5 100%)'
  }}>
    <Empty
      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
      imageStyle={{
        height: 200,
      }}
      description={
        <Space direction="vertical" align="center">
          <Title level={3}> {isGuest?"You have Nothing yet":"No Groups or Direct Chats Yet"}
       </Title>
          <Text type="secondary" style={{ fontSize: '16px', textAlign: 'center' }}>
            {isGuest?"Click on Chat with PSM button to start chatting.":"No active conversations. Initiate a Group or direct chat to begin."}
          </Text>
        </Space>
      }
    > {!isGuest &&(
  
      <Space size="large">
        <Button 
          type="primary" 
          icon={<UsergroupAddOutlined />} 
          size="large"
          onClick={() => {
            setCreateType('team');
            setIsCreating(true);
          }}
        >
          Group Chat
        </Button>
        <Button 
          icon={<MessageOutlined />} 
          size="large"
          onClick={() => {
            setCreateType('messaging');
            setIsCreating(true);
          }}
        >
          Direct Chat
        </Button>
      </Space>
    ) }
    </Empty>
  </div>
);
}

const ChannelContainer = ({ isCreating, setIsCreating, isEditing, setIsEditing, createType, setCreateType }) => {
  const { channel } = useChannelStateContext();
  const { client } = useChatContext();

  const allChannels = Object.values(client.activeChannels);
  const hasChannelsOrChats = allChannels.length > 0;

  if (isCreating) {
    return (
      <div className="channel__container">
        <CreateChannel createType={createType} setIsCreating={setIsCreating} />
      </div>
    );
  }

  if (isEditing) {
    return (
      <div className="channel__container">
        <EditChannel setIsEditing={setIsEditing} />
      </div>
    );
  }

  if (!hasChannelsOrChats) {
    return <NoChannelsOrChats setIsCreating={setIsCreating} setCreateType={setCreateType} />;
  }

  return (
    <div className="channel__container">
      <Channel
        EmptyStateIndicator={EmptyState}
        Avatar={CustomAvatar}
      >
        <ChannelInner setIsEditing={setIsEditing} />
      </Channel>
    </div>
  );
};

export default ChannelContainer;