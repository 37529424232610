import React, { useState, useEffect } from "react";
import { FireOutlined, LeftOutlined, RightOutlined, LaptopOutlined,
  BarChartOutlined, } from "@ant-design/icons";
import {
  getSearchedJob,
  GetHotJobs,
  GetTechJobs,
  GetNonTechJobs,
} from "../api/partner";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { UserState } from "../features/userSlice";
import { filterJobsPartner } from "../api/partner";
import {
  PartnerState,
  selectNonTechJobs,
  selectTechJobs,
} from "../features/partnersSlice";

import ViewJobDetails from "./ViewJobDetails";
import {
  Button,
  Typography,
  Input,
  Divider,
  Form,
  Select,
  Spin,
  Row,
  Col,
  List,
  Drawer,
} from "antd";
import { getAllJobProfiles, fetchAllSkills } from "../api/auth";

const { Title } = Typography;
const { Option } = Select;

//Search Bar
const { Search } = Input;
//Prints search value (only for testing purpose)

//filter items for dropdown menus

const statusMenu = [
  {
    label: "Open",
    value: "open",
  },
  {
    label: "Candidate Offered",
    value: "candidate offered",
  },
  {
    label: "Candidate Accepted",
    value: "candidate accepted",
  },
  {
    label: "Closed",
    value: "closed",
  },
];

const exp = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26, 27, 28, 29, 30,
];

const OpenJobs = () => {
  const [form] = Form.useForm();
  const partnerState = useSelector(PartnerState);
  const dispatch = useDispatch();
  const JobData = partnerState?.assignedJobs;
  const [filterOpen, setFilterOpen] = useState(false);
  const [opendrawer, setOpenDrawer] = useState(false);

  const userState = useSelector(UserState);
  const [flag, setFlag] = useState(false);

  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const onClose = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    const fetchHotJobs = async (id) => {
      try {
        await GetHotJobs({ active_status: 1 }, dispatch);
      } catch (error) {
        console.log(error);
      }
    };
    const fetchTechJobs = async (id) => {
      try {
        await GetTechJobs({ active_status: 1 }, dispatch);
      } catch (error) {
        console.log(error);
      }
    };
    const fetchNonTechJobs = async (id) => {
      try {
        await GetNonTechJobs({ active_status: 1 }, dispatch);
      } catch (error) {
        console.log(error);
      }
    };
    const fetchJobProfiles = async () => {
      try {
        await getAllJobProfiles(dispatch);
      } catch (error) {
        console.log(error);
      }
    };
    const fetchSkills = async () => {
      try {
        await fetchAllSkills(dispatch);
      } catch (error) {
        console.log(error);
      }
    };
    fetchHotJobs();
    fetchNonTechJobs();
    fetchTechJobs();
    fetchJobProfiles();
    fetchSkills();
  }, [flag, dispatch]);
  const onFinish = (values) => {
    filterJobsPartner(
      {
        job_title: values.job_title,
        min_experience_req: values.min_experience_req,
        max_experience_req: values.max_experience_req,
        status: values.status,
        skills_req: values.skills_req,
        active_status: 1,
      },
      dispatch
    );
    setFilterOpen(true);
  };
  const AllSkills = userState.gSkills;
  const AllProfiles = userState.gProfile;

  const onFormLayoutChange = ({ size }) => {
    setComponentSize(size);
  };
  const [componentSize, setComponentSize] = useState("default");
  const onSearch = async (value) => {
    await getSearchedJob({ job: value, active_status: 1 }, dispatch);
    setFilterOpen(true);
  };

  const HotJobsData = partnerState.HotJobs;
  const TechJobsData = useSelector(selectTechJobs);
  const NonTechJobsData = useSelector(selectNonTechJobs);

  return (
    <>
      <br></br>

      <Drawer
        width={420}
        breakpoint="lg"
        placement="right"
        onClose={onClose}
        visible={opendrawer}
        title="Job Filter"
        initialValues={{
          size: componentSize,
        }}
        onValuesChange={onFormLayoutChange}
        size={componentSize}
      >
        <div style={{ width: "100%", padding: "10px" }}>
          <Form
            form={form}
            labelCol={{
              span: 4,
            }}
            style={{ width: "100%" }}
            layout="horizontal"
            onFinish={onFinish}
          >
            <label>Min Experience </label>
            <Form.Item name="min_experience_req">
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
              >
                {exp.map((data, index) => {
                  return (
                    <Option key={index} value={data}>
                      {data}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <label>Max Experience </label>
            <Form.Item name="max_experience_req">
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
              >
                {exp.map((data, index) => {
                  return (
                    <Option key={index} value={data}>
                      {data}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <label>Profile</label>
            <Form.Item name="job_title">
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
              >
                {AllProfiles.map((data, idx) => {
                  return (
                    <>
                      <Option key={idx} value={data.profile}>
                        {data.profile}
                      </Option>
                    </>
                  );
                })}
              </Select>
            </Form.Item>
            <label>Skills</label>
            <Form.Item name="skills_req">
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
              >
                {AllSkills.map((data, idx) => {
                  return (
                    <>
                      <Option key={idx} value={data.skill}>
                        {data.skill}
                      </Option>
                    </>
                  );
                })}
              </Select>
            </Form.Item>
            <label>Status</label>
            <Form.Item name="status">
              <Select
                style={{
                  width: "100%",
                }}
                options={statusMenu}
              ></Select>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Filter
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                style={{ width: "100%" }}
                onClick={() => {
                  form.resetFields();
                  setFlag((flag) => !flag);
                  setFilterOpen(false);
                }}
              >
                Reset All Filters
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Drawer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "10px",
        }}
        className="searchbox"
      >
        <Button onClick={showDrawer}>Filter</Button>
        <Search
          placeholder="input search text"
          allowClear
          autoFocus
          autoSize
          enterButton="Search"
          onSearch={onSearch}
          onChange={(e) => {
            if ((e.target.value = "")) {
              setFilterOpen(false);
            }
          }}
          style={{
            maxWidth: 400,
          }}
        />
      </div>

      {/* ALL THE JOBDATA WILL BE DISPLAYED HERE */}
      {partnerState.isPending === true ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          {filterOpen === false ? (
            <>
              {" "}
              <Divider orientation="left">
                <Title level={3}>
                  {" "}
                  <FireOutlined filled={true} style={{ color: "orange" }} /> Hot
                  Jobs
                </Title>
              </Divider>
              <div className="job-carousel" id="1" style={{ display: "flex", justifyItems: "center", position: "relative" }}>
                <Button style={{
                  position: "sticky",
                  top: '50%',
                  zIndex: 10,
                  left: 0,
                  width: "40px",
                  paddingLeft: "5px",
                  borderColor: "#6855f6",
                  boxShadow: "1px 2px 9px #B3A8FF"
                }} onClick={() => {
                  document.getElementById('1').scrollLeft -= 400;
                }}
                  // type="primary"
                  icon={<LeftOutlined style={{
                    color: "#6855f6", width: "40px",
                  }} />}
                />
                {/* <Carousel dots={{ className: "slider-dots" }} > */}
                {HotJobsData.map((data) => {
                  return (
                    <div style={{ width: "100%" }}>
                      {/* // <div style={{minHeight:"350px" ,minWidth:"270px", marginLeft:"10px", marginRight:"10px"}} key={data.id}> */}
                      <ViewJobDetails data={data} width={400} />
                    </div>
                  );
                })}
                <Button style={{
                  position: "sticky",
                  width: "40px",
                  paddingLeft: "5px",
                  right: 20,
                  top: '50%',
                  zIndex: 10,
                  borderColor: "#6855f6",
                  boxShadow: "1px 2px 9px #B3A8FF"
                }} onClick={() => {
                  document.getElementById('1').scrollLeft += 400;
                }}
                  icon={<RightOutlined style={{
                    color: "#6855f6", width: "40px",
                  }} />}
                />
              </div>
              <Divider orientation="left">
                <Title level={3}><LaptopOutlined style={{ color: "green" }} /> Tech Jobs</Title>
              </Divider>
              <div className="job-carousel" id="2" style={{ display: "flex", justifyItems: "center", position: "relative" }}>
                <Button style={{
                  position: "sticky",
                  top: '50%',
                  zIndex: 10,
                  left: 0,
                  width: "40px",
                  paddingLeft: "5px",
                  borderColor: "#6855f6",
                  boxShadow: "1px 2px 9px #B3A8FF"
                }} onClick={() => {
                  document.getElementById('2').scrollLeft -= 400;
                }}
                  // type="primary"
                  icon={<LeftOutlined style={{
                    color: "#6855f6", width: "40px",
                  }} />}
                />
                {TechJobsData.map((data) => {
                  return (
                    <div style={{ width: "100%" }}>
                      {/* // <div style={{minHeight:"350px" ,minWidth:"270px", marginLeft:"10px", marginRight:"10px"}} key={data.id}> */}
                      <ViewJobDetails data={data} width={400} />
                    </div>
                  );
                })}
                <Button style={{
                  position: "sticky",
                  width: "40px",
                  paddingLeft: "5px",
                  right: 20,
                  top: '50%',
                  zIndex: 10,
                  borderColor: "#6855f6",
                  boxShadow: "1px 2px 9px #B3A8FF"
                }} onClick={() => {
                  document.getElementById('2').scrollLeft += 400;
                }}
                  icon={<RightOutlined style={{
                    color: "#6855f6", width: "40px",
                  }} />}
                />
              </div>
              <Divider orientation="left">
                <Title level={3}>
                <BarChartOutlined style={{ color: "blue" }} /> Non-Tech Jobs</Title>
              </Divider>
              <div className="job-carousel" id="3" style={{ display: "flex", justifyItems: "center", position: "relative" }}>
                <Button style={{
                  position: "sticky",
                  top: '50%',
                  zIndex: 10,
                  left: 0,
                  width: "40px",
                  paddingLeft: "5px",
                  borderColor: "#6855f6",
                  boxShadow: "1px 2px 9px #B3A8FF"
                }} onClick={() => {
                  document.getElementById('3').scrollLeft -= 400;
                }}
                  // type="primary"
                  icon={<LeftOutlined style={{
                    color: "#6855f6", width: "40px",
                  }} />}
                />
                {NonTechJobsData.map((data) => {
                  return (
                    <div style={{ width: "100%" }}>
                      {/* // <div style={{minHeight:"350px" ,minWidth:"270px", marginLeft:"10px", marginRight:"10px"}} key={data.id}> */}
                      <ViewJobDetails data={data} width={400} />
                    </div>
                  );
                })}
                <Button style={{
                  position: "sticky",
                  width: "40px",
                  paddingLeft: "5px",
                  right: 20,
                  top: '50%',
                  zIndex: 10,
                  borderColor: "#6855f6",
                  boxShadow: "1px 2px 9px #B3A8FF"
                }} onClick={() => {
                  document.getElementById('3').scrollLeft += 400;
                }}
                  icon={<RightOutlined style={{
                    color: "#6855f6", width: "40px",
                  }} />}
                />
              </div>
            </>
          ) : (
            <Row>
              <Col flex={1.5}></Col>
              <Col flex={7}>
                <List
                  grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 3,
                    lg: 3,
                    xl: 3,
                    xxl: 3,
                  }}
                  dataSource={JobData}
                  renderItem={(item) => (
                    <List.Item>
                      <ViewJobDetails data={item} />
                      {/* <JobCard data={item} /> */}

                      {/* <Card hoverable bordered={false} title={item.title} style={{borderRadius:"5px"}}>{item.description}</Card> */}
                    </List.Item>
                  )}
                />
              </Col>
              <Col flex={1.5}></Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default OpenJobs;
