const countryCode = [
  {
    label: "+1 🇺🇸🇨🇦",
    value: "+1",
  },
  {
    label: "+44 🇬🇧",
    value: "+44",
  },
  {
    label: "+61 🇦🇺",
    value: "+61",
  },
  {
    label: "+63 🇵🇭",
    value: "+63",
  },
  {
    label: "+91 🇮🇳",
    value: "+91",
  },
];

export const countryCodeSemantic = [
  { id: 1, text: "+1 🇺🇸🇨🇦", value: "+1" },
  {
    id: 2,
    text: "+44 🇬🇧",
    value: "+44",
  },
  {
    id: 3,
    text: "+61 🇦🇺",
    value: "+61",
  },
  {
    id: 4,
    text: "+63 🇵🇭",
    value: "+63",
  },
  {
    id: 5,
    text: "+91 🇮🇳",
    value: "+91",
  },
];

export const defaultcCode = "+91";

export default countryCode;
