import { createSlice } from "@reduxjs/toolkit";
import { openNotificationWithIcon } from "../App";
import {
  clearLocalCache,
  readItemInLocalCache,
  setItemInLocalCache,
} from "../cache/localStorage";

const val = JSON.parse(localStorage.getItem("user"));
let check = false;
if (val) check = true;

export const userSlice = createSlice({
  name: "user",
  initialState: {
    userInfo: readItemInLocalCache("user"),
    isPending: false,
    isLoggedIn: check,
    openJobs: [],
    isErrors: false,
    role: readItemInLocalCache("role"),
    gSkills: [],
    gProfile: [],
    queries: [],
    sourcingVertical: [],
    stats: {
      jobs: readItemInLocalCache("stats.jobs") || 0,
      hotJobs: readItemInLocalCache("stats.hotJobs") || 0,
      AllApplicants: readItemInLocalCache("stats.AllApplicants") || 0,
      closedOffers: readItemInLocalCache("stats.closedOffers") || 0,
      qcAccepted: readItemInLocalCache("stats.qcAccepted") || 0,
    },
    ApplicantsDataExcel: [],
    FormErrorMessage: {
      createApplicant: null,
      updateProfile: null,
      updateCandidate: null,
    },
    FormSuccess: {
      updateCandidate: false,
      createApplicant: false,
    },
  },
  reducers: {
    start: (state) => {
      state.isPending = true;
      state.isErrors = false;
    },
    registersuccess: (state, action) => {
      state.isPending = false;
    },
    success: (state, action) => {
      state.userInfo = action.payload.user;

      state.role = action.payload.type;

      setItemInLocalCache("user", action.payload.user);
      setItemInLocalCache("chatToken", action.payload.chatToken);
      setItemInLocalCache("role", action.payload.type);
      state.isPending = false;
      state.isLoggedIn = true;
    },

    createPartnerAdminSuccess: (state, action) => {
      state.isPending = false;
      openNotificationWithIcon(
        "success",
        "Partner Admin Created Successfully !!",
        "bottomRight"
      );
    },
    successGlobalSkills: (state, action) => {
      state.isPending = false;
      state.gSkills = action.payload;
    },
    successGlobalcompany: (state, action) => {
      state.isPending = false;
      state.gCompany = action.payload;
    },
    logoutSucess: (state) => {
      state.isPending = false;
      state.userInfo = null;
      state.isLoggedIn = false;
      state.openJobs = [];
      state.role = null;
      clearLocalCache(); // clearing the localStorage after logout
    },
    successJobProfiles: (state, action) => {
      state.isPending = false;
      state.gProfile = action.payload;
    },
    errors: (state) => {
      state.isErrors = true;
      state.isPending = false;
      openNotificationWithIcon("error", "Some Error Occured !!", "bottomRight");
      // console.log(state);
    },
    fetchErrors: (state) => {
      state.isErrors = true;
      state.isPending = false;
    },
    queryFetchSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;

      state.queries = action.payload;
    },
    jobFetchSuccess: (state, action) => {
      state.openJobs = action.payload;
      state.isPending = false;
    },
    forgotPasswordSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      openNotificationWithIcon("success", "Reset Link Sent to your Email !!! ");
    },
    forgotPasswordError: (state) => {
      state.isErrors = true;
      state.isPending = false;
      openNotificationWithIcon(
        "error",
        "Some Error Occured !!! Please Try Again"
      );
    },
    forgotResetPasswordSuccess: (state) => {
      state.isErrors = false;
      state.isPending = false;
      openNotificationWithIcon(
        "success",
        "Password Has been Reset SuccessFully",
        "bottomRight"
      );
    },

    qeuryResolvedSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
    },
    statsfetchErrror: (state, action) => {
      state.isErrors = true;
      state.isPending = false;
      if (action.payload.status === 401) {
        if (state.isLoggedIn === true) {
          openNotificationWithIcon("info", "Please Login Again", "bottomRight");
          state.isLoggedIn = false;
          state.userInfo = null;
          localStorage.clear();
        } else {
          state.isErrors = false;
        }
      }
    },
    countJobStatsSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      state.stats.jobs = action.payload._count.active_status;

      setItemInLocalCache("stats.jobs", action.payload._count.active_status);
      setItemInLocalCache("stats.date", new Date());
    },
    countHotJobsStatsSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      state.stats.hotJobs = action.payload;
      setItemInLocalCache("stats.hotJobs", action.payload);
      setItemInLocalCache("stats.date", new Date());
    },
    countAllApplicantStatsSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      state.stats.AllApplicants = action.payload;
      setItemInLocalCache("stats.AllApplicants", action.payload);
      setItemInLocalCache("stats.date", new Date());
    },
    countOffersClosedStatsSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      state.stats.closedOffers = action.payload;
      setItemInLocalCache("stats.closedOffers", action.payload);
      setItemInLocalCache("stats.date", new Date());
    },
    countQCAcceptedStatsSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      state.stats.qcAccepted = action.payload;

      setItemInLocalCache("stats.qcAccepted", action.payload);
      setItemInLocalCache("stats.date", new Date());
    },
    userUpdateStart: (state) => {
      state.isErrors = false;
      state.FormErrorMessage.updateProfile = null;
      state.isPending = true;
    },
    userUpdateError: (state, action) => {
      state.isErrors = true;
      state.isPending = false;

      if (action.payload.status === 401) {
        if (state.isLoggedIn === true) {
          openNotificationWithIcon("info", "Please Login Again", "bottomRight");
          state.isLoggedIn = false;
          state.userInfo = null;
          clearLocalCache();
        } else {
          state.isErrors = false;
        }
      } else {
        state.FormErrorMessage.updateProfile = action.payload.data.message;
      }
    },
    userUpdateSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      state.userInfo = action.payload;

      setItemInLocalCache("user", action.payload);
      openNotificationWithIcon(
        "success",
        "Profile Updated Successfully",
        "bottomRight"
      );
    },
    getApplicantsDataForExcelStart: (state) => {
      state.isErrors = false;
      state.isPending = true;
    },
    getApplicantsDataForExcelSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;

      state.ApplicantsDataExcel = action.payload;
    },
    getApplicantsDataForExcelError: (state, action) => {
      state.isErrors = true;
      state.isPending = false;
      if (action.payload.status === 401) {
        // whenever a token is expired or cookie gets expired user has to login again
        if (state.isLoggedIn === true) {
          openNotificationWithIcon("info", "Please Login Again", "bottomRight");
          state.isLoggedIn = false;
          state.userInfo = null;
          clearLocalCache();
        } else {
          state.isErrors = false;
        }
      }
    },
    createApplicantStart: (state) => {
      state.isErrors = false;
      state.isPending = true;
      state.FormErrorMessage.createApplicant = null;
      state.FormSuccess.createApplicant = false;
    },
    createApplicantSuccess: (state, action) => {
      state.isPending = false;
      state.isErrors = false;
      openNotificationWithIcon(
        "success",
        "Applicant created successfully !!!",
        "bottomRight"
      );
      state.FormSuccess.createApplicant = true;
    },
    createApplicantError: (state, action) => {
      state.isErrors = true;
      state.isPending = false;
      state.FormSuccess.createApplicant = false;
      if (action.payload.status === 401) {
        if (state.isLoggedIn === true) {
          openNotificationWithIcon("info", "Please Login Again", "bottomRight");
          state.isLoggedIn = false;
          state.userInfo = null;
          clearLocalCache();
        } else {
          state.isErrors = false;
        }
      } else {
        openNotificationWithIcon(
          "error",
          JSON.stringify(action.payload.data.message) || "Some Error Occured",
          "bottomRight"
        );
        state.FormErrorMessage.createApplicant = JSON.stringify(
          action.payload.data.message
        );
      }
    },
    getApplicantByIDStart: (state) => {
      state.isPending = true;
      state.isErrors = false;
    },
    getApplicantByIDSuccess: (state, action) => {
      state.isPending = false;
    },
    getApplicantByIDErrors: (state, action) => {
      state.isPending = false;
      state.isErrors = true;
    },
    updateCandidateStart: (state) => {
      state.isPending = true;
      state.isErrors = false;
      state.FormSuccess.updateCandidate = false;
    },
    updateCandidateSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      state.FormSuccess.updateCandidate = true;
    },
    updateCandidateError: (state, action) => {
      state.isErrors = true;
      state.isPending = false;
      state.FormSuccess.updateCandidate = false;
      if (action.payload.status === 401) {
        if (state.isLoggedIn === true) {
          openNotificationWithIcon("info", "Please Login Again", "bottomRight");
          state.isLoggedIn = false;
          state.userInfo = null;
          clearLocalCache();
        } else {
          state.isErrors = false;
        }
      } else {
        state.FormErrorMessage.updateCandidate = action.payload.data.message;
      }
    },
    setSourcingVerticalData: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      state.sourcingVertical = action.payload
    }
  },
});

export const {
  start,
  success,
  errors,
  logoutSucess,
  registersuccess,
  createPartnerAdminSuccess,
  createApplicantSuccess,
  createJobSuccess,
  jobFetchSuccess,
  successGlobalSkills,
  createClientSuccess,
  successJobProfiles,
  queryFetchSuccess,
  qeuryResolvedSuccess,
  forgotPasswordSuccess,
  forgotPasswordError,
  forgotResetPasswordSuccess,
  fetchErrors,
  countHotJobsStatsSuccess,
  countAllApplicantStatsSuccess,
  countJobStatsSuccess,
  countQCAcceptedStatsSuccess,
  countOffersClosedStatsSuccess,
  statsfetchErrror,
  userUpdateError,
  userUpdateSuccess,
  getApplicantsDataForExcelError,
  getApplicantsDataForExcelStart,
  getApplicantsDataForExcelSuccess,
  createApplicantError,
  createApplicantStart,
  userUpdateStart,
  getApplicantByIDErrors,
  getApplicantByIDStart,
  getApplicantByIDSuccess,
  updateCandidateSuccess,
  updateCandidateError,
  setSourcingVerticalData,
  updateCandidateStart,
  successGlobalcompany,
} = userSlice.actions;

export const selectUser = (state) => state.user.userInfo;
export const UserState = (state) => state.user;
export const selectJobs = (state) => state.user.openJobs;
export const sourcingVerticalOptions = (state) => state.user.sourcingVertical;
export const gSkillsOptions = (state) => state.user.gSkills;
export const gProfileOptions = (state) => state.user.gProfile;
export default userSlice.reducer;
