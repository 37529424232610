import { createSlice } from "@reduxjs/toolkit";
import { openNotificationWithIcon } from "../App";

export const zyvkaMemberSlice = createSlice({
  name: "zyvkaMember",
  initialState: {
    myPartnerAdmins: [],
    AllPartnerAdmins: [],
    myJobs: [],
    isPending: false,
    isErrors: false,
    AllApplicants: [],
    AllClients: [],
    HotJobs: [],
    TechJobs: [],
    NonTechJobs: [],
    zyvkaMembers: [],
    queryOnMyJob: [],
    disabledPartnerAdmins: [],
    FormErrorMessage: {
      partnerAdminCreate: null,
      zyvkaMemberCreate: null,
      clientCreate: null,
      createJob: null,
    },
  },
  reducers: {
    createJobProfileStart: (state) => {
      state.isErrors = false;
      state.isPending = true;
    },
    createJobProfileErrors: (state) => {
      state.isErrors = true;
      state.isPending = false;
      openNotificationWithIcon(
        "error",
        "Some Error Occured  !!!",
        "bottomRight"
      );
    },
    createJobProfileSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      openNotificationWithIcon(
        "success",
        "Profile Added Successfully !!!",
        "bottomRight"
      );
    },
    addGlobalSkillsStart: (state) => {
      state.isErrors = false;
      state.isPending = true;
    },
    addGlobalSkillsErrors: (state, action) => {
      state.isErrors = true;
      state.isPending = false;
      console.log(action.payload);
      if (action.payload?.status === 401) {
        // whenever a token is expired or cookie gets expired user has to login again
        if (state.isLoggedIn === true) {
          openNotificationWithIcon("info", "Please Login Again", "bottomRight");
          state.isLoggedIn = false;
          state.userInfo = null;
          localStorage.clear();
        } else {
          state.isErrors = false;
        }
      } else {
        openNotificationWithIcon(
          "error",
          action.payload?.data?.message,
          "bottomRight"
        );
      }
    },
    addGlobalSkillsSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      openNotificationWithIcon(
        "success",
        "Skill Added Successfully !!!",
        "bottomRight"
      );
    },
    startfetch: (state) => {
      state.isPending = true;
      state.isErrors = false;
    },
    startUpdatePartnerAdmin: (state) => {
      state.isPending = true;
      state.isErrors = false;
    },
    ErrorsUpdateParnterAdmin: (state) => {
      state.isErrors = true;
      state.isPending = false;
    },
    success: (state, action) => {
      state.isPending = false;
    },
    editStatusStart: (state) => {
      state.isPending = true;
      state.isErrors = false;
    },
    editStatusErrors: (state) => {
      state.isErrors = true;
      state.isPending = false;
      openNotificationWithIcon("errror", "Some Error Occured!!", "bottomRight");
    },
    editStatusSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
    },
    successfetch: (state, action) => {
      state.isPending = false;
      state.myPartnerAdmins = action.payload;
    },
    successfetchAllPartnerAdmins: (state, action) => {
      state.isPending = false;
      state.AllPartnerAdmins = action.payload;
    },
    successfetchMyJobs: (state, action) => {
      state.isPending = false;
      state.myJobs = action.payload;
    },
    successfetchMyCandidates: (state, action) => {
      state.isPending = false;
      state.myJobs = action.payload;
    },
    successfetchAllApplicants: (state, action) => {
      state.isPending = false;
      state.AllApplicants = action.payload;
    },
    fetcherrors: (state, action) => {
      state.isErrors = true;
      state.isPending = false;
      if (action.payload?.status === 401) {
        // whenever a token is expired or cookie gets expired user has to login again
        if (state.isLoggedIn === true) {
          openNotificationWithIcon("info", "Please Login Again", "bottomRight");
          state.isLoggedIn = false;
          state.userInfo = null;
          localStorage.clear();
        } else {
          state.isErrors = false;
        }
      }
    },
    successfetchClients: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      state.AllClients = action.payload;
    },
    queryOpenStart: (state) => {
      state.isPending = true;
      state.isErrors = false;
    },
    queryOpenError: (state) => {
      state.isErrors = true;
      state.isPending = false;
    },
    qeuryOpenSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
    },
    hotJobsFetchSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      state.HotJobs = action.payload;
    },
    TechJobsFetchSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      state.TechJobs = action.payload;
    },
    NonTechJobsFetchSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      state.NonTechJobs = action.payload;
    },
    succesUpdatePartnerAdmin: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      openNotificationWithIcon(
        "success",
        "Updated Successfully !!!",
        "bottomRight"
      );
    },
    updateProfileStart: (state) => {
      state.isErrors = false;
      state.isPending = true;
    },
    updateProfileErrors: (state) => {
      state.isErrors = true;
      state.isPending = false;
      openNotificationWithIcon(
        "error",
        "Some Error Occured !!!",
        "bottomRight"
      );
    },
    updateProfileSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      openNotificationWithIcon(
        "success",
        "Job Profile Updated Successfully !!!",
        "bottomRight"
      );
    },
    createZyvkaUserStart: (state) => {
      state.isErrors = false;
      state.FormErrorMessage.zyvkaMemberCreate = null;
      state.isPending = true;
    },
    createZyvkaUserSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      openNotificationWithIcon(
        "success",
        "Zyvka User Created Successfully",
        "bottomRight"
      );
    },
    createZyvkaUserError: (state, action) => {
      state.isErrors = true;
      state.isPending = false;
      if (action.payload.status === 401) {
        if (state.isLoggedIn === true) {
          openNotificationWithIcon("info", "Please Login Again", "bottomRight");
          state.isLoggedIn = false;
          state.userInfo = null;
          localStorage.clear();
        } else {
          state.isErrors = false;
        }
      } else {
        openNotificationWithIcon(
          "error",
          action.payload.data.message || "Some Error Occured",
          "bottomRight"
        );
        state.FormErrorMessage.zyvkaMemberCreate = action.payload.data.message;
      }
    },

    fetchAllZyvkaUsersSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      state.zyvkaMembers = action.payload;
    },
    StartUpdateZyvka: (state) => {
      state.isErrors = false;
      state.isPending = true;
    },
    ErrorsUpdateZyvka: (state, action) => {
      state.isErrors = true;
      state.isPending = false;
    },
    SuccessUpdateZyvka: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
    },
    updateJobStart: (state) => {
      state.isErrors = false;
      state.isPending = true;
    },
    updateJobSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      openNotificationWithIcon(
        "success",
        "Your Job Has beed Updated Successfully",
        "bottomRight"
      );
    },
    updateJobErrors: (state) => {
      state.isErrors = true;
      state.isPending = false;
      openNotificationWithIcon("error", "Something went wrong", "bottomRight");
    },
    jobStatusUpdateStart: (state) => {
      state.isErrors = false;
      state.isPending = true;
    },
    jobStatusUpdateErrors: (state) => {
      state.isErrors = false;
      state.isPending = false;
      openNotificationWithIcon(
        "error",
        "Unable to Change The Status",
        "bottomRight"
      );
    },
    jobStatusUpdateSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      openNotificationWithIcon(
        "success",
        "Your Job's Status Has beed Updated Successfully",
        "bottomRight"
      );
    },
    QueryAskedInJobsStart: (state) => {
      state.isErrors = false;
      state.isPending = true;
    },
    QueryAskedInJobsSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      state.queryOnMyJob = action.payload;
    },
    QueryAskedInJobsErrors: (state, action) => {
      state.isErrors = true;
      state.isPending = false;
      if (action.payload.status === 401) {
        // whenever a token is expired or cookie gets expired user has to login again
        if (state.isLoggedIn === true) {
          openNotificationWithIcon("info", "Please Login Again", "bottomRight");
          state.isLoggedIn = false;
          state.userInfo = null;
          localStorage.clear();
        } else {
          state.isErrors = false;
        }
      }
    },
    DisablePartnerStart: (state) => {
      state.isErrors = false;
      state.isPending = true;
    },
    DisablePartnerSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      openNotificationWithIcon(
        "success",
        action.payload.message,
        "bottomRight"
      );
    },
    DisablePartnerError: (state, action) => {
      state.isErrors = true;
      state.isPending = false;
      if (action.payload.status === 401) {
        // whenever a token is expired or cookie gets expired user has to login again
        if (state.isLoggedIn === true) {
          openNotificationWithIcon("info", "Please Login Again", "bottomRight");
          state.isLoggedIn = false;
          state.userInfo = null;
          localStorage.clear();
        } else {
          state.isErrors = false;
        }
      } else {
        openNotificationWithIcon("error", "Some Error Occured", "bottomRight");
      }
    },
    EnablePartnerStart: (state) => {
      state.isErrors = false;
      state.isPending = true;
    },
    EnablePartnerSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      openNotificationWithIcon(
        "success",
        action.payload.message,
        "bottomRight"
      );
    },
    EnablePartnerError: (state, action) => {
      state.isErrors = true;
      state.isPending = false;
      if (action.payload.status === 401) {
        // whenever a token is expired or cookie gets expired user has to login again
        if (state.isLoggedIn === true) {
          openNotificationWithIcon("info", "Please Login Again", "bottomRight");
          state.isLoggedIn = false;
          state.userInfo = null;
          localStorage.clear();
        } else {
          state.isErrors = false;
        }
      } else {
        openNotificationWithIcon("error", "Some Error Occured", "bottomRight");
      }
    },
    getDisabledPartnerAdminSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      state.disabledPartnerAdmins = action.payload;
    },
    createPartnerAdminStart: (state) => {
      state.isErrors = false;
      state.FormErrorMessage.partnerAdminCreate = null;
      state.isPending = true;
    },
    createPartnerAdminSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      openNotificationWithIcon(
        "success",
        "Partner Admin Created Successfully",
        "bottomRight"
      );
    },
    createPartnerAdminError: (state, action) => {
      state.isErrors = true;
      state.isPending = false;
      if (action.payload.status === 401) {
        if (state.isLoggedIn === true) {
          openNotificationWithIcon("info", "Please Login Again", "bottomRight");
          state.isLoggedIn = false;
          state.userInfo = null;
          localStorage.clear();
        } else {
          state.isErrors = false;
        }
      } else {
        openNotificationWithIcon(
          "error",
          action.payload.data.message,
          "bottomRight"
        );
        state.FormErrorMessage.partnerAdminCreate = action.payload.data.message;
      }
    },
    createClientStart: (state) => {
      state.isErrors = false;
      state.FormErrorMessage.clientCreate = null;
      state.isPending = true;
    },

    createClientSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      openNotificationWithIcon(
        "success",
        "Client created successfully !!!",
        "bottomRight"
      );
    },
    createClientError: (state, action) => {
      state.isErrors = true;
      state.isPending = false;
      if (action.payload.status === 401) {
        if (state.isLoggedIn === true) {
          openNotificationWithIcon("info", "Please Login Again", "bottomRight");
          state.isLoggedIn = false;
          state.userInfo = null;
          localStorage.clear();
        } else {
          state.isErrors = false;
        }
      } else {
        openNotificationWithIcon(
          "error",
          action.payload.data.message || "Some Error Occured",
          "bottomRight"
        );
        state.FormErrorMessage.clientCreate = action.payload.data.message;
      }
    },
    createJobStart: (state) => {
      state.isErrors = false;
      state.FormErrorMessage.createJob = null;
      state.isPending = true;
    },

    createJobSuccess: (state, action) => {
      state.isErrors = false;
      state.FormErrorMessage.createJob = null;
      state.isPending = false;
      openNotificationWithIcon(
        "success",
        "Job Created Succesfully",
        "bottomRight"
      );
    },
    createJobError: (state, action) => {
      state.isErrors = true;
      state.isPending = false;
      if (action.payload.status === 401) {
        if (state.isLoggedIn === true) {
          openNotificationWithIcon("info", "Please Login Again", "bottomRight");
          state.isLoggedIn = false;
          state.userInfo = null;
          localStorage.clear();
        } else {
          state.isErrors = false;
        }
      } else {
        openNotificationWithIcon(
          "error",
          action.payload.data.message || "Some Error Occured",
          "bottomRight"
        );
        state.FormErrorMessage.createJob = action.payload.data.message;
      }
    },
    makeAdminStart: (state) => {
      state.isErrors = false;
      state.isPending = true;
    },
    makeAdminSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      openNotificationWithIcon(
        "success",
        action.payload?.message,
        "bottomRight"
      );
    },
    makeAdminError: (state, action) => {
      state.isErrors = true;
      state.isPending = false;
      if (action.payload.status === 401) {
        if (state.isLoggedIn === true) {
          openNotificationWithIcon("info", "Please Login Again", "bottomRight");
          state.isLoggedIn = false;
          state.userInfo = null;
          localStorage.clear();
        } else {
          state.isErrors = false;
        }
      } else {
        openNotificationWithIcon(
          "error",
          action.payload.data.message || "Some Error Occured",
          "bottomRight"
        );
      }
    },
    makePSMStart: (state) => {
      state.isErrors = false;
      state.isPending = true;
    },
    makePSMSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      openNotificationWithIcon(
        "success",
        action.payload?.message,
        "bottomRight"
      );
    },
    makePSMError: (state, action) => {
      state.isErrors = true;
      state.isPending = false;
      if (action.payload.status === 401) {
        if (state.isLoggedIn === true) {
          openNotificationWithIcon("info", "Please Login Again", "bottomRight");
          state.isLoggedIn = false;
          state.userInfo = null;
          localStorage.clear();
        } else {
          state.isErrors = false;
        }
      } else {
        openNotificationWithIcon(
          "error",
          action.payload.data.message || "Some Error Occured",
          "bottomRight"
        );
      }
    },

    archiveJobStart: (state) => {
      state.isErrors = false;
      state.isPending = true;
    },
    archiveJobSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;

      openNotificationWithIcon(
        "success",
        action.payload?.message,
        "bottomRight"
      );
    },

    archiveJobError: (state, action) => {
      state.isErrors = true;
      state.isPending = false;
      if (action.payload.status === 401) {
        if (state.isLoggedIn === true) {
          openNotificationWithIcon("info", "Please Login Again", "bottomRight");
          state.isLoggedIn = false;
          state.userInfo = null;
          localStorage.clear();
        } else {
          state.isErrors = false;
        }
      } else {
        openNotificationWithIcon(
          "error",
          action?.payload?.data?.message || "Some Error Occured",
          "bottomRight"
        );
      }
    },
    unarchiveJobStart: (state) => {
      state.isErrors = false;
      state.isPending = true;
    },
    unarchiveJobSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;

      openNotificationWithIcon(
        "success",
        action.payload?.message,
        "bottomRight"
      );
    },

    unarchiveJobError: (state, action) => {
      state.isErrors = true;
      state.isPending = false;
      if (action.payload.status === 401) {
        if (state.isLoggedIn === true) {
          openNotificationWithIcon("info", "Please Login Again", "bottomRight");
          state.isLoggedIn = false;
          state.userInfo = null;
          localStorage.clear();
        } else {
          state.isErrors = false;
        }
      } else {
        openNotificationWithIcon(
          "error",
          action?.payload?.data?.message || "Some Error Occured",
          "bottomRight"
        );
      }
    },
    AddClientContactPersonStart: (state) => {
      state.isErrors = false;
      state.isPending = true;
    },
    AddClientContactPersonSuccess: (state, action) => {
      state.isErrors = false;
      state.isPending = false;
      openNotificationWithIcon(
        "success",
        "Contact Person Added Successfully",
        "bottomRight"
      );
    },
    AddClientContactPersonError: (state, action) => {
      state.isErrors = true;
      state.isPending = false;
      if (action.payload.status === 401) {
        if (state.isLoggedIn === true) {
          openNotificationWithIcon("info", "Please Login Again", "bottomRight");
          state.isLoggedIn = false;
          state.userInfo = null;
          localStorage.clear();
        } else {
          state.isErrors = false;
        }
      } else {
        openNotificationWithIcon(
          "error",
          action?.payload?.data?.message || "Some Error Occured",
          "bottomRight"
        );
      }
    },
  },
});

export const {
  startfetch,
  success,
  fetcherrors,
  successfetch,
  successfetchMyJobs,
  successfetchMyCandidates,
  successfetchAllApplicants,
  successfetchClients,
  queryOpenStart,
  queryOpenError,
  qeuryOpenSuccess,
  hotJobsFetchSuccess,
  TechJobsFetchSuccess,
  successfetchAllPartnerAdmins,
  NonTechJobsFetchSuccess,
  editStatusErrors,
  editStatusStart,
  editStatusSuccess,
  startUpdatePartnerAdmin,
  ErrorsUpdateParnterAdmin,
  succesUpdatePartnerAdmin,
  createJobProfileStart,
  createJobProfileErrors,
  createJobProfileSuccess,
  addGlobalSkillsErrors,
  addGlobalSkillsSuccess,
  addGlobalSkillsStart,
  updateProfileStart,
  updateProfileErrors,
  updateProfileSuccess,
  createZyvkaUserStart,
  createZyvkaUserSuccess,
  createZyvkaUserError,
  fetchAllZyvkaUsersSuccess,
  StartUpdateZyvka,
  ErrorsUpdateZyvka,
  SuccessUpdateZyvka,
  updateJobStart,
  updateJobErrors,
  updateJobSuccess,
  jobStatusUpdateErrors,
  jobStatusUpdateStart,
  jobStatusUpdateSuccess,
  QueryAskedInJobsStart,
  QueryAskedInJobsErrors,
  QueryAskedInJobsSuccess,
  DisablePartnerError,
  DisablePartnerStart,
  DisablePartnerSuccess,
  EnablePartnerStart,
  EnablePartnerError,
  EnablePartnerSuccess,
  getDisabledPartnerAdminSuccess,
  createPartnerAdminStart,
  createPartnerAdminError,
  createPartnerAdminSuccess,
  createClientStart,
  createClientError,
  createClientSuccess,
  createJobStart,
  createJobError,
  createJobSuccess,
  makeAdminStart,
  makeAdminSuccess,
  makeAdminError,
  makePSMError,
  makePSMStart,
  makePSMSuccess,
  archiveJobError,
  archiveJobStart,
  archiveJobSuccess,
  unarchiveJobStart,
  unarchiveJobError,
  unarchiveJobSuccess,
  AddClientContactPersonError,
  AddClientContactPersonSuccess,
  AddClientContactPersonStart,
} = zyvkaMemberSlice.actions;

export const selectmyPartnerAdminsDetails = (state) =>
  state.zyvkaMember.myPartnerAdmins;
export const selectAllPartnerAdminsDetails = (state) =>
  state.zyvkaMember.AllPartnerAdmins;
export const ZyvkaMemberState = (state) => state.zyvkaMember;
export const selectMyJobs = (state) => state.zyvkaMember.myJobs;
export const selectTechJobs = (state) => state.zyvkaMember.TechJobs;
export const selectHotJobs = (state) => state.zyvkaMember.HotJobs;
export const selectNonTechJobs = (state) => state.zyvkaMember.NonTechJobs;
export const selectAllApplicants = (state) => state.zyvkaMember.AllApplicants;
export default zyvkaMemberSlice.reducer;
