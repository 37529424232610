import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createPartnerAdmin, getGeogrpahyData, getIndustryData, getPrimarySkillsData, getVerticalSourcingData } from "../api/ZyvkaMember";
import { useDispatch } from "react-redux";
import { gProfileOptions, gSkillsOptions, selectUser, sourcingVerticalOptions } from "../features/userSlice";
import { ZyvkaMemberState } from "../features/ZyvkaMemberSlice";
import data from "../Data/Data";
import { defaultcCode } from "../Data/CountryCode";
import { Form, Card } from "semantic-ui-react";
import ErrorMessage from "../Error/ErrorMessage";
import { countryCodeSemantic } from "../Data/CountryCode";
import { message } from "antd";
import { fetchAllSkills, getAllJobProfiles } from "../api/auth";

const AddPartnerAdmin = () => {
  const zyvkaState = useSelector(ZyvkaMemberState);
  const [fileObj, setFileObj] = useState()
  const [industryOptions, setIndustryOptions] = useState([])
  const [primarySkillsOptions, setPrimarySkillsOptions] = useState([])
  const fetchPrimarySkills = async () => {
    try {
      const res = await getPrimarySkillsData();
      setPrimarySkillsOptions(res?.primarySkills);
    } catch (e) {
      console.error(e)
    }
  }
  const fetchIndustryData = async () => {
    try {
      const res = await getIndustryData();
      setIndustryOptions(res?.industry);
    } catch (e) {
      console.error(e)
    }
  }
  const [formValues, setFormValues] = useState({
    fname: "",
    lname: "",
    cCode: defaultcCode,
    company_name: "",
    mobileNum: "",
    email: "",
    confirmEmail: "",
    isFreelancer: false,
    typeOfHiring: "",
    sourcingVertical: "",
    geography: "",
    rolesFulfilled: "",
    salary: null,
    primary_roles: null,
    industry: null
  });

  const [geoOptions, setGeoOptions] = useState()
  const hiringOptions = [
    { id: 1, text: "Tech", value: "Tech" },
    { id: 2, text: "Non-Tech", value: "Non-Tech" },
    { id: 3, text: "Both", value: "Both" },
  ];
  const salaryOptions = [
    { id: 1, text: "0-10 Lac", value: "0-10" },
    { id: 2, text: "10-20 Lac", value: "10-20" },
    { id: 3, text: "20-30 Lac", value: "20-30" },
    { id: 4, text: "40+ Lac", value: "40+" },
  ];
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const verticlalOptions = useSelector(sourcingVerticalOptions)
  const rolesOptions = useSelector(gProfileOptions)
  const AddPAdmin = data.Dashboard.zyvka_partner_management.AddPartnerAdmin;
  useEffect(() => {
    const getVerticalData = async () => {
      await getVerticalSourcingData(dispatch)
      await getAllJobProfiles(dispatch);
      const res = await getGeogrpahyData();
      setGeoOptions(res?.geography);
    }
    getVerticalData()
    fetchPrimarySkills()
    fetchIndustryData()
  }, [])
  const submitForm = async (e) => {
    try {
      e.preventDefault();
      const formdata = new FormData()
      formdata.append("file", fileObj)
      const datavalues = {
        firstName: formValues.fname,
        lastName: formValues.lname,
        zyvkaUserID: user.id,
        countryCode: formValues.cCode,
        email: formValues.email,
        mobileNum: formValues.mobileNum,
        company_name: formValues.company_name,
        isFreelancer: formValues.isFreelancer,
        typeOfHiring: formValues.typeOfHiring,
        sourcingVertical: formValues.sourcingVertical,
        geography: formValues.geography,
        rolesFulfilled: formValues?.rolesFulfilled,
        primary_roles: formValues?.primary_roles,
        salary: formValues?.salary,
        industry: formValues?.industry
      }
      formdata.append("formvalues", JSON.stringify(datavalues))
      await createPartnerAdmin(
        formdata,
        dispatch,
        setFormValues
      );
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const changeHandler = (e) => {
    console.log(e);
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };
  return (
    <div className="site-card-border-less-wrapper">
      <br></br>
      <Card
        headStyle={{ fontSize: "25px", fontWeight: "bold" }}
        bordered={false}
        style={{
          width: "auto",
          padding: "15px",
          maxWidth: "900px",
        }}
      >
        <Card.Content header={AddPAdmin.title}></Card.Content>
        <Card.Content>
          <Form onSubmit={submitForm}>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                placeholder="First name"
                name="fname"
                value={formValues?.fname}
                onChange={changeHandler}
                required={AddPAdmin.form.inputs.fname.required}
                label={AddPAdmin.form.inputs.fname.label}
              />
              <Form.Input
                fluid
                name="lname"
                value={formValues?.lname}
                onChange={changeHandler}
                label={AddPAdmin.form.inputs.lname.label}
                placeholder="Last name"
                required={AddPAdmin.form.inputs.lname.required}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                name="email"
                type="email"
                value={formValues?.email}
                onChange={changeHandler}
                label={AddPAdmin.form.inputs.email.label}
                placeholder="Email"
                required={AddPAdmin.form.inputs.email.required}
              />
              <Form.Input
                fluid
                name="confirmEmail"
                label="Confirm Email"
                value={formValues?.confirmEmail}
                onChange={changeHandler}
                error={
                  formValues?.confirmEmail
                    ? formValues?.confirmEmail !== formValues?.email &&
                    "Both Email should be same"
                    : false
                }
                placeholder="Confirm Email"
                required={true}
              />
            </Form.Group>
            <Form.Group widths={"18"}>
              <Form.Select
                label="Country Code"
                name="cCode"
                value={formValues?.cCode}
                onChange={(e, data) => {
                  setFormValues({ ...formValues, [data.name]: data.value });
                }}
                placeholder="country code"
                options={countryCodeSemantic}
              ></Form.Select>
              <Form.Input
                type="text"
                fluid
                label={AddPAdmin.form.inputs.mobile.label}
                name="mobileNum"
                error={
                  formValues?.mobileNum
                    ? formValues?.mobileNum.length > 10 &&
                    "Mobile Number should be 10 digits only"
                    : false
                }
                value={formValues?.mobileNum}
                onChange={changeHandler}
                required={AddPAdmin.form.inputs.mobile.required}
                placeholder="Enter partner's mobile number"
                width={12}
              />
            </Form.Group>
            <Form.Group widths={"18"}>
              <Form.Select
                width={6}
                search
                multiple
                options={primarySkillsOptions?.map((data, idx) => {
                  return { key: idx, value: data.name, text: data.name };
                })}
                placeholder="Select Primary Roles"
                name="primary_roles"
                value={formValues?.primary_roles}
                onChange={(e, data) => {
                  setFormValues({ ...formValues, [data.name]: data.value });
                }}
                label="Primary Roles"
              ></Form.Select>
              <Form.Select
                width={6}
                search
                multiple
                options={industryOptions?.map((data, idx) => {
                  return { key: idx, value: data.name, text: data.name };
                })}
                placeholder="Select Client Industry"
                name="industry"
                value={formValues?.industry}
                onChange={(e, data) => {
                  setFormValues({ ...formValues, [data.name]: data.value });
                }}
                label="Client Industry"
              ></Form.Select>
              <Form.Select
                width={6}
                search
                multiple
                options={salaryOptions}
                placeholder="Select Salary Bracket(2 Options)"
                name="salary"
                value={formValues?.salary}
                onChange={(e, data) => {
                  if (data?.value?.length <= 2) {
                    setFormValues({ ...formValues, [data.name]: data.value });
                  } else if (data?.value?.length > 2) {
                    setFormValues({ ...formValues, [data.name]: data.value.slice(-2) });
                    message.info('Only 2 salary bracket can be opted')
                  }
                }}
                label="Salary Bracket"
              ></Form.Select>
            </Form.Group>
            <Form.Group widths={"18"}>
              <Form.Select
                fluid
                search
                label="Type of Hiring"
                name="typeOfHiring"
                value={formValues?.typeOfHiring}
                onChange={(e, data) => {
                  setFormValues({ ...formValues, [data.name]: data.value });
                }}
                options={hiringOptions}
                required={true}
                placeholder="Select type of hiring"
                width={6}
              />
              <Form.Checkbox
                style={{ marginTop: "30px" }}
                toggle
                name="isFreelancer"
                label="Freelancer ?"
                checked={formValues?.isFreelancer}
                onChange={(e, data) => {
                  setFormValues({
                    ...formValues, [data.name]: data.checked,
                    company_name: data.checked ? `${formValues?.fname} ${formValues?.lname}` : ''
                  });
                }}
                required={true}
                width={4}
              ></Form.Checkbox>
              <Form.Input
                fluid
                name="company_name"
                value={formValues?.company_name}
                onChange={changeHandler}
                label={AddPAdmin.form.inputs.company_name.label}
                placeholder="Company name"
                required={AddPAdmin.form.inputs.company_name.required}
                width={8}
              />
            </Form.Group>
            <Form.Group widths={"18"}>
              <Form.Select
                fluid
                search
                label="Sourcing Verticals"
                multiple
                name="sourcingVertical"
                value={formValues?.sourcingVertical}
                onChange={(e, data) => {
                  setFormValues({ ...formValues, [data.name]: data.value });
                }}
                options={verticlalOptions?.map((id, el) => {
                  return {
                    text: id?.sourcingVertical,
                    value: id?.sourcingVertical,
                    id: id?.id
                  }
                })}
                placeholder="Select sourcing vertical"
                required={true}
                width={6}
              />
              <Form.Select
                fluid
                multiple
                search
                label="Roles Fulfilled"
                name="rolesFulfilled"
                value={formValues?.rolesFulfilled}
                // onChange={(e, data) => {
                //   console.log(data.value[0])
                //   setFormValues({ ...formValues, [data.name]: data.value[0] });
                // }}
                onChange={(e, data) => {
                  setFormValues({
                    ...formValues,
                    [data.name]: data.value,
                  })
                }}
                options={rolesOptions?.map((el) => {
                  return {
                    id: el?.id,
                    text: el?.profile,
                    value: el?.profile
                  }
                })}
                placeholder="Select roles Fulfilled"
                required={true}
                width={6}
              />
              <Form.Select
                fluid
                search
                multiple
                label="Geography"
                name="geography"
                value={formValues?.geography}
                onChange={(e, data) => {
                  setFormValues({ ...formValues, [data.name]: data.value });
                }}
                options={geoOptions?.map((el) => {
                  return {
                    id: el?.id,
                    text: el?.location,
                    value: el?.location
                  }
                })}
                placeholder="Select geography"
                required={true}
                width={6}
              />
            </Form.Group>
            <Form.Group widths={"18"}>
              <Form.Input
                type="file"
                label="Partner Contract (optional)"
                placeholder="Add Partner Contract (optional)"
                accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                name="file"
                onChange={(e) => {
                  setFileObj(
                    e.target.files[0],
                  )
                }}
              ></Form.Input>
            </Form.Group>
            {zyvkaState?.isErrors && (
              <ErrorMessage
                header={"Error Message"}
                content={zyvkaState?.FormErrorMessage?.partnerAdminCreate}
              />
            )}

            <Form.Button
              type="submit"
              // floated="right"
              style={{
                backgroundColor: "#6855f6",
                color: "white",
                marginTop: "10px",
              }}
            >
              {AddPAdmin.form.inputs.submit.label}
            </Form.Button>
          </Form>
        </Card.Content>
      </Card>
    </div >
  );
};

export default AddPartnerAdmin;
