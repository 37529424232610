import React, { useEffect, useState } from "react";
import {
    Button,
    Space,
    Table,
    Select,
    Modal,
    Form,
    Input,
    DatePicker,
    InputNumber,
    Radio,
    Typography
} from "antd";
import { DownloadOutlined, EditOutlined } from "@ant-design/icons";
import { ApplicantRemarkMethod, editApplicantStatusThroughClient } from "../api/ZyvkaMember";
import { useParams, useNavigate, Link } from "react-router-dom";
import { ZyvkaMemberState } from "../features/ZyvkaMemberSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { clientData, email, setTableData } from "../features/clientSlice";

const { Column, ColumnGroup } = Table;

const { Option } = Select;
const { TextArea } = Input;
const { Title, Paragraph } = Typography;
const API = axios.create({ baseURL: process.env.REACT_APP_SERVER_URL });



const ApplicantRemarks = (props) => {

    const [statusOption, setStatusOption] = useState([]);
    const [flag, setFlag] = useState(false);
    const [currencyType, setCurrencyType] = useState("INR");
    const [join_date, setJoinDate] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [status, setStatus] = useState([]);
    const [round, setRound] = useState();
    const [value, setValue] = useState([]);
    const [tempID, setTempID] = useState("");

    //Joining modal states and functions
    const [isModalOpen, setIsModalOpen] = useState("");
    const [isRejectModalOpen, setIsRejectModalOpen] = useState("");
    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

    //search 
    const [searchValue, setSearchValue] = useState("");
    const [remarkModal, setRemarkModal] = useState(false);
    // const [remarkValue, setRemarkValue] = useState([]);
    const [tabData, setTabData] = useState(useSelector(clientData) || null);
    // const [remarkArray, setRemarkArray] = useState(["he is good", "He works fine", "he is fast", "he gets the job done"])
    const [newRemark, setNewRemark] = useState('');


    const fetchJobData = async () => {
        try {
            const result = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/api/jobs/getJobByID/${props.id}`,
                { withCredentials: true }
            );
            let arr = [];
            result?.data?.hiring_process?.forEach((element) => {
                arr.push(JSON.parse(element)?.name);
            });
            // console.log(arr);
            setStatusOption(arr);
            // console.log(statusOption);
        } catch (error) {
            console.log(error);
        }
    };

    const remarkModalHandler = (prevRemark, AddedData, applicantID) => {
        console.log(applicantID)
        remarkChangeHandler(prevRemark, AddedData, applicantID);
        setRemarkModal(false);
    }
    const remarkModalFail = (error) => {
        console.log(error);
    }
    const remarkModalOpener = (id) => {
        setRemarkModal(id);
        console.log("remark modal opened with id -> ", id);
    }

    // copied from other file 
    const onRejectModalFinish = async (data, status) => {
        try {
            if (window.confirm(`You Want to Reject the candidate? you wont be able ro revert back the changes`)) {
                const applicationID = isRejectModalOpen;
                const upValue = getValue(applicationID);
                statusUpdate(applicationID, upValue);
                const regex = /R(\d+)/;
                const match = upValue.match(regex);
                let res;
                if (match) {
                    res = parseInt(match[1]);
                } else {
                    res = 0;
                }

                // const res = parseInt(upValue?.split(" ")[0].substring(1));
                // console.log(status);
                rejectModalCancel();
                console.log(res);
                await editApplicantStatusThroughClient(
                    {
                        email: clEmail,
                        applicationID: applicationID,
                        status: upValue,
                        reject_reason: data.reject_reason,
                        round_number: res,
                        isLastRound:
                            (parseInt(upValue?.split("-")[0]?.substring(1)) === statusOption.length)
                                ? true
                                : false,
                    });
                setFlag((flag) => !flag);
            }
            // console.log(data.reject_reason, "reason is here");
        } catch (error) {
            console.log(error);
        }
    };
    const rejectModalCancel = () => {
        setIsRejectModalOpen("");
    }
    const onRejectModalFail = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const statusChangeHandler = async (status, applicationID) => {
        let statusSplitArray = status.split(" ");
        valueUpdate(applicationID, status);
        //! setValue(status); --> 

        // statusUpdate(applicationID, status);
        if (status === "Candidate Joined") {
            // statusUpdate(applicationID, status);
            setIsModalOpen(applicationID);
        }
        else if (statusSplitArray[statusSplitArray.length - 1] === "Rejected") {
            // statusUpdate(applicationID, status);
            setIsRejectModalOpen(applicationID);
        }
        else {
            if (window.confirm(`You Want to change Status to ${status}`)) {
                if (statusSplitArray[statusSplitArray.length - 1] === "Selected") {
                    const stat = status.split(" ").slice(0, 3).join(" ")
                    statusUpdate(applicationID, status);
                    const res = parseInt(status.split(" ")[0].substring(1))
                    // const new = status.split(" ")[3]
                    setRound(res);
                    await editApplicantStatusThroughClient(
                        {
                            email: clEmail,
                            applicationID: applicationID,
                            status: status,
                            round_number: res,
                            isLastRound:
                                (parseInt(status?.split("-")[0]?.substring(1)) === statusOption.length)
                                    ? true
                                    : false,
                        }
                    );
                    // console.log("updated", round, "~");
                } else {
                    statusUpdate(applicationID, status);
                    const res = status.split(" ")[0] === "Offer" ? 0 : 0;
                    setRound(round);
                    await editApplicantStatusThroughClient(
                        {
                            email: clEmail,

                            applicationID: applicationID,
                            status: status,
                            //! current_round_num or round_number
                            round_number: res,
                        }
                    );
                    // console.log("updated", round, "~");
                }
            }
            setFlag((flag) => !flag);
        }
    };
    const handleCancel = () => {
        setIsModalOpen("");
    };
    const showStatusModal = (id) => {
        setIsStatusModalOpen(id);
    };
    const handleOkStatus = (id) => {
        setIsStatusModalOpen(false);
        // statusUpdate(tempID, value);
        const upValue = getValue(id);
        const status = getState(id);
        if (status !== upValue) {
            statusChangeHandler(upValue, id);
        }
    };
    const handleCancelStatus = (id) => {
        setIsStatusModalOpen(false);
        valueRevert(id);
    };

    const candidateJoinedStatusChangeHandler = async (values) => {
        try {
            if (window.confirm(`You Want to change Status to Candidate Joined`)) {
                const applicationID = isModalOpen;
                statusUpdate(applicationID, "Candidate Joined");
                const currency_type = currencyType;
                const joining_date = join_date;
                handleCancel();
                setCurrencyType("INR");
                setJoinDate("");

                await editApplicantStatusThroughClient(
                    {
                        email: clEmail,
                        applicationID: applicationID,
                        status: "Candidate Joined",
                        final_salary: values.final_salary,
                        currencyType: currency_type,
                        joining_date: joining_date,
                    }
                );

                setFlag((flag) => !flag);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const remarkChangeHandler = async (prevData, addedData, id) => {
        try {
            console.log(addedData, id, "here is the data");
            let resData = [...prevData, addedData];
            await ApplicantRemarkMethod(
                {
                    remark: JSON.stringify(resData),
                    applicantID: id,
                    email: clEmail
                },
                {
                    withCredentials: true
                }
            );
            window.alert(`Remark Successfully Updated`)
            setFlag(!flag);
        }
        catch (e) {
            window.alert(`There was some Error updating the remarks `)
            console.log(e);
        }
    }
    const candidateJoinedStatusFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    // job status setter and getter function 
    const statusUpdate = (stateId, newStatus) => {
        // Check if there's an object with the same id as stateId in the status
        const existingStateIndex = status.findIndex((item) => item.id === stateId);

        if (existingStateIndex !== -1) {
            // If an object with stateId exists, update its status
            const updatedStateArray = [...status];
            updatedStateArray[existingStateIndex] = { id: stateId, status: newStatus };
            setStatus(updatedStateArray);
        } else {
            // If an object with stateId doesn't exist, add a new object to the status
            const newStateItem = { id: stateId, status: newStatus };
            setStatus([...status, newStateItem]);
        }
    }
    const getState = (id, nStatus) => {
        const existingObject = status.find(el => el.id === id);
        if (existingObject) {
            // console.log(nStatus, "exists");
            return existingObject.status;
        } else {
            statusUpdate(id, nStatus);
            // console.log(nStatus, "update");
            return null;
        }
    };

    //change and update value 
    const valueUpdate = (id, newValue) => {
        const index = value.findIndex((item) => item.id === id);
        if (index === -1) {
            // Add new object to state array
            setValue([...value, { id, value: newValue }]);
        } else {
            // Update existing object in state array
            const updatedData = [...value];
            updatedData[index] = { id, value: newValue };
            setValue(updatedData);
        }
    };
    const getValue = (id) => {
        const item = value?.find((item) => item?.id === id);
        return item?.value || "";
    };
    const valueRevert = (id) => {
        const item = status.find((item) => item.id === id);
        const idx = value.findIndex((item) => item.id === id);
        // console.log(item);
        const updateValue = [...value];
        updateValue[idx] = { id: id, value: item?.status };
        setValue(updateValue);
    }

    // button and radio handler
    const onChangeRadio = (e, id) => {
        // if (e.target.value !== status) {
        //   setValue(e.target.value);
        // }
        valueUpdate(id, e.target.value);
        // setValue(e.target.value);

    };
    const handleButtonClick = (id, status) => {
        // console.log(id);
        setTempID(id);
        //* setStatus(status)
        // setStaus(status);
        valueUpdate(id, status);
        // setValue(status);
        showStatusModal(id);
    }
    const onChange = (date, dateString) => {
        setJoinDate(dateString);
    };
    const clickHandler = (id1) => {
        const url = `/dashboard/applicant/job/${id1}`;
        navigate(url);
    };
    const currencySelector = (
        <Form.Item name="currency_type" noStyle>
            <Select
                defaultValue={currencyType}
                rules={[
                    {
                        required: true,
                        message: "This is Required",
                    },
                ]}
                onChange={(e) => setCurrencyType(e)}
            >
                <Option value="USD">$</Option>
                <Option value="INR">₹</Option>
            </Select>
        </Form.Item>
    );


    // useEffect(() => {
    //     fetchData();
    //     fetchJobData();
    // }, [flag, status]);


    const getValueForRadio = () => {
        const item = value?.find((item) => item?.id === tempID);
        return item?.value;
    };
    const clEmail = useSelector(email);



    useEffect(() => {
        const getData = async () => {
            const res2 = await API.post(
                "api/client/privillageAccess/getCandidateData",
                {
                    email: clEmail,
                },
                {
                    withCredentials: true,
                }
            );
            const { quans } = res2.data;
            console.log(quans, "here");
            setTabData(quans);
            // fetchJobData(quans[0].jobID);
            dispatch(setTableData(quans));
        };
        getData();
    }, [flag]);
    useEffect(() => {
        fetchJobData();
    }, []);
    const pushToPage = (id) => {
        navigate(`/applicantdetail/applicant/${id}`);
    };
    console.log(tabData)
    return (
        <div className="tableDiv" style={{ overflow: "scroll", marginTop: "-15px", minHeight: "full" }} >
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flexWrap: "wrap",
                    gap: "10px",
                }}
            >
                <Space direction="horizontal" wrap={true}>
                    <Input
                        placeholder="Search Here"
                        style={{
                            minWidth: 200,
                            maxWidth: 400,
                            paddingLeft: 10,
                        }}
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                        }}
                    />
                    <Button style={{ width: 80 }}>Search</Button>
                </Space>
            </div>
            {
                tabData ? (
                    <Table scroll={{ x: true }} dataSource={tabData} bordered={true} rowKey={(record) => record?.id} key="table">
                        <Column
                            title="Name"
                            key="id"
                            render={(_, record) => (
                                <Link
                                    to={`/applicant/${record?.applicantID}`}
                                    // onClick={(e) => pushToPage(record?.id)}
                                    size="middle"
                                    key={record.id}
                                >
                                    {record.applicant.firstName + " " + record.applicant.lastName}
                                </Link>
                            )}
                        />
                        <Column
                            title="email"
                            key="email"
                            filteredValue={[searchValue]}
                            onFilter={(value, record) => {
                                //console.log(record?.firstName?.includes(value));
                                return (
                                    String(
                                        record?.applicant?.firstName + " " + record?.applicant?.lastName
                                    )
                                        ?.toLowerCase()
                                        .includes(value.toLocaleLowerCase()) ||
                                    String(record?.applicant?.lastName)
                                        ?.toLowerCase()
                                        .includes(value.toLocaleLowerCase()) ||
                                    String(record?.applicant?.email)
                                        ?.toLowerCase()
                                        .includes(value.toLocaleLowerCase()) ||
                                    String(record?.applicant?.mobileNum)
                                        ?.toLowerCase()
                                        .includes(value.toLocaleLowerCase()) ||
                                    String(record?.current_status)
                                        ?.toLowerCase()
                                        .includes(value.toLocaleLowerCase())
                                );
                            }}
                            render={(_, record) => (
                                <Space size="middle" key={record.id}>
                                    {record.applicant.email}
                                </Space>
                            )}
                        />
                        <Column
                            title="Mobile No"
                            key="mobileNum"
                            render={(_, record) => (
                                <Space size="middle" key={record.id}>
                                    {record.applicant.mobileNum}
                                </Space>
                            )}
                        />

                        <Column
                            title="Status"
                            key="job_status"
                            render={(_, record) => (
                                <>
                                    {
                                        record?.current_status.toLowerCase().includes("reject") ? (
                                            <Button danger onClick={() => handleButtonClick(record?.id, record?.current_status)}>
                                                {
                                                    record?.current_status
                                                }
                                            </Button>
                                        ) : (
                                            getState(record?.id, record?.current_status) === "Candidate Joined" ? (
                                                <Button type="primary" ghost onClick={() => handleButtonClick(record?.id, record?.current_status)}>

                                                    {
                                                        getState(record?.id, record?.current_status) || record?.current_status
                                                    }
                                                </Button>
                                            )
                                                : (
                                                    <Button onClick={() => handleButtonClick(record?.id, record?.current_status)}>

                                                        {
                                                            getState(record?.id, record?.current_status) || record?.current_status
                                                        }
                                                    </Button>
                                                )
                                        )
                                    }

                                    <Modal title="Change Status" visible={isStatusModalOpen === record?.id} onOk={(e) => handleOkStatus(record?.id)} onCancel={(e) => handleCancelStatus(record?.id)}>
                                        <Radio.Group onChange={(e) => onChangeRadio(e, record?.id)} value={getValue(record?.id)}>
                                            <Space direction="vertical" key={record?.id}>
                                                <Radio value="Under Review">Under Review</Radio>
                                                <Radio value="Zyvka Shortlisted">Zyvka Shortlisted</Radio>
                                                <Radio value="Zyvka Rejected">Zyvka Rejected</Radio>
                                                <Radio value="Client Shortlisted">Client Shortlisted</Radio>
                                                <Radio value="Client Rejected">Client Rejected</Radio>
                                                {statusOption.map((data, idx) => {
                                                    return (
                                                        <React.Fragment key={record?.id}>
                                                            <Radio value={data + " Selected"}>
                                                                {data + " Selected"}
                                                            </Radio>
                                                            <Radio value={data + " Rejected"}>
                                                                {data + " Rejected"}
                                                            </Radio>
                                                        </React.Fragment>
                                                    );
                                                })}
                                                <Radio value="Assignment Pending"> Assignment Pending </Radio>
                                                <Radio value="Assignment Rejected"> Assignment Rejected </Radio>
                                                <Radio value="Offer Discussion"> Offer Discussion </Radio>
                                                <Radio value="Offer rolled out"> Offer rolled out </Radio>
                                                <Radio value="Offer Accepted"> Offer Accepted </Radio>
                                                <Radio value="Offer Rejected"> Offer Rejected </Radio>
                                                <Radio value="Candidate Withdrawn"> Candidate Withdrawn </Radio>
                                                <Radio value="Didn't turn up for Drive"> Didn't turn up for Drive </Radio>
                                                <Radio value="Candidate Joined"> Candidate Joined </Radio>
                                                <Radio disabled={true} value="Candidate Resigned"> Candidate Resigned </Radio>
                                                <Radio value="Position Closed"> Position Closed </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Modal>
                                    <Modal
                                        title="Joining Details"
                                        visible={isModalOpen === record?.id}
                                        // onOk={handleOk}
                                        onCancel={handleCancel}
                                        footer={null}
                                    >
                                        <Form
                                            name="basic"
                                            onFinish={candidateJoinedStatusChangeHandler}
                                            onFinishFailed={candidateJoinedStatusFailed}
                                            autoComplete="off"
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",

                                                justifyContent: "center",
                                            }}
                                            labelCol={{
                                                span: 4,
                                            }}
                                            wrapperCol={{
                                                span: 20,
                                            }}
                                        >
                                            <Form.Item
                                                label="Salary"
                                                name="final_salary"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Salary Information Is Required",
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    style={{ width: "100%" }}
                                                    addonBefore={currencySelector}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                label="Joining Date"
                                                name="joining_date"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input joining date!",
                                                    },
                                                ]}
                                            >
                                                <DatePicker style={{ width: "100%" }} onChange={onChange} />
                                            </Form.Item>

                                            <Form.Item
                                                wrapperCol={{
                                                    offset: 0,
                                                    span: 24,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        width: "100%",

                                                        justifyContent: "space-between",
                                                        flexWrap: "wrap",
                                                    }}
                                                >
                                                    <Button type="danger" onClick={handleCancel}>
                                                        Cancel
                                                    </Button>
                                                    <Button type="primary" htmlType="submit">
                                                        Update Status
                                                    </Button>
                                                </div>
                                            </Form.Item>
                                        </Form>
                                    </Modal>

                                    {/* Reject MODAL */}
                                    <Modal
                                        title="Reject Reason"
                                        visible={isRejectModalOpen === record?.id}
                                        // onOk={handleOk}
                                        onCancel={rejectModalCancel}
                                        footer={null}
                                    >
                                        <Form
                                            name="basic"
                                            onFinish={onRejectModalFinish}
                                            onFinishFailed={onRejectModalFail}
                                            autoComplete="off"
                                        >
                                            <Form.Item
                                                label="Reason"
                                                name="reject_reason"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input reason for Rejection',
                                                    },
                                                ]}
                                            >
                                                <TextArea rows={4} />
                                            </Form.Item>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    width: "100%",

                                                    justifyContent: "space-between",
                                                    flexWrap: "wrap",
                                                }}
                                            >
                                                <Button type="danger" onClick={rejectModalCancel}>
                                                    Cancel
                                                </Button>
                                                <Button type="primary" htmlType="submit">
                                                    Submit Reason
                                                </Button>
                                            </div>
                                        </Form>
                                    </Modal>
                                </>
                            )}
                        />
                        {/* <Column
                            title="Remarks"
                            key="app_remark"
                            render={(_, record) => (
                                <>
                                    <Button primary type="dashed" icon={<EditOutlined />} onClick={() => remarkModalOpener(record?.id)}>
                                        Client Remarks
                                    </Button>
                                    <Modal
                                        title="Basic Modal"
                                        visible={remarkModal === record?.id}
                                        footer={null}
                                        onCancel={() => setRemarkModal(false)
                                        }
                                    >
                                        <div>
                                            <ul>
                                                {
                                                    remarkArray?.map((el) => (
                                                        <li>{el}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                        <Form
                                            name="basic"
                                            onFinish={(e) => remarkModalHandler(e.remark, record?.applicant?.id)}
                                            onFinishFailed={remarkModalFail}
                                            autoComplete="off"
                                        >
                                            <Form.Item
                                                label="Remark"
                                                name="remark"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input remark',
                                                    },
                                                ]}
                                            >
                                                <TextArea defaultValue={record?.client_remark} onChange={(e) => updateRecordRemark(record?.id, e.target.value)} rows={4} />
                                            </Form.Item>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    width: "100%",

                                                    justifyContent: "space-between",
                                                    flexWrap: "wrap",
                                                }}
                                            >
                                                <Button type="danger" onClick={() => setRemarkModal(false)}>
                                                    Cancel
                                                </Button>
                                                <Button type="primary" htmlType="submit">
                                                    Add another Remark
                                                </Button>
                                            </div>
                                        </Form>
                                    </Modal>

                                </>
                            )}
                        /> */}
                        <Column
                            title="Remarks"
                            key="app_remark"
                            render={(_, record) => (
                                <>
                                    <Button primary type="dashed" icon={<EditOutlined />} onClick={() => remarkModalOpener(record?.id)}>
                                        Client Remarks
                                    </Button>
                                    <Modal
                                        title="Remarks Modal"
                                        visible={remarkModal === record?.id}
                                        footer={null}
                                        onCancel={() => setRemarkModal(false)}
                                    >
                                        <div>
                                            <ul>
                                                {
                                                    record?.client_remark?.length > 0 ?
                                                        record?.client_remark.map((remark, index) => (
                                                            <li key={index}>{remark}</li>
                                                        )) : 'No remarks yet'
                                                }
                                            </ul>
                                        </div>
                                        <Form
                                            name="basic"
                                            onFinish={(values) => {
                                                remarkModalHandler(record?.client_remark, values?.newRemark, record?.applicantID);
                                                setRemarkModal(false);
                                            }}
                                            onFinishFailed={remarkModalFail}
                                            autoComplete="off"
                                        >
                                            <Form.Item
                                                label="New Remark"
                                                name="newRemark"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input a new remark',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Button type="primary" htmlType="submit">
                                                    Add Remark
                                                </Button>
                                            </div>
                                        </Form>
                                    </Modal>
                                </>
                            )}
                        />

                        <Column
                            title="Resume"
                            key="resume_download"
                            render={(_, record) => (
                                <a href={`${process.env.REACT_APP_SERVER_URL}/api/files/resume/${record?.applicant?.resume_urn}`} >
                                    <DownloadOutlined style={{ fontSize: "20px" }} />
                                </a>
                            )}
                        />
                    </Table>
                ) : (
                    "loading please wait"
                )
            }
        </div>
    );
};

export default ApplicantRemarks;

