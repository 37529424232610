import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Descriptions,
  Tag,
  Comment,
  List,
  Form,
  Input,
  Avatar,
  Image,
} from "antd";
import {
  VerticalAlignBottomOutlined,
  DownloadOutlined,
  UserOutlined,
  LinkedinFilled,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import axios from "axios";
import { UserState } from "../features/userSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AskQuery } from "../api/ZyvkaMember";
import { ResolveQuery } from "../api/auth";
import ReactTimeAgo from "react-time-ago";
import defaultUserImg from "../assets/images/user-icon.svg";
import { profilePicID, profilePicURL } from "../utils/functions";
import { ctcMap, CurrencySymbols } from "../Data/Currency";
//Comments
const { TextArea } = Input;

const CommentList = ({ comments }) => {
  // add reject reason 
  return (
    <List
      className="comment-list"
      header={`${comments.length} Queries`}
      itemLayout="horizontal"
      style={{ maxHeight: "700px", overflow: "scroll" }}
      dataSource={comments}
      renderItem={(item) => (
        <li style={{ borderBottom: "dashed 0.5px gray" }}>
          <Comment
            // actions={item.actions}
            author={item.author}
            avatar={
              <Avatar
                size={45}
                style={{ backgroundColor: "#6855f6" }}
                icon={
                  <Image
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    src={profilePicID(item.id)}
                    fallback={defaultUserImg}
                  />
                }
              />
            }
            content={<p>{item.content}</p>}
            datetime={item.datetime}
          />
        </li>
      )}
    />
  );
};
const Editor = ({
  onChange,
  onSubmit,
  submitting,
  value,
  userState,
  temp,
  applicant,
  onSubmit2,
}) => (
  <>
    <Form.Item>
      <TextArea
        rows={4}
        onChange={onChange}
        value={value}
        style={{ width: "95%" }}
      />
    </Form.Item>
    {/* <p>{JSON.stringify(applicant)}</p> */}
    <Form.Item>
      <Button
        htmlType="submit"
        loading={submitting}
        onClick={temp ? onSubmit2 : onSubmit}
        disabled={temp && applicant.current_status !== "Query Raised"}
        type="primary"
      >
        {(userState.role === "zyvkaAdmin" || userState.role === "zyvkaUser") &&
          temp === false
          ? "Ask query"
          : "Resolve Query"}
      </Button>
    </Form.Item>
  </>
);
//Comments data ends

const ViewApplicant = () => {
  const [applicant, setApplicant] = useState({});
  const userState = useSelector(UserState);
  const dispatch = useDispatch();
  const [flag, setFlag] = useState(false);
  const { applicationID } = useParams();

  //Comments
  const [comments, setComments] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState("");
  const [count, setCount] = useState();
  const fetchData = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/applicant/applicantdetails/${applicationID}`,
        { withCredentials: true }
      );

      setApplicant(result.data);
      let arr = [];
      result.data.query?.map((item, idx) => {
        // console.log(item);
        const tmp = JSON.parse(item);
        setComments([...comments, tmp]);
        // console.log(tmp);
        const content = tmp.content;
        arr.push({
          author: tmp.author,
          id: tmp.id,
          content: (
            <pre style={{ "white-space": "pre-wrap", fontFamily: "inherit" }}>
              {content}
            </pre>
          ),
          datetime: (
            <span>
              <ReactTimeAgo
                date={tmp.datetime}
                locale="en-US"
                timeStyle="facebook"
              />
            </span>
          ),
        });
      });

      await setCount(result.data?.query.length);
      await setComments(arr);
      console.log(comments);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitOpen = async () => {
    if (!value) return;
    setSubmitting(true);
    const data = {
      author:
        userState.userInfo?.firstName + " " + userState.userInfo?.lastName,
      id: userState.userInfo.id,
      content: value,
      datetime: new Date(),
    };

    const data1 = JSON.stringify(data);

    // console.log(data1 + " : " + id);
    await AskQuery(
      { query_msg: data1, applicantJobStatusID: applicationID },
      dispatch
    );
    setSubmitting(false);
    setValue("");
    setFlag((flag) => !flag);
  };
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  //Comments
  const handleSubmitResolve = async () => {
    if (!value) return;
    setSubmitting(true);
    const data = {
      author: userState.userInfo.firstName + " " + userState.userInfo.lastName,
      id: userState.userInfo.id,
      content: value,
      datetime: new Date(),
    };
    // console.log(data);
    const data1 = JSON.stringify(data);

    // console.log(data1 + " : " + id);
    await ResolveQuery(
      { query_msg: data1, applicantJobStatusID: applicationID },
      dispatch
    );
    setSubmitting(false);
    setValue("");
    setFlag((flag) => !flag);
  };

  useEffect(() => {
    fetchData();
  }, [flag]);

  const temp =
    userState.userInfo.id === applicant?.applicant?.creatorId &&
    userState.role === applicant?.applicant?.creatorRole;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        overflow: "scroll",
        marginTop: "10px",
      }}
    >
      <div
        style={{
          height: "95vh",
          overflow: "scroll",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Card
          title={
            applicant.applicant?.firstName +
            " " +
            applicant?.applicant?.lastName
          }
          headStyle={{ fontSize: "25px", fontWeight: "bold" }}
          style={{ borderRadius: "10px", maxWidth: "1000px", flex: "3" }}
          extra={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "20px",
              }}
            >
              <a href={applicant?.applicant?.linkedIn_URL} target="_blank" rel="noreferrer">
                <LinkedinFilled
                  style={{
                    fontSize: "40px",
                    color: "rgb(75,79,181)",
                    cursor: "pointer",
                  }}
                />
              </a>

              <a
                href={`${applicant?.applicant?.resume_urn?.split(" ").length > 1
                  ? applicant?.applicant?.resume_urn?.split(" ")[1]
                  : `${process.env.REACT_APP_SERVER_URL}/api/files/resume/${applicant?.applicant?.resume_urn}`
                  }`}
              >
                <Button type="primary" icon={<DownloadOutlined />}>
                  Download Resume
                </Button>
              </a>
            </div>
          }
        >
          <Descriptions layout="vertical" style={{ paddingLeft: "10px" }}>
            {/* <Descriptions.Item label="Experience">
            {applicant.experience} years
          </Descriptions.Item> */}
            <Descriptions.Item label="Current Salary">
              {`${applicant?.applicant?.salary_currency
                ? CurrencySymbols[applicant?.applicant?.salary_currency]
                : CurrencySymbols["INR"]
                } ${applicant?.applicant?.current_salary}${applicant?.applicant?.salary_currency
                  ? ctcMap[applicant?.applicant?.salary_currency]
                  : ctcMap["INR"]
                } P.A.`}
            </Descriptions.Item>
            <Descriptions.Item label="Expected Salary">
              {`${applicant?.applicant?.salary_currency
                ? CurrencySymbols[applicant?.applicant?.salary_currency]
                : CurrencySymbols["INR"]
                } ${applicant?.applicant?.expected_salary}${applicant?.applicant?.salary_currency
                  ? ctcMap[applicant?.applicant?.salary_currency]
                  : ctcMap["INR"]
                } P.A.`}

              {/* {applicant?.applicant?.expected_salary} Lacs per annum */}
            </Descriptions.Item>
            <Descriptions.Item label="Key Skills">
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  flexWrap: "wrap",
                  maxWidth: "200px",
                }}
              >
                {applicant.applicant?.key_skills?.length > 0 ? (
                  applicant.applicant?.key_skills?.map((data, idx) => {
                    return <Tag color="blue">{data.toUpperCase() + "  "}</Tag>;
                  })
                ) : (
                  <>Not Mentioned</>
                )}
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="Email">
              {applicant?.applicant?.email}
            </Descriptions.Item>
            <Descriptions.Item label="Remarks">
              {applicant?.applicant?.remarks}
            </Descriptions.Item>
            <Descriptions.Item label="Phone Number">
              {applicant?.applicant?.country_code}{" "}
              {applicant?.applicant?.mobileNum}
            </Descriptions.Item>

            <Descriptions.Item label="Candidate's Highlight">
              <pre style={{ "white-space": "pre-wrap" }}>
                {applicant?.applicant_story}
              </pre>
            </Descriptions.Item>
          </Descriptions>

          <br></br>
        </Card>
        <div style={{ flex: "2", paddingLeft: "20px" }}>
          <CommentList comments={comments} />
          {temp ? (
            <>
              <Comment
                content={
                  <Editor
                    onChange={handleChange}
                    onSubmit={handleSubmitOpen}
                    onSubmit2={handleSubmitResolve}
                    submitting={submitting}
                    value={value}
                    userState={userState}
                    applicant={applicant}
                    temp={temp}
                  />
                }
              />
            </>
          ) : (
            <>
              {" "}
              {count === 0 ? (
                (userState.role === "zyvkaAdmin" ||
                  userState.role === "zyvkaUser") && (
                  <Button
                    type="primary"
                    style={{ marginTop: "30px" }}
                    onClick={() => setCount((count) => count + 1)}
                  >
                    Ask first Query
                  </Button>
                )
              ) : (
                <Comment
                  content={
                    <Editor
                      onChange={handleChange}
                      onSubmit={handleSubmitOpen}
                      onSubmit2={handleSubmitResolve}
                      submitting={submitting}
                      value={value}
                      userState={userState}
                      applicant={applicant}
                      temp={temp}
                    />
                  }
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewApplicant;
