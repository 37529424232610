import axios from "axios";
import { readItemInLocalCache } from "../cache/localStorage";
import { defaultcCode } from "../Data/CountryCode";
import { defaultCurrency } from "../Data/Currency";
import {
  start,
  success,
  errors,
  logoutSucess,
  registersuccess,
  jobFetchSuccess,
  successGlobalSkills,
  successJobProfiles,
  queryFetchSuccess,
  qeuryResolvedSuccess,
  forgotPasswordSuccess,
  forgotPasswordError,
  fetchErrors,
  forgotResetPasswordSuccess,
  statsfetchErrror,
  countJobStatsSuccess,
  countHotJobsStatsSuccess,
  countAllApplicantStatsSuccess,
  countOffersClosedStatsSuccess,
  countQCAcceptedStatsSuccess,
  userUpdateError,
  userUpdateSuccess,
  getApplicantsDataForExcelStart,
  getApplicantsDataForExcelSuccess,
  getApplicantsDataForExcelError,
  createApplicantStart,
  createApplicantSuccess,
  createApplicantError,
  userUpdateStart,
  getApplicantByIDStart,
  getApplicantByIDSuccess,
  getApplicantByIDErrors,
  updateCandidateStart,
  updateCandidateError,
  updateCandidateSuccess,
  successGlobalcompany,
} from "../features/userSlice";
import {
  email,
  setEmail,
  setError,
  setLoading,
  setTableData,
} from "../features/clientSlice";
import { useSelector } from "react-redux";

const API = axios.create({ baseURL: process.env.REACT_APP_SERVER_URL });

export const registerUser = async (user, dispatch) => {
  dispatch(start());
  try {
    const result = await API.post("/api/auth/register", user, {
      withCredentials: true,
    });
    dispatch(registersuccess(result.data));
  } catch (error) {
    console.log(error);
    dispatch(errors());
  }
};
export const LoginUser = async (user, dispatch, navigate) => {
  dispatch(start());
  try {
    const result = await API.post("/api/auth/login", user, {
      withCredentials: true,
    });
    await dispatch(success(result.data));
    navigate("/dashboard");
  } catch (error) {
    console.log(error);
    dispatch(errors());
  }
};
export const LogOutUser = async (dispatch, navigate) => {
  dispatch(start());
  try {
    const res = await API.get("api/auth/logout", {
      withCredentials: true,
    });
    dispatch(logoutSucess());
    navigate("/login");
  } catch (error) {
    console.log(error);
    dispatch(errors());
  }
};

export const fetchAllSkills = async (dispatch, setSkillOption = (e) => {}) => {
  dispatch(start());
  try {
    const result = await API.get("/api/zyvka/getGlobalSkills", {
      withCredentials: true,
    });
    dispatch(successGlobalSkills(result.data));
    let tmp = [];
    result?.data?.forEach((data) => {
      tmp.push({
        key: data?.id,
        text: data.skill,
        value: data.skill,
      });
    });
    setSkillOption(tmp);
  } catch (error) {
    dispatch(fetchErrors());
    console.log(error);
  }
};
export const fetchAllCurrentComp = async (
  dispatch,
  setCompOption = (e) => {}
) => {
  dispatch(start());
  try {
    const result = await API.get(
      "/api/zyvka/getAllApplicantsCurrentCompanies",
      {
        withCredentials: true,
      }
    );
    dispatch(successGlobalcompany(result.data));
    let tmp = [];
    result?.data?.forEach((data) => {
      tmp.push({
        key: data?.id,
        text: data.current_company,
        value: data.current_company,
      });
    });
    setCompOption(tmp);
  } catch (error) {
    dispatch(fetchErrors());
    console.log(error);
  }
};

export const fetchALlPartnerCompanies = async () => {
  try {
    const result = await API.get("/api/zyvka/getPartnerCompanies", {
      withCredentials: true,
    });

    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllJobProfiles = async (dispatch) => {
  dispatch(start());
  try {
    const result = await API.get("/api/zyvka/getJobProfiles", {
      withCredentials: true,
    });
    // console.log(result.data);

    dispatch(successJobProfiles(result.data));
  } catch (error) {
    dispatch(fetchErrors());
    console.log(error);
  }
};
export const fetchAllSkillsAndProfiles = async (dispatch) => {
  dispatch(start());
  try {
    const result = await API.get("/api/zyvka/getJobProfiles", {
      withCredentials: true,
    });
    dispatch(successJobProfiles(result.data));
    const result1 = await API.get("/api/zyvka/getGlobalSkills", {
      withCredentials: true,
    });
    dispatch(successGlobalSkills(result1.data));
  } catch (error) {
    dispatch(fetchErrors());
    console.log(error);
  }
};

export const fetchQueries = async (dispatch) => {
  dispatch(start());
  try {
    const result = await API.get("/api/applicant/getQueriedApplicant", {
      withCredentials: true,
    });

    dispatch(queryFetchSuccess(result.data));
  } catch (error) {
    dispatch(fetchErrors());
    console.log(error);
  }
};
export const ResolveQuery = async (data, dispatch) => {
  dispatch(start());
  try {
    // console.log("data : " + data);
    const result = await API.post("/api/query/resolveQuery", data, {
      withCredentials: true,
    });

    dispatch(qeuryResolvedSuccess(result.data));
  } catch (error) {
    dispatch(errors());
    console.log(error);
  }
};

export const forgotPass = async (data, dispatch) => {
  dispatch(start());
  try {
    const result = await API.post("/api/auth/forgotPassword", data, {
      withCredentials: true,
    });

    dispatch(forgotPasswordSuccess(result.data));
  } catch (error) {
    dispatch(errors());
    console.log(error);
  }
};

export const forgotResetPassword = async (data, dispatch, navigate) => {
  dispatch(start());
  try {
    const result = await API.post(
      `/api/auth/resetPassword/${data.id}/${data.token}`,
      {
        newPassword: data.newPassword,
        confirmedPassword: data.confirmedPassword,
      },
      { withCredentials: true }
    );

    // console.log(result.data);
    dispatch(forgotResetPasswordSuccess());
    navigate("/login");
  } catch (error) {
    dispatch(errors());
  }
};

export const fetchJobCountStats = async (dispatch, refresh = false) => {
  let lastRefresh = new Date(readItemInLocalCache("stats.date"));
  let currentTime = new Date();

  const timeDifference = currentTime - lastRefresh;
  // Differnce between current time and the last refresh
  const minutesDiff = timeDifference / (1000 * 60);
  if (
    readItemInLocalCache("stats.jobs") === null ||
    minutesDiff > 10 ||
    refresh === true
  ) {
    dispatch(start());
    try {
      const result = await API.get("/api/stats/countJobs", {
        withCredentials: true,
      });
      dispatch(countJobStatsSuccess(result.data));
    } catch (error) {
      console.log(error);
      dispatch(statsfetchErrror(error.response));
    }
  } else {
  }
};
export const fetchHotJobCountStats = async (dispatch, refresh = false) => {
  let lastRefresh = new Date(readItemInLocalCache("stats.date"));
  let currentTime = new Date();

  const timeDifference = currentTime - lastRefresh;
  // Differnce between current time and the last refresh
  const minutesDiff = timeDifference / (1000 * 60);
  if (
    readItemInLocalCache("stats.hotJobs") === null ||
    minutesDiff > 10 ||
    refresh === true
  ) {
    dispatch(start());
    try {
      const result = await API.get("/api/stats/countHotJobs", {
        withCredentials: true,
      });
      dispatch(countHotJobsStatsSuccess(result.data._count.hotJob));
    } catch (error) {
      console.log(error);
      dispatch(statsfetchErrror(error.response));
    }
  }
};

export const fetchTotalCandidatesCountStats = async (
  dispatch,
  refresh = false
) => {
  let lastRefresh = new Date(readItemInLocalCache("stats.date"));
  let currentTime = new Date();
  const timeDifference = currentTime - lastRefresh;
  // Differnce between current time and the last refresh
  const minutesDiff = timeDifference / (1000 * 60);
  if (
    readItemInLocalCache("stats.AllApplicants") === null ||
    minutesDiff > 10 ||
    refresh === true
  ) {
    dispatch(start());
    let result;
    try {
      result = await API.get("/api/stats/countAllCandidates", {
        withCredentials: true,
      });
      dispatch(countAllApplicantStatsSuccess(result.data._count.id));
    } catch (error) {
      console.log(error);
      dispatch(statsfetchErrror(error.response));
    }
  }
};

export const fetchTotalOffersClosedCountStats = async (dispatch, refresh) => {
  let lastRefresh = new Date(readItemInLocalCache("stats.date"));
  let currentTime = new Date();
  const timeDifference = currentTime - lastRefresh;
  // Differnce between current time and the last refresh
  const minutesDiff = timeDifference / (1000 * 60);
  if (
    readItemInLocalCache("stats.closedOffers") === null ||
    minutesDiff > 10 ||
    refresh === true
  ) {
    dispatch(start());
    try {
      const result = await API.get("/api/stats/countOffersClosed", {
        withCredentials: true,
      });
      dispatch(
        countOffersClosedStatsSuccess(result.data._count.current_status)
      );
    } catch (error) {
      console.log(error);
      dispatch(statsfetchErrror(error.response));
    }
  }
};

export const fetchQCAcceptedClosedCountStats = async (dispatch, refresh) => {
  let lastRefresh = new Date(readItemInLocalCache("stats.date"));
  let currentTime = new Date();
  const timeDifference = currentTime - lastRefresh;
  // Differnce between current time and the last refresh
  const minutesDiff = timeDifference / (1000 * 60);
  if (
    readItemInLocalCache("stats.qcAccepted") === null ||
    minutesDiff > 10 ||
    refresh === true
  ) {
    dispatch(start());
    try {
      const result = await API.get("/api/stats/countQCAccepted", {
        withCredentials: true,
      });
      dispatch(countQCAcceptedStatsSuccess(result.data._count.current_status));
    } catch (error) {
      console.log(error);
      dispatch(statsfetchErrror(error.response));
    }
  }
};

export const updateUserProfile = async (data, dispatch, handleCancel) => {
  dispatch(userUpdateStart());
  try {
    const result = await API.post(`/api/auth/update/${data.id}`, data, {
      withCredentials: true,
    });
    // console.log(result.data);
    dispatch(userUpdateSuccess(result.data));

    handleCancel();
  } catch (error) {
    console.log(error);
    dispatch(userUpdateError(error.response));
  }
};

export const getApplicantsDataForExcel = async (dispatch) => {
  dispatch(getApplicantsDataForExcelStart());
  let result;
  try {
    result = await API.get("/api/csvData/getCandidateDataForCSV", {
      withCredentials: true,
    });
    dispatch(getApplicantsDataForExcelSuccess(result.data));
  } catch (error) {
    console.log(error);
    dispatch(getApplicantsDataForExcelError(error.response));
  }
};

export const updateProfilePicture = async (data, dispatch) => {
  dispatch(start());
  try {
    const result = await API.post(`/api/auth/uploadpfp`, data, {
      withCredentials: true,
    });
    // console.log(result.data);
    dispatch(userUpdateSuccess(result.data));
  } catch (error) {
    console.log(error);
    dispatch(userUpdateError());
  }
};

export const createApplicant = async (formData, dispatch, setFormData) => {
  dispatch(createApplicantStart());
  try {
    const result = await API.post(
      "/api/applicant/create",
      formData,
      { withCredentials: true },
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    dispatch(createApplicantSuccess(result.data));
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      countryCode: defaultcCode,
      mobileNum: "",
      key_skills: [],
      file: null,
      current_company: "",
      current_designation: "",
      salary_currency: defaultCurrency,
      current_ctc: 0,
      expected_ctc: 0,
      notice_period: 0,
      total_experience: 0,
      location: "",
      applicant_story: "",
      remarks: "",
      candidateSource: "",
      candidateSourceOther: "",
      linkedIn_URL: "",
      ctc_negotiable: false,
    });
    return result;
  } catch (error) {
    console.log(error);
    dispatch(createApplicantError(error.response));
  }
};

export const referNewApplicant = async (formData, dispatch, setFormData) => {
  dispatch(createApplicantStart());
  try {
    const result = await API.post(
      "/api/jobs/addApplicantForm",
      formData,
      { withCredentials: true },
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    dispatch(createApplicantSuccess(result.data));
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      countryCode: defaultcCode,
      mobileNum: "",
      key_skills: [],
      file: null,
      salary_currency: defaultCurrency,
      current_ctc: 0,
      expected_ctc: 0,
      notice_period: 0,
      total_experience: 0,
      location: "",
      applicant_story: "",
      current_company: "",
      current_designation: "",
      remarks: "",
      candidateSource: "",
      candidateSourceOther: "",
      linkedIn_URL: "",
      ctc_negotiable: false,
    });
    return result;
  } catch (error) {
    console.log(error);
    dispatch(createApplicantError(error.response));
  }
};

// company name added
export const getApplicantDetailsByID = async (
  data,
  dispatch,
  setApplicantDetails = (e) => {},
  setCandidateUpdateForm = (e) => {}
) => {
  dispatch(getApplicantByIDStart());
  try {
    const result = await API.get(`/api/applicant/${data?.id}`, {
      withCredentials: true,
    });
    console.log(result.data);
    setApplicantDetails(result?.data);
    setCandidateUpdateForm({
      firstName: result?.data?.firstName,
      lastName: result?.data?.lastName,
      mobileNum: result?.data?.mobileNum,
      email: result?.data?.email,
      location: result?.data?.location,
      countryCode: result?.data?.country_code,
      key_skills: result?.data?.key_skills,
      linkedIn_URL: result?.data?.linkedIn_URL || "",
      salary_currency: result?.data?.salary_currency,
      current_salary: result?.data?.current_salary,
      expected_salary: result?.data?.expected_salary,
      remarks: result?.data?.remarks || "",
      total_experience: result?.data?.total_experience,
      notice_period: result?.data?.notice_period,
      candidateSource: result?.data?.candidateSource,
      reject_reason: result?.data.reject_reason,
    });
    dispatch(getApplicantByIDSuccess(result?.data));
    return result.data;
  } catch (error) {
    console.log(error);
    dispatch(getApplicantByIDErrors(error.response));
  }
};

export const updateCanidateDetails = async (
  data,
  dispatch,
  setFlag = (e) => {}
) => {
  dispatch(updateCandidateStart());
  try {
    const result = await API.post(
      "/api/applicant/updateApplicantData",
      data,
      {
        withCredentials: true,
      },
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    dispatch(updateCandidateSuccess(result?.Data));
    setFlag((flag) => !flag);
  } catch (error) {
    dispatch(updateCandidateError(error.response));
  }
};

// ll
export const clientLoginAuth = async (data, dispatch, navigate) => {
  dispatch(setLoading());
  try {
    const result = await API.post(
      `/api/client/privillageAccess/${data.token}`,
      {
        email: data.email,
        password: data.password,
      },
      { withCredentials: true }
    );
    const res2 = await API.post(
      "api/client/privillageAccess/getCandidateData",
      {
        email: data.email,
      },
      {
        withCredentials: true,
      }
    );
    dispatch(setTableData(res2.data.quans));
    dispatch(setEmail(data.email));
    // console.log(result.data);
    // dispatch(forgotResetPasswordSuccess());
    navigate(`/applicantdetail/${res2.data.quans[0].jobID}`);
  } catch (error) {
    dispatch(setError());
  }
};




export const verifyApplicant = async (data) => {
  try {
    const result = await API.post(
      "/api/applicant/verifyDuplicateCandidate",
      {
        email: data?.email,
        mobileNum: data?.mobileNum
      },
      { withCredentials: true },
    );
    return result?.data
  } catch (error) {
    console.log(error);
  }
};

export const verifyApplicantForJob = async (data) => {
  try {
    const result = await API.post(
      "/api/applicant/verifyDuplicateCandidateForJob",
      {
        email: data?.email,
        mobileNum: data?.mobileNum,
        jobId: data?.jobId
      },
      { withCredentials: true },
    );
    return result?.data
  } catch (error) {
    console.log(error);
  }
};